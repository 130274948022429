import { useCallback, useMemo } from 'react';
import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { Currency } from 'commonTypes';
import { OrderDirection, AffiliateReportOrderBy } from 'generatedGraphql';
import { useGetPeriodOfStatistic } from '../../../hooks/useGetPeriodOfStatistic';
import { useGetCpaCompanyReportLazyQuery } from '../queries/generated/GetCpaCompanyReport';

const cellHeight = Heights.defaultCellHeight;

interface UseGetCpaCompanyReportParams {
  companyId: string;
  isAllTimeCpa: boolean;
}

export const useGetCpaCompanyReport = ({
  companyId,
  isAllTimeCpa,
}: UseGetCpaCompanyReportParams) => {
  const period = useGetPeriodOfStatistic();

  const [loadCpaCompanyReport, { data, loading, error, fetchMore, refetch }] =
    useGetCpaCompanyReportLazyQuery({
      variables: {
        first: getCountByWindowHeight(cellHeight, 0),
        currency: Currency.Usd,
        id: companyId,
        ordering: {
          orderBy: AffiliateReportOrderBy.AllDeposit,
          direction: OrderDirection.Desc,
        },
        period,
        showAllTimeCpa: isAllTimeCpa,
      },
    });

  const cpaReport = data?.company.cpaReport;
  const edges = cpaReport?.edges;
  const total = cpaReport?.total;
  const hasTotalRow = !!total;
  const endCursor = cpaReport?.pageInfo.endCursor;
  const hasNextPage = cpaReport?.pageInfo.hasNextPage;

  const cpaCompanyReportList = useMemo(() => {
    const report = edges?.map(({ node }) => node);

    if (hasTotalRow) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      report?.unshift(total);
    }

    return report;
  }, [edges, hasTotalRow, total]);

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: 10,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false }
      ),
    [endCursor, fetchMore]
  );

  return {
    data,
    cpaCompanyReportList,
    loadCpaCompanyReport,
    loading,
    error,
    refetch,
    fetchMoreCallback,
    hasNextPage,
    hasTotalRow,
    company: data?.company,
  };
};
