import { FC, useContext } from 'react';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import Block from 'ui/Block';
import { BlockContext, withBlockProvider } from 'ui/Block/store';
import SearchInput from 'ui/SearchInput';
import { COUNT_PER_PAGE } from 'ui/SearchInput/const';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import {
  SearchContextProvider,
  useSearchContext,
} from 'src/contexts/SearchContext';
import { PageTypeStorage } from 'src/utils/storage';
import { usePartnersList } from './hooks/usePartnersList';
import { PartnersListTable } from './components/PartnersListTable';
import {
  PARTNERS_SEARCH_INPUT_NAME,
  PARTNERS_SEARCH_PLACEHOLDER,
} from './const';

const PartnersList: FC = withBlockProvider(() => {
  const { state } = useContext(BlockContext);
  const { handleRefetchClick, partnersList, loadPartnersList } =
    usePartnersList();
  const { searchValue } = useSearchContext();

  const loadPartnersVariables = {
    variables: {
      search: searchValue,
      first: COUNT_PER_PAGE,
    },
  };

  const handleSubmit = () => {
    loadPartnersList(loadPartnersVariables);
  };

  return (
    <>
      <SearchInput
        name={PARTNERS_SEARCH_INPUT_NAME}
        placeholder={PARTNERS_SEARCH_PLACEHOLDER}
        onSubmit={handleSubmit}
      />
      <Block
        title="Партнёры"
        id="partnersList"
        headerContent={
          <ActionButton
            actionType={ButtonType.Update}
            hidden={!state.isOpened}
            disabled={state.isLoading}
            onClick={handleRefetchClick}
          />
        }
        shrinkLoaderWrapper={false}
        emptyText="Партнёры не найдены"
        isEmpty={isListEmpty(partnersList)}
        shouldReturnToPrevPage={false}
        isPermanentOpened
      >
        {hasList(partnersList) && (
          <PartnersListTable partnersList={partnersList} />
        )}
      </Block>
    </>
  );
});

export default () => (
  <SearchContextProvider pageType={PageTypeStorage.PartnerPage}>
    <PartnersList />
  </SearchContextProvider>
);
