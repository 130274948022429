export enum PlayerExternalTransfersColumnId {
  Id = 'id',
  Affiliate = 'affiliate',
  Sum = 'sum',
  CreatedAt = 'createdAt',
}

export const mapExternalMoneyTransfersColumnIdToName: Record<
  PlayerExternalTransfersColumnId,
  string
> = {
  [PlayerExternalTransfersColumnId.Id]: 'ID',
  [PlayerExternalTransfersColumnId.Affiliate]: 'Отправитель',
  [PlayerExternalTransfersColumnId.Sum]: 'Сумма',
  [PlayerExternalTransfersColumnId.CreatedAt]: 'Создан',
};

export const blockColumnsIds = [
  PlayerExternalTransfersColumnId.Id,
  PlayerExternalTransfersColumnId.Affiliate,
  PlayerExternalTransfersColumnId.Sum,
  PlayerExternalTransfersColumnId.CreatedAt,
];

export const pageColumnsIds = [
  PlayerExternalTransfersColumnId.Id,
  PlayerExternalTransfersColumnId.Affiliate,
  PlayerExternalTransfersColumnId.Sum,
  PlayerExternalTransfersColumnId.CreatedAt,
];
