import { FC } from 'react';
import { withBlockProvider } from 'ui/Block/store';
import { useLocation } from 'react-router-dom';
import { useGetTransfersColumns } from './hooks/useGetTransfersColumns';
import TransfersTable from './components/TransfersTable';

interface TransfersBlockProps {
  id: string;
}

const TransfersBlock: FC<TransfersBlockProps> = ({ id: playerId }) => {
  const location = useLocation();
  const { columns } = useGetTransfersColumns(false);

  return (
    <TransfersTable
      playerId={playerId}
      columns={columns}
      routeToCustomPage={`${location.pathname}/transfers`}
      isFullScreenTable={false}
    />
  );
};

export default withBlockProvider(TransfersBlock);
