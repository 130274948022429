import { useCallback, useMemo } from 'react';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import {
  getCountByWindowHeight,
  Heights,
} from '../../../utils/getCountByWindowHeight';
import { useGetPartnersLazyQuery } from '../queries/generated/GetPartners';
import { SEARCH_INPUT_HEIGHT } from '../../../const';

const cellHeight = Heights.defaultCellHeight;
const COUNT_PER_PAGE = 10;

export const usePartnersList = () => {
  const [loadPartnersList, { data, loading, error, fetchMore, refetch }] =
    useGetPartnersLazyQuery({
      variables: {
        search: '',
        first: getCountByWindowHeight(cellHeight, SEARCH_INPUT_HEIGHT),
      },
    });

  useBlockComponentState({
    loadData: loadPartnersList,
    loading,
    error,
  });

  const partnersList = useMemo(
    () => data?.partners?.edges.map(({ node }) => node),
    [data?.partners?.edges]
  );

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: COUNT_PER_PAGE,
            after: data?.partners?.pageInfo.endCursor,
          },
        },
        { shouldShowErrorToast: false }
      ),
    [data?.partners?.pageInfo.endCursor, fetchMore]
  );

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage: data?.partners?.pageInfo.hasNextPage,
    isFullScreenTable: true,
  });

  const handleRefetchClick = () => {
    refetch();
  };

  return {
    handleRefetchClick,
    partnersList,
    loadPartnersList,
  };
};
