import { FC } from 'react';
import { withBlockProvider } from 'ui/Block/store';
import { useLocation } from 'react-router-dom';
import { useGetDepositsColumns } from './hooks/useGetDepositsColumns';
import DepositsTable from './DepositsTable/DepositsTable';

interface DepositsBlockProps {
  id: string;
}

const DepositsBlock: FC<DepositsBlockProps> = ({ id }) => {
  const location = useLocation();
  const { columns } = useGetDepositsColumns(false);

  return (
    <DepositsTable
      playerId={id}
      columns={columns}
      routeToCustomPage={`${location.pathname}/deposits`}
      isFullScreenTable={false}
    />
  );
};

export default withBlockProvider(DepositsBlock);
