import {
  getCountByWindowHeight,
  Heights,
} from '../../../../../../../utils/getCountByWindowHeight';
import { useGetCompanyReferralLinksBlockLazyQuery } from '../queries/generated/GetCompanyReferralLinksBlock';
import { useGetCompanyReferralLinksLazyQuery } from '../queries/generated/GetCompanyReferralLinks';

const COUNT_PER_PAGE = 10;
const cellHeight = Heights.defaultCellHeight;

export const useGetCompanyReferralLinksByTableSize = (
  companyId: string,
  isFullScreenTable: boolean
) => {
  const countPerPageByTableSize = isFullScreenTable
    ? getCountByWindowHeight(cellHeight)
    : COUNT_PER_PAGE;

  const currentUseGetCompanyReferralLinks = isFullScreenTable
    ? useGetCompanyReferralLinksLazyQuery
    : useGetCompanyReferralLinksBlockLazyQuery;

  const [
    loadCompanyReferralLinks,
    { data, loading, error, fetchMore, refetch },
  ] = currentUseGetCompanyReferralLinks({
    variables: {
      id: companyId,
      first: countPerPageByTableSize,
    },
  });

  return {
    loadCompanyReferralLinks,
    data,
    loading,
    error,
    fetchMore,
    refetch,
  };
};
