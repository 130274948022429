import NowrapCell from 'ui/Table/components/NowrapCell';
import mayBeNullCell from 'ui/Table/components/MayBeNullCell';
import { playerRankMap } from 'src/const';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { MultilineCellWithLink } from 'ui/Table/components/MultilineCellWithLink';
import { createColumnHelper } from '@tanstack/react-table';
import { ColumnsSource } from 'ui/Table/types';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { PlayerIdentityCellWithCopy } from '../../PlayerIdentityCellWithCopy';
import { PlayerListData } from '../../../queries/generated/PlayerListData';
import {
  columnsIds,
  mapPlayersListIdToName,
  PlayersListColumnId,
} from '../const';
import { getAffiliateInfo } from '../helpers';

const columnHelper = createColumnHelper<PlayerListData>();

const columnsSource: ColumnsSource<PlayerListData> = [
  columnHelper.accessor(PlayersListColumnId.Id, {
    id: PlayersListColumnId.Id,
    header: mapPlayersListIdToName[PlayersListColumnId.Id],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnHelper.accessor(PlayersListColumnId.Player, {
    id: PlayersListColumnId.Player,
    header: mapPlayersListIdToName[PlayersListColumnId.Player],
    cell: ({ row }) => {
      const { name, email, phone } = row.original;

      return (
        <PlayerIdentityCellWithCopy name={name} email={email} phone={phone} />
      );
    },
  }),
  columnHelper.accessor(PlayersListColumnId.Rank, {
    id: PlayersListColumnId.Rank,
    header: mapPlayersListIdToName[PlayersListColumnId.Rank],
    cell: ({ row }) => playerRankMap[row.original.rank],
  }),
  columnHelper.accessor(PlayersListColumnId.Country, {
    id: PlayersListColumnId.Country,
    header: mapPlayersListIdToName[PlayersListColumnId.Country],
  }),
  columnHelper.accessor(PlayersListColumnId.Affiliate, {
    id: PlayersListColumnId.Affiliate,
    header: mapPlayersListIdToName[PlayersListColumnId.Affiliate],
    cell: ({ row }) => {
      const { affiliate } = row.original;
      const { name, email, route } = getAffiliateInfo(affiliate);

      return mayBeNullCell(
        affiliate && name && (
          <MultilineCellWithLink
            isOpenInNewWindow
            title={name}
            route={route}
            subTitle={email}
          />
        ),
        '—'
      );
    },
  }),
  columnHelper.accessor(PlayersListColumnId.Note, {
    id: PlayersListColumnId.Note,
    header: mapPlayersListIdToName[PlayersListColumnId.Note],
  }),
  columnHelper.accessor(PlayersListColumnId.LastVisited, {
    id: PlayersListColumnId.LastVisited,
    header: mapPlayersListIdToName[PlayersListColumnId.LastVisited],
    cell: ({ row }) => {
      const { lastVisited } = row.original;

      return mayBeNullCell(
        lastVisited && (
          <NowrapCell>
            {formatDate(lastVisited, DateFormat.FullDate)}
          </NowrapCell>
        ),
        '—'
      );
    },
  }),
];

export const useGetPlayersListColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
