import { FC } from 'react';
import { withBlockProvider } from 'ui/Block/store';
import { useLocation } from 'react-router-dom';
import WithdrawalsTable from './components/WithdrawalsTable/WithdrawalsTable';
import { useGetWithdrawalsColumns } from './hooks/useGetWithdrawalsColumns';

interface WithdrawalsBlockProps {
  id: string;
}

const WithdrawalsBlock: FC<WithdrawalsBlockProps> = ({ id }) => {
  const location = useLocation();
  const { columns } = useGetWithdrawalsColumns(false);

  return (
    <WithdrawalsTable
      playerId={id}
      columns={columns}
      routeToCustomPage={`${location.pathname}/withdrawals`}
      isFullScreenTable={false}
    />
  );
};

export default withBlockProvider(WithdrawalsBlock);
