import { createColumnHelper } from '@tanstack/react-table';
import { ColumnsSource } from 'ui/Table/types';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { Route } from 'src/router/routes.const';
import { addPercentToValue } from 'src/utils/addPercentToValue';
import { formatNumberWithRuLocale } from 'src/utils/formatNumberWithRuLocale';
import { CpaGeneralReportData } from '../../../queries/generated/CpaGeneralReportData';
import {
  columnsIds,
  mapCpaGeneralReportColumnIdToName,
  CpaGeneralReportColumnId,
} from '../const';
import { STATISTIC_TOTAL_ROW_TITLE } from '../../../../../const';
import { AddTwoLinedSubTitle } from '../../../../AddTwoLinedSubTitle';
import { getAffiliateInfo } from '../../../../../helpers';
import { MultilineCellWithLinkWithPeriodQueryParams } from '../../../../MultilineCellWithLinkWithPeriodQueryParams';

const columnHelper = createColumnHelper<CpaGeneralReportData>();

const columnsSource: ColumnsSource<CpaGeneralReportData> = [
  columnHelper.accessor(CpaGeneralReportColumnId.Affiliate, {
    id: CpaGeneralReportColumnId.Affiliate,
    header:
      mapCpaGeneralReportColumnIdToName[CpaGeneralReportColumnId.Affiliate],
    cell: ({ row }) => {
      if (!row.original.affiliate) {
        return STATISTIC_TOTAL_ROW_TITLE;
      }

      const { id, name, email, phone, typeName } = getAffiliateInfo<
        CpaGeneralReportData['affiliate']
      >(row.original.affiliate);

      return (
        <MultilineCellWithLinkWithPeriodQueryParams
          id={id}
          title={name}
          subTitle={
            <AddTwoLinedSubTitle firstValue={email} secondValue={phone} />
          }
          route={
            typeName === 'Partner'
              ? Route.STATISTIC_CPA_PARTNER
              : Route.STATISTIC_CPA_COMPANY_PARTNERS
          }
        />
      );
    },
    enablePinning: true,
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(CpaGeneralReportColumnId.Hits, {
    id: CpaGeneralReportColumnId.Hits,
    header: mapCpaGeneralReportColumnIdToName[CpaGeneralReportColumnId.Hits],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.hits),
  }),
  columnHelper.accessor(CpaGeneralReportColumnId.Hosts, {
    id: CpaGeneralReportColumnId.Hosts,
    header: mapCpaGeneralReportColumnIdToName[CpaGeneralReportColumnId.Hosts],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.hosts),
  }),
  columnHelper.accessor(CpaGeneralReportColumnId.Registrations, {
    id: CpaGeneralReportColumnId.Registrations,
    header:
      mapCpaGeneralReportColumnIdToName[CpaGeneralReportColumnId.Registrations],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.registrations),
  }),
  columnHelper.accessor(CpaGeneralReportColumnId.FirstDeposits, {
    id: CpaGeneralReportColumnId.FirstDeposits,
    header:
      mapCpaGeneralReportColumnIdToName[CpaGeneralReportColumnId.FirstDeposits],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.firstDeposits),
  }),
  columnHelper.accessor(CpaGeneralReportColumnId.HostToRegConversion, {
    id: CpaGeneralReportColumnId.HostToRegConversion,
    header:
      mapCpaGeneralReportColumnIdToName[
        CpaGeneralReportColumnId.HostToRegConversion
      ],
    cell: ({ row }) =>
      addPercentToValue(row.original.marketingStatistic.hostToRegConversion),
  }),
  columnHelper.accessor(CpaGeneralReportColumnId.RegToDepConversion, {
    id: CpaGeneralReportColumnId.RegToDepConversion,
    header:
      mapCpaGeneralReportColumnIdToName[
        CpaGeneralReportColumnId.RegToDepConversion
      ],
    cell: ({ row }) =>
      addPercentToValue(row.original.marketingStatistic.regToDepConversion),
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(CpaGeneralReportColumnId.AllDeposit, {
    id: CpaGeneralReportColumnId.AllDeposit,
    header:
      mapCpaGeneralReportColumnIdToName[CpaGeneralReportColumnId.AllDeposit],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.allDeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaGeneralReportColumnId.AverageDeposit, {
    id: CpaGeneralReportColumnId.AverageDeposit,
    header:
      mapCpaGeneralReportColumnIdToName[
        CpaGeneralReportColumnId.AverageDeposit
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.averageDeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaGeneralReportColumnId.AllRedeposit, {
    id: CpaGeneralReportColumnId.AllRedeposit,
    header:
      mapCpaGeneralReportColumnIdToName[CpaGeneralReportColumnId.AllRedeposit],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.allRedeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaGeneralReportColumnId.Redeposits, {
    id: CpaGeneralReportColumnId.Redeposits,
    header:
      mapCpaGeneralReportColumnIdToName[CpaGeneralReportColumnId.Redeposits],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.redeposits),
  }),
  columnHelper.accessor(CpaGeneralReportColumnId.NewDeposit, {
    id: CpaGeneralReportColumnId.NewDeposit,
    header:
      mapCpaGeneralReportColumnIdToName[CpaGeneralReportColumnId.NewDeposit],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerRegisteredStatistic.newDeposit;

      return formatSumWithCurrency(amount, currency);
    },
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(CpaGeneralReportColumnId.Withdrawal, {
    id: CpaGeneralReportColumnId.Withdrawal,
    header:
      mapCpaGeneralReportColumnIdToName[CpaGeneralReportColumnId.Withdrawal],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.withdrawal;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaGeneralReportColumnId.NewWithdrawal, {
    id: CpaGeneralReportColumnId.NewWithdrawal,
    header:
      mapCpaGeneralReportColumnIdToName[CpaGeneralReportColumnId.NewWithdrawal],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerRegisteredStatistic.newWithdrawal;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaGeneralReportColumnId.NewProfit, {
    id: CpaGeneralReportColumnId.NewProfit,
    header:
      mapCpaGeneralReportColumnIdToName[CpaGeneralReportColumnId.NewProfit],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerRegisteredStatistic.newProfit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaGeneralReportColumnId.Hold, {
    id: CpaGeneralReportColumnId.Hold,
    header: mapCpaGeneralReportColumnIdToName[CpaGeneralReportColumnId.Hold],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.playerStatusStatistic.hold),
  }),
  columnHelper.accessor(CpaGeneralReportColumnId.Qualified, {
    id: CpaGeneralReportColumnId.Qualified,
    header:
      mapCpaGeneralReportColumnIdToName[CpaGeneralReportColumnId.Qualified],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.playerStatusStatistic.qualified),
  }),
  columnHelper.accessor(CpaGeneralReportColumnId.NotQualified, {
    id: CpaGeneralReportColumnId.NotQualified,
    header:
      mapCpaGeneralReportColumnIdToName[CpaGeneralReportColumnId.NotQualified],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.playerStatusStatistic.canceled),
  }),
  columnHelper.accessor(CpaGeneralReportColumnId.Approved, {
    id: CpaGeneralReportColumnId.Approved,
    header:
      mapCpaGeneralReportColumnIdToName[CpaGeneralReportColumnId.Approved],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.playerStatusStatistic.approved),
  }),
  columnHelper.accessor(CpaGeneralReportColumnId.Rejected, {
    id: CpaGeneralReportColumnId.Rejected,
    header:
      mapCpaGeneralReportColumnIdToName[CpaGeneralReportColumnId.Rejected],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.playerStatusStatistic.rejected),
  }),
  columnHelper.accessor(CpaGeneralReportColumnId.Paid, {
    id: CpaGeneralReportColumnId.Paid,
    header: mapCpaGeneralReportColumnIdToName[CpaGeneralReportColumnId.Paid],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.playerStatusStatistic.paid),
  }),
  columnHelper.accessor(CpaGeneralReportColumnId.Unpaid, {
    id: CpaGeneralReportColumnId.Unpaid,
    header: mapCpaGeneralReportColumnIdToName[CpaGeneralReportColumnId.Unpaid],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.playerStatusStatistic.unpaid),
  }),
  columnHelper.accessor(CpaGeneralReportColumnId.RevShare, {
    id: CpaGeneralReportColumnId.RevShare,
    header:
      mapCpaGeneralReportColumnIdToName[CpaGeneralReportColumnId.RevShare],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.playerStatusStatistic.revShare),
  }),
  columnHelper.accessor(CpaGeneralReportColumnId.NGR, {
    id: CpaGeneralReportColumnId.NGR,
    header: mapCpaGeneralReportColumnIdToName[CpaGeneralReportColumnId.NGR],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerStatistic.financialIndicators.ngr;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaGeneralReportColumnId.Bonus, {
    id: CpaGeneralReportColumnId.Bonus,
    header: mapCpaGeneralReportColumnIdToName[CpaGeneralReportColumnId.Bonus],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.bonus;

      return formatSumWithCurrency(amount, currency);
    },
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(CpaGeneralReportColumnId.Profit, {
    id: CpaGeneralReportColumnId.Profit,
    header: mapCpaGeneralReportColumnIdToName[CpaGeneralReportColumnId.Profit],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerStatistic.financialIndicators.profit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaGeneralReportColumnId.Upcoming, {
    id: CpaGeneralReportColumnId.Upcoming,
    header:
      mapCpaGeneralReportColumnIdToName[CpaGeneralReportColumnId.Upcoming],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.affiliateRewardStatistic.upcoming;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaGeneralReportColumnId.RewardPaid, {
    id: CpaGeneralReportColumnId.RewardPaid,
    header:
      mapCpaGeneralReportColumnIdToName[CpaGeneralReportColumnId.RewardPaid],
    cell: ({ row }) => {
      const { amount, currency } = row.original.affiliateRewardStatistic.paid;

      return formatSumWithCurrency(amount, currency);
    },
  }),
];

export const useGetCpaGeneralReportColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
