export type Falsy = undefined | null | '' | false | 0;

export interface Edge<T, D> {
  cursor: string;
  node: T;
  __typename: D;
}

export enum Currency {
  Usd = 'USD',
  Rub = 'RUB',
  Eur = 'EUR',
  Uah = 'UAH',
  Kzt = 'KZT',
  Brl = 'BRL',
  Try = 'TRY',
  Mxn = 'MXN',
  Pln = 'PLN',
  Huf = 'HUF',
  Bgn = 'BGN',
  Sek = 'SEK',
  Jpy = 'JPY',
  Krw = 'KRW',
  Aud = 'AUD',
  Nok = 'NOK',
  Ron = 'RON',
  Byn = 'BYN',
  Cad = 'CAD',
  Uzs = 'UZS',
  Azn = 'AZN',
  Xxx = 'XXX',
}

export enum PaymentProcessor {
  BTech = 'BTECH',
  Cjmm = 'CJMM',
  CoinsPaid = 'COINS_PAID',
  Ecommpay = 'ECOMMPAY',
  EcommpayLatAm = 'ECOMMPAY_LAT_AM',
  GateExpress = 'GATE_EXPRESS',
  Interkassa = 'INTERKASSA',
  Jeton = 'JETON',
  Maldopay = 'MALDOPAY',
  Mpay = 'MPAY',
  MuchBetter = 'MUCH_BETTER',
  Neteller = 'NETELLER',
  Paycos = 'PAYCOS',
  PaycosCard = 'PAYCOS_CARD',
  PaycosCode = 'PAYCOS_CODE',
  PaycosWebCode = 'PAYCOS_WEB_CARD',
  Piastrix = 'PIASTRIX',
  PiastrixTransfer = 'PIASTRIX_TRANSFER',
  Qiwi = 'QIWI',
  QPayments = 'QPAYMENTS',
  QpaymentsCardRu = 'QPAYMENTS.CARD_RU',
  QpaymentsCardWorld = 'QPAYMENTS.CARD_WORLD',
  RapidTransferSkrill = 'RAPID_TRANSFER_SKRILL',
  SkrillUsdEea = 'SKRILL.USD_EEA',
  SkrillUsdRow = 'SKRILL.USD_ROW',
  Tossacoin = 'TOSSACOIN',
  Unlimint = 'UNLIMINT',
  Xchange = 'XCHANGE',
  XchangeFundry = 'XCHANGE_FUNDRY',
}

export enum AccountEventGroupType {
  Bonus = 'BONUS',
  Game = 'GAME',
  Deposit = 'DEPOSIT',
  Jackpot = 'JACKPOT',
  MoneyTransfer = 'MONEY_TRANSFER',
  Tournament = 'TOURNAMENT',
  Withdrawal = 'WITHDRAWAL',
}

export enum DepositAccountEventType {
  Success = 'SUCCESS',
}

export enum WithdrawalAccountEventType {
  Created = 'CREATED',
  Cancelled = 'CANCELLED',
  Declined = 'DECLINED',
}

export enum GameAccountEventType {
  Win = 'WIN',
  Bet = 'BET',
}

export enum BonusAccountEventType {
  Activated = 'ACTIVATED',
  Win = 'WIN',
  Cancelled = 'CANCELLED',
  Expired = 'EXPIRED',
}

export enum MoneyTransferAccountEventType {
  Incoming = 'INCOMING',
}

export enum JackpotAccountEventType {
  Win = 'WIN',
}
export enum TournamentAccountEventType {
  Rebuy = 'REBUY',
  Win = 'WIN',
}

export enum AccountEventBalanceType {
  Billing = 'BILLING',
  Bonus = 'BONUS',
}

export enum BanStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Expired = 'EXPIRED',
}

export enum BonusTypeName {
  Cashback = 'CashbackBonus',
  FirstDeposit = 'FirstDepositBonus',
  Freespin = 'FreespinBonus',
  Gift = 'GiftBonus',
  PromoCode = 'PromoCodeBonus',
  PersonalBonus = 'PersonalBonus',
}

export enum AccountEventTypeName {
  GameAccountEvent = 'GameAccountEvent',
  BonusAccountEvent = 'BonusAccountEvent',
  TournamentAccountEvent = 'TournamentAccountEvent',
  JackpotAccountEvent = 'JackpotAccountEvent',
  DepositAccountEvent = 'DepositAccountEvent',
  WithdrawalAccountEvent = 'WithdrawalAccountEvent',
  MoneyTransferAccountEvent = 'MoneyTransferAccountEvent',
}

export enum RestrictionTypeName {
  ReceiveGift = 'ReceiveGiftRestriction',
  Withdrawal = 'WithdrawalRestriction',
  ChargeBack = 'ChargeBackRestriction',
  BadBank = 'BadBankRestriction',
}

export enum TournamentsTypeName {
  Cash = 'CashTournament',
  Freespin = 'FreespinTournament',
  X = 'XTournament',
}

export enum PlayerRank {
  Noob = 'NOOB',
  Gambler = 'GAMBLER',
  Bronze = 'BRONZE',
  Silver = 'SILVER',
  Gold = 'GOLD',
  Platinum = 'PLATINUM',
}

export enum PaymentMethodName {
  BankCard = 'BANK_CARD',
  Bitcoin = 'BITCOIN',
  Binance = 'BINANCE',
  BloomzedWallet = 'BLOOMZED_WALLET',
  Boleto = 'BOLETO',
  Cepbank = 'CEPBANK',
  Eparatr = 'EPARATR',
  Ethereum = 'ETHEREUM',
  Jeton = 'JETON',
  Litecoin = 'LITECOIN',
  Loterica = 'LOTERICA',
  Monetix = 'MONETIX',
  MuchBetter = 'MUCH_BETTER',
  Neteller = 'NETELLER',
  OnlineBrazilBanks = 'ONLINE_BRAZIL_BANKS',
  Piastrix = 'PIASTRIX',
  Pix = 'PIX',
  Qiwi = 'QIWI',
  RapidTransfer = 'RAPID_TRANSFER',
  Skrill = 'SKRILL',
  Tether = 'TETHER',
  TetherTRC20 = 'TETHER_TRC20',
  Tron = 'TRON',
  Webmoney = 'WEBMONEY',
  YandexMoney = 'YANDEX_MONEY',
}

export enum DepositPaymentMethodName {
  ApplePay = 'APPLE_PAY',
  BankCard = 'BANK_CARD',
  BankCardForm = 'BANK_CARD_FORM',
  Bitcoin = 'BITCOIN',
  Boleto = 'BOLETO',
  Bloomzed = 'BLOOMZED_WALLET',
  Binance = 'BINANCE',
  BinanceERC20 = 'BINANCE_ERC20',
  Cepbank = 'CEPBANK',
  Code = 'CODE',
  Jeton = 'JETON',
  FasterPaymentSystem = 'FASTER_PAYMENTS_SYSTEM',
  Ethereum = 'ETHEREUM',
  Pix = 'PIX',
  Eparatr = 'EPARATR',
  Hizliqr = 'HIZLIQR',
  Loterica = 'LOTERICA',
  Litecoin = 'LITECOIN',
  MexicoOnlineBanking = 'MEXICO_ONLINE_BANKING',
  Mkarekod = 'MKAREKOD',
  Mobile = 'MOBILE',
  MuchBetter = 'MUCH_BETTER',
  Monetix = 'MONETIX',
  Neteller = 'NETELLER',
  OnlineBrazilBanks = 'ONLINE_BRAZIL_BANKS',
  Oxxo = 'OXXO',
  P2p = 'P2P',
  Piastrix = 'PIASTRIX',
  RapidTransfer = 'RAPID_TRANSFER',
  Qiwi = 'QIWI',
  Tether = 'TETHER',
  TetherTRC20 = 'TETHER_TRC20',
  Tron = 'TRON',
  Skrill = 'SKRILL',
  Webmoney = 'WEBMONEY',
  YandexMoney = 'YANDEX_MONEY',
}

export enum AdminRole {
  RoleAnalyst = 'ROLE_ANALYST',
  RoleCashier = 'ROLE_CASHIER',
  RoleCashierLead = 'ROLE_CASHIER_LEAD',
  RoleCashierPlus = 'ROLE_CASHIER_PLUS',
  RoleContentManager = 'ROLE_CONTENT_MANAGER',
  RolePartnerSecurity = 'ROLE_PARTNER_SECURITY',
  RolePartnerSupport = 'ROLE_PARTNER_SUPPORT',
  RolePartnerSupportLead = 'ROLE_PARTNER_SUPPORT_LEAD',
  RolePartnerSupportPlus = 'ROLE_PARTNER_SUPPORT_PLUS',
  RolePaymentSystemManager = 'ROLE_PAYMENT_SYSTEM_MANAGER',
  RoleProviderManager = 'ROLE_PROVIDER_MANAGER',
  RoleSecurity = 'ROLE_SECURITY',
  RoleSecurityLead = 'ROLE_SECURITY_LEAD',
  RoleSuperAdmin = 'ROLE_SUPER_ADMIN',
  RoleSupport = 'ROLE_SUPPORT',
  RoleSupportLead = 'ROLE_SUPPORT_LEAD',
  RoleSupportLeadTournament = 'ROLE_SUPPORT_LEAD_TOURNAMENT',
  RoleSupportPlus = 'ROLE_SUPPORT_PLUS',
}

export enum PaymentProvider {
  ApplePayCoreEcommpayPaymentPage = 'APPLE_PAY_CORE_ECOMMPAY_PAYMENT_PAGE',
  BadBank = 'BAD_BANK',
  BinanceBTech = 'BINANCE_BTECH',
  BinanceCoinsPaidInvoiceless = 'BINANCE_COINS_PAID_INVOICELESS',
  BinanceERC20BTech = 'BINANCE_ERC20_BTECH',
  BitcoinBTech = 'BITCOIN_BTECH',
  BitcoinCoinsPaid = 'BITCOIN_COINS_PAID',
  BitcoinCoinsPaidInvoiceless = 'BITCOIN_COINS_PAID_INVOICELESS',
  BitcoinPiastrix = 'BITCOIN_PIASTRIX',
  BloomzedWalletEcommpayPaymentPage = 'BLOOMZED_WALLET_ECOMMPAY_PAYMENT_PAGE',
  BoletoEcommpayPaymentPage = 'BOLETO_ECOMMPAY_PAYMENT_PAGE',
  CardEcommpayPaymentPage = 'CARD_ECOMMPAY_PAYMENT_PAGE',
  CardPartnerEcommpayPaymentPage = 'CARD_PARTNER_ECOMMPAY_PAYMENT_PAGE',
  CardPaycosPaymentPage = 'CARD_PAYCOS_PAYMENT_PAGE',
  CardPaycosWebPaymentPage = 'CARD_PAYCOS_WEB_PAYMENT_PAGE',
  CardPiastrix = 'CARD_PIASTRIX',
  CardQpaymentsGate = 'CARD_QPAYMENTS_GATE',
  CepbankEcommpayPaymentPage = 'CEPBANK_ECOMMPAY_PAYMENT_PAGE',
  ChargeBack = 'CHARGEBACK',
  Cjmm = 'CJMM',
  CodePaycosPaymentPage = 'CODE_PAYCOS_PAYMENT_PAGE',
  EcommpayGate = 'ECOMMPAY_GATE',
  EparatrMaldopayPaymentPage = 'EPARATR_MALDOPAY_PAYMENT_PAGE',
  EthereumBTech = 'ETHEREUM_BTECH',
  EthereumCoinsPaid = 'ETHEREUM_COINS_PAID',
  EthereumCoinsPaidInvoiceless = 'ETHEREUM_COINS_PAID_INVOICELESS',
  EthereumPiastrix = 'ETHEREUM_PIASTRIX',
  FasterPaymentsSystemECommpayPaymentPage = 'FASTER_PAYMENTS_SYSTEM_ECOMMPAY_PAYMENT_PAGE',
  FasterPaymentsSystemPiastrix = 'FASTER_PAYMENTS_SYSTEM_PIASTRIX',
  GateExpress = 'GATE_EXPRESS',
  GateExpressPaymentPage = 'GATE_EXPRESS_PAYMENT_PAGE',
  HizliqrEcommpayPaymentPage = 'HIZLIQR_ECOMMPAY_PAYMENT_PAGE',
  Interkassa = 'INTERKASSA',
  Jeton = 'JETON',
  Kassify = 'KASSIFY',
  LiteCoinBTech = 'LITECOIN_BTECH',
  LiteCoinCoinsPaid = 'LITECOIN_COINS_PAID',
  LiteCoinCoinsPaidInvoiceless = 'LITECOIN_COINS_PAID_INVOICELESS',
  LotericaEcommpayPaymentPage = 'LOTERICA_ECOMMPAY_PAYMENT_PAGE',
  McQpaymentsGate = 'MC_QPAYMENTS_GATE',
  MexicoOnlineBankingEcommpayPaymentPage = 'MEXICO_ONLINE_BANKING_ECOMMPAY_PAYMENT_PAGE',
  MkarekodMaldopayPaymentPage = 'MKAREKOD_MALDOPAY_PAYMENT_PAGE',
  MobileEcommpayPaymentPage = 'MOBILE_ECOMMPAY_PAYMENT_PAGE',
  MonetixWalletEcommpayPaymentPage = 'MONETIX_WALLET_ECOMMPAY_PAYMENT_PAGE',
  MpayGate = 'MPAY_GATE',
  MpayPaymentPage = 'MPAY_PAYMENT_PAGE',
  MuchBetter = 'MUCH_BETTER',
  Neteller = 'NETELLER',
  OnlineBrazilBanksEcommpayPaymentPage = 'ONLINE_BRAZIL_BANKS_ECOMMPAY_PAYMENT_PAGE',
  OxxoEcommpayPaymentPage = 'OXXO_ECOMMPAY_PAYMENT_PAGE',
  P2pPaycosPaymentPage = 'P2P_PAYCOS_PAYMENT_PAGE',
  PaycosGate = 'PAYCOS_GATE',
  PaycosWebGate = 'PAYCOS_WEB_GATE',
  Piastrix = 'PIASTRIX',
  PixECommpayPaymentPage = 'PIX_ECOMMPAY_PAYMENT_PAGE',
  Qiwi = 'QIWI',
  QiwiEcommpayPaymentPage = 'QIWI_ECOMMPAY_PAYMENT_PAGE',
  QiwiKzEcommpayPaymentPage = 'QIWI_KZ_ECOMMPAY_PAYMENT_PAGE',
  QiwiPiastrix = 'QIWI_PIASTRIX',
  QpaymentsForm = 'QPAYMENTS_FORM',
  RapidTransferSkrill = 'RAPID_TRANSFER_SKRILL',
  Skrill = 'SKRILL',
  TetherBTech = 'TETHER_BTECH',
  TetherCoinsPaid = 'TETHER_COINS_PAID',
  TetherCoinsPaidInvoiceless = 'TETHER_COINS_PAID_INVOICELESS',
  TetherPiastrix = 'TETHER_PIASTRIX',
  TetherTRC20BTech = 'TETHER_TRC20_BTECH',
  TetherTRC20CoinsPaid = 'TETHER_TRC20_COINS_PAID',
  TetherTRC20CoinsPaidInvoiceless = 'TETHER_TRC20_COINS_PAID_INVOICELESS',
  Tossacoin = 'TOSSACOIN',
  TronBTech = 'TRON_BTECH',
  TronCoinsPaidInvoiceless = 'TRON_COINS_PAID_INVOICELESS',
  UnlimintGate = 'UNLIMINT_GATE',
  WebmoneyLightEcommpayPaymentPage = 'WEBMONEY_LIGHT_ECOMMPAY_PAYMENT_PAGE',
  XchangeGate = 'XCHANGE_GATE',
  YandexMoneyPiastrix = 'YANDEX_MONEY_PIASTRIX',
}

export enum Locale {
  de = 'de',
  en = 'en',
  en_CA = 'en-CA',
  es_MX = 'es-MX',
  fr_CA = 'fr-CA',
  kk = 'kk',
  pl = 'pl',
  pt_BR = 'pt-BR',
  ru = 'ru',
  tr = 'tr',
}

export enum RewardTariffTypeName {
  Cpa = 'CpaRewardTariff',
  RevShare = 'RevShareRewardTariff',
}

export enum RewardTariffType {
  Cpa = 'CPA',
  RevShare = 'REV_SHARE',
}

export enum MediaItemTypeName {
  ReferralLink = 'ReferralLinkMediaItem',
}

export enum MediaItemRewardTariffTypeName {
  RevShare = 'MediaItemRevShareRewardTariff',
  Cpa = 'MediaItemCpaRewardTariff',
}

export enum DepositFilterMember {
  DepositId = 'depositId',
  Status = 'status',
  MethodName = 'paymentMethodName',
  Provider = 'paymentProvider',
  AccountNumber = 'accountNumber',
}

export enum ContactForCommunicationType {
  Skype = 'SKYPE',
  Telegram = 'TELEGRAM',
  Whatsapp = 'WHATSAPP',
  Other = 'OTHER',
}

export enum CompanyApplicationStatus {
  Active = 'ACTIVE',
  Declined = 'DECLINED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
}

export enum WithdrawalFilterMember {
  WithdrawalId = 'withdrawalId',
  Status = 'status',
  MethodName = 'paymentMethodName',
}

export enum MoneyTransferType {
  Incoming = 'Incoming',
  Outgoing = 'Outgoing',
}

export enum MoneyTransferFilterMember {
  Type = 'type',
}

export enum OwnerTypeName {
  Partner = 'Partner',
  Company = 'Company',
  CompanyAffiliate = 'CompanyAffiliate',
}

export enum ReferralReviewResultTypeName {
  Success = 'SuccessReferralReviewResult',
  SuccessWithoutVerification = 'SuccessWithoutVerificationReferralReviewResult',
  Duplicate = 'DuplicatePaymentMethodReferralReviewResult',
  Note = 'MarkedNoteReferralReviewResult',
  Geolocation = 'GeolocationMismatchReferralReviewResult',
}

export enum AffiliateTypeName {
  Partner = 'Partner',
  Company = 'Company',
}
