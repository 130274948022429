import { nbsp } from 'src/const';

export enum RevShareReferralLinkReportColumnId {
  Player = 'player',
  FirstDeposit = 'firstDeposit',
  AllDeposit = 'playerStatistic.allDeposit',
  AllRedeposit = 'playerStatistic.allRedeposit',
  Redeposits = 'redeposits',
  IncomingMoneyTransfer = 'playerStatistic.incomingMoneyTransfer',

  Withdrawal = 'playerStatistic.withdrawal',
  DepositFee = 'playerStatistic.depositFee',
  WithdrawalFee = 'playerStatistic.withdrawalFee',
  AdminFee = 'adminFee',
  Bonus = 'playerStatistic.bonus',
  TournamentPrize = 'playerStatistic.tournamentPrize',
  Jackpot = 'playerStatistic.jackpot',
  NGR = 'playerStatistic.financialIndicators.ngr',

  Profit = 'playerStatistic.financialIndicators.profit',
  AffiliateUpcomingReward = 'affiliateUpcomingReward',
}

export const mapRevShareReferralLinkReportColumnIdToName: Record<
  RevShareReferralLinkReportColumnId,
  string
> = {
  [RevShareReferralLinkReportColumnId.Player]: 'Игрок',
  [RevShareReferralLinkReportColumnId.FirstDeposit]: 'Первый депозит',
  [RevShareReferralLinkReportColumnId.AllDeposit]: 'Депозиты',
  [RevShareReferralLinkReportColumnId.AllRedeposit]: 'Редепозиты',
  [RevShareReferralLinkReportColumnId.Redeposits]: 'Количество редепозитов',
  [RevShareReferralLinkReportColumnId.IncomingMoneyTransfer]:
    'Входящие переводы',

  [RevShareReferralLinkReportColumnId.Withdrawal]: 'Выводы',
  [RevShareReferralLinkReportColumnId.DepositFee]: `Комиссии за${nbsp}депозиты`,
  [RevShareReferralLinkReportColumnId.WithdrawalFee]: `Комиссии за${nbsp}выводы`,
  [RevShareReferralLinkReportColumnId.AdminFee]: 'Admin fee',
  [RevShareReferralLinkReportColumnId.Bonus]: 'Бонусы',
  [RevShareReferralLinkReportColumnId.TournamentPrize]: 'Призовые',
  [RevShareReferralLinkReportColumnId.Jackpot]: 'Джекпоты',
  [RevShareReferralLinkReportColumnId.NGR]: 'NGR',

  [RevShareReferralLinkReportColumnId.Profit]: 'Доход',
  [RevShareReferralLinkReportColumnId.AffiliateUpcomingReward]:
    'Вознаграждение',
};

export const columnsIds: Array<RevShareReferralLinkReportColumnId> = [
  RevShareReferralLinkReportColumnId.Player,
  RevShareReferralLinkReportColumnId.FirstDeposit,
  RevShareReferralLinkReportColumnId.AllDeposit,
  RevShareReferralLinkReportColumnId.AllRedeposit,
  RevShareReferralLinkReportColumnId.Redeposits,
  RevShareReferralLinkReportColumnId.IncomingMoneyTransfer,

  RevShareReferralLinkReportColumnId.Withdrawal,
  RevShareReferralLinkReportColumnId.DepositFee,
  RevShareReferralLinkReportColumnId.WithdrawalFee,
  RevShareReferralLinkReportColumnId.AdminFee,
  RevShareReferralLinkReportColumnId.Bonus,
  RevShareReferralLinkReportColumnId.TournamentPrize,
  RevShareReferralLinkReportColumnId.Jackpot,
  RevShareReferralLinkReportColumnId.NGR,

  RevShareReferralLinkReportColumnId.Profit,
  RevShareReferralLinkReportColumnId.AffiliateUpcomingReward,
];
