import NowrapCell from 'ui/Table/components/NowrapCell/NowrapCell';
import MultilineCellWithIcon from 'ui/Table/components/MultilineCellWithIcon';
import MayBeNullCell from 'ui/Table/components/MayBeNullCell';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { contactForCommunicationTypeMap } from 'src/const';
import { ColumnsSource } from 'ui/Table/types';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { createColumnHelper } from '@tanstack/react-table';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { CompanyApplicationData } from '../../../queries/generated/CompanyApplicationData';
import { getCompanyApplicationStatus } from '../../../helpers';
import {
  mapCompanyApplicationsColumnIdToName,
  CompanyApplicationsColumnId,
  columnsIds,
} from '../const';

const columnHelper = createColumnHelper<CompanyApplicationData>();

const columnsSource: ColumnsSource<CompanyApplicationData> = [
  columnHelper.accessor(CompanyApplicationsColumnId.Id, {
    id: CompanyApplicationsColumnId.Id,
    header:
      mapCompanyApplicationsColumnIdToName[CompanyApplicationsColumnId.Id],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnHelper.accessor(CompanyApplicationsColumnId.Company, {
    id: CompanyApplicationsColumnId.Company,
    header:
      mapCompanyApplicationsColumnIdToName[CompanyApplicationsColumnId.Company],
    cell: ({ row }) => {
      const { companyEmail, companyName } = row.original;

      return MultilineCellWithIcon({
        title: companyName,
        subTitle: companyEmail || null,
      });
    },
  }),
  columnHelper.accessor(CompanyApplicationsColumnId.ContactForCommunication, {
    id: CompanyApplicationsColumnId.ContactForCommunication,
    header:
      mapCompanyApplicationsColumnIdToName[
        CompanyApplicationsColumnId.ContactForCommunication
      ],
    cell: ({ row }) => {
      const { value, type } = row.original.contactForCommunication;

      return MultilineCellWithIcon({
        title: value,
        subTitle: contactForCommunicationTypeMap[type],
      });
    },
  }),
  columnHelper.accessor(CompanyApplicationsColumnId.Company, {
    id: CompanyApplicationsColumnId.WebSite,
    header:
      mapCompanyApplicationsColumnIdToName[CompanyApplicationsColumnId.WebSite],
    cell: ({ row }) => MayBeNullCell(row.original.website),
  }),
  columnHelper.accessor(CompanyApplicationsColumnId.TrafficSource, {
    id: CompanyApplicationsColumnId.TrafficSource,
    header:
      mapCompanyApplicationsColumnIdToName[
        CompanyApplicationsColumnId.TrafficSource
      ],
  }),
  columnHelper.accessor(CompanyApplicationsColumnId.Status, {
    id: CompanyApplicationsColumnId.Status,
    header:
      mapCompanyApplicationsColumnIdToName[CompanyApplicationsColumnId.Status],
    cell: ({ row }) => {
      const { status, admin, declineReason } = row.original;
      const [title, iconConfig, subTitle] = getCompanyApplicationStatus(
        status,
        admin,
        declineReason
      );

      return MultilineCellWithIcon({ title, iconConfig, subTitle });
    },
  }),
  columnHelper.accessor(CompanyApplicationsColumnId.CreatedAt, {
    id: CompanyApplicationsColumnId.CreatedAt,
    header:
      mapCompanyApplicationsColumnIdToName[
        CompanyApplicationsColumnId.CreatedAt
      ],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.DefaultDateOld)}
      </NowrapCell>
    ),
  }),
  columnHelper.accessor(CompanyApplicationsColumnId.UpdatedAt, {
    id: CompanyApplicationsColumnId.UpdatedAt,
    header:
      mapCompanyApplicationsColumnIdToName[
        CompanyApplicationsColumnId.UpdatedAt
      ],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.updatedAt, DateFormat.DefaultDateOld)}
      </NowrapCell>
    ),
  }),
];

export const useGetCompanyApplicationsColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
