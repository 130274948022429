import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { CompanyReferralLinkMediaItemTable } from './components/CompanyReferralLinkMediaItemTable';
import { useGetCompanyReferralLinksColumns } from './components/CompanyReferralLinkMediaItemTable/hooks/useGetCompanyReferralLinksColumns';

interface CompanyMediaItemsBlockProps {
  id: string;
}

export const CompanyMediaItemsBlock: FC<CompanyMediaItemsBlockProps> = ({
  id,
}) => {
  const location = useLocation();
  const { columns } = useGetCompanyReferralLinksColumns(false);

  return (
    <CompanyReferralLinkMediaItemTable
      companyId={id}
      columns={columns}
      routeToCustomPage={`${location.pathname}/media-items`}
      isFullScreenTable={false}
    />
  );
};
