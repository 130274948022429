import NowrapCell from 'ui/Table/components/NowrapCell/NowrapCell';
import MultilineCellWithIcon from 'ui/Table/components/MultilineCellWithIcon';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { contactForCommunicationTypeMap } from 'src/const';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { createColumnHelper } from '@tanstack/react-table';
import { ColumnsSource } from 'ui/Table/types';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { getPartnerApplicationStatus } from '../../../helpers';
import { PartnerApplicationData } from '../../../queries/generated/PartnerApplicationData';
import {
  columnsIds,
  mapPartnerApplicationsColumnIdColumnToName,
  PartnerApplicationsColumnId,
} from '../const';

const columnHelper = createColumnHelper<PartnerApplicationData>();

const columnsSource: ColumnsSource<PartnerApplicationData> = [
  columnHelper.accessor(PartnerApplicationsColumnId.Id, {
    id: PartnerApplicationsColumnId.Id,
    header:
      mapPartnerApplicationsColumnIdColumnToName[
        PartnerApplicationsColumnId.Id
      ],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnHelper.accessor(PartnerApplicationsColumnId.PartnerInfo, {
    id: PartnerApplicationsColumnId.PartnerInfo,
    header:
      mapPartnerApplicationsColumnIdColumnToName[
        PartnerApplicationsColumnId.PartnerInfo
      ],
    cell: ({ row }) => {
      const { name, email } = row.original.partner;

      return MultilineCellWithIcon({
        title: name,
        subTitle: email || null,
      });
    },
  }),
  columnHelper.accessor(PartnerApplicationsColumnId.ContactForCommunication, {
    id: PartnerApplicationsColumnId.ContactForCommunication,
    header:
      mapPartnerApplicationsColumnIdColumnToName[
        PartnerApplicationsColumnId.ContactForCommunication
      ],
    cell: ({ row }) => {
      const { contactForCommunication } = row.original;

      return MultilineCellWithIcon({
        title: contactForCommunication.value,
        subTitle: contactForCommunicationTypeMap[contactForCommunication.type],
      });
    },
  }),
  columnHelper.accessor(PartnerApplicationsColumnId.TrafficSource, {
    id: PartnerApplicationsColumnId.TrafficSource,
    header:
      mapPartnerApplicationsColumnIdColumnToName[
        PartnerApplicationsColumnId.TrafficSource
      ],
  }),
  columnHelper.accessor(PartnerApplicationsColumnId.Status, {
    id: PartnerApplicationsColumnId.Status,
    header:
      mapPartnerApplicationsColumnIdColumnToName[
        PartnerApplicationsColumnId.Status
      ],
    cell: ({ row }) => {
      const { status, admin, declineReason } = row.original;
      const [title, iconConfig, subTitle] = getPartnerApplicationStatus(
        status,
        admin,
        declineReason
      );

      return MultilineCellWithIcon({ title, iconConfig, subTitle });
    },
  }),
  columnHelper.accessor(PartnerApplicationsColumnId.CreatedAt, {
    id: PartnerApplicationsColumnId.CreatedAt,
    header:
      mapPartnerApplicationsColumnIdColumnToName[
        PartnerApplicationsColumnId.CreatedAt
      ],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
  columnHelper.accessor(PartnerApplicationsColumnId.UpdatedAt, {
    id: PartnerApplicationsColumnId.UpdatedAt,
    header:
      mapPartnerApplicationsColumnIdColumnToName[
        PartnerApplicationsColumnId.UpdatedAt
      ],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.updatedAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
];

export const useGetPartnerApplicationsColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
