import NowrapCell from 'ui/Table/components/NowrapCell';
import { CopyTableRow } from 'ui/CopyTableRow';
import MultilineCellWithIcon from 'ui/Table/components/MultilineCellWithIcon';
import { promoCodeUsageTypeMap } from 'src/const';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import MayBeNullCell from 'ui/Table/components/MayBeNullCell';
import { MediaItemPromoCodeBasicData } from 'src/queries/generated/MediaItemPromoCodeBasicData';
import { ColumnsSource } from 'ui/Table/types';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { createColumnHelper } from '@tanstack/react-table';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { ProgressBarWithLimit } from '../components/ProgressBarWithLimit';
import {
  MediaItemsPromoCodeColumnId,
  mapMediaItemsPromoCodeIdToName,
  columnsIds,
} from '../const';

const columnHelper = createColumnHelper<MediaItemPromoCodeBasicData>();

const columnsSource: ColumnsSource<MediaItemPromoCodeBasicData> = [
  columnHelper.accessor(MediaItemsPromoCodeColumnId.Id, {
    id: MediaItemsPromoCodeColumnId.Id,
    header: mapMediaItemsPromoCodeIdToName[MediaItemsPromoCodeColumnId.Id],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnHelper.accessor(MediaItemsPromoCodeColumnId.Token, {
    id: MediaItemsPromoCodeColumnId.Token,
    header: mapMediaItemsPromoCodeIdToName[MediaItemsPromoCodeColumnId.Token],
  }),
  columnHelper.accessor(MediaItemsPromoCodeColumnId.Activations, {
    id: MediaItemsPromoCodeColumnId.Activations,
    header:
      mapMediaItemsPromoCodeIdToName[MediaItemsPromoCodeColumnId.Activations],
    cell: ({ row }) => {
      const { activation } = row.original.promoCode;

      return MayBeNullCell(
        activation && (
          <ProgressBarWithLimit
            activated={activation.activated}
            limit={activation.limit}
          />
        ),
        '—'
      );
    },
  }),
  columnHelper.accessor(MediaItemsPromoCodeColumnId.MediaCampaign, {
    id: MediaItemsPromoCodeColumnId.MediaCampaign,
    header:
      mapMediaItemsPromoCodeIdToName[MediaItemsPromoCodeColumnId.MediaCampaign],
    cell: ({ row }) => {
      const { name, url } = row.original.mediaCampaign;

      return <MultilineCellWithIcon title={name} subTitle={url} />;
    },
  }),
  columnHelper.accessor(MediaItemsPromoCodeColumnId.UsageType, {
    id: MediaItemsPromoCodeColumnId.UsageType,
    header:
      mapMediaItemsPromoCodeIdToName[MediaItemsPromoCodeColumnId.UsageType],
    cell: ({ row }) => promoCodeUsageTypeMap[row.original.usageType],
  }),
  columnHelper.accessor(MediaItemsPromoCodeColumnId.ReferralLink, {
    id: MediaItemsPromoCodeColumnId.ReferralLink,
    header:
      mapMediaItemsPromoCodeIdToName[MediaItemsPromoCodeColumnId.ReferralLink],
    cell: ({ row }) => {
      const { referralLink } = row.original;

      return MayBeNullCell(
        referralLink && (
          <CopyTableRow
            value={referralLink.referralLink}
            toastText="Реферальная ссылка скопирована"
          />
        ),
        '—'
      );
    },
  }),
  columnHelper.accessor(MediaItemsPromoCodeColumnId.CreatedAt, {
    id: MediaItemsPromoCodeColumnId.CreatedAt,
    header:
      mapMediaItemsPromoCodeIdToName[MediaItemsPromoCodeColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.DefaultDateOld)}
      </NowrapCell>
    ),
  }),
  columnHelper.accessor(MediaItemsPromoCodeColumnId.UpdatedAt, {
    id: MediaItemsPromoCodeColumnId.UpdatedAt,
    header:
      mapMediaItemsPromoCodeIdToName[MediaItemsPromoCodeColumnId.UpdatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.updatedAt, DateFormat.DefaultDateOld)}
      </NowrapCell>
    ),
  }),
];

export const useGetMediaItemsPromoCodeColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
