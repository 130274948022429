import { ClientData } from 'commonComponents/ClientDataBlock/types';
import { ClientDataBlockRowConfirm } from 'commonComponents/ClientDataBlockRowConfirm/ClientDataBlockRowConfirm';
import { getDisplayName } from 'src/utils/getDisplayName';
import capitalize from 'src/utils/capitalize';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { nbsp } from 'src/const';
import { AffiliatePlayerAccountStatus } from 'generatedGraphql';
import { GetPartnerAdditionalData } from './queries/generated/GetPartnerAdditionalData';
import { PlayerAccountManagement } from './components/PlayerAccountManagement';
import { getDateConfirmed } from '../../../../../tests/utils';

interface GetPartnerDataConfigParams {
  data?: GetPartnerAdditionalData;
  onPartnerIdentitiesEdit: () => void;
  onPlayerAccountEdit: () => void;
  isEditButtonVisible: boolean;
  hasPrivilegeToDeclinePlayerAccount: boolean;
}

export const getPartnerDataConfig = ({
  data,
  onPartnerIdentitiesEdit,
  onPlayerAccountEdit,
  isEditButtonVisible,
  hasPrivilegeToDeclinePlayerAccount,
}: GetPartnerDataConfigParams): ClientData | null => {
  if (!data) return null;

  const {
    partner: {
      id,
      phone,
      email,
      createdAt,
      lastVisited,
      emailConfirmedAt,
      locale,
      trafficSource,
      phoneConfirmedAt,
      contactForCommunication: { contact, method },
      playerAccount,
    },
  } = data;

  const displayName = locale && getDisplayName({ value: locale });

  const capitalizedDisplayName = displayName
    ?.split(' ')
    .map(capitalize)
    .join(' ');

  const rowsList: ClientData = [
    {
      title: 'Данные входа',
      isEditButtonVisible,
      onEdit: onPartnerIdentitiesEdit,
      rows: [
        {
          title: 'Почта',
          value: email,
          alignRightValue: email && (
            <ClientDataBlockRowConfirm
              confirmation={getDateConfirmed(
                emailConfirmedAt,
                'Не подтверждена'
              )}
            />
          ),
        },
        {
          title: 'Телефон',
          value: phone,
          alignRightValue: phone && (
            <ClientDataBlockRowConfirm
              confirmation={getDateConfirmed(
                phoneConfirmedAt,
                'Не подтверждена'
              )}
            />
          ),
        },
        {
          title: 'Дата регистрации',
          value: createdAt && formatDate(createdAt, DateFormat.DefaultDateOld),
        },
        {
          title: 'Последний вход',
          value: lastVisited && formatDate(lastVisited, DateFormat.FullDate),
        },
      ],
    },
    {
      title: 'Персональные данные',
      rows: [
        {
          title: 'Метод связи',
          value: method,
        },
        {
          title: `Контакт${nbsp}для${nbsp}связи`,
          value: contact,
        },
        {
          title: 'Язык',
          value: locale && capitalizedDisplayName,
        },
        {
          title: 'Источник трафика',
          value: trafficSource,
        },
      ],
    },
  ];

  if (playerAccount) {
    const isEditPlayerAccountButtonVisible =
      playerAccount.status === AffiliatePlayerAccountStatus.Approved &&
      hasPrivilegeToDeclinePlayerAccount;

    rowsList.push({
      title: 'Игровой аккаунт',
      isEditButtonVisible: isEditPlayerAccountButtonVisible,
      onEdit: onPlayerAccountEdit,
      rows: [
        {
          title: 'Аккаунт',
          value: (
            <PlayerAccountManagement
              playerAccount={playerAccount}
              partnerId={id}
            />
          ),
        },
      ],
    });
  }

  return rowsList;
};
