export enum PartnerMediaCampaignsColumnId {
  Id = 'id',
  Title = 'name',
  Url = 'url',
  CreatedAt = 'createdAt',
}

export const mapPartnerMediaCampaignsColumnIdToName: Record<
  PartnerMediaCampaignsColumnId,
  string
> = {
  [PartnerMediaCampaignsColumnId.Id]: 'ID',
  [PartnerMediaCampaignsColumnId.Title]: 'Название',
  [PartnerMediaCampaignsColumnId.Url]: 'URL',
  [PartnerMediaCampaignsColumnId.CreatedAt]: 'Создан',
};

export const columnsIds = [
  PartnerMediaCampaignsColumnId.Id,
  PartnerMediaCampaignsColumnId.Title,
  PartnerMediaCampaignsColumnId.Url,
  PartnerMediaCampaignsColumnId.CreatedAt,
];
