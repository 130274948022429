import { formatSumWithCurrency } from 'src/utils/accounting';
import { ColumnsSource } from 'ui/Table/types';
import { createColumnHelper } from '@tanstack/react-table';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { addPercentToValue } from 'src/utils/addPercentToValue';
import { formatNumberWithRuLocale } from 'src/utils/formatNumberWithRuLocale';
import { Route } from 'src/router/routes.const';
import {
  columnsIds,
  RevShareCompanyAffiliateReportColumnId,
  mapRevShareCompanyAffiliateReportColumnIdToName,
} from '../const';
import { STATISTIC_TOTAL_ROW_TITLE } from '../../../../../const';
import { MultilineCellWithLinkWithPeriodQueryParams } from '../../../../../components/MultilineCellWithLinkWithPeriodQueryParams';
import { RevShareCompanyAffiliateReportData } from '../../../queries/generated/RevShareCompanyAffiliateReportData';

const columnHelper = createColumnHelper<RevShareCompanyAffiliateReportData>();

const columnsSource: ColumnsSource<RevShareCompanyAffiliateReportData> = [
  columnHelper.accessor(RevShareCompanyAffiliateReportColumnId.ReferralCode, {
    id: RevShareCompanyAffiliateReportColumnId.ReferralCode,
    header:
      mapRevShareCompanyAffiliateReportColumnIdToName[
        RevShareCompanyAffiliateReportColumnId.ReferralCode
      ],
    cell: ({ row }) => {
      if (!row.original.mediaItem) {
        return STATISTIC_TOTAL_ROW_TITLE;
      }

      const { id, name } = row.original.mediaItem;

      return (
        <MultilineCellWithLinkWithPeriodQueryParams
          id={id}
          title={name}
          subTitle={addHashTagToId(id)}
          route={Route.STATISTIC_REV_SHARE_REFERRAL_LINK}
        />
      );
    },
    enablePinning: true,
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(RevShareCompanyAffiliateReportColumnId.Hits, {
    id: RevShareCompanyAffiliateReportColumnId.Hits,
    header:
      mapRevShareCompanyAffiliateReportColumnIdToName[
        RevShareCompanyAffiliateReportColumnId.Hits
      ],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.hits),
  }),
  columnHelper.accessor(RevShareCompanyAffiliateReportColumnId.Hosts, {
    id: RevShareCompanyAffiliateReportColumnId.Hosts,
    header:
      mapRevShareCompanyAffiliateReportColumnIdToName[
        RevShareCompanyAffiliateReportColumnId.Hosts
      ],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.hosts),
  }),
  columnHelper.accessor(RevShareCompanyAffiliateReportColumnId.Registrations, {
    id: RevShareCompanyAffiliateReportColumnId.Registrations,
    header:
      mapRevShareCompanyAffiliateReportColumnIdToName[
        RevShareCompanyAffiliateReportColumnId.Registrations
      ],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.registrations),
  }),
  columnHelper.accessor(RevShareCompanyAffiliateReportColumnId.FirstDeposits, {
    id: RevShareCompanyAffiliateReportColumnId.FirstDeposits,
    header:
      mapRevShareCompanyAffiliateReportColumnIdToName[
        RevShareCompanyAffiliateReportColumnId.FirstDeposits
      ],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.firstDeposits),
  }),
  columnHelper.accessor(
    RevShareCompanyAffiliateReportColumnId.HostToRegConversion,
    {
      id: RevShareCompanyAffiliateReportColumnId.HostToRegConversion,
      header:
        mapRevShareCompanyAffiliateReportColumnIdToName[
          RevShareCompanyAffiliateReportColumnId.HostToRegConversion
        ],
      cell: ({ row }) =>
        addPercentToValue(row.original.marketingStatistic.hostToRegConversion),
    }
  ),
  columnHelper.accessor(
    RevShareCompanyAffiliateReportColumnId.RegToDepConversion,
    {
      id: RevShareCompanyAffiliateReportColumnId.RegToDepConversion,
      header:
        mapRevShareCompanyAffiliateReportColumnIdToName[
          RevShareCompanyAffiliateReportColumnId.RegToDepConversion
        ],
      cell: ({ row }) =>
        addPercentToValue(row.original.marketingStatistic.regToDepConversion),
      meta: {
        hasRightBorder: true,
      },
    }
  ),
  columnHelper.accessor(RevShareCompanyAffiliateReportColumnId.AllDeposit, {
    id: RevShareCompanyAffiliateReportColumnId.AllDeposit,
    header:
      mapRevShareCompanyAffiliateReportColumnIdToName[
        RevShareCompanyAffiliateReportColumnId.AllDeposit
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.allDeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyAffiliateReportColumnId.AverageDeposit, {
    id: RevShareCompanyAffiliateReportColumnId.AverageDeposit,
    header:
      mapRevShareCompanyAffiliateReportColumnIdToName[
        RevShareCompanyAffiliateReportColumnId.AverageDeposit
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.averageDeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyAffiliateReportColumnId.AllRedeposit, {
    id: RevShareCompanyAffiliateReportColumnId.AllRedeposit,
    header:
      mapRevShareCompanyAffiliateReportColumnIdToName[
        RevShareCompanyAffiliateReportColumnId.AllRedeposit
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.allRedeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyAffiliateReportColumnId.Redeposits, {
    id: RevShareCompanyAffiliateReportColumnId.Redeposits,
    header:
      mapRevShareCompanyAffiliateReportColumnIdToName[
        RevShareCompanyAffiliateReportColumnId.Redeposits
      ],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.redeposits),
  }),
  columnHelper.accessor(RevShareCompanyAffiliateReportColumnId.NewDeposit, {
    id: RevShareCompanyAffiliateReportColumnId.NewDeposit,
    header:
      mapRevShareCompanyAffiliateReportColumnIdToName[
        RevShareCompanyAffiliateReportColumnId.NewDeposit
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerRegisteredStatistic.newDeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(
    RevShareCompanyAffiliateReportColumnId.IncomingMoneyTransfer,
    {
      id: RevShareCompanyAffiliateReportColumnId.IncomingMoneyTransfer,
      header:
        mapRevShareCompanyAffiliateReportColumnIdToName[
          RevShareCompanyAffiliateReportColumnId.IncomingMoneyTransfer
        ],
      cell: ({ row }) => {
        const { amount, currency } =
          row.original.playerStatistic.incomingMoneyTransfer;

        return formatSumWithCurrency(amount, currency);
      },
      meta: {
        hasRightBorder: true,
      },
    }
  ),
  columnHelper.accessor(RevShareCompanyAffiliateReportColumnId.Withdrawal, {
    id: RevShareCompanyAffiliateReportColumnId.Withdrawal,
    header:
      mapRevShareCompanyAffiliateReportColumnIdToName[
        RevShareCompanyAffiliateReportColumnId.Withdrawal
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.withdrawal;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyAffiliateReportColumnId.NewWithdrawal, {
    id: RevShareCompanyAffiliateReportColumnId.NewWithdrawal,
    header:
      mapRevShareCompanyAffiliateReportColumnIdToName[
        RevShareCompanyAffiliateReportColumnId.NewWithdrawal
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerRegisteredStatistic.newWithdrawal;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyAffiliateReportColumnId.NewProfit, {
    id: RevShareCompanyAffiliateReportColumnId.NewProfit,
    header:
      mapRevShareCompanyAffiliateReportColumnIdToName[
        RevShareCompanyAffiliateReportColumnId.NewProfit
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerRegisteredStatistic.newProfit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyAffiliateReportColumnId.DepositFee, {
    id: RevShareCompanyAffiliateReportColumnId.DepositFee,
    header:
      mapRevShareCompanyAffiliateReportColumnIdToName[
        RevShareCompanyAffiliateReportColumnId.DepositFee
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.depositFee;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyAffiliateReportColumnId.WithdrawalFee, {
    id: RevShareCompanyAffiliateReportColumnId.WithdrawalFee,
    header:
      mapRevShareCompanyAffiliateReportColumnIdToName[
        RevShareCompanyAffiliateReportColumnId.WithdrawalFee
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.withdrawalFee;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyAffiliateReportColumnId.AdminFee, {
    id: RevShareCompanyAffiliateReportColumnId.AdminFee,
    header:
      mapRevShareCompanyAffiliateReportColumnIdToName[
        RevShareCompanyAffiliateReportColumnId.AdminFee
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.adminFee;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyAffiliateReportColumnId.Bonus, {
    id: RevShareCompanyAffiliateReportColumnId.Bonus,
    header:
      mapRevShareCompanyAffiliateReportColumnIdToName[
        RevShareCompanyAffiliateReportColumnId.Bonus
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.bonus;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(
    RevShareCompanyAffiliateReportColumnId.TournamentPrize,
    {
      id: RevShareCompanyAffiliateReportColumnId.TournamentPrize,
      header:
        mapRevShareCompanyAffiliateReportColumnIdToName[
          RevShareCompanyAffiliateReportColumnId.TournamentPrize
        ],
      cell: ({ row }) => {
        const { amount, currency } =
          row.original.playerStatistic.tournamentPrize;

        return formatSumWithCurrency(amount, currency);
      },
    }
  ),
  columnHelper.accessor(RevShareCompanyAffiliateReportColumnId.Jackpot, {
    id: RevShareCompanyAffiliateReportColumnId.Jackpot,
    header:
      mapRevShareCompanyAffiliateReportColumnIdToName[
        RevShareCompanyAffiliateReportColumnId.Jackpot
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.jackpot;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyAffiliateReportColumnId.NGR, {
    id: RevShareCompanyAffiliateReportColumnId.NGR,
    header:
      mapRevShareCompanyAffiliateReportColumnIdToName[
        RevShareCompanyAffiliateReportColumnId.NGR
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerStatistic.financialIndicators.ngr;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyAffiliateReportColumnId.Profit, {
    id: RevShareCompanyAffiliateReportColumnId.Profit,
    header:
      mapRevShareCompanyAffiliateReportColumnIdToName[
        RevShareCompanyAffiliateReportColumnId.Profit
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerStatistic.financialIndicators.profit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyAffiliateReportColumnId.Upcoming, {
    id: RevShareCompanyAffiliateReportColumnId.Upcoming,
    header:
      mapRevShareCompanyAffiliateReportColumnIdToName[
        RevShareCompanyAffiliateReportColumnId.Upcoming
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.affiliateUpcomingReward;

      return formatSumWithCurrency(amount, currency);
    },
  }),
];

export const useGetRevShareCompanyAffiliateReportColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
