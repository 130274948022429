import { formatSumWithCurrency } from 'src/utils/accounting';
import { ColumnsSource } from 'ui/Table/types';
import { createColumnHelper } from '@tanstack/react-table';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { addPercentToValue } from 'src/utils/addPercentToValue';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { formatNumberWithRuLocale } from 'src/utils/formatNumberWithRuLocale';
import { Route } from 'src/router/routes.const';
import {
  columnsIds,
  CpaCompanyAffiliateReportColumnId,
  mapCpaCompanyAffiliateReportColumnIdToName,
} from '../const';
import { STATISTIC_TOTAL_ROW_TITLE } from '../../../../../const';
import { MultilineCellWithLinkWithPeriodQueryParams } from '../../../../../components/MultilineCellWithLinkWithPeriodQueryParams';
import { CpaCompanyAffiliateReportData } from '../../../queries/generated/CpaCompanyAffiliateReportData';

const columnHelper = createColumnHelper<CpaCompanyAffiliateReportData>();

const columnsSource: ColumnsSource<CpaCompanyAffiliateReportData> = [
  columnHelper.accessor(CpaCompanyAffiliateReportColumnId.ReferralCode, {
    id: CpaCompanyAffiliateReportColumnId.ReferralCode,
    header:
      mapCpaCompanyAffiliateReportColumnIdToName[
        CpaCompanyAffiliateReportColumnId.ReferralCode
      ],
    cell: ({ row }) => {
      if (!row.original.mediaItem) {
        return STATISTIC_TOTAL_ROW_TITLE;
      }

      const { id, name } = row.original.mediaItem;

      return (
        <MultilineCellWithLinkWithPeriodQueryParams
          id={id}
          title={name}
          subTitle={addHashTagToId(id)}
          route={Route.STATISTIC_CPA_REFERRAL_LINK}
        />
      );
    },
    enablePinning: true,
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(CpaCompanyAffiliateReportColumnId.Hits, {
    id: CpaCompanyAffiliateReportColumnId.Hits,
    header:
      mapCpaCompanyAffiliateReportColumnIdToName[
        CpaCompanyAffiliateReportColumnId.Hits
      ],
    cell: ({ row }) => {
      const { hits } = row.original.marketingStatistic;

      return formatNumberWithRuLocale(hits);
    },
  }),
  columnHelper.accessor(CpaCompanyAffiliateReportColumnId.Hosts, {
    id: CpaCompanyAffiliateReportColumnId.Hosts,
    header:
      mapCpaCompanyAffiliateReportColumnIdToName[
        CpaCompanyAffiliateReportColumnId.Hosts
      ],
    cell: ({ row }) => {
      const { hosts } = row.original.marketingStatistic;

      return formatNumberWithRuLocale(hosts);
    },
  }),
  columnHelper.accessor(CpaCompanyAffiliateReportColumnId.Registrations, {
    id: CpaCompanyAffiliateReportColumnId.Registrations,
    header:
      mapCpaCompanyAffiliateReportColumnIdToName[
        CpaCompanyAffiliateReportColumnId.Registrations
      ],
    cell: ({ row }) => {
      const { registrations } = row.original.marketingStatistic;

      return formatNumberWithRuLocale(registrations);
    },
  }),
  columnHelper.accessor(CpaCompanyAffiliateReportColumnId.FirstDeposits, {
    id: CpaCompanyAffiliateReportColumnId.FirstDeposits,
    header:
      mapCpaCompanyAffiliateReportColumnIdToName[
        CpaCompanyAffiliateReportColumnId.FirstDeposits
      ],
    cell: ({ row }) => {
      const { firstDeposits } = row.original.marketingStatistic;

      return formatNumberWithRuLocale(firstDeposits);
    },
  }),
  columnHelper.accessor(CpaCompanyAffiliateReportColumnId.HostToRegConversion, {
    id: CpaCompanyAffiliateReportColumnId.HostToRegConversion,
    header:
      mapCpaCompanyAffiliateReportColumnIdToName[
        CpaCompanyAffiliateReportColumnId.HostToRegConversion
      ],
    cell: ({ row }) => {
      const { hostToRegConversion } = row.original.marketingStatistic;

      return addPercentToValue(hostToRegConversion);
    },
  }),
  columnHelper.accessor(CpaCompanyAffiliateReportColumnId.RegToDepConversion, {
    id: CpaCompanyAffiliateReportColumnId.RegToDepConversion,
    header:
      mapCpaCompanyAffiliateReportColumnIdToName[
        CpaCompanyAffiliateReportColumnId.RegToDepConversion
      ],
    cell: ({ row }) => {
      const { regToDepConversion } = row.original.marketingStatistic;

      return addPercentToValue(regToDepConversion);
    },
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(CpaCompanyAffiliateReportColumnId.AllDeposit, {
    id: CpaCompanyAffiliateReportColumnId.AllDeposit,
    header:
      mapCpaCompanyAffiliateReportColumnIdToName[
        CpaCompanyAffiliateReportColumnId.AllDeposit
      ],
    cell: ({ row }) => {
      const { allDeposit } = row.original.playerStatistic;

      return formatSumWithCurrency(allDeposit.amount, allDeposit.currency);
    },
  }),
  columnHelper.accessor(CpaCompanyAffiliateReportColumnId.AverageDeposit, {
    id: CpaCompanyAffiliateReportColumnId.AverageDeposit,
    header:
      mapCpaCompanyAffiliateReportColumnIdToName[
        CpaCompanyAffiliateReportColumnId.AverageDeposit
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.averageDeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaCompanyAffiliateReportColumnId.AllRedeposit, {
    id: CpaCompanyAffiliateReportColumnId.AllRedeposit,
    header:
      mapCpaCompanyAffiliateReportColumnIdToName[
        CpaCompanyAffiliateReportColumnId.AllRedeposit
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.allRedeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaCompanyAffiliateReportColumnId.Redeposits, {
    id: CpaCompanyAffiliateReportColumnId.Redeposits,
    header:
      mapCpaCompanyAffiliateReportColumnIdToName[
        CpaCompanyAffiliateReportColumnId.Redeposits
      ],
    cell: ({ row }) => {
      const { redeposits } = row.original.marketingStatistic;

      return formatNumberWithRuLocale(redeposits);
    },
  }),
  columnHelper.accessor(CpaCompanyAffiliateReportColumnId.NewDeposit, {
    id: CpaCompanyAffiliateReportColumnId.NewDeposit,
    header:
      mapCpaCompanyAffiliateReportColumnIdToName[
        CpaCompanyAffiliateReportColumnId.NewDeposit
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerRegisteredStatistic.newDeposit;

      return formatSumWithCurrency(amount, currency);
    },
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(CpaCompanyAffiliateReportColumnId.Withdrawal, {
    id: CpaCompanyAffiliateReportColumnId.Withdrawal,
    header:
      mapCpaCompanyAffiliateReportColumnIdToName[
        CpaCompanyAffiliateReportColumnId.Withdrawal
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.withdrawal;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaCompanyAffiliateReportColumnId.NewWithdrawal, {
    id: CpaCompanyAffiliateReportColumnId.NewWithdrawal,
    header:
      mapCpaCompanyAffiliateReportColumnIdToName[
        CpaCompanyAffiliateReportColumnId.NewWithdrawal
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerRegisteredStatistic.newWithdrawal;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaCompanyAffiliateReportColumnId.NewProfit, {
    id: CpaCompanyAffiliateReportColumnId.NewProfit,
    header:
      mapCpaCompanyAffiliateReportColumnIdToName[
        CpaCompanyAffiliateReportColumnId.NewProfit
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerRegisteredStatistic.newProfit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaCompanyAffiliateReportColumnId.Hold, {
    id: CpaCompanyAffiliateReportColumnId.Hold,
    header:
      mapCpaCompanyAffiliateReportColumnIdToName[
        CpaCompanyAffiliateReportColumnId.Hold
      ],
    cell: ({ row }) => {
      const { hold } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(hold);
    },
  }),
  columnHelper.accessor(CpaCompanyAffiliateReportColumnId.Qualified, {
    id: CpaCompanyAffiliateReportColumnId.Qualified,
    header:
      mapCpaCompanyAffiliateReportColumnIdToName[
        CpaCompanyAffiliateReportColumnId.Qualified
      ],
    cell: ({ row }) => {
      const { qualified } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(qualified);
    },
  }),
  columnHelper.accessor(CpaCompanyAffiliateReportColumnId.NotQualified, {
    id: CpaCompanyAffiliateReportColumnId.NotQualified,
    header:
      mapCpaCompanyAffiliateReportColumnIdToName[
        CpaCompanyAffiliateReportColumnId.NotQualified
      ],
    cell: ({ row }) => {
      const { canceled } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(canceled);
    },
  }),
  columnHelper.accessor(CpaCompanyAffiliateReportColumnId.Approved, {
    id: CpaCompanyAffiliateReportColumnId.Approved,
    header:
      mapCpaCompanyAffiliateReportColumnIdToName[
        CpaCompanyAffiliateReportColumnId.Approved
      ],
    cell: ({ row }) => {
      const { approved } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(approved);
    },
  }),
  columnHelper.accessor(CpaCompanyAffiliateReportColumnId.Rejected, {
    id: CpaCompanyAffiliateReportColumnId.Rejected,
    header:
      mapCpaCompanyAffiliateReportColumnIdToName[
        CpaCompanyAffiliateReportColumnId.Rejected
      ],
    cell: ({ row }) => {
      const { rejected } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(rejected);
    },
  }),
  columnHelper.accessor(CpaCompanyAffiliateReportColumnId.Paid, {
    id: CpaCompanyAffiliateReportColumnId.Paid,
    header:
      mapCpaCompanyAffiliateReportColumnIdToName[
        CpaCompanyAffiliateReportColumnId.Paid
      ],
    cell: ({ row }) => {
      const { paid } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(paid);
    },
  }),
  columnHelper.accessor(CpaCompanyAffiliateReportColumnId.Unpaid, {
    id: CpaCompanyAffiliateReportColumnId.Unpaid,
    header:
      mapCpaCompanyAffiliateReportColumnIdToName[
        CpaCompanyAffiliateReportColumnId.Unpaid
      ],
    cell: ({ row }) => {
      const { unpaid } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(unpaid);
    },
  }),
  columnHelper.accessor(CpaCompanyAffiliateReportColumnId.RevShare, {
    id: CpaCompanyAffiliateReportColumnId.RevShare,
    header:
      mapCpaCompanyAffiliateReportColumnIdToName[
        CpaCompanyAffiliateReportColumnId.RevShare
      ],
    cell: ({ row }) => {
      const { revShare } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(revShare);
    },
  }),
  columnHelper.accessor(CpaCompanyAffiliateReportColumnId.NGR, {
    id: CpaCompanyAffiliateReportColumnId.NGR,
    header:
      mapCpaCompanyAffiliateReportColumnIdToName[
        CpaCompanyAffiliateReportColumnId.NGR
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerStatistic.financialIndicators.ngr;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaCompanyAffiliateReportColumnId.Profit, {
    id: CpaCompanyAffiliateReportColumnId.Profit,
    header:
      mapCpaCompanyAffiliateReportColumnIdToName[
        CpaCompanyAffiliateReportColumnId.Profit
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerStatistic.financialIndicators.profit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaCompanyAffiliateReportColumnId.Upcoming, {
    id: CpaCompanyAffiliateReportColumnId.Upcoming,
    header:
      mapCpaCompanyAffiliateReportColumnIdToName[
        CpaCompanyAffiliateReportColumnId.Upcoming
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.affiliateRewardStatistic.upcoming;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaCompanyAffiliateReportColumnId.RewardPaid, {
    id: CpaCompanyAffiliateReportColumnId.RewardPaid,
    header:
      mapCpaCompanyAffiliateReportColumnIdToName[
        CpaCompanyAffiliateReportColumnId.RewardPaid
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.affiliateRewardStatistic.paid;

      return formatSumWithCurrency(amount, currency);
    },
  }),
];

export const useGetCpaCompanyAffiliateReportColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
