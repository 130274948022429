import { useCallback, useMemo } from 'react';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { Currency } from 'commonTypes';
import { GeneralReportOrderBy, OrderDirection } from 'generatedGraphql';
import { useGetCpaGeneralReportLazyQuery } from '../queries/generated/GetCpaGeneralReport';
import { useGetPeriodOfStatistic } from '../../../hooks/useGetPeriodOfStatistic';

const COUNT_PER_PAGE = 10;
const cellHeight = Heights.defaultCellHeight;

export const useGetCpaGeneralReport = (isAllTimeCpa: boolean) => {
  const period = useGetPeriodOfStatistic();

  const [loadCpaGeneralReport, { data, loading, error, fetchMore, refetch }] =
    useGetCpaGeneralReportLazyQuery({
      variables: {
        currency: Currency.Usd,
        first: getCountByWindowHeight(cellHeight, 0),
        period,
        ordering: {
          orderBy: GeneralReportOrderBy.AllDeposit,
          direction: OrderDirection.Desc,
        },
        showAllTimeCpa: isAllTimeCpa,
      },
    });

  useBlockComponentState({
    loadData: loadCpaGeneralReport,
    loading,
    error,
  });

  const cpaGeneralReport = data?.cpaGeneralReport;
  const edges = cpaGeneralReport?.edges;
  const total = cpaGeneralReport?.total;
  const hasTotalRow = !!total;
  const endCursor = cpaGeneralReport?.pageInfo.endCursor;
  const hasNextPage = cpaGeneralReport?.pageInfo.hasNextPage;

  const cpaGeneralReportList = useMemo(() => {
    const report = edges?.map(({ node }) => node);

    if (hasTotalRow) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      report?.unshift(total);
    }

    return report;
  }, [edges, hasTotalRow, total]);

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: COUNT_PER_PAGE,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false }
      ),
    [endCursor, fetchMore]
  );

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage,
    isFullScreenTable: true,
  });

  return {
    cpaGeneralReportList,
    hasTotalRow,
    refetch,
  };
};
