import { FC, useContext } from 'react';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import Block from 'ui/Block';
import { BlockContext, withBlockProvider } from 'ui/Block/store';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { usePartnerApplicationsData } from './hooks/usePartnerApplicationsData';
import { PartnerApplicationsTable } from './components/PartnerApplicationsTable';

export const PartnerApplications: FC = withBlockProvider(() => {
  const { handleRefetchClick, partnerApplicationsList } =
    usePartnerApplicationsData();
  const { state } = useContext(BlockContext);

  return (
    <Block
      title="Заявки партнёров"
      id="partnerApplications"
      headerContent={
        <ActionButton
          actionType={ButtonType.Update}
          hidden={false}
          disabled={state.isLoading}
          onClick={handleRefetchClick}
        />
      }
      shrinkLoaderWrapper={false}
      emptyText="Нет заявок"
      isEmpty={isListEmpty(partnerApplicationsList)}
      shouldReturnToPrevPage={false}
      isPermanentOpened
    >
      {hasList(partnerApplicationsList) && (
        <PartnerApplicationsTable
          partnerApplications={partnerApplicationsList}
        />
      )}
    </Block>
  );
});
