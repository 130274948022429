import { formatSumWithCurrency } from 'src/utils/accounting';
import { ColumnsSource } from 'ui/Table/types';
import { createColumnHelper } from '@tanstack/react-table';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { addPercentToValue } from 'src/utils/addPercentToValue';
import { formatNumberWithRuLocale } from 'src/utils/formatNumberWithRuLocale';
import { Route } from 'src/router/routes.const';
import {
  columnsIds,
  RevShareCompanyReportColumnId,
  mapRevShareCompanyReportColumnIdToName,
} from '../const';
import { STATISTIC_TOTAL_ROW_TITLE } from '../../../../../const';
import { MultilineCellWithLinkWithPeriodQueryParams } from '../../../../../components/MultilineCellWithLinkWithPeriodQueryParams';
import { RevShareCompanyReportData } from '../../../queries/generated/RevShareCompanyReportData';

const columnHelper = createColumnHelper<RevShareCompanyReportData>();

const columnsSource: ColumnsSource<RevShareCompanyReportData> = [
  columnHelper.accessor(RevShareCompanyReportColumnId.ReferralCode, {
    id: RevShareCompanyReportColumnId.ReferralCode,
    header:
      mapRevShareCompanyReportColumnIdToName[
        RevShareCompanyReportColumnId.ReferralCode
      ],
    cell: ({ row }) => {
      if (!row.original.mediaItem) {
        return STATISTIC_TOTAL_ROW_TITLE;
      }

      const { id, name } = row.original.mediaItem;

      return (
        <MultilineCellWithLinkWithPeriodQueryParams
          id={id}
          title={name}
          subTitle={addHashTagToId(id)}
          route={Route.STATISTIC_REV_SHARE_REFERRAL_LINK}
        />
      );
    },
    enablePinning: true,
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(RevShareCompanyReportColumnId.Hits, {
    id: RevShareCompanyReportColumnId.Hits,
    header:
      mapRevShareCompanyReportColumnIdToName[
        RevShareCompanyReportColumnId.Hits
      ],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.hits),
  }),
  columnHelper.accessor(RevShareCompanyReportColumnId.Hosts, {
    id: RevShareCompanyReportColumnId.Hosts,
    header:
      mapRevShareCompanyReportColumnIdToName[
        RevShareCompanyReportColumnId.Hosts
      ],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.hosts),
  }),
  columnHelper.accessor(RevShareCompanyReportColumnId.Registrations, {
    id: RevShareCompanyReportColumnId.Registrations,
    header:
      mapRevShareCompanyReportColumnIdToName[
        RevShareCompanyReportColumnId.Registrations
      ],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.registrations),
  }),
  columnHelper.accessor(RevShareCompanyReportColumnId.FirstDeposits, {
    id: RevShareCompanyReportColumnId.FirstDeposits,
    header:
      mapRevShareCompanyReportColumnIdToName[
        RevShareCompanyReportColumnId.FirstDeposits
      ],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.firstDeposits),
  }),
  columnHelper.accessor(RevShareCompanyReportColumnId.HostToRegConversion, {
    id: RevShareCompanyReportColumnId.HostToRegConversion,
    header:
      mapRevShareCompanyReportColumnIdToName[
        RevShareCompanyReportColumnId.HostToRegConversion
      ],
    cell: ({ row }) =>
      addPercentToValue(row.original.marketingStatistic.hostToRegConversion),
  }),
  columnHelper.accessor(RevShareCompanyReportColumnId.RegToDepConversion, {
    id: RevShareCompanyReportColumnId.RegToDepConversion,
    header:
      mapRevShareCompanyReportColumnIdToName[
        RevShareCompanyReportColumnId.RegToDepConversion
      ],
    cell: ({ row }) =>
      addPercentToValue(row.original.marketingStatistic.regToDepConversion),
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(RevShareCompanyReportColumnId.AllDeposit, {
    id: RevShareCompanyReportColumnId.AllDeposit,
    header:
      mapRevShareCompanyReportColumnIdToName[
        RevShareCompanyReportColumnId.AllDeposit
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.allDeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyReportColumnId.AverageDeposit, {
    id: RevShareCompanyReportColumnId.AverageDeposit,
    header:
      mapRevShareCompanyReportColumnIdToName[
        RevShareCompanyReportColumnId.AverageDeposit
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.averageDeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyReportColumnId.AllRedeposit, {
    id: RevShareCompanyReportColumnId.AllRedeposit,
    header:
      mapRevShareCompanyReportColumnIdToName[
        RevShareCompanyReportColumnId.AllRedeposit
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.allRedeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyReportColumnId.Redeposits, {
    id: RevShareCompanyReportColumnId.Redeposits,
    header:
      mapRevShareCompanyReportColumnIdToName[
        RevShareCompanyReportColumnId.Redeposits
      ],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.redeposits),
  }),
  columnHelper.accessor(RevShareCompanyReportColumnId.NewDeposit, {
    id: RevShareCompanyReportColumnId.NewDeposit,
    header:
      mapRevShareCompanyReportColumnIdToName[
        RevShareCompanyReportColumnId.NewDeposit
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerRegisteredStatistic.newDeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyReportColumnId.IncomingMoneyTransfer, {
    id: RevShareCompanyReportColumnId.IncomingMoneyTransfer,
    header:
      mapRevShareCompanyReportColumnIdToName[
        RevShareCompanyReportColumnId.IncomingMoneyTransfer
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerStatistic.incomingMoneyTransfer;

      return formatSumWithCurrency(amount, currency);
    },
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(RevShareCompanyReportColumnId.Withdrawal, {
    id: RevShareCompanyReportColumnId.Withdrawal,
    header:
      mapRevShareCompanyReportColumnIdToName[
        RevShareCompanyReportColumnId.Withdrawal
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.withdrawal;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyReportColumnId.NewWithdrawal, {
    id: RevShareCompanyReportColumnId.NewWithdrawal,
    header:
      mapRevShareCompanyReportColumnIdToName[
        RevShareCompanyReportColumnId.NewWithdrawal
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerRegisteredStatistic.newWithdrawal;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyReportColumnId.NewProfit, {
    id: RevShareCompanyReportColumnId.NewProfit,
    header:
      mapRevShareCompanyReportColumnIdToName[
        RevShareCompanyReportColumnId.NewProfit
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerRegisteredStatistic.newProfit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyReportColumnId.DepositFee, {
    id: RevShareCompanyReportColumnId.DepositFee,
    header:
      mapRevShareCompanyReportColumnIdToName[
        RevShareCompanyReportColumnId.DepositFee
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.depositFee;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyReportColumnId.WithdrawalFee, {
    id: RevShareCompanyReportColumnId.WithdrawalFee,
    header:
      mapRevShareCompanyReportColumnIdToName[
        RevShareCompanyReportColumnId.WithdrawalFee
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.withdrawalFee;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyReportColumnId.AdminFee, {
    id: RevShareCompanyReportColumnId.AdminFee,
    header:
      mapRevShareCompanyReportColumnIdToName[
        RevShareCompanyReportColumnId.AdminFee
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.adminFee;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyReportColumnId.Bonus, {
    id: RevShareCompanyReportColumnId.Bonus,
    header:
      mapRevShareCompanyReportColumnIdToName[
        RevShareCompanyReportColumnId.Bonus
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.bonus;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyReportColumnId.TournamentPrize, {
    id: RevShareCompanyReportColumnId.TournamentPrize,
    header:
      mapRevShareCompanyReportColumnIdToName[
        RevShareCompanyReportColumnId.TournamentPrize
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.tournamentPrize;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyReportColumnId.Jackpot, {
    id: RevShareCompanyReportColumnId.Jackpot,
    header:
      mapRevShareCompanyReportColumnIdToName[
        RevShareCompanyReportColumnId.Jackpot
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.jackpot;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyReportColumnId.NGR, {
    id: RevShareCompanyReportColumnId.NGR,
    header:
      mapRevShareCompanyReportColumnIdToName[RevShareCompanyReportColumnId.NGR],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerStatistic.financialIndicators.ngr;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyReportColumnId.Profit, {
    id: RevShareCompanyReportColumnId.Profit,
    header:
      mapRevShareCompanyReportColumnIdToName[
        RevShareCompanyReportColumnId.Profit
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerStatistic.financialIndicators.profit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyReportColumnId.Upcoming, {
    id: RevShareCompanyReportColumnId.Upcoming,
    header:
      mapRevShareCompanyReportColumnIdToName[
        RevShareCompanyReportColumnId.Upcoming
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.affiliateUpcomingReward;

      return formatSumWithCurrency(amount, currency);
    },
  }),
];

export const useGetRevShareCompanyReportColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
