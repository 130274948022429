import { createColumnHelper } from '@tanstack/react-table';
import { ColumnsSource } from 'ui/Table/types';
import { formatNumberWithRuLocale } from 'src/utils/formatNumberWithRuLocale';
import { addPercentToValue } from 'src/utils/addPercentToValue';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import {
  columnsIds,
  mapRevShareCompanyPartnersReportColumnIdToName,
  RevShareCompanyPartnersReportColumnId,
} from '../const';
import { STATISTIC_TOTAL_ROW_TITLE } from '../../../../../const';
import { RevShareCompanyPartnersReportRow } from '../../../types';
import { RevShareMediaOwnerCell } from '../../RevShareMediaOwnerCell';

const columnHelper = createColumnHelper<RevShareCompanyPartnersReportRow>();

const columnsSource: ColumnsSource<RevShareCompanyPartnersReportRow> = [
  columnHelper.accessor(RevShareCompanyPartnersReportColumnId.MediaOwner, {
    id: RevShareCompanyPartnersReportColumnId.MediaOwner,
    header:
      mapRevShareCompanyPartnersReportColumnIdToName[
        RevShareCompanyPartnersReportColumnId.MediaOwner
      ],
    cell: ({ row }) => {
      if (!row.original.companyPartner && !row.original.company) {
        return STATISTIC_TOTAL_ROW_TITLE;
      }

      return <RevShareMediaOwnerCell row={row.original} />;
    },
    enablePinning: true,
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(RevShareCompanyPartnersReportColumnId.Hits, {
    id: RevShareCompanyPartnersReportColumnId.Hits,
    header:
      mapRevShareCompanyPartnersReportColumnIdToName[
        RevShareCompanyPartnersReportColumnId.Hits
      ],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.hits),
  }),
  columnHelper.accessor(RevShareCompanyPartnersReportColumnId.Hosts, {
    id: RevShareCompanyPartnersReportColumnId.Hosts,
    header:
      mapRevShareCompanyPartnersReportColumnIdToName[
        RevShareCompanyPartnersReportColumnId.Hosts
      ],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.hosts),
  }),
  columnHelper.accessor(RevShareCompanyPartnersReportColumnId.Registrations, {
    id: RevShareCompanyPartnersReportColumnId.Registrations,
    header:
      mapRevShareCompanyPartnersReportColumnIdToName[
        RevShareCompanyPartnersReportColumnId.Registrations
      ],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.registrations),
  }),
  columnHelper.accessor(RevShareCompanyPartnersReportColumnId.FirstDeposits, {
    id: RevShareCompanyPartnersReportColumnId.FirstDeposits,
    header:
      mapRevShareCompanyPartnersReportColumnIdToName[
        RevShareCompanyPartnersReportColumnId.FirstDeposits
      ],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.firstDeposits),
  }),
  columnHelper.accessor(
    RevShareCompanyPartnersReportColumnId.HostToRegConversion,
    {
      id: RevShareCompanyPartnersReportColumnId.HostToRegConversion,
      header:
        mapRevShareCompanyPartnersReportColumnIdToName[
          RevShareCompanyPartnersReportColumnId.HostToRegConversion
        ],
      cell: ({ row }) =>
        addPercentToValue(row.original.marketingStatistic.hostToRegConversion),
    }
  ),
  columnHelper.accessor(
    RevShareCompanyPartnersReportColumnId.RegToDepConversion,
    {
      id: RevShareCompanyPartnersReportColumnId.RegToDepConversion,
      header:
        mapRevShareCompanyPartnersReportColumnIdToName[
          RevShareCompanyPartnersReportColumnId.RegToDepConversion
        ],
      cell: ({ row }) =>
        addPercentToValue(row.original.marketingStatistic.regToDepConversion),
      meta: {
        hasRightBorder: true,
      },
    }
  ),
  columnHelper.accessor(RevShareCompanyPartnersReportColumnId.AllDeposit, {
    id: RevShareCompanyPartnersReportColumnId.AllDeposit,
    header:
      mapRevShareCompanyPartnersReportColumnIdToName[
        RevShareCompanyPartnersReportColumnId.AllDeposit
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.allDeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyPartnersReportColumnId.AverageDeposit, {
    id: RevShareCompanyPartnersReportColumnId.AverageDeposit,
    header:
      mapRevShareCompanyPartnersReportColumnIdToName[
        RevShareCompanyPartnersReportColumnId.AverageDeposit
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.averageDeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyPartnersReportColumnId.AllRedeposit, {
    id: RevShareCompanyPartnersReportColumnId.AllRedeposit,
    header:
      mapRevShareCompanyPartnersReportColumnIdToName[
        RevShareCompanyPartnersReportColumnId.AllRedeposit
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.allRedeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyPartnersReportColumnId.Redeposits, {
    id: RevShareCompanyPartnersReportColumnId.Redeposits,
    header:
      mapRevShareCompanyPartnersReportColumnIdToName[
        RevShareCompanyPartnersReportColumnId.Redeposits
      ],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.redeposits),
  }),
  columnHelper.accessor(RevShareCompanyPartnersReportColumnId.NewDeposit, {
    id: RevShareCompanyPartnersReportColumnId.NewDeposit,
    header:
      mapRevShareCompanyPartnersReportColumnIdToName[
        RevShareCompanyPartnersReportColumnId.NewDeposit
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerRegisteredStatistic.newDeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(
    RevShareCompanyPartnersReportColumnId.IncomingMoneyTransfer,
    {
      id: RevShareCompanyPartnersReportColumnId.IncomingMoneyTransfer,
      header:
        mapRevShareCompanyPartnersReportColumnIdToName[
          RevShareCompanyPartnersReportColumnId.IncomingMoneyTransfer
        ],
      cell: ({ row }) => {
        const { amount, currency } =
          row.original.playerStatistic.incomingMoneyTransfer;

        return formatSumWithCurrency(amount, currency);
      },
      meta: {
        hasRightBorder: true,
      },
    }
  ),
  columnHelper.accessor(RevShareCompanyPartnersReportColumnId.Withdrawal, {
    id: RevShareCompanyPartnersReportColumnId.Withdrawal,
    header:
      mapRevShareCompanyPartnersReportColumnIdToName[
        RevShareCompanyPartnersReportColumnId.Withdrawal
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.withdrawal;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyPartnersReportColumnId.NewWithdrawal, {
    id: RevShareCompanyPartnersReportColumnId.NewWithdrawal,
    header:
      mapRevShareCompanyPartnersReportColumnIdToName[
        RevShareCompanyPartnersReportColumnId.NewWithdrawal
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerRegisteredStatistic.newWithdrawal;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyPartnersReportColumnId.NewProfit, {
    id: RevShareCompanyPartnersReportColumnId.NewProfit,
    header:
      mapRevShareCompanyPartnersReportColumnIdToName[
        RevShareCompanyPartnersReportColumnId.NewProfit
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerRegisteredStatistic.newProfit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyPartnersReportColumnId.DepositFee, {
    id: RevShareCompanyPartnersReportColumnId.DepositFee,
    header:
      mapRevShareCompanyPartnersReportColumnIdToName[
        RevShareCompanyPartnersReportColumnId.DepositFee
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.depositFee;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyPartnersReportColumnId.WithdrawalFee, {
    id: RevShareCompanyPartnersReportColumnId.WithdrawalFee,
    header:
      mapRevShareCompanyPartnersReportColumnIdToName[
        RevShareCompanyPartnersReportColumnId.WithdrawalFee
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.withdrawalFee;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyPartnersReportColumnId.AdminFee, {
    id: RevShareCompanyPartnersReportColumnId.AdminFee,
    header:
      mapRevShareCompanyPartnersReportColumnIdToName[
        RevShareCompanyPartnersReportColumnId.AdminFee
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.adminFee;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyPartnersReportColumnId.Bonus, {
    id: RevShareCompanyPartnersReportColumnId.Bonus,
    header:
      mapRevShareCompanyPartnersReportColumnIdToName[
        RevShareCompanyPartnersReportColumnId.Bonus
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.bonus;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyPartnersReportColumnId.TournamentPrize, {
    id: RevShareCompanyPartnersReportColumnId.TournamentPrize,
    header:
      mapRevShareCompanyPartnersReportColumnIdToName[
        RevShareCompanyPartnersReportColumnId.TournamentPrize
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.tournamentPrize;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyPartnersReportColumnId.Jackpot, {
    id: RevShareCompanyPartnersReportColumnId.Jackpot,
    header:
      mapRevShareCompanyPartnersReportColumnIdToName[
        RevShareCompanyPartnersReportColumnId.Jackpot
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.jackpot;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyPartnersReportColumnId.NGR, {
    id: RevShareCompanyPartnersReportColumnId.NGR,
    header:
      mapRevShareCompanyPartnersReportColumnIdToName[
        RevShareCompanyPartnersReportColumnId.NGR
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerStatistic.financialIndicators.ngr;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyPartnersReportColumnId.Profit, {
    id: RevShareCompanyPartnersReportColumnId.Profit,
    header:
      mapRevShareCompanyPartnersReportColumnIdToName[
        RevShareCompanyPartnersReportColumnId.Profit
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerStatistic.financialIndicators.profit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareCompanyPartnersReportColumnId.Upcoming, {
    id: RevShareCompanyPartnersReportColumnId.Upcoming,
    header:
      mapRevShareCompanyPartnersReportColumnIdToName[
        RevShareCompanyPartnersReportColumnId.Upcoming
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.affiliateUpcomingReward;

      return formatSumWithCurrency(amount, currency);
    },
  }),
];

export const useGetRevShareCompanyPartnersReportColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
