export enum CpaRewardTariffsColumnId {
  Id = 'id',
  Title = 'title',
  Countries = 'countries',
  Baseline = 'baselineSum',
  RewardSum = 'rewardSum',
  CreatedAt = 'createdAt',
}

export const mapCpaRewardTariffsColumnIdColumnToName: Record<
  CpaRewardTariffsColumnId,
  string
> = {
  [CpaRewardTariffsColumnId.Id]: 'ID',
  [CpaRewardTariffsColumnId.Title]: 'Название',
  [CpaRewardTariffsColumnId.Countries]: 'Геолокация',
  [CpaRewardTariffsColumnId.Baseline]: 'Baseline',
  [CpaRewardTariffsColumnId.RewardSum]: 'Вознаграждение',
  [CpaRewardTariffsColumnId.CreatedAt]: 'Создан',
};

export const columnsIds = [
  CpaRewardTariffsColumnId.Id,
  CpaRewardTariffsColumnId.Title,
  CpaRewardTariffsColumnId.Countries,
  CpaRewardTariffsColumnId.Baseline,
  CpaRewardTariffsColumnId.RewardSum,
  CpaRewardTariffsColumnId.CreatedAt,
];
