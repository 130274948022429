import { FC } from 'react';
import Block from 'ui/Block';
import { withBlockProvider } from 'ui/Block/store';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useParams } from 'react-router';
import { Route } from 'src/router/routes.const';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import {
  REV_SHARE_COMPANY_AFFILIATE_STATISTIC_BLOCK_TITLE,
  STATISTIC_NO_DATA,
} from '../../const';
import { useGeneratePathWithSavePeriodQueryParams } from '../../hooks/useGeneratePathWithSavePeriodQueryParams';
import { StatisticBlockHeaderContent } from '../../components/StatisticBlockHeaderContent';
import { useGetRevShareCompanyAffiliateReport } from './hooks/useGetRevShareCompanyAffiliateReport';
import { RevShareCompanyAffiliateReportTable } from './components/RevShareCompanyAffiliateReportTable';
import { BlockSubHeaderCompanyAffiliateInfo } from '../../components/BlockSubHeaderCompanyAffiliateInfo';

interface RevShareCompanyAffiliateReportProps {
  companyId: string;
  affiliateId: string;
}

const Component: FC<RevShareCompanyAffiliateReportProps> = withBlockProvider(
  ({ companyId, affiliateId }) => {
    const {
      data,
      revShareCompanyAffiliateReportList,
      loadRevShareCompanyAffiliateReport,
      loading,
      error,
      refetch,
      fetchMoreCallback,
      hasNextPage,
      hasTotalRow,
      affiliate,
    } = useGetRevShareCompanyAffiliateReport(companyId, affiliateId);

    useBlockComponentState({
      loadData: loadRevShareCompanyAffiliateReport,
      loading,
      error,
    });

    useBlockTableComponentState({
      data,
      fetchMoreCallback,
      refetch,
      loading,
      hasNextPage,
      isFullScreenTable: true,
    });

    const generatePathWithPeriodQueryParams =
      useGeneratePathWithSavePeriodQueryParams();

    const customPathToPrevPage = generatePathWithPeriodQueryParams({
      originalPath: Route.STATISTIC_REV_SHARE_COMPANY_PARTNERS,
      params: {
        id: companyId,
      },
    });

    return (
      <Block
        title={REV_SHARE_COMPANY_AFFILIATE_STATISTIC_BLOCK_TITLE}
        id="revShareCompanyAffiliateStatistic"
        headerContent={
          <StatisticBlockHeaderContent handleRefetchClick={refetch} />
        }
        subHeader={
          affiliate && (
            <BlockSubHeaderCompanyAffiliateInfo
              companyName={affiliate.company.name}
              affiliateName={affiliate.name}
            />
          )
        }
        shrinkLoaderWrapper={false}
        emptyText={STATISTIC_NO_DATA}
        isEmpty={isListEmpty(revShareCompanyAffiliateReportList)}
        shouldReturnToPrevPage
        isPermanentOpened
        customPathToPrevPage={customPathToPrevPage}
      >
        {hasList(revShareCompanyAffiliateReportList) && (
          <RevShareCompanyAffiliateReportTable
            revShareCompanyAffiliateReport={revShareCompanyAffiliateReportList}
            hasTotalRow={hasTotalRow}
          />
        )}
      </Block>
    );
  }
);

export const RevShareCompanyAffiliateReport = () => {
  const { companyId, affiliateId } = useParams();

  if (!companyId || !affiliateId) {
    return null;
  }

  return <Component companyId={companyId} affiliateId={affiliateId} />;
};
