export enum DepositsColumnId {
  Id = 'id',
  Provider = 'paymentProvider',
  PaymentMethodName = 'paymentMethodName',
  PaymentMethodAccount = 'paymentMethod.account',
  Sum = 'sum',
  Wager = 'wager',
  PaidSum = 'paidSum',
  Status = 'status',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export const mapDepositsColumnIdToName: Record<DepositsColumnId, string> = {
  [DepositsColumnId.Id]: 'ID',
  [DepositsColumnId.Provider]: 'Провайдер',
  [DepositsColumnId.PaymentMethodName]: 'Метод',
  [DepositsColumnId.PaymentMethodAccount]: 'Номер счета',
  [DepositsColumnId.Sum]: 'Сумма',
  [DepositsColumnId.Wager]: 'Отыгрыш',
  [DepositsColumnId.PaidSum]: 'Фактическая сумма',
  [DepositsColumnId.Status]: 'Статус',
  [DepositsColumnId.CreatedAt]: 'Создан',
  [DepositsColumnId.UpdatedAt]: 'Обновлён',
};

export const blockColumnsIds = [
  DepositsColumnId.PaymentMethodName,
  DepositsColumnId.PaymentMethodAccount,
  DepositsColumnId.Sum,
  DepositsColumnId.Wager,
  DepositsColumnId.Status,
  DepositsColumnId.CreatedAt,
];

export const pageColumnsIds = [
  DepositsColumnId.Id,
  DepositsColumnId.Provider,
  DepositsColumnId.PaymentMethodName,
  DepositsColumnId.PaymentMethodAccount,
  DepositsColumnId.Sum,
  DepositsColumnId.Wager,
  DepositsColumnId.PaidSum,
  DepositsColumnId.Status,
  DepositsColumnId.CreatedAt,
  DepositsColumnId.UpdatedAt,
];

export const countPerPage = 10;
