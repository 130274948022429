import { ModalActions, ModalContext } from 'ui/Modal';
import { useContext } from 'react';
import { useApolloClient } from '@apollo/client';
import { CompanyBasicData } from 'src/queries/generated/CompanyBasicData';
import { CreateReferralLinkForm } from '../../../CreateReferralLinkForm';

interface UseOpenCreateReferralLinkModalParams {
  companyId: string;
  isFullScreenTable: boolean;
}

export const useOpenCreateReferralLinkModal = ({
  companyId,
  isFullScreenTable,
}: UseOpenCreateReferralLinkModalParams) => {
  const client = useApolloClient();
  const { dispatch } = useContext(ModalContext);

  const company = client.readFragment({
    id: `Company:${companyId}`,
    fragment: CompanyBasicData,
  });

  const openCreateReferralLinkModal = () => {
    dispatch({
      type: ModalActions.Open,
      payload: {
        title: 'Создание реферальной ссылки',
        subTitle: `${company?.name} #${companyId}`,
        content: (
          <CreateReferralLinkForm
            companyInfo={{ id: companyId, name: company.name }}
            isFullScreenTable={isFullScreenTable}
          />
        ),
      },
    });
  };

  return {
    openCreateReferralLinkModal,
  };
};
