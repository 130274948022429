import { DateFormat, formatDate } from 'src/utils/formatDate';
import NowrapCell from 'ui/Table/components/NowrapCell';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { ColumnsSource } from 'ui/Table/types';
import { createColumnHelper } from '@tanstack/react-table';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import {
  mapAffiliatesListIdToName,
  AffiliatesListColumnId,
  columnsIds,
} from '../const';
import { CompanyAffiliateBasicData } from '../../../queries/generated/CompanyAffiliateBasicData';

const columnHelper = createColumnHelper<CompanyAffiliateBasicData>();

const columnsSource: ColumnsSource<CompanyAffiliateBasicData> = [
  columnHelper.accessor(AffiliatesListColumnId.Id, {
    id: AffiliatesListColumnId.Id,
    header: mapAffiliatesListIdToName[AffiliatesListColumnId.Id],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnHelper.accessor(AffiliatesListColumnId.Name, {
    id: AffiliatesListColumnId.Name,
    header: mapAffiliatesListIdToName[AffiliatesListColumnId.Name],
  }),
  columnHelper.accessor(AffiliatesListColumnId.Note, {
    id: AffiliatesListColumnId.Note,
    header: mapAffiliatesListIdToName[AffiliatesListColumnId.Note],
  }),
  columnHelper.accessor(AffiliatesListColumnId.MediaItemsCount, {
    id: AffiliatesListColumnId.MediaItemsCount,
    header: mapAffiliatesListIdToName[AffiliatesListColumnId.MediaItemsCount],
  }),
  columnHelper.accessor(AffiliatesListColumnId.CreatedAt, {
    id: AffiliatesListColumnId.CreatedAt,
    header: mapAffiliatesListIdToName[AffiliatesListColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.DefaultDateOld)}
      </NowrapCell>
    ),
  }),
];

export const useGetAffiliatesColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
