import { formatSumWithCurrency } from 'src/utils/accounting';
import { ColumnsSource } from 'ui/Table/types';
import { createColumnHelper } from '@tanstack/react-table';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { addPercentToValue } from 'src/utils/addPercentToValue';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { formatNumberWithRuLocale } from 'src/utils/formatNumberWithRuLocale';
import { Route } from 'src/router/routes.const';
import {
  columnsIds,
  CpaCompanyReportColumnId,
  mapCpaCompanyReportColumnIdToName,
} from '../const';
import { STATISTIC_TOTAL_ROW_TITLE } from '../../../../../const';
import { MultilineCellWithLinkWithPeriodQueryParams } from '../../../../../components/MultilineCellWithLinkWithPeriodQueryParams';
import { CpaCompanyReportData } from '../../../queries/generated/CpaCompanyReportData';

const columnHelper = createColumnHelper<CpaCompanyReportData>();

const columnsSource: ColumnsSource<CpaCompanyReportData> = [
  columnHelper.accessor(CpaCompanyReportColumnId.ReferralCode, {
    id: CpaCompanyReportColumnId.ReferralCode,
    header:
      mapCpaCompanyReportColumnIdToName[CpaCompanyReportColumnId.ReferralCode],
    cell: ({ row }) => {
      if (!row.original.mediaItem) {
        return STATISTIC_TOTAL_ROW_TITLE;
      }

      const { id, name } = row.original.mediaItem;

      return (
        <MultilineCellWithLinkWithPeriodQueryParams
          id={id}
          title={name}
          subTitle={addHashTagToId(id)}
          route={Route.STATISTIC_CPA_REFERRAL_LINK}
        />
      );
    },
    enablePinning: true,
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(CpaCompanyReportColumnId.Hits, {
    id: CpaCompanyReportColumnId.Hits,
    header: mapCpaCompanyReportColumnIdToName[CpaCompanyReportColumnId.Hits],
    cell: ({ row }) => {
      const { hits } = row.original.marketingStatistic;

      return formatNumberWithRuLocale(hits);
    },
  }),
  columnHelper.accessor(CpaCompanyReportColumnId.Hosts, {
    id: CpaCompanyReportColumnId.Hosts,
    header: mapCpaCompanyReportColumnIdToName[CpaCompanyReportColumnId.Hosts],
    cell: ({ row }) => {
      const { hosts } = row.original.marketingStatistic;

      return formatNumberWithRuLocale(hosts);
    },
  }),
  columnHelper.accessor(CpaCompanyReportColumnId.Registrations, {
    id: CpaCompanyReportColumnId.Registrations,
    header:
      mapCpaCompanyReportColumnIdToName[CpaCompanyReportColumnId.Registrations],
    cell: ({ row }) => {
      const { registrations } = row.original.marketingStatistic;

      return formatNumberWithRuLocale(registrations);
    },
  }),
  columnHelper.accessor(CpaCompanyReportColumnId.FirstDeposits, {
    id: CpaCompanyReportColumnId.FirstDeposits,
    header:
      mapCpaCompanyReportColumnIdToName[CpaCompanyReportColumnId.FirstDeposits],
    cell: ({ row }) => {
      const { firstDeposits } = row.original.marketingStatistic;

      return formatNumberWithRuLocale(firstDeposits);
    },
  }),
  columnHelper.accessor(CpaCompanyReportColumnId.HostToRegConversion, {
    id: CpaCompanyReportColumnId.HostToRegConversion,
    header:
      mapCpaCompanyReportColumnIdToName[
        CpaCompanyReportColumnId.HostToRegConversion
      ],
    cell: ({ row }) => {
      const { hostToRegConversion } = row.original.marketingStatistic;

      return addPercentToValue(hostToRegConversion);
    },
  }),
  columnHelper.accessor(CpaCompanyReportColumnId.RegToDepConversion, {
    id: CpaCompanyReportColumnId.RegToDepConversion,
    header:
      mapCpaCompanyReportColumnIdToName[
        CpaCompanyReportColumnId.RegToDepConversion
      ],
    cell: ({ row }) => {
      const { regToDepConversion } = row.original.marketingStatistic;

      return addPercentToValue(regToDepConversion);
    },
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(CpaCompanyReportColumnId.AllDeposit, {
    id: CpaCompanyReportColumnId.AllDeposit,
    header:
      mapCpaCompanyReportColumnIdToName[CpaCompanyReportColumnId.AllDeposit],
    cell: ({ row }) => {
      const { allDeposit } = row.original.playerStatistic;

      return formatSumWithCurrency(allDeposit.amount, allDeposit.currency);
    },
  }),
  columnHelper.accessor(CpaCompanyReportColumnId.AverageDeposit, {
    id: CpaCompanyReportColumnId.AverageDeposit,
    header:
      mapCpaCompanyReportColumnIdToName[
        CpaCompanyReportColumnId.AverageDeposit
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.averageDeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaCompanyReportColumnId.AllRedeposit, {
    id: CpaCompanyReportColumnId.AllRedeposit,
    header:
      mapCpaCompanyReportColumnIdToName[CpaCompanyReportColumnId.AllRedeposit],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.allRedeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaCompanyReportColumnId.Redeposits, {
    id: CpaCompanyReportColumnId.Redeposits,
    header:
      mapCpaCompanyReportColumnIdToName[CpaCompanyReportColumnId.Redeposits],
    cell: ({ row }) => {
      const { redeposits } = row.original.marketingStatistic;

      return formatNumberWithRuLocale(redeposits);
    },
  }),
  columnHelper.accessor(CpaCompanyReportColumnId.NewDeposit, {
    id: CpaCompanyReportColumnId.NewDeposit,
    header:
      mapCpaCompanyReportColumnIdToName[CpaCompanyReportColumnId.NewDeposit],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerRegisteredStatistic.newDeposit;

      return formatSumWithCurrency(amount, currency);
    },
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(CpaCompanyReportColumnId.Withdrawal, {
    id: CpaCompanyReportColumnId.Withdrawal,
    header:
      mapCpaCompanyReportColumnIdToName[CpaCompanyReportColumnId.Withdrawal],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.withdrawal;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaCompanyReportColumnId.NewWithdrawal, {
    id: CpaCompanyReportColumnId.NewWithdrawal,
    header:
      mapCpaCompanyReportColumnIdToName[CpaCompanyReportColumnId.NewWithdrawal],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerRegisteredStatistic.newWithdrawal;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaCompanyReportColumnId.NewProfit, {
    id: CpaCompanyReportColumnId.NewProfit,
    header:
      mapCpaCompanyReportColumnIdToName[CpaCompanyReportColumnId.NewProfit],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerRegisteredStatistic.newProfit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaCompanyReportColumnId.Hold, {
    id: CpaCompanyReportColumnId.Hold,
    header: mapCpaCompanyReportColumnIdToName[CpaCompanyReportColumnId.Hold],
    cell: ({ row }) => {
      const { hold } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(hold);
    },
  }),
  columnHelper.accessor(CpaCompanyReportColumnId.Qualified, {
    id: CpaCompanyReportColumnId.Qualified,
    header:
      mapCpaCompanyReportColumnIdToName[CpaCompanyReportColumnId.Qualified],
    cell: ({ row }) => {
      const { qualified } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(qualified);
    },
  }),
  columnHelper.accessor(CpaCompanyReportColumnId.NotQualified, {
    id: CpaCompanyReportColumnId.NotQualified,
    header:
      mapCpaCompanyReportColumnIdToName[CpaCompanyReportColumnId.NotQualified],
    cell: ({ row }) => {
      const { canceled } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(canceled);
    },
  }),
  columnHelper.accessor(CpaCompanyReportColumnId.Approved, {
    id: CpaCompanyReportColumnId.Approved,
    header:
      mapCpaCompanyReportColumnIdToName[CpaCompanyReportColumnId.Approved],
    cell: ({ row }) => {
      const { approved } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(approved);
    },
  }),
  columnHelper.accessor(CpaCompanyReportColumnId.Rejected, {
    id: CpaCompanyReportColumnId.Rejected,
    header:
      mapCpaCompanyReportColumnIdToName[CpaCompanyReportColumnId.Rejected],
    cell: ({ row }) => {
      const { rejected } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(rejected);
    },
  }),
  columnHelper.accessor(CpaCompanyReportColumnId.Paid, {
    id: CpaCompanyReportColumnId.Paid,
    header: mapCpaCompanyReportColumnIdToName[CpaCompanyReportColumnId.Paid],
    cell: ({ row }) => {
      const { paid } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(paid);
    },
  }),
  columnHelper.accessor(CpaCompanyReportColumnId.Unpaid, {
    id: CpaCompanyReportColumnId.Unpaid,
    header: mapCpaCompanyReportColumnIdToName[CpaCompanyReportColumnId.Unpaid],
    cell: ({ row }) => {
      const { unpaid } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(unpaid);
    },
  }),
  columnHelper.accessor(CpaCompanyReportColumnId.RevShare, {
    id: CpaCompanyReportColumnId.RevShare,
    header:
      mapCpaCompanyReportColumnIdToName[CpaCompanyReportColumnId.RevShare],
    cell: ({ row }) => {
      const { revShare } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(revShare);
    },
  }),
  columnHelper.accessor(CpaCompanyReportColumnId.NGR, {
    id: CpaCompanyReportColumnId.NGR,
    header: mapCpaCompanyReportColumnIdToName[CpaCompanyReportColumnId.NGR],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerStatistic.financialIndicators.ngr;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaCompanyReportColumnId.Profit, {
    id: CpaCompanyReportColumnId.Profit,
    header: mapCpaCompanyReportColumnIdToName[CpaCompanyReportColumnId.Profit],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerStatistic.financialIndicators.profit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaCompanyReportColumnId.Upcoming, {
    id: CpaCompanyReportColumnId.Upcoming,
    header:
      mapCpaCompanyReportColumnIdToName[CpaCompanyReportColumnId.Upcoming],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.affiliateRewardStatistic.upcoming;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaCompanyReportColumnId.RewardPaid, {
    id: CpaCompanyReportColumnId.RewardPaid,
    header:
      mapCpaCompanyReportColumnIdToName[CpaCompanyReportColumnId.RewardPaid],
    cell: ({ row }) => {
      const { amount, currency } = row.original.affiliateRewardStatistic.paid;

      return formatSumWithCurrency(amount, currency);
    },
  }),
];

export const useGetCpaCompanyReportColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
