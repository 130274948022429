import { nbsp } from 'src/const';

export enum RevShareCompanyAffiliateReportColumnId {
  ReferralCode = 'mediaItem',
  Hits = 'marketingStatistic.hits',
  Hosts = 'marketingStatistic.hosts',
  Registrations = 'marketingStatistic.registrations',
  FirstDeposits = 'marketingStatistic.firstDeposits',
  HostToRegConversion = 'marketingStatistic.hostToRegConversion',
  RegToDepConversion = 'marketingStatistic.regToDepConversion',

  AllDeposit = 'playerStatistic.allDeposit',
  AverageDeposit = 'playerStatistic.averageDeposit',
  AllRedeposit = 'playerStatistic.allRedeposit',
  Redeposits = 'marketingStatistic.redeposits',
  NewDeposit = 'playerRegisteredStatistic.newDeposit',
  IncomingMoneyTransfer = 'playerStatistic.incomingMoneyTransfer',

  Withdrawal = 'playerStatistic.withdrawal',
  NewWithdrawal = 'playerRegisteredStatistic.newWithdrawal',
  NewProfit = 'playerRegisteredStatistic.newProfit',
  DepositFee = 'playerStatistic.depositFee',
  WithdrawalFee = 'playerStatistic.withdrawalFee',
  AdminFee = 'adminFee',
  Bonus = 'playerStatistic.bonus',
  TournamentPrize = 'playerStatistic.tournamentPrize',
  Jackpot = 'playerStatistic.jackpot',
  NGR = 'playerStatistic.financialIndicators.ngr',
  Profit = 'playerStatistic.financialIndicators.profit',
  Upcoming = 'affiliateUpcomingReward',
}

export const mapRevShareCompanyAffiliateReportColumnIdToName: Record<
  RevShareCompanyAffiliateReportColumnId,
  string
> = {
  [RevShareCompanyAffiliateReportColumnId.ReferralCode]: 'Реферальный код',
  [RevShareCompanyAffiliateReportColumnId.Hits]: 'Хиты',
  [RevShareCompanyAffiliateReportColumnId.Hosts]: 'Хосты',
  [RevShareCompanyAffiliateReportColumnId.Registrations]: 'Регистрации',
  [RevShareCompanyAffiliateReportColumnId.FirstDeposits]: 'Первые депозиты',
  [RevShareCompanyAffiliateReportColumnId.HostToRegConversion]: 'Host2reg',
  [RevShareCompanyAffiliateReportColumnId.RegToDepConversion]: 'Reg2dep',

  [RevShareCompanyAffiliateReportColumnId.AllDeposit]: 'Все депозиты',
  [RevShareCompanyAffiliateReportColumnId.AverageDeposit]: 'Средний депозит',
  [RevShareCompanyAffiliateReportColumnId.AllRedeposit]: 'Все редепозиты',
  [RevShareCompanyAffiliateReportColumnId.Redeposits]: 'Количество редепозитов',
  [RevShareCompanyAffiliateReportColumnId.NewDeposit]: 'Новые депозиты',
  [RevShareCompanyAffiliateReportColumnId.IncomingMoneyTransfer]:
    'Входящие переводы',

  [RevShareCompanyAffiliateReportColumnId.Withdrawal]: 'Выводы',
  [RevShareCompanyAffiliateReportColumnId.NewWithdrawal]: 'Новые выводы',
  [RevShareCompanyAffiliateReportColumnId.NewProfit]: 'Новый доход',
  [RevShareCompanyAffiliateReportColumnId.DepositFee]: `Комиссии за${nbsp}депозиты`,
  [RevShareCompanyAffiliateReportColumnId.WithdrawalFee]: `Комиссии за${nbsp}выводы`,
  [RevShareCompanyAffiliateReportColumnId.AdminFee]: 'Admin fee',
  [RevShareCompanyAffiliateReportColumnId.Bonus]: 'Бонусы',
  [RevShareCompanyAffiliateReportColumnId.TournamentPrize]: 'Призовые',
  [RevShareCompanyAffiliateReportColumnId.Jackpot]: 'Джекпоты',
  [RevShareCompanyAffiliateReportColumnId.NGR]: 'NGR',
  [RevShareCompanyAffiliateReportColumnId.Profit]: 'Доход',
  [RevShareCompanyAffiliateReportColumnId.Upcoming]: 'Вознаграждение',
};

export const columnsIds = [
  RevShareCompanyAffiliateReportColumnId.ReferralCode,
  RevShareCompanyAffiliateReportColumnId.Hits,
  RevShareCompanyAffiliateReportColumnId.Hosts,
  RevShareCompanyAffiliateReportColumnId.Registrations,
  RevShareCompanyAffiliateReportColumnId.FirstDeposits,
  RevShareCompanyAffiliateReportColumnId.HostToRegConversion,
  RevShareCompanyAffiliateReportColumnId.RegToDepConversion,

  RevShareCompanyAffiliateReportColumnId.AllDeposit,
  RevShareCompanyAffiliateReportColumnId.AverageDeposit,
  RevShareCompanyAffiliateReportColumnId.AllRedeposit,
  RevShareCompanyAffiliateReportColumnId.Redeposits,
  RevShareCompanyAffiliateReportColumnId.NewDeposit,
  RevShareCompanyAffiliateReportColumnId.IncomingMoneyTransfer,

  RevShareCompanyAffiliateReportColumnId.Withdrawal,
  RevShareCompanyAffiliateReportColumnId.NewWithdrawal,
  RevShareCompanyAffiliateReportColumnId.NewProfit,
  RevShareCompanyAffiliateReportColumnId.DepositFee,
  RevShareCompanyAffiliateReportColumnId.WithdrawalFee,
  RevShareCompanyAffiliateReportColumnId.AdminFee,
  RevShareCompanyAffiliateReportColumnId.Bonus,
  RevShareCompanyAffiliateReportColumnId.TournamentPrize,
  RevShareCompanyAffiliateReportColumnId.Jackpot,
  RevShareCompanyAffiliateReportColumnId.NGR,
  RevShareCompanyAffiliateReportColumnId.Profit,
  RevShareCompanyAffiliateReportColumnId.Upcoming,
];
