import { BrowserRouter } from 'react-router-dom';
import './styles/main.scss';
import {
  ApolloClient,
  ApolloProvider,
  from,
  fromPromise,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { FC, useEffect, useMemo } from 'react';
import { relayStylePagination } from '@apollo/client/utilities';
import { ApolloLink } from '@apollo/client/link/core';
import errorToast from 'ui/Toast/ErrorToast';
import moment from 'moment';
import {
  AccountEventTypeName,
  BonusTypeName,
  MediaItemRewardTariffTypeName,
  MediaItemTypeName,
  ReferralReviewResultTypeName,
  RestrictionTypeName,
  RewardTariffTypeName,
  TournamentsTypeName,
} from 'commonTypes';
import AppRoutes from './router/AppRouter';
import { useAuth } from './components/Auth';
import { SECONDS_BEFORE_EXPIRATION } from './components/Auth/const';
import DelayedQueueLink from './DelayedQueueLink';
import { monthArray } from './utils/formatDate';
import { apolloFetch } from './utils/apolloFetch';

const App: FC = () => {
  const auth = useAuth();

  useEffect(() => {
    window.onbeforeunload = () => {
      if (DelayedQueueLink.isNotEmpty()) {
        DelayedQueueLink.run();
      }
    };
  }, []);

  const tokenLifetimeMiddleware = new ApolloLink((operation, forward) => {
    const { accessToken } = auth;

    if (accessToken.current.token) {
      const currentTime = Math.floor(new Date().getTime() / 1000);
      const remainingTime = accessToken.current.expiredAt - currentTime;

      if (remainingTime <= SECONDS_BEFORE_EXPIRATION) {
        return fromPromise(auth.refreshToken()).flatMap(() =>
          forward(operation)
        );
      }
    }

    return forward(operation);
  });

  const withToken = setContext((_, { headers }) => {
    const { accessToken } = auth;

    return {
      headers: {
        ...headers,
        Authorization: accessToken.current.token
          ? `Bearer ${accessToken.current.token}`
          : '',
      },
    };
  });

  const logOutLink = onError(({ networkError }): void => {
    if (networkError) {
      errorToast({
        header: 'Соединение с\u00a0сервером потеряно',
        text: 'Если интернет-соединение стабильно, обратитесь к\u00a0системному администратору',
        toastId: 'network-error',
      });
    }

    if (
      networkError &&
      networkError.name === 'ServerError' &&
      'statusCode' in networkError &&
      networkError.statusCode === 401
    ) {
      auth.logOut();
    }
  });

  const authLink = tokenLifetimeMiddleware.concat(withToken.concat(logOutLink));

  const client = useMemo(() => {
    const cache = new InMemoryCache({
      possibleTypes: {
        BonusInterface: Object.values(BonusTypeName),
        RestrictionInterface: Object.values(RestrictionTypeName),
        AccountEventInterface: Object.values(AccountEventTypeName),
        TournamentInterface: Object.values(TournamentsTypeName),
        MediaItemInterface: Object.values(MediaItemTypeName),
        RewardTariffInterface: Object.values(RewardTariffTypeName),
        MediaItemRewardTariffInterface: Object.values(
          MediaItemRewardTariffTypeName
        ),
        ReferralReviewResultInterface: Object.values(
          ReferralReviewResultTypeName
        ),
      },
      typePolicies: {
        Query: {
          fields: {
            admins: relayStylePagination(['search']),
            players: relayStylePagination(['search']),
            partners: relayStylePagination(['search']),
            withdrawals: relayStylePagination(['status', 'search']),
            cpaRewardTariffs: relayStylePagination(),
            companies: relayStylePagination(),
            revShareRewardTariffs: relayStylePagination(),
            partnerApplications: relayStylePagination(),
            mediaItems: relayStylePagination(),
            mediaCampaigns: relayStylePagination(['search']),
            companyApplications: relayStylePagination(),
            companyAffiliateApplications: relayStylePagination(),
            promoCodeMediaItems: relayStylePagination(),
            qualifiedReferrals: relayStylePagination(),
            postbackDisabledNotifications: relayStylePagination(),
            rewards: relayStylePagination(['tariffType']),
            cpaGeneralReport: relayStylePagination([
              'period',
              'showAllTimeCpa',
            ]),
            revShareGeneralReport: relayStylePagination(['period']),
            qualifiedReferralConfirmationSettings: relayStylePagination(),
          },
        },
        Account: {
          fields: {
            eventGroups: relayStylePagination(),
          },
        },
        AccountEventGroup: {
          fields: {
            events: relayStylePagination(),
          },
        },
        Admin: {
          fields: {
            withdrawals: relayStylePagination(['status', 'search']),
          },
        },
        Player: {
          fields: {
            bonuses: relayStylePagination(),
            deposits: relayStylePagination(),
            notes: relayStylePagination(),
            freespins: relayStylePagination(),
            messages: relayStylePagination(),
            paymentMethods: relayStylePagination(),
            withdrawals: relayStylePagination(),
            documents: relayStylePagination(),
            moneyTransfers: relayStylePagination(),
            tournamentRebuys: relayStylePagination(),
            lastRestrictions: {
              merge: true,
            },
            restrictions: relayStylePagination(),
            signInRestrictions: relayStylePagination(),
            externalMoneyTransfers: relayStylePagination(),
          },
        },
        AccountStatistic: {
          merge: true,
        },
        Withdrawal: {
          fields: {
            payments: relayStylePagination(),
          },
        },
        Company: {
          fields: {
            affiliates: relayStylePagination(),
            referralLinkMediaItems: relayStylePagination(),
            mediaCampaigns: relayStylePagination(),
            outgoingMoneyTransfers: relayStylePagination(),
            revShareCompanyPartnerReport: relayStylePagination(['period']),
            cpaCompanyPartnerReport: relayStylePagination([
              'period',
              'showAllTimeCpa',
            ]),
            revShareReport: relayStylePagination(['period']),
            cpaReport: relayStylePagination(['period', 'showAllTimeCpa']),
          },
        },
        Partner: {
          fields: {
            referralLinkMediaItems: relayStylePagination(),
            mediaCampaigns: relayStylePagination(),
            cpaReport: relayStylePagination(['period', 'showAllTimeCpa']),
            revShareReport: relayStylePagination(['period']),
            outgoingMoneyTransfers: relayStylePagination(),
          },
        },
        CompanyAffiliate: {
          fields: {
            promoCodeMediaItems: relayStylePagination(),
            referralLinkMediaItems: relayStylePagination(),
            revShareReport: relayStylePagination(['period']),
            cpaReport: relayStylePagination(['period', 'showAllTimeCpa']),
          },
        },
        ReferralLinkMediaItem: {
          fields: {
            cpaReport: relayStylePagination(['period', 'showAllTimeCpa']),
            revShareReport: relayStylePagination(['period']),
          },
        },
      },
    });

    const httpLink = createUploadLink({
      uri: process.env.GRAPHQL_URI,
      fetch: apolloFetch,
    });

    return new ApolloClient({
      link: from([DelayedQueueLink, authLink.concat(httpLink)]),
      cache,
      defaultOptions: {
        watchQuery: {
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'cache-and-network',
          nextFetchPolicy: (lastFetchPolicy) => {
            if (
              lastFetchPolicy === 'cache-and-network' ||
              lastFetchPolicy === 'network-only'
            ) {
              return 'cache-first';
            }

            return lastFetchPolicy;
          },
          errorPolicy: 'all',
        },
        mutate: {
          errorPolicy: 'all',
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  moment.updateLocale('ru', {
    monthsShort: monthArray,
  });

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </ApolloProvider>
  );
};

export default App;
