import { FC } from 'react';
import { useParams } from 'react-router';
import { PartnerPageContainer } from '../../components/PartnerPageContainer/PartnerPageContainer';
import { PartnerReferralLinkMediaItemTable } from '../../components/PartnerMediaItemsBlock/components/PartnerReferralLinkMediaItemTable';
import { useGetPartnerReferralLinkMediaItemColumns } from '../../components/PartnerMediaItemsBlock/hooks/useGetPartnerReferralLinkMediaItemColumns';

interface PartnerMediaItemsBlockProps {
  partnerId: string;
}

const Component: FC<PartnerMediaItemsBlockProps> = ({ partnerId }) => {
  const { columns } = useGetPartnerReferralLinkMediaItemColumns(true);

  return (
    <PartnerReferralLinkMediaItemTable
      columns={columns}
      partnerId={partnerId}
      isFullScreenTable
      shouldReturnToPrevPage
    />
  );
};

export const PartnerMediaItems = () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <PartnerPageContainer partnerId={id}>
      <Component partnerId={id} />
    </PartnerPageContainer>
  );
};
