export enum WithdrawalsColumnId {
  Id = 'id',
  PaymentMethodName = 'paymentMethod.name',
  Account = 'paymentMethod.account',
  Sum = 'sum',
  PaidSum = 'paidSum',
  Status = 'status',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export const mapWithdrawalsColumnIdToName: Record<WithdrawalsColumnId, string> =
  {
    [WithdrawalsColumnId.Id]: 'ID',
    [WithdrawalsColumnId.PaymentMethodName]: 'Метод',
    [WithdrawalsColumnId.Account]: 'Номер счёта',
    [WithdrawalsColumnId.Sum]: 'Сумма',
    [WithdrawalsColumnId.PaidSum]: 'Фактическая сумма',
    [WithdrawalsColumnId.Status]: 'Статус',
    [WithdrawalsColumnId.CreatedAt]: 'Создан',
    [WithdrawalsColumnId.UpdatedAt]: 'Обновлен',
  };

export const blockColumnsIds = [
  WithdrawalsColumnId.PaymentMethodName,
  WithdrawalsColumnId.Account,
  WithdrawalsColumnId.Sum,
  WithdrawalsColumnId.Status,
  WithdrawalsColumnId.CreatedAt,
];

export const pageColumnsIds = [
  WithdrawalsColumnId.Id,
  WithdrawalsColumnId.PaymentMethodName,
  WithdrawalsColumnId.Account,
  WithdrawalsColumnId.Sum,
  WithdrawalsColumnId.PaidSum,
  WithdrawalsColumnId.Status,
  WithdrawalsColumnId.CreatedAt,
  WithdrawalsColumnId.UpdatedAt,
];
