import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { ColumnsSource } from 'ui/Table/types';
import { createColumnHelper } from '@tanstack/react-table';
import { TableCellAlign } from 'ui/Table/const';
import NowrapCell from 'ui/Table/components/NowrapCell';
import { MultilineCellWithLink } from 'ui/Table/components/MultilineCellWithLink';
import { generatePath } from 'react-router';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { Route } from 'src/router/routes.const';
import {
  PlayerExternalTransfersColumnId,
  blockColumnsIds,
  mapExternalMoneyTransfersColumnIdToName,
  pageColumnsIds,
} from '../const';
import { PlayerExternalMoneyTransferData } from '../queries/generated/PlayerExternalMoneyTransferData';

const columnHelper = createColumnHelper<PlayerExternalMoneyTransferData>();

const columnsSource: ColumnsSource<PlayerExternalMoneyTransferData> = [
  columnHelper.accessor(PlayerExternalTransfersColumnId.Id, {
    id: PlayerExternalTransfersColumnId.Id,
    header:
      mapExternalMoneyTransfersColumnIdToName[
        PlayerExternalTransfersColumnId.Id
      ],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnHelper.accessor(PlayerExternalTransfersColumnId.Affiliate, {
    id: PlayerExternalTransfersColumnId.Affiliate,
    header:
      mapExternalMoneyTransfersColumnIdToName[
        PlayerExternalTransfersColumnId.Affiliate
      ],
    cell: ({ row }) => {
      const { affiliate } = row.original;
      const { id, name } = affiliate;

      const isCompany = affiliate.__typename === 'Company';

      const email = isCompany ? affiliate.companyEmail : affiliate.partnerEmail;

      return MultilineCellWithLink({
        title: name,
        subTitle: email,
        route: generatePath(isCompany ? Route.COMPANY : Route.PARTNER, { id }),
      });
    },
  }),
  columnHelper.accessor(PlayerExternalTransfersColumnId.Sum, {
    id: PlayerExternalTransfersColumnId.Sum,
    header:
      mapExternalMoneyTransfersColumnIdToName[
        PlayerExternalTransfersColumnId.Sum
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.sum;

      return formatSumWithCurrency(amount, currency);
    },
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnHelper.accessor(PlayerExternalTransfersColumnId.CreatedAt, {
    id: PlayerExternalTransfersColumnId.CreatedAt,
    header:
      mapExternalMoneyTransfersColumnIdToName[
        PlayerExternalTransfersColumnId.CreatedAt
      ],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
];

export const useGetExternalMoneyTransfersColumns = (
  isFullScreenTable: boolean
) => {
  const columns = useGetTableColumns(
    isFullScreenTable ? pageColumnsIds : blockColumnsIds,
    columnsSource
  );

  return {
    columns,
  };
};
