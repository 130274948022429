import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useCallback, useMemo } from 'react';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { useGetPlayersLazyQuery } from '../queries/generated/GetPlayers';
import {
  getCountByWindowHeight,
  Heights,
} from '../../../utils/getCountByWindowHeight';
import { SEARCH_INPUT_HEIGHT } from '../../../const';

const cellHeight = Heights.defaultCellHeight;
const COUNT_PER_PAGE = 10;

export const useGetPlayers = () => {
  const [loadPlayers, { data, loading, error, fetchMore, refetch }] =
    useGetPlayersLazyQuery({
      variables: {
        search: '',
        first: getCountByWindowHeight(cellHeight, SEARCH_INPUT_HEIGHT),
      },
    });

  useBlockComponentState({
    loadData: loadPlayers,
    loading,
    error,
  });

  const playersList = useMemo(
    () => data?.players.edges.map(({ node }) => node),
    [data?.players.edges]
  );

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: COUNT_PER_PAGE,
            after: data?.players.pageInfo.endCursor,
          },
        },
        { shouldShowErrorToast: false }
      ),
    [data?.players.pageInfo.endCursor, fetchMore]
  );

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage: data?.players.pageInfo.hasNextPage,
    isFullScreenTable: true,
  });

  const handleRefetchClick = () => {
    refetch();
  };

  return {
    handleRefetchClick,
    playersList,
    loadPlayers,
  };
};
