export enum PartnerApplicationsColumnId {
  Id = 'id',
  PartnerInfo = 'partner',
  ContactForCommunication = 'contactForCommunication',
  TrafficSource = 'trafficSource',
  Status = 'status',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export const mapPartnerApplicationsColumnIdColumnToName: Record<
  PartnerApplicationsColumnId,
  string
> = {
  [PartnerApplicationsColumnId.Id]: 'ID',
  [PartnerApplicationsColumnId.PartnerInfo]: 'Имя пользователя и почта',
  [PartnerApplicationsColumnId.ContactForCommunication]:
    'Контактная информация',
  [PartnerApplicationsColumnId.TrafficSource]: 'Источник трафика',
  [PartnerApplicationsColumnId.Status]: 'Статус',
  [PartnerApplicationsColumnId.CreatedAt]: 'Создан',
  [PartnerApplicationsColumnId.UpdatedAt]: 'Обновлён',
};

export const columnsIds = [
  PartnerApplicationsColumnId.Id,
  PartnerApplicationsColumnId.PartnerInfo,
  PartnerApplicationsColumnId.ContactForCommunication,
  PartnerApplicationsColumnId.TrafficSource,
  PartnerApplicationsColumnId.Status,
  PartnerApplicationsColumnId.CreatedAt,
  PartnerApplicationsColumnId.UpdatedAt,
];
