import { FC, useContext } from 'react';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import Block from 'ui/Block';
import { BlockContext, withBlockProvider } from 'ui/Block/store';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useCompaniesList } from './hooks/useCompaniesList';
import { CompaniesListTable } from './components/CompaniesListTable';

const CompaniesList: FC = () => {
  const { companiesList, handleRefetchClick } = useCompaniesList();
  const { state } = useContext(BlockContext);

  return (
    <Block
      title="Компании"
      id="companyList"
      headerContent={
        <ActionButton
          actionType={ButtonType.Update}
          hidden={!state.isOpened}
          disabled={state.isLoading}
          onClick={handleRefetchClick}
        />
      }
      shrinkLoaderWrapper={false}
      emptyText="Компании не найдены"
      isEmpty={isListEmpty(companiesList)}
      shouldReturnToPrevPage={false}
      isPermanentOpened
    >
      {hasList(companiesList) && (
        <CompaniesListTable companiesList={companiesList} />
      )}
    </Block>
  );
};

export default withBlockProvider(CompaniesList);
