export enum QualifiedReferralConfirmationSettingsColumnId {
  Id = 'id',
  SettingType = 'type',
  Affiliate = 'affiliate',
  ReferralCode = 'code',
  Comment = 'comment',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export const mapQualifiedReferralConfirmationSettingsColumnIdToName: Record<
  QualifiedReferralConfirmationSettingsColumnId,
  string
> = {
  [QualifiedReferralConfirmationSettingsColumnId.Id]: 'ID',
  [QualifiedReferralConfirmationSettingsColumnId.SettingType]: 'Тип настройки',
  [QualifiedReferralConfirmationSettingsColumnId.Affiliate]: 'Партнёр/Компания',
  [QualifiedReferralConfirmationSettingsColumnId.ReferralCode]:
    'Реферальный код',
  [QualifiedReferralConfirmationSettingsColumnId.Comment]: 'Комментарий',
  [QualifiedReferralConfirmationSettingsColumnId.CreatedAt]: 'Создан',
  [QualifiedReferralConfirmationSettingsColumnId.UpdatedAt]: 'Обновлен',
};

export const columnsIds = [
  QualifiedReferralConfirmationSettingsColumnId.Id,
  QualifiedReferralConfirmationSettingsColumnId.SettingType,
  QualifiedReferralConfirmationSettingsColumnId.Affiliate,
  QualifiedReferralConfirmationSettingsColumnId.ReferralCode,
  QualifiedReferralConfirmationSettingsColumnId.Comment,
  QualifiedReferralConfirmationSettingsColumnId.CreatedAt,
  QualifiedReferralConfirmationSettingsColumnId.UpdatedAt,
];
