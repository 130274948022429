import { FC, useState } from 'react';
import { withBlockProvider } from 'ui/Block/store';
import Block from 'ui/Block';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useParams } from 'react-router';
import { RewardTariffType } from 'commonTypes';
import { StatisticBlockHeaderContent } from '../../components/StatisticBlockHeaderContent';
import {
  CPA_REFERRAL_LINK_REPORT_BLOCK_TITLE,
  STATISTIC_NO_DATA,
} from '../../const';
import { useGetCpaReferralLinkReport } from './hooks/useGetCpaReferralLinkReport';
import { CpaReferralLinkReportTable } from './components/CpaReferralLinkReportTable';
import { ReferralLinkReportBlockSubHeader } from '../../components/ReferralLinkReportBlockSubHeader';
import { useGeneratePathWithSavePeriodQueryParams } from '../../hooks/useGeneratePathWithSavePeriodQueryParams';
import { getPathParamsToOwnerPreviousCpaReport } from './helpers';

interface CpaReferralLinkReportProps {
  referralLinkId: string;
}

export const Component: FC<CpaReferralLinkReportProps> = withBlockProvider(
  ({ referralLinkId }) => {
    const [isAllTimeCpa, setIsAllTimeCpa] = useState(false);
    const { cpaReferralLinkReportList, refetch, mediaItemInfo, hasTotalRow } =
      useGetCpaReferralLinkReport({
        referralLinkId,
        isAllTimeCpa,
      });

    const generatePathWithPeriodQueryParams =
      useGeneratePathWithSavePeriodQueryParams();
    const { route, params } = getPathParamsToOwnerPreviousCpaReport(
      mediaItemInfo?.owner
    );

    const backToCpaPartnerReportUrl =
      mediaItemInfo?.owner &&
      generatePathWithPeriodQueryParams({
        originalPath: route,
        params,
      });

    return (
      <Block
        title={CPA_REFERRAL_LINK_REPORT_BLOCK_TITLE}
        id="cpaReferralLinkReport"
        headerContent={
          <StatisticBlockHeaderContent
            handleRefetchClick={refetch}
            showAllCpa={(value) => setIsAllTimeCpa(value)}
            rewardTariffType={RewardTariffType.Cpa}
          />
        }
        subHeader={
          mediaItemInfo && (
            <ReferralLinkReportBlockSubHeader mediaItemInfo={mediaItemInfo} />
          )
        }
        shrinkLoaderWrapper={false}
        emptyText={STATISTIC_NO_DATA}
        isEmpty={isListEmpty(cpaReferralLinkReportList)}
        isPermanentOpened
        shouldReturnToPrevPage
        customPathToPrevPage={backToCpaPartnerReportUrl}
      >
        {hasList(cpaReferralLinkReportList) && (
          <CpaReferralLinkReportTable
            report={cpaReferralLinkReportList}
            hasTotalRow={hasTotalRow}
          />
        )}
      </Block>
    );
  }
);

export const CpaReferralLinkReport = () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return <Component referralLinkId={id} />;
};
