import { FC } from 'react';
import { withBlockProvider } from 'ui/Block/store';
import { useLocation } from 'react-router-dom';
import AffiliatesTable from './components/AffiliatesTable';
import { useGetAffiliatesColumns } from './components/AffiliatesTable/hooks/useGetAffiliatesColumns';

interface AffiliatesBlockProps {
  id: string;
}

const AffiliatesBlock: FC<AffiliatesBlockProps> = ({ id }) => {
  const location = useLocation();
  const { columns } = useGetAffiliatesColumns();

  return (
    <AffiliatesTable
      companyId={id}
      columns={columns}
      routeToCustomPage={`${location.pathname}/affiliates`}
      isFullScreenTable={false}
    />
  );
};

export default withBlockProvider(AffiliatesBlock);
