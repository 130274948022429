import NowrapCell from 'ui/Table/components/NowrapCell/NowrapCell';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { TournamentsTypeName } from 'commonTypes';
import { ColumnsSource } from 'ui/Table/types';
import { createColumnHelper } from '@tanstack/react-table';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import {
  blockColumnsIds,
  mapTournamentRebuysColumnToName,
  pageColumnsIds,
  TournamentRebuysColumnId,
} from '../const';
import { getNameTournamentByTypeName } from '../../../const';
import { TournamentRebuysData } from '../../../queries/generated/TournamentRebuysData';

const columnHelper = createColumnHelper<TournamentRebuysData>();

const columnsSource: ColumnsSource<TournamentRebuysData> = [
  columnHelper.accessor(TournamentRebuysColumnId.Id, {
    id: TournamentRebuysColumnId.Id,
    header: mapTournamentRebuysColumnToName[TournamentRebuysColumnId.Id],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnHelper.accessor(TournamentRebuysColumnId.TournamentType, {
    id: TournamentRebuysColumnId.TournamentType,
    header:
      mapTournamentRebuysColumnToName[TournamentRebuysColumnId.TournamentType],
    cell: ({ row }) =>
      getNameTournamentByTypeName(
        row.original.tournament.__typename as TournamentsTypeName
      ),
  }),
  columnHelper.accessor(TournamentRebuysColumnId.Name, {
    id: TournamentRebuysColumnId.Name,
    header: mapTournamentRebuysColumnToName[TournamentRebuysColumnId.Name],
  }),
  columnHelper.accessor(TournamentRebuysColumnId.Sum, {
    id: TournamentRebuysColumnId.Sum,
    header: mapTournamentRebuysColumnToName[TournamentRebuysColumnId.Sum],
    cell: ({ row }) => {
      const { sum } = row.original;

      return formatSumWithCurrency(sum.amount, sum.currency);
    },
  }),
  columnHelper.accessor(TournamentRebuysColumnId.CreatedAt, {
    id: TournamentRebuysColumnId.CreatedAt,
    header: mapTournamentRebuysColumnToName[TournamentRebuysColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
];

export const useGetTournamentRebuysColumns = (isFullScreenTable: boolean) => {
  const columns = useGetTableColumns(
    isFullScreenTable ? pageColumnsIds : blockColumnsIds,
    columnsSource
  );

  return {
    columns,
  };
};
