export enum CompaniesListColumnId {
  Id = 'id',
  Name = 'name',
  Email = 'email',
  ContactForCommunication = 'contactForCommunication',
  Note = 'note.text',
}

export const mapCompaniesListIdToName: Record<CompaniesListColumnId, string> = {
  [CompaniesListColumnId.Id]: 'ID',
  [CompaniesListColumnId.Name]: 'Название',
  [CompaniesListColumnId.Email]: 'Почта',
  [CompaniesListColumnId.ContactForCommunication]:
    'Контакт\u00A0для\u00A0связи',
  [CompaniesListColumnId.Note]: 'Заметка',
};

export const columnsIds = [
  CompaniesListColumnId.Id,
  CompaniesListColumnId.Name,
  CompaniesListColumnId.Email,
  CompaniesListColumnId.ContactForCommunication,
  CompaniesListColumnId.Note,
];
