import { FC } from 'react';
import { withBlockProvider } from 'ui/Block/store';
import Block from 'ui/Block';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useParams } from 'react-router';
import { StatisticBlockHeaderContent } from '../../components/StatisticBlockHeaderContent';
import {
  REV_SHARE_REFERRAL_LINK_REPORT_BLOCK_TITLE,
  STATISTIC_NO_DATA,
} from '../../const';
import { useGetRevShareReferralLinkReport } from './hooks/useGetRevShareReferralLinkReport';
import { RevShareReferralLinkReportTable } from './components/RevShareReferralLinkReportTable';
import { ReferralLinkReportBlockSubHeader } from '../../components/ReferralLinkReportBlockSubHeader';
import { useGeneratePathWithSavePeriodQueryParams } from '../../hooks/useGeneratePathWithSavePeriodQueryParams';
import { getPathParamsToOwnerPreviousRevShareReport } from './helpers';

interface RevShareReferralLinkReportProps {
  referralLinkId: string;
}

export const Component: FC<RevShareReferralLinkReportProps> = withBlockProvider(
  ({ referralLinkId }) => {
    const {
      revShareReferralLinkReportList,
      refetch,
      mediaItemInfo,
      hasTotalRow,
    } = useGetRevShareReferralLinkReport({
      referralLinkId,
    });

    const generatePathWithPeriodQueryParams =
      useGeneratePathWithSavePeriodQueryParams();
    const { route, params } = getPathParamsToOwnerPreviousRevShareReport(
      mediaItemInfo?.owner
    );

    const backToRevSharePartnerReportUrl =
      mediaItemInfo?.owner &&
      generatePathWithPeriodQueryParams({
        originalPath: route,
        params,
      });

    return (
      <Block
        title={REV_SHARE_REFERRAL_LINK_REPORT_BLOCK_TITLE}
        id="revShareReferralLinkReport"
        headerContent={
          <StatisticBlockHeaderContent handleRefetchClick={refetch} />
        }
        subHeader={
          mediaItemInfo && (
            <ReferralLinkReportBlockSubHeader mediaItemInfo={mediaItemInfo} />
          )
        }
        shrinkLoaderWrapper={false}
        emptyText={STATISTIC_NO_DATA}
        isEmpty={isListEmpty(revShareReferralLinkReportList)}
        isPermanentOpened
        shouldReturnToPrevPage
        customPathToPrevPage={backToRevSharePartnerReportUrl}
      >
        {hasList(revShareReferralLinkReportList) && (
          <RevShareReferralLinkReportTable
            report={revShareReferralLinkReportList}
            hasTotalRow={hasTotalRow}
          />
        )}
      </Block>
    );
  }
);

export const RevShareReferralLinkReport = () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return <Component referralLinkId={id} />;
};
