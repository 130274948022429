import { FC } from 'react';
import { RewardTariffType } from 'commonTypes';
import { withBlockProvider } from 'ui/Block/store';
import Block from 'ui/Block';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { STATISTIC_BLOCK_TITLE, STATISTIC_NO_DATA } from '../../const';
import { StatisticTabs } from '../StatisticTabs';
import { StatisticBlockHeaderContent } from '../StatisticBlockHeaderContent';
import { useGetRevShareGeneralReport } from './hooks/useGetRevShareGeneralReport';
import { RevShareGeneralReportTable } from './components/RevShareGeneralReportTable';

interface RevShareGeneralReportProps {
  setTariffType: (type: RewardTariffType) => void;
}

export const RevShareGeneralReport: FC<RevShareGeneralReportProps> =
  withBlockProvider(({ setTariffType }) => {
    const { revShareGeneralReportList, refetch, hasTotalRow } =
      useGetRevShareGeneralReport();

    return (
      <Block
        title={STATISTIC_BLOCK_TITLE}
        id="statisticRevShare"
        headerContent={
          <StatisticBlockHeaderContent handleRefetchClick={refetch} />
        }
        subHeader={
          <StatisticTabs
            currentTabId={RewardTariffType.RevShare}
            setTariffType={setTariffType}
          />
        }
        shrinkLoaderWrapper={false}
        emptyText={STATISTIC_NO_DATA}
        isEmpty={isListEmpty(revShareGeneralReportList)}
        shouldReturnToPrevPage={false}
        isPermanentOpened
      >
        {hasList(revShareGeneralReportList) && (
          <RevShareGeneralReportTable
            report={revShareGeneralReportList}
            hasTotalRow={hasTotalRow}
          />
        )}
      </Block>
    );
  });
