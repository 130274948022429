import { FC, useContext } from 'react';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import Block from 'ui/Block';
import { BlockContext, withBlockProvider } from 'ui/Block/store';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { UserPrivilege } from 'generatedUserPrivilege';
import Privilege from 'commonComponents/Privilege';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import styles from './MediaCampaigns.module.scss';
import { MediaItemsTable } from './components/MediaItemsTable';
import { useOpenModal } from './hooks/useOpenModal';
import { useMediaCampaigns } from './hooks/useMediaCampaigns';

export const MediaCampaigns: FC = withBlockProvider(() => {
  const { openModal } = useOpenModal();
  const { state } = useContext(BlockContext);

  const {
    data,
    error,
    fetchMoreCallback,
    loadMediaCampaigns,
    loading,
    refetch,
    mediaCampaigns,
  } = useMediaCampaigns();

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage: data?.mediaCampaigns?.pageInfo.hasNextPage,
    isFullScreenTable: true,
  });

  useBlockComponentState({
    loadData: loadMediaCampaigns,
    loading,
    error,
  });

  const handleRefetchClick = () => {
    refetch();
  };

  return (
    <Block
      title="Медиа кампании"
      id="mediaCampaigns"
      headerContent={
        <div className={styles.headerContent}>
          <Privilege privileges={[UserPrivilege.CreateMediaCampaign]}>
            <ActionButton
              actionType={ButtonType.Add}
              hidden={!state.isOpened}
              disabled={state.isLoading}
              onClick={openModal}
            />
          </Privilege>
          <ActionButton
            actionType={ButtonType.Update}
            hidden={!state.isOpened}
            disabled={state.isLoading}
            onClick={handleRefetchClick}
          />
        </div>
      }
      shrinkLoaderWrapper={false}
      emptyText="Медиа Кампании не найдены"
      isEmpty={isListEmpty(mediaCampaigns)}
      shouldReturnToPrevPage={false}
      isPermanentOpened
    >
      {hasList(mediaCampaigns) && (
        <MediaItemsTable mediaItems={mediaCampaigns} />
      )}
    </Block>
  );
});
