import { useCallback, useMemo } from 'react';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { Currency } from 'commonTypes';
import { OrderDirection, AffiliateReportOrderBy } from 'generatedGraphql';
import { useGetCpaPartnerReportLazyQuery } from '../queries/generated/GetCpaPartnerReport';
import { useGetPeriodOfStatistic } from '../../../hooks/useGetPeriodOfStatistic';

const cellHeight = Heights.defaultCellHeight;

interface UseGetCpaPartnerReportParams {
  partnerId: string;
  isAllTimeCpa: boolean;
}

export const useGetCpaPartnerReport = ({
  partnerId,
  isAllTimeCpa,
}: UseGetCpaPartnerReportParams) => {
  const period = useGetPeriodOfStatistic();

  const [loadCpaPartnerReport, { data, loading, error, fetchMore, refetch }] =
    useGetCpaPartnerReportLazyQuery({
      variables: {
        first: getCountByWindowHeight(cellHeight, 0),
        currency: Currency.Usd,
        id: partnerId,
        ordering: {
          orderBy: AffiliateReportOrderBy.AllDeposit,
          direction: OrderDirection.Desc,
        },
        period,
        showAllTimeCpa: isAllTimeCpa,
      },
    });

  useBlockComponentState({
    loadData: loadCpaPartnerReport,
    loading,
    error,
  });

  const cpaReport = data?.partner.cpaReport;
  const edges = cpaReport?.edges;
  const total = cpaReport?.total;
  const hasTotalRow = !!total;
  const endCursor = cpaReport?.pageInfo.endCursor;
  const hasNextPage = cpaReport?.pageInfo.hasNextPage;

  const cpaPartnerReport = useMemo(() => {
    const report = edges?.map(({ node }) => node);

    if (hasTotalRow) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      report?.unshift(total);
    }

    return report;
  }, [edges, hasTotalRow, total]);

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: 10,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false }
      ),
    [endCursor, fetchMore]
  );

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage,
    isFullScreenTable: true,
  });

  return {
    refetch,
    cpaPartnerReport,
    hasTotalRow,
    partner: data?.partner,
  };
};
