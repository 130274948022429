import { createColumnHelper } from '@tanstack/react-table';
import { ColumnsSource } from 'ui/Table/types';
import { MultilineCellWithLink } from 'ui/Table/components/MultilineCellWithLink';
import { Route } from 'src/router/routes.const';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { formatNumberWithRuLocale } from 'src/utils/formatNumberWithRuLocale';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { generatePath } from 'react-router';
import MayBeNullCell from 'ui/Table/components/MayBeNullCell';
import {
  columnsIds,
  mapRevShareReferralLinkReportColumnIdToName,
  RevShareReferralLinkReportColumnId,
} from '../const';
import { RevShareReferralLinkReportData } from '../../../queries/generated/RevShareReferralLinkReportData';
import { STATISTIC_TOTAL_ROW_TITLE } from '../../../../../const';
import { AddTwoLinedSubTitle } from '../../../../../components/AddTwoLinedSubTitle';
import { getFirstDepositInfo } from '../../../../helpers';

const columnHelper = createColumnHelper<RevShareReferralLinkReportData>();

const columnsSource: ColumnsSource<RevShareReferralLinkReportData> = [
  columnHelper.accessor(RevShareReferralLinkReportColumnId.Player, {
    id: RevShareReferralLinkReportColumnId.Player,
    header:
      mapRevShareReferralLinkReportColumnIdToName[
        RevShareReferralLinkReportColumnId.Player
      ],
    cell: ({ row }) => {
      if (!row.original.player) {
        return STATISTIC_TOTAL_ROW_TITLE;
      }

      const { id, name, email, phone } = row.original.player;
      const playerUrl = generatePath(Route.PLAYER, { id });

      return (
        <MultilineCellWithLink
          isOpenInNewWindow
          title={name}
          subTitle={
            <AddTwoLinedSubTitle firstValue={email} secondValue={phone} />
          }
          route={playerUrl}
        />
      );
    },
    enablePinning: true,
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(RevShareReferralLinkReportColumnId.FirstDeposit, {
    id: RevShareReferralLinkReportColumnId.FirstDeposit,
    header:
      mapRevShareReferralLinkReportColumnIdToName[
        RevShareReferralLinkReportColumnId.FirstDeposit
      ],
    cell: ({ row }) =>
      MayBeNullCell(getFirstDepositInfo(row.original.firstDeposit), '—'),
  }),
  columnHelper.accessor(RevShareReferralLinkReportColumnId.AllDeposit, {
    id: RevShareReferralLinkReportColumnId.AllDeposit,
    header:
      mapRevShareReferralLinkReportColumnIdToName[
        RevShareReferralLinkReportColumnId.AllDeposit
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.allDeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareReferralLinkReportColumnId.AllRedeposit, {
    id: RevShareReferralLinkReportColumnId.AllRedeposit,
    header:
      mapRevShareReferralLinkReportColumnIdToName[
        RevShareReferralLinkReportColumnId.AllRedeposit
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.allRedeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareReferralLinkReportColumnId.Redeposits, {
    id: RevShareReferralLinkReportColumnId.Redeposits,
    header:
      mapRevShareReferralLinkReportColumnIdToName[
        RevShareReferralLinkReportColumnId.Redeposits
      ],
    cell: ({ row }) => formatNumberWithRuLocale(row.original.redeposits),
  }),
  columnHelper.accessor(
    RevShareReferralLinkReportColumnId.IncomingMoneyTransfer,
    {
      id: RevShareReferralLinkReportColumnId.IncomingMoneyTransfer,
      header:
        mapRevShareReferralLinkReportColumnIdToName[
          RevShareReferralLinkReportColumnId.IncomingMoneyTransfer
        ],
      cell: ({ row }) => {
        const { amount, currency } =
          row.original.playerStatistic.incomingMoneyTransfer;

        return formatSumWithCurrency(amount, currency);
      },
      meta: {
        hasRightBorder: true,
      },
    }
  ),
  columnHelper.accessor(RevShareReferralLinkReportColumnId.Withdrawal, {
    id: RevShareReferralLinkReportColumnId.Withdrawal,
    header:
      mapRevShareReferralLinkReportColumnIdToName[
        RevShareReferralLinkReportColumnId.Withdrawal
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.withdrawal;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareReferralLinkReportColumnId.DepositFee, {
    id: RevShareReferralLinkReportColumnId.DepositFee,
    header:
      mapRevShareReferralLinkReportColumnIdToName[
        RevShareReferralLinkReportColumnId.DepositFee
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.depositFee;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareReferralLinkReportColumnId.WithdrawalFee, {
    id: RevShareReferralLinkReportColumnId.WithdrawalFee,
    header:
      mapRevShareReferralLinkReportColumnIdToName[
        RevShareReferralLinkReportColumnId.WithdrawalFee
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.withdrawalFee;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareReferralLinkReportColumnId.AdminFee, {
    id: RevShareReferralLinkReportColumnId.AdminFee,
    header:
      mapRevShareReferralLinkReportColumnIdToName[
        RevShareReferralLinkReportColumnId.AdminFee
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.adminFee;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareReferralLinkReportColumnId.Bonus, {
    id: RevShareReferralLinkReportColumnId.Bonus,
    header:
      mapRevShareReferralLinkReportColumnIdToName[
        RevShareReferralLinkReportColumnId.Bonus
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.bonus;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareReferralLinkReportColumnId.TournamentPrize, {
    id: RevShareReferralLinkReportColumnId.TournamentPrize,
    header:
      mapRevShareReferralLinkReportColumnIdToName[
        RevShareReferralLinkReportColumnId.TournamentPrize
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.tournamentPrize;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareReferralLinkReportColumnId.Jackpot, {
    id: RevShareReferralLinkReportColumnId.Jackpot,
    header:
      mapRevShareReferralLinkReportColumnIdToName[
        RevShareReferralLinkReportColumnId.Jackpot
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.jackpot;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareReferralLinkReportColumnId.NGR, {
    id: RevShareReferralLinkReportColumnId.NGR,
    header:
      mapRevShareReferralLinkReportColumnIdToName[
        RevShareReferralLinkReportColumnId.NGR
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerStatistic.financialIndicators.ngr;

      return formatSumWithCurrency(amount, currency);
    },
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(RevShareReferralLinkReportColumnId.Profit, {
    id: RevShareReferralLinkReportColumnId.Profit,
    header:
      mapRevShareReferralLinkReportColumnIdToName[
        RevShareReferralLinkReportColumnId.Profit
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerStatistic.financialIndicators.profit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(
    RevShareReferralLinkReportColumnId.AffiliateUpcomingReward,
    {
      id: RevShareReferralLinkReportColumnId.AffiliateUpcomingReward,
      header:
        mapRevShareReferralLinkReportColumnIdToName[
          RevShareReferralLinkReportColumnId.AffiliateUpcomingReward
        ],
      cell: ({ row }) => {
        const { amount, currency } = row.original.affiliateUpcomingReward;

        return formatSumWithCurrency(amount, currency);
      },
    }
  ),
];

export const useGetRevShareReferralLinkColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
