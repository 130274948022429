import { nbsp } from 'src/const';

export enum RevShareCompanyReportColumnId {
  ReferralCode = 'mediaItem',
  Hits = 'marketingStatistic.hits',
  Hosts = 'marketingStatistic.hosts',
  Registrations = 'marketingStatistic.registrations',
  FirstDeposits = 'marketingStatistic.firstDeposits',
  HostToRegConversion = 'marketingStatistic.hostToRegConversion',
  RegToDepConversion = 'marketingStatistic.regToDepConversion',

  AllDeposit = 'playerStatistic.allDeposit',
  AverageDeposit = 'playerStatistic.averageDeposit',
  AllRedeposit = 'playerStatistic.allRedeposit',
  Redeposits = 'marketingStatistic.redeposits',
  NewDeposit = 'playerRegisteredStatistic.newDeposit',
  IncomingMoneyTransfer = 'playerStatistic.incomingMoneyTransfer',

  Withdrawal = 'playerStatistic.withdrawal',
  NewWithdrawal = 'playerRegisteredStatistic.newWithdrawal',
  NewProfit = 'playerRegisteredStatistic.newProfit',
  DepositFee = 'playerStatistic.depositFee',
  WithdrawalFee = 'playerStatistic.withdrawalFee',
  AdminFee = 'adminFee',
  Bonus = 'playerStatistic.bonus',
  TournamentPrize = 'playerStatistic.tournamentPrize',
  Jackpot = 'playerStatistic.jackpot',
  NGR = 'playerStatistic.financialIndicators.ngr',
  Profit = 'playerStatistic.financialIndicators.profit',
  Upcoming = 'affiliateUpcomingReward',
}

export const mapRevShareCompanyReportColumnIdToName: Record<
  RevShareCompanyReportColumnId,
  string
> = {
  [RevShareCompanyReportColumnId.ReferralCode]: 'Реферальный код',
  [RevShareCompanyReportColumnId.Hits]: 'Хиты',
  [RevShareCompanyReportColumnId.Hosts]: 'Хосты',
  [RevShareCompanyReportColumnId.Registrations]: 'Регистрации',
  [RevShareCompanyReportColumnId.FirstDeposits]: 'Первые депозиты',
  [RevShareCompanyReportColumnId.HostToRegConversion]: 'Host2reg',
  [RevShareCompanyReportColumnId.RegToDepConversion]: 'Reg2dep',

  [RevShareCompanyReportColumnId.AllDeposit]: 'Все депозиты',
  [RevShareCompanyReportColumnId.AverageDeposit]: 'Средний депозит',
  [RevShareCompanyReportColumnId.AllRedeposit]: 'Все редепозиты',
  [RevShareCompanyReportColumnId.Redeposits]: 'Количество редепозитов',
  [RevShareCompanyReportColumnId.NewDeposit]: 'Новые депозиты',
  [RevShareCompanyReportColumnId.IncomingMoneyTransfer]: 'Входящие переводы',

  [RevShareCompanyReportColumnId.Withdrawal]: 'Выводы',
  [RevShareCompanyReportColumnId.NewWithdrawal]: 'Новые выводы',
  [RevShareCompanyReportColumnId.NewProfit]: 'Новый доход',
  [RevShareCompanyReportColumnId.DepositFee]: `Комиссии за${nbsp}депозиты`,
  [RevShareCompanyReportColumnId.WithdrawalFee]: `Комиссии за${nbsp}выводы`,
  [RevShareCompanyReportColumnId.AdminFee]: 'Admin fee',
  [RevShareCompanyReportColumnId.Bonus]: 'Бонусы',
  [RevShareCompanyReportColumnId.TournamentPrize]: 'Призовые',
  [RevShareCompanyReportColumnId.Jackpot]: 'Джекпоты',
  [RevShareCompanyReportColumnId.NGR]: 'NGR',
  [RevShareCompanyReportColumnId.Profit]: 'Доход',
  [RevShareCompanyReportColumnId.Upcoming]: 'Вознаграждение',
};

export const columnsIds = [
  RevShareCompanyReportColumnId.ReferralCode,
  RevShareCompanyReportColumnId.Hits,
  RevShareCompanyReportColumnId.Hosts,
  RevShareCompanyReportColumnId.Registrations,
  RevShareCompanyReportColumnId.FirstDeposits,
  RevShareCompanyReportColumnId.HostToRegConversion,
  RevShareCompanyReportColumnId.RegToDepConversion,

  RevShareCompanyReportColumnId.AllDeposit,
  RevShareCompanyReportColumnId.AverageDeposit,
  RevShareCompanyReportColumnId.AllRedeposit,
  RevShareCompanyReportColumnId.Redeposits,
  RevShareCompanyReportColumnId.NewDeposit,
  RevShareCompanyReportColumnId.IncomingMoneyTransfer,

  RevShareCompanyReportColumnId.Withdrawal,
  RevShareCompanyReportColumnId.NewWithdrawal,
  RevShareCompanyReportColumnId.NewProfit,
  RevShareCompanyReportColumnId.DepositFee,
  RevShareCompanyReportColumnId.WithdrawalFee,
  RevShareCompanyReportColumnId.AdminFee,
  RevShareCompanyReportColumnId.Bonus,
  RevShareCompanyReportColumnId.TournamentPrize,
  RevShareCompanyReportColumnId.Jackpot,
  RevShareCompanyReportColumnId.NGR,
  RevShareCompanyReportColumnId.Profit,
  RevShareCompanyReportColumnId.Upcoming,
];
