import { FC, useState } from 'react';
import Block from 'ui/Block';
import { withBlockProvider } from 'ui/Block/store';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useParams } from 'react-router';
import { Route } from 'src/router/routes.const';
import { AffiliateTypeName, RewardTariffType } from 'commonTypes';
import { CpaPartnerReportTable } from './components/CpaPartnerReportTable';
import { useGetCpaPartnerReport } from './hooks/useGetCpaPartnerReport';
import {
  CPA_PARTNER_STATISTIC_BLOCK_TITLE,
  STATISTIC_NO_DATA,
} from '../../const';
import { BlockSubHeaderAffiliateInfo } from '../../components/BlockSubHeaderAffiliateInfo';
import { useGeneratePathWithSavePeriodQueryParams } from '../../hooks/useGeneratePathWithSavePeriodQueryParams';
import { StatisticBlockHeaderContent } from '../../components/StatisticBlockHeaderContent';

interface CpaPartnerReportProps {
  partnerId: string;
}

const Component: FC<CpaPartnerReportProps> = withBlockProvider(
  ({ partnerId }) => {
    const [isAllTimeCpa, setIsAllTimeCpa] = useState(false);
    const { cpaPartnerReport, partner, refetch, hasTotalRow } =
      useGetCpaPartnerReport({ partnerId, isAllTimeCpa });

    const generatePathWithPeriodQueryParams =
      useGeneratePathWithSavePeriodQueryParams();

    const customPathToPrevPage = generatePathWithPeriodQueryParams({
      originalPath: Route.STATISTIC,
    });

    return (
      <Block
        title={CPA_PARTNER_STATISTIC_BLOCK_TITLE}
        id="cpaPartnerStatistic"
        headerContent={
          <StatisticBlockHeaderContent
            handleRefetchClick={refetch}
            rewardTariffType={RewardTariffType.Cpa}
            showAllCpa={(value) => setIsAllTimeCpa(value)}
          />
        }
        subHeader={
          partner && (
            <BlockSubHeaderAffiliateInfo
              name={partner.name}
              email={partner.email}
              affiliateType={AffiliateTypeName.Partner}
            />
          )
        }
        shrinkLoaderWrapper={false}
        emptyText={STATISTIC_NO_DATA}
        isEmpty={isListEmpty(cpaPartnerReport)}
        shouldReturnToPrevPage
        isPermanentOpened
        customPathToPrevPage={customPathToPrevPage}
      >
        {hasList(cpaPartnerReport) && (
          <CpaPartnerReportTable
            cpaPartnerReport={cpaPartnerReport}
            hasTotalRow={hasTotalRow}
          />
        )}
      </Block>
    );
  }
);

export const CpaPartnerReport = () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return <Component partnerId={id} />;
};
