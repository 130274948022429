
    export enum UserPrivilege {
      ViewAccountEventgroup = "view_account_eventgroup",
ViewAdminWithdrawal = "view_admin_withdrawal",
ViewCompanyAffiliate = "view_company_affiliate",
ViewCompanyCpaCompanyPartnerReport = "view_company_cpa_company_partner_report",
ViewCompanyCpaReport = "view_company_cpa_report",
ViewCompanyMediaCampaign = "view_company_media_campaign",
ViewCompanyNote = "view_company_note",
ViewCompanyOutgoingMoneyTransfer = "view_company_outgoing_money_transfer",
ViewQualifiedReferralConfirmationSetting = "view_qualified_referral_confirmation_setting",
ViewCompanyReferralLinkMediaItem = "view_company_referral_link_media_item",
ViewCompanyRevShareCompanyPartnerReport = "view_company_rev_share_company_partner_report",
ViewCompanyRevShareReport = "view_company_rev_share_report",
ViewCompanyAffiliateCpaReport = "view_company_affiliate_cpa_report",
ViewCompanyAffiliateNote = "view_company_affiliate_note",
ViewCompanyAffiliatePromoCodeMediaItem = "view_company_affiliate_promo_code_media_item",
ViewCompanyAffiliateReferralLinkMediaItem = "view_company_affiliate_referral_link_media_item",
ViewCompanyAffiliateRevShareReport = "view_company_affiliate_rev_share_report",
ViewCompanyAffiliateApplicationItemNote = "view_company_affiliate_application_item_note",
AcceptAllRewards = "accept_all_rewards",
AcceptCompanyAffiliateApplication = "accept_company_affiliate_application",
AcceptCompanyApplication = "accept_company_application",
AcceptDeposit = "accept_deposit",
AcceptPartnerApplication = "accept_partner_application",
AcceptPayment = "accept_payment",
AcceptReward = "accept_reward",
AddCompanyAffiliates = "add_company_affiliates",
ApproveAllQualifiedReferrals = "approve_all_qualified_referrals",
ApproveCompanyPlayerAccount = "approve_company_player_account",
ApprovePartnerPlayerAccount = "approve_partner_player_account",
ApproveQualifiedReferral = "approve_qualified_referral",
AssignCompanyAffiliateApplication = "assign_company_affiliate_application",
AssignCompanyApplication = "assign_company_application",
AssignPartnerApplication = "assign_partner_application",
AssignWithdrawal = "assign_withdrawal",
CancelBadBankRestriction = "cancel_bad_bank_restriction",
CancelChargebackRestriction = "cancel_chargeback_restriction",
CancelGiftBonus = "cancel_gift_bonus",
CancelReceiveGiftRestriction = "cancel_receive_gift_restriction",
CancelSignInRestriction = "cancel_sign_in_restriction",
CancelWithdrawalRestriction = "cancel_withdrawal_restriction",
ConfirmCompanyAffiliateApplication = "confirm_company_affiliate_application",
CreateAdmin = "create_admin",
CreateBadBankRestriction = "create_bad_bank_restriction",
CreateChargebackRestriction = "create_chargeback_restriction",
CreateCompanyAffiliatePromoCodeMediaItem = "create_company_affiliate_promo_code_media_item",
CreateCompanyAffiliateReferralLinkMediaItem = "create_company_affiliate_referral_link_media_item",
CreateCompanyReferralLinkMediaItem = "create_company_referral_link_media_item",
CreateCpaRewardTariff = "create_cpa_reward_tariff",
CreateDocuments = "create_documents",
CreateFreespin = "create_freespin",
CreateGiftBonus = "create_gift_bonus",
CreateMediaCampaign = "create_media_campaign",
CreateMessage = "create_message",
CreatePartnerReferralLinkMediaItem = "create_partner_referral_link_media_item",
CreatePayment = "create_payment",
CreatePromoCodeMediaItem = "create_promo_code_media_item",
CreateQualifiedReferralConfirmationSetting = "create_qualified_referral_confirmation_setting",
CreateReceiveGiftRestriction = "create_receive_gift_restriction",
CreateReferralLinkMediaItem = "create_referral_link_media_item",
CreateRevShareRewardTariff = "create_rev_share_reward_tariff",
CreateSignInRestriction = "create_sign_in_restriction",
CreateWithdrawalRestriction = "create_withdrawal_restriction",
DeclineCompanyAffiliateApplication = "decline_company_affiliate_application",
DeclineCompanyApplication = "decline_company_application",
DeclineCompanyPlayerAccount = "decline_company_player_account",
DeclinePartnerApplication = "decline_partner_application",
DeclinePartnerPlayerAccount = "decline_partner_player_account",
DeclinePayment = "decline_payment",
DeclineReward = "decline_reward",
DeclineWithdrawal = "decline_withdrawal",
DeleteAdmin = "delete_admin",
DeleteAllPostbackDisabledNotifications = "delete_all_postback_disabled_notifications",
DeleteDocument = "delete_document",
DeleteMessage = "delete_message",
DeletePlayer = "delete_player",
DeletePostbackDisabledNotification = "delete_postback_disabled_notification",
DeleteQualifiedReferralConfirmationSetting = "delete_qualified_referral_confirmation_setting",
EnableAllPostbacks = "enable_all_postbacks",
EnablePostback = "enable_postback",
RejectAllQualifiedReferrals = "reject_all_qualified_referrals",
RejectQualifiedReferral = "reject_qualified_referral",
ResetPassword = "reset_password",
ResetCompanyTwoFactor = "reset_company_two_factor",
ResetPartnerName = "reset_partner_name",
ResetPartnerTwoFactor = "reset_partner_two_factor",
ResetPlayerName = "reset_player_name",
ResetPlayerTwoFactor = "reset_player_two_factor",
RevertDepositToPresumablyDeclined = "revert_deposit_to_presumably_declined",
SuspectQualifiedReferral = "suspect_qualified_referral",
SuspectReward = "suspect_reward",
SuspendCompanyAffiliateApplication = "suspend_company_affiliate_application",
SuspendCompanyApplication = "suspend_company_application",
SuspendPartnerApplication = "suspend_partner_application",
SuspendWithdrawal = "suspend_withdrawal",
UndeletePlayer = "undelete_player",
UnsuspectQualifiedReferral = "unsuspect_qualified_referral",
UnsuspectReward = "unsuspect_reward",
UpdateAdmin = "update_admin",
UpdateCompanyAffiliateApplication = "update_company_affiliate_application",
UpdateCompanyAffiliateApplicationItem = "update_company_affiliate_application_item",
UpdateCompanyContactForCommunication = "update_company_contact_for_communication",
UpdateCompanyIdentities = "update_company_identities",
UpdateCompanyName = "update_company_name",
UpdateCompanyNote = "update_company_note",
UpdateCompanyPersonalData = "update_company_personal_data",
UpdateCpaRewardTariffTitle = "update_cpa_reward_tariff_title",
UpdateDocumentNote = "update_document_note",
UpdateMediaCampaign = "update_media_campaign",
UpdatePartnerIdentities = "update_partner_identities",
UpdatePartnerNote = "update_partner_note",
UpdatePlayerIdentities = "update_player_identities",
UpdatePlayerNote = "update_player_note",
UpdatePlayerRank = "update_player_rank",
UpdateQualifiedReferralConfirmationSetting = "update_qualified_referral_confirmation_setting",
UpdateReferralLinkMediaItemName = "update_referral_link_media_item_name",
UploadFile = "upload_file",
ViewPartnerCpaReport = "view_partner_cpa_report",
ViewPartnerMediaCampaign = "view_partner_media_campaign",
ViewPartnerNote = "view_partner_note",
ViewPartnerOutgoingMoneyTransfer = "view_partner_outgoing_money_transfer",
ViewPartnerReferralLinkMediaItem = "view_partner_referral_link_media_item",
ViewPartnerRevShareReport = "view_partner_rev_share_report",
ViewPaymentmethodAccount = "view_paymentmethod_account",
ViewPlayerAccount = "view_player_account",
ViewPlayerBonus = "view_player_bonus",
ViewPlayerDeposit = "view_player_deposit",
ViewPlayerDocument = "view_player_document",
ViewPlayerExternalMoneyTransfer = "view_player_external_money_transfer",
ViewPlayerFreespin = "view_player_freespin",
ViewPlayerLastRestriction = "view_player_last_restriction",
ViewPlayerMessage = "view_player_message",
ViewPlayerMoneytransfer = "view_player_moneytransfer",
ViewPlayerNote = "view_player_note",
ViewPlayerPaymentmethod = "view_player_paymentmethod",
ViewPlayerRestriction = "view_player_restriction",
ViewPlayerSignInRestriction = "view_player_sign_in_restriction",
ViewPlayerStatistic = "view_player_statistic",
ViewPlayerTournamentrebuy = "view_player_tournamentrebuy",
ViewPlayerWithdrawal = "view_player_withdrawal",
ViewAccount = "view_account",
ViewAccounteventgroup = "view_accounteventgroup",
ViewAdmin = "view_admin",
ViewBonus = "view_bonus",
ViewCompany = "view_company",
ViewCompanyAffiliateApplication = "view_company_affiliate_application",
ViewCompanyApplication = "view_company_application",
ConvertCurrency = "convert_currency",
ViewCountries = "view_countries",
ViewCpaGeneralReport = "view_cpa_general_report",
ViewCpaRewardTariff = "view_cpa_reward_tariff",
ViewDeposit = "view_deposit",
ViewFreespin = "view_freespin",
ViewGame = "view_game",
GenerateReferralLinkCode = "generate_referral_link_code",
ViewMediaCampaign = "view_media_campaign",
ViewMediaItem = "view_media_item",
ViewMessage = "view_message",
ViewMoneytransfer = "view_moneytransfer",
ViewPartner = "view_partner",
ViewPartnerApplication = "view_partner_application",
ViewPlayer = "view_player",
ViewPostbackDisabledNotification = "view_postback_disabled_notification",
ViewPromoCodeMediaItem = "view_promo_code_media_item",
ViewQualifiedReferral = "view_qualified_referral",
ViewRestriction = "view_restriction",
ViewRevShareGeneralReport = "view_rev_share_general_report",
ViewRevShareRewardTariff = "view_rev_share_reward_tariff",
ViewReward = "view_reward",
ViewSignInRestriction = "view_sign_in_restriction",
ViewTournamentrebuy = "view_tournamentrebuy",
ViewWithdrawal = "view_withdrawal",
ViewReferralLinkMediaItemCpaReport = "view_referral_link_media_item_cpa_report",
ViewReferralLinkMediaItemRevShareReport = "view_referral_link_media_item_rev_share_report"
    }
  