export enum OutgoingMoneyTransferColumnId {
  Id = 'id',
  CreatedAt = 'createdAt',
  MoneyTransferAccount = 'player.moneyTransferAccount',
  Sum = 'sum',
  Player = 'player',
}

export const mapOutgoingMoneyTransferColumnIdToName: Record<
  OutgoingMoneyTransferColumnId,
  string
> = {
  [OutgoingMoneyTransferColumnId.Id]: 'ID',
  [OutgoingMoneyTransferColumnId.CreatedAt]: 'Создан',
  [OutgoingMoneyTransferColumnId.MoneyTransferAccount]: 'Номер счёта',
  [OutgoingMoneyTransferColumnId.Sum]: 'Сумма',
  [OutgoingMoneyTransferColumnId.Player]: 'Получатель',
};

export const blockColumnsIds = [
  OutgoingMoneyTransferColumnId.MoneyTransferAccount,
  OutgoingMoneyTransferColumnId.Player,
  OutgoingMoneyTransferColumnId.Sum,
  OutgoingMoneyTransferColumnId.CreatedAt,
];

export const pageColumnsIds = [
  OutgoingMoneyTransferColumnId.Id,
  OutgoingMoneyTransferColumnId.MoneyTransferAccount,
  OutgoingMoneyTransferColumnId.Player,
  OutgoingMoneyTransferColumnId.Sum,
  OutgoingMoneyTransferColumnId.CreatedAt,
];
