import { useCallback, useMemo } from 'react';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import {
  getCountByWindowHeight,
  Heights,
} from '../../../utils/getCountByWindowHeight';
import { useGetCompaniesLazyQuery } from '../queries/generated/GetCompanies';

const cellHeight = Heights.defaultCellHeight;
const COUNT_PER_PAGE = 10;

export const useCompaniesList = () => {
  const [loadCompaniesList, { data, loading, error, fetchMore, refetch }] =
    useGetCompaniesLazyQuery({
      variables: {
        first: getCountByWindowHeight(cellHeight, 0),
      },
    });

  useBlockComponentState({
    loadData: loadCompaniesList,
    loading,
    error,
  });

  const companiesList = useMemo(
    () => data?.companies?.edges.map(({ node }) => node),
    [data?.companies?.edges]
  );

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: COUNT_PER_PAGE,
            after: data?.companies?.pageInfo.endCursor,
          },
        },
        { shouldShowErrorToast: false }
      ),
    [data?.companies?.pageInfo.endCursor, fetchMore]
  );

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage: data?.companies?.pageInfo.hasNextPage,
    isFullScreenTable: true,
  });

  const handleRefetchClick = () => {
    refetch();
  };

  return {
    handleRefetchClick,
    companiesList,
  };
};
