import { FC } from 'react';
import { Dropdown } from 'ui/Dropdown';
import { useGetCompanyAffiliatesQuery } from './hooks/useGetCompanyAffiliatesQuery';
import { getCompanyAffiliatesOptions } from './helpers';

interface CompanyAffiliatesDropdownProps {
  name: string;
  label: string;
  companyId: string;
}

export const CompanyAffiliatesDropdown: FC<CompanyAffiliatesDropdownProps> = ({
  name,
  label,
  companyId,
}) => {
  const { data, loading, hasNextPage, fetchMore, error } =
    useGetCompanyAffiliatesQuery(companyId);

  const companyAffiliatesOptions = getCompanyAffiliatesOptions(
    data?.company.affiliates.edges
  );

  return (
    <Dropdown
      fetchMoreConfig={{
        fetchMore: () => {
          if (hasNextPage) {
            fetchMore();
          }
        },
        hasNextPage,
      }}
      loading={loading}
      loadingError={error}
      name={name}
      label={label}
      options={companyAffiliatesOptions}
    />
  );
};
