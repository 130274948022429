import {
  Heights,
  getCountByWindowHeight,
} from 'src/utils/getCountByWindowHeight';
import { useGetPartnerReferralLinkMediaItemsLazyQuery } from '../queries/generated/GetPartnerReferralLinkMediaItems';
import { useGetPartnerReferralLinkMediaItemsBlockLazyQuery } from '../queries/generated/GetPartnerReferralLinkMediaItemsBlock';
import { COUNT_PER_PAGE } from '../const';

interface UseGetPartnerReferralLinkMediaItemsByTableSizeParams {
  isFullScreenTable: boolean;
  partnerId: string;
}
export const useGetPartnerReferralLinkMediaItemsByTableSize = ({
  partnerId,
  isFullScreenTable,
}: UseGetPartnerReferralLinkMediaItemsByTableSizeParams) => {
  const countPerPage = isFullScreenTable
    ? getCountByWindowHeight(Heights.defaultCellHeight)
    : COUNT_PER_PAGE;

  const currentUseGetPartnerReferralLinkMediaItems = isFullScreenTable
    ? useGetPartnerReferralLinkMediaItemsLazyQuery
    : useGetPartnerReferralLinkMediaItemsBlockLazyQuery;

  const [
    loadPartnerReferralLinkMediaItems,
    { data, loading, error, fetchMore, refetch },
  ] = currentUseGetPartnerReferralLinkMediaItems({
    variables: {
      partnerId,
      first: countPerPage,
    },
  });

  const referralLinkMediaItemsData = data?.partner.referralLinkMediaItems;

  return {
    referralLinkMediaItemsData,
    loadPartnerReferralLinkMediaItems,
    loading,
    error,
    refetch,
    fetchMore,
  };
};
