import { useCallback, useContext, useMemo } from 'react';
import { BlockContext } from 'ui/Block/store';
import Block from 'ui/Block';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import BlockTable from 'ui/Block/BlockTable';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { UserPrivilege } from 'generatedUserPrivilege';
import Privilege from 'commonComponents/Privilege';
import { ColumnDef } from '@tanstack/react-table';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import styles from './PartnerReferralLinkMediaItemTable.module.scss';
import { useGetPartnerReferralLinkMediaItemsByTableSize } from './hooks/useGetPartnerReferralLinkMediaItemsByTableSize';
import { COUNT_PER_PAGE } from './const';
import { useOpenCreateReferralLinkModal } from './useOpenCreateReferralLinkModal';
import { useOpenReferralLinkDetailsModal } from './hooks/useOpenReferralLinkDetailsModal';

interface PartnerReferralLinkMediaItemTableProps<T extends object> {
  isFullScreenTable: boolean;
  partnerId: string;
  shouldReturnToPrevPage?: boolean;
  routeToCustomPage?: string;
  columns: Array<ColumnDef<T>>;
}

export const PartnerReferralLinkMediaItemTable = <
  T extends { __typename: string }
>({
  isFullScreenTable,
  partnerId,
  shouldReturnToPrevPage,
  routeToCustomPage,
  columns,
}: PartnerReferralLinkMediaItemTableProps<T>) => {
  const { state } = useContext(BlockContext);
  const { openReferralLinkDetailsModal } =
    useOpenReferralLinkDetailsModal<T>(partnerId);
  const {
    referralLinkMediaItemsData,
    fetchMore,
    loadPartnerReferralLinkMediaItems,
    loading,
    error,
    refetch,
  } = useGetPartnerReferralLinkMediaItemsByTableSize({
    isFullScreenTable,
    partnerId,
  });

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: COUNT_PER_PAGE,
            after: referralLinkMediaItemsData?.pageInfo.endCursor,
          },
        },
        { shouldShowErrorToast: false }
      ),
    [referralLinkMediaItemsData?.pageInfo.endCursor, fetchMore]
  );

  useBlockComponentState({
    loadData: loadPartnerReferralLinkMediaItems,
    loading,
    error,
  });

  useBlockTableComponentState({
    data: referralLinkMediaItemsData,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage: referralLinkMediaItemsData?.pageInfo.hasNextPage,
    isFullScreenTable,
  });

  const referralLinkMediaItems = useMemo(
    () =>
      referralLinkMediaItemsData?.edges.map(
        ({ node }) => node
      ) as unknown as Array<T>,
    [referralLinkMediaItemsData]
  );

  const refetchPartnerMediaItemsReferralLink = () => {
    refetch?.();
  };

  const { openCreateReferralLinkModal } = useOpenCreateReferralLinkModal({
    partnerId,
    isFullScreenTable,
  });

  return (
    <Block
      title="Медиа элементы"
      id="partnerMediaItemsReferralLink"
      headerContent={
        <div className={styles.headerContent}>
          <Privilege
            privileges={[UserPrivilege.CreatePartnerReferralLinkMediaItem]}
          >
            <ActionButton
              actionType={ButtonType.Add}
              onClick={openCreateReferralLinkModal}
            />
          </Privilege>
          <ActionButton
            actionType={ButtonType.Update}
            hidden={!state.isOpened}
            disabled={state.isLoading}
            onClick={refetchPartnerMediaItemsReferralLink}
          />
        </div>
      }
      shrinkLoaderWrapper={false}
      emptyText="Медиа элементы не найдены"
      isEmpty={isListEmpty(referralLinkMediaItems)}
      shouldReturnToPrevPage={shouldReturnToPrevPage}
    >
      {hasList(referralLinkMediaItems) && (
        <BlockTable
          isFullScreenTable={isFullScreenTable}
          data={referralLinkMediaItems}
          columns={columns}
          routeToCustomPage={routeToCustomPage}
          onClickOnRow={openReferralLinkDetailsModal}
        />
      )}
    </Block>
  );
};
