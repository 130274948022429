import MayBeNullCell from 'ui/Table/components/MayBeNullCell';
import NowrapCell from 'ui/Table/components/NowrapCell';
import MultilineCellWithIcon from 'ui/Table/components/MultilineCellWithIcon';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { ColumnsSource } from 'ui/Table/types';
import { createColumnHelper } from '@tanstack/react-table';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { getStatusAndIconConfig, restrictionTypeMap } from '../helpers';
import {
  blockColumnsIds,
  mapRestrictionsColumnIdToName,
  pageColumnsIds,
  RestrictionsColumnId,
} from '../const';
import { RestrictionsData } from '../queries/generated/RestrictionsData';

const columnHelper = createColumnHelper<RestrictionsData>();

const columnsSource: ColumnsSource<RestrictionsData> = [
  columnHelper.accessor(RestrictionsColumnId.Id, {
    id: RestrictionsColumnId.Id,
    header: mapRestrictionsColumnIdToName[RestrictionsColumnId.Id],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnHelper.accessor(RestrictionsColumnId.Type, {
    id: RestrictionsColumnId.Type,
    header: mapRestrictionsColumnIdToName[RestrictionsColumnId.Type],
    cell: ({ row }) => restrictionTypeMap[row.original.__typename],
  }),
  columnHelper.accessor(RestrictionsColumnId.Reason, {
    id: RestrictionsColumnId.Reason,
    header: mapRestrictionsColumnIdToName[RestrictionsColumnId.Reason],
    cell: ({ row }) => MayBeNullCell(row.original.reason, '—'),
  }),
  columnHelper.accessor(RestrictionsColumnId.Type, {
    id: RestrictionsColumnId.Status,
    header: mapRestrictionsColumnIdToName[RestrictionsColumnId.Status],
    cell: ({ row }) => {
      const { status, iconConfig } = getStatusAndIconConfig(row.original);

      return MultilineCellWithIcon({
        title: status,
        iconConfig: iconConfig.icon ? iconConfig : undefined,
      });
    },
  }),
  columnHelper.accessor(RestrictionsColumnId.CreatedAt, {
    id: RestrictionsColumnId.CreatedAt,
    header: mapRestrictionsColumnIdToName[RestrictionsColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
  columnHelper.accessor(RestrictionsColumnId.UpdatedAt, {
    id: RestrictionsColumnId.UpdatedAt,
    header: mapRestrictionsColumnIdToName[RestrictionsColumnId.UpdatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.updatedAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
];

export const useGetRestrictionHistoryColumns = (isFullScreenTable: boolean) => {
  const columns = useGetTableColumns(
    isFullScreenTable ? pageColumnsIds : blockColumnsIds,
    columnsSource
  );

  return {
    columns,
  };
};
