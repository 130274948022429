export enum CompanyReferralLinksColumnsId {
  Id = 'id',
  Name = 'name',
  MediaCampaign = 'mediaCampaign',
  ReferralLink = 'referralLink',
  PostbackCount = 'postbackCount',
  RewardTariff = 'mediaItemRewardTariff.rewardTariff.title',
  CreatedAt = 'createdAt',
}

export const mapCompanyReferralLinksColumnsIdToName: Record<
  CompanyReferralLinksColumnsId,
  string
> = {
  [CompanyReferralLinksColumnsId.Id]: 'ID',
  [CompanyReferralLinksColumnsId.Name]: 'Название',
  [CompanyReferralLinksColumnsId.MediaCampaign]: 'Медиа кампания',
  [CompanyReferralLinksColumnsId.ReferralLink]: 'Реферальная ссылка',
  [CompanyReferralLinksColumnsId.PostbackCount]: 'Постбэк',
  [CompanyReferralLinksColumnsId.RewardTariff]: 'Партнёрский тариф',
  [CompanyReferralLinksColumnsId.CreatedAt]: 'Создан',
};

export const blockColumnsIds = [
  CompanyReferralLinksColumnsId.Name,
  CompanyReferralLinksColumnsId.MediaCampaign,
  CompanyReferralLinksColumnsId.ReferralLink,
  CompanyReferralLinksColumnsId.RewardTariff,
  CompanyReferralLinksColumnsId.CreatedAt,
];

export const pageColumnsIds = [
  CompanyReferralLinksColumnsId.Id,
  CompanyReferralLinksColumnsId.Name,
  CompanyReferralLinksColumnsId.MediaCampaign,
  CompanyReferralLinksColumnsId.ReferralLink,
  CompanyReferralLinksColumnsId.PostbackCount,
  CompanyReferralLinksColumnsId.RewardTariff,
  CompanyReferralLinksColumnsId.CreatedAt,
];
