import { nbsp } from 'src/const';

export enum CpaPartnerReportColumnId {
  ReferralCode = 'mediaItem',
  Hits = 'marketingStatistic.hits',
  Hosts = 'marketingStatistic.hosts',
  Registrations = 'marketingStatistic.registrations',
  FirstDeposits = 'marketingStatistic.firstDeposits',
  HostToRegConversion = 'marketingStatistic.hostToRegConversion',
  RegToDepConversion = 'marketingStatistic.regToDepConversion',

  AllDeposit = 'playerStatistic.allDeposit',
  AverageDeposit = 'playerStatistic.averageDeposit',
  AllRedeposit = 'playerStatistic.allRedeposit',
  Redeposits = 'marketingStatistic.redeposits',
  NewDeposit = 'playerRegisteredStatistic.newDeposit',

  Withdrawal = 'playerStatistic.withdrawal',
  NewWithdrawal = 'playerRegisteredStatistic.newWithdrawal',
  NewProfit = 'playerRegisteredStatistic.newProfit',
  Hold = 'playerStatusStatistic.hold',
  Qualified = 'playerStatusStatistic.qualified',
  NotQualified = 'playerStatusStatistic.canceled',
  Approved = 'playerStatusStatistic.approved',
  Rejected = 'playerStatusStatistic.rejected',
  Paid = 'playerStatusStatistic.paid',
  Unpaid = 'playerStatusStatistic.unpaid',
  RevShare = 'playerStatusStatistic.revShare',
  NGR = 'playerStatistic.financialIndicators.ngr',
  Profit = 'playerStatistic.financialIndicators.profit',
  Upcoming = 'affiliateRewardStatistic.upcoming',
  RewardPaid = 'affiliateRewardStatistic.paid',
}

export const mapCpaPartnerReportColumnIdToName: Record<
  CpaPartnerReportColumnId,
  string
> = {
  [CpaPartnerReportColumnId.ReferralCode]: 'Реферальный код',
  [CpaPartnerReportColumnId.Hits]: 'Хиты',
  [CpaPartnerReportColumnId.Hosts]: 'Хосты',
  [CpaPartnerReportColumnId.Registrations]: 'Регистрации',
  [CpaPartnerReportColumnId.FirstDeposits]: 'Первые депозиты',
  [CpaPartnerReportColumnId.HostToRegConversion]: 'Host2reg',
  [CpaPartnerReportColumnId.RegToDepConversion]: 'Reg2dep',

  [CpaPartnerReportColumnId.AllDeposit]: 'Все депозиты',
  [CpaPartnerReportColumnId.AverageDeposit]: 'Средний депозит',
  [CpaPartnerReportColumnId.AllRedeposit]: 'Все редепозиты',
  [CpaPartnerReportColumnId.Redeposits]: 'Количество редепозитов',
  [CpaPartnerReportColumnId.NewDeposit]: 'Новые депозиты',

  [CpaPartnerReportColumnId.Withdrawal]: 'Выводы',
  [CpaPartnerReportColumnId.NewWithdrawal]: 'Новые выводы',
  [CpaPartnerReportColumnId.NewProfit]: 'Новый доход',
  [CpaPartnerReportColumnId.Hold]: `В${nbsp}ожидании`,
  [CpaPartnerReportColumnId.Qualified]: 'Квалифицированные',
  [CpaPartnerReportColumnId.NotQualified]: 'Неквалифицированные',
  [CpaPartnerReportColumnId.Approved]: 'Подтвержденные',
  [CpaPartnerReportColumnId.Rejected]: 'Отклонённые',
  [CpaPartnerReportColumnId.Paid]: 'Оплаченные',
  [CpaPartnerReportColumnId.Unpaid]: `Без${nbsp}оплаты`,
  [CpaPartnerReportColumnId.RevShare]: `В${nbsp}RevShare`,
  [CpaPartnerReportColumnId.NGR]: 'NGR',
  [CpaPartnerReportColumnId.Profit]: 'Доход',
  [CpaPartnerReportColumnId.Upcoming]: 'Вознаграждение',
  [CpaPartnerReportColumnId.RewardPaid]: 'Выплачено',
};

export const columnsIds = [
  CpaPartnerReportColumnId.ReferralCode,
  CpaPartnerReportColumnId.Hits,
  CpaPartnerReportColumnId.Hosts,
  CpaPartnerReportColumnId.Registrations,
  CpaPartnerReportColumnId.FirstDeposits,
  CpaPartnerReportColumnId.HostToRegConversion,
  CpaPartnerReportColumnId.RegToDepConversion,

  CpaPartnerReportColumnId.AllDeposit,
  CpaPartnerReportColumnId.AverageDeposit,
  CpaPartnerReportColumnId.AllRedeposit,
  CpaPartnerReportColumnId.Redeposits,
  CpaPartnerReportColumnId.NewDeposit,

  CpaPartnerReportColumnId.Withdrawal,
  CpaPartnerReportColumnId.NewWithdrawal,
  CpaPartnerReportColumnId.NewProfit,
  CpaPartnerReportColumnId.Hold,
  CpaPartnerReportColumnId.Qualified,
  CpaPartnerReportColumnId.NotQualified,
  CpaPartnerReportColumnId.Approved,
  CpaPartnerReportColumnId.Rejected,
  CpaPartnerReportColumnId.Paid,
  CpaPartnerReportColumnId.Unpaid,
  CpaPartnerReportColumnId.RevShare,
  CpaPartnerReportColumnId.NGR,
  CpaPartnerReportColumnId.Profit,
  CpaPartnerReportColumnId.Upcoming,
  CpaPartnerReportColumnId.RewardPaid,
];
