import { createColumnHelper } from '@tanstack/react-table';
import { ColumnsSource } from 'ui/Table/types';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { MultilineCellWithLink } from 'ui/Table/components/MultilineCellWithLink';
import { Route } from 'src/router/routes.const';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { generatePath } from 'react-router';
import MayBeNullCell from 'ui/Table/components/MayBeNullCell';
import { CpaReferralLinkReportData } from '../../../queries/generated/CpaReferralLinkReportData';
import {
  columnsIds,
  CpaReferralLinkReportColumnId,
  mapCpaReferralLinkReportColumnIdToName,
} from '../const';
import { STATISTIC_TOTAL_ROW_TITLE } from '../../../../../const';
import { AddTwoLinedSubTitle } from '../../../../../components/AddTwoLinedSubTitle';
import { CpaTariffCell } from '../../CpaTariffCell';
import { getFirstDepositInfo } from '../../../../helpers';
import { getReferralStatus } from '../../../helpers';

const columnHelper = createColumnHelper<CpaReferralLinkReportData>();

const columnsSource: ColumnsSource<CpaReferralLinkReportData> = [
  columnHelper.accessor(CpaReferralLinkReportColumnId.Player, {
    id: CpaReferralLinkReportColumnId.Player,
    header:
      mapCpaReferralLinkReportColumnIdToName[
        CpaReferralLinkReportColumnId.Player
      ],
    cell: ({ row }) => {
      if (!row.original.player) {
        return STATISTIC_TOTAL_ROW_TITLE;
      }

      const { id, name, email, phone } = row.original.player;
      const playerUrl = generatePath(Route.PLAYER, { id });

      return (
        <MultilineCellWithLink
          isOpenInNewWindow
          title={name}
          subTitle={
            <AddTwoLinedSubTitle firstValue={email} secondValue={phone} />
          }
          route={playerUrl}
        />
      );
    },
    enablePinning: true,
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(CpaReferralLinkReportColumnId.FirstDeposit, {
    id: CpaReferralLinkReportColumnId.FirstDeposit,
    header:
      mapCpaReferralLinkReportColumnIdToName[
        CpaReferralLinkReportColumnId.FirstDeposit
      ],
    cell: ({ row }) =>
      MayBeNullCell(getFirstDepositInfo(row.original.firstDeposit), '—'),
  }),
  columnHelper.accessor(CpaReferralLinkReportColumnId.Deposit, {
    id: CpaReferralLinkReportColumnId.Deposit,
    header:
      mapCpaReferralLinkReportColumnIdToName[
        CpaReferralLinkReportColumnId.Deposit
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.deposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaReferralLinkReportColumnId.ReferralLink, {
    id: CpaReferralLinkReportColumnId.ReferralLink,
    header:
      mapCpaReferralLinkReportColumnIdToName[
        CpaReferralLinkReportColumnId.ReferralLink
      ],
    cell: ({ row }) => {
      const { cpaRewardTariffReward, cpaRewardTariffBaseline } = row.original;

      return (
        <CpaTariffCell
          reward={cpaRewardTariffReward}
          baseLine={cpaRewardTariffBaseline}
        />
      );
    },
  }),
  columnHelper.accessor(CpaReferralLinkReportColumnId.ReferralStatus, {
    id: CpaReferralLinkReportColumnId.ReferralStatus,
    header:
      mapCpaReferralLinkReportColumnIdToName[
        CpaReferralLinkReportColumnId.ReferralStatus
      ],
    cell: ({ row }) =>
      MayBeNullCell(getReferralStatus(row.original.referralStatus), '—'),
  }),
  columnHelper.accessor(CpaReferralLinkReportColumnId.Redeposit, {
    id: CpaReferralLinkReportColumnId.Redeposit,
    header:
      mapCpaReferralLinkReportColumnIdToName[
        CpaReferralLinkReportColumnId.Redeposit
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.redeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaReferralLinkReportColumnId.Redeposits, {
    id: CpaReferralLinkReportColumnId.Redeposits,
    header:
      mapCpaReferralLinkReportColumnIdToName[
        CpaReferralLinkReportColumnId.Redeposits
      ],
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(CpaReferralLinkReportColumnId.Withdrawal, {
    id: CpaReferralLinkReportColumnId.Withdrawal,
    header:
      mapCpaReferralLinkReportColumnIdToName[
        CpaReferralLinkReportColumnId.Withdrawal
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.withdrawal;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaReferralLinkReportColumnId.NGR, {
    id: CpaReferralLinkReportColumnId.NGR,
    header:
      mapCpaReferralLinkReportColumnIdToName[CpaReferralLinkReportColumnId.NGR],
    cell: ({ row }) => {
      const { amount, currency } = row.original.financialIndicators.ngr;

      return formatSumWithCurrency(amount, currency);
    },
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(CpaReferralLinkReportColumnId.Profit, {
    id: CpaReferralLinkReportColumnId.Profit,
    header:
      mapCpaReferralLinkReportColumnIdToName[
        CpaReferralLinkReportColumnId.Profit
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.financialIndicators.profit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaReferralLinkReportColumnId.Upcoming, {
    id: CpaReferralLinkReportColumnId.Upcoming,
    header:
      mapCpaReferralLinkReportColumnIdToName[
        CpaReferralLinkReportColumnId.Upcoming
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.affiliateRewardStatistic.upcoming;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaReferralLinkReportColumnId.Paid, {
    id: CpaReferralLinkReportColumnId.Paid,
    header:
      mapCpaReferralLinkReportColumnIdToName[
        CpaReferralLinkReportColumnId.Paid
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.affiliateRewardStatistic.paid;

      return formatSumWithCurrency(amount, currency);
    },
  }),
];

export const useGetCpaReferralLinkReportColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
