export enum AccountEventsColumnId {
  Id = 'id',
  EventType = '__typename',
  Sum = 'sum',
  Balance = 'balance',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export const mapAccountEventsColumnToName: Record<
  AccountEventsColumnId,
  string
> = {
  [AccountEventsColumnId.Id]: 'ID',
  [AccountEventsColumnId.EventType]: 'Тип события',
  [AccountEventsColumnId.Sum]: 'Фактическая сумма события',
  [AccountEventsColumnId.Balance]: 'Баланс',
  [AccountEventsColumnId.CreatedAt]: 'Создан',
  [AccountEventsColumnId.UpdatedAt]: 'Обновлён',
};

export const blockColumnsIds = [
  AccountEventsColumnId.EventType,
  AccountEventsColumnId.Sum,
  AccountEventsColumnId.Balance,
  AccountEventsColumnId.CreatedAt,
];

export const pageColumnsIds = [
  AccountEventsColumnId.Id,
  AccountEventsColumnId.EventType,
  AccountEventsColumnId.Sum,
  AccountEventsColumnId.Balance,
  AccountEventsColumnId.CreatedAt,
  AccountEventsColumnId.UpdatedAt,
];
