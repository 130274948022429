import { nbsp } from 'src/const';

export enum RevSharePartnerReportColumnId {
  ReferralCode = 'mediaItem',
  Hits = 'marketingStatistic.hits',
  Hosts = 'marketingStatistic.hosts',
  Registrations = 'marketingStatistic.registrations',
  FirstDeposits = 'marketingStatistic.firstDeposits',
  HostToRegConversion = 'marketingStatistic.hostToRegConversion',
  RegToDepConversion = 'marketingStatistic.regToDepConversion',

  AllDeposit = 'playerStatistic.allDeposit',
  AverageDeposit = 'playerStatistic.averageDeposit',
  AllRedeposit = 'playerStatistic.allRedeposit',
  Redeposits = 'marketingStatistic.redeposits',
  NewDeposit = 'playerRegisteredStatistic.newDeposit',
  IncomingMoneyTransfer = 'playerStatistic.incomingMoneyTransfer',

  Withdrawal = 'playerStatistic.withdrawal',
  NewWithdrawal = 'playerRegisteredStatistic.newWithdrawal',
  NewProfit = 'playerRegisteredStatistic.newProfit',
  DepositFee = 'playerStatistic.depositFee',
  WithdrawalFee = 'playerStatistic.withdrawalFee',
  AdminFee = 'adminFee',
  Bonus = 'playerStatistic.bonus',
  TournamentPrize = 'playerStatistic.tournamentPrize',
  Jackpot = 'playerStatistic.jackpot',
  NGR = 'playerStatistic.financialIndicators.ngr',
  Profit = 'playerStatistic.financialIndicators.profit',
  Upcoming = 'affiliateUpcomingReward',
}

export const mapRevSharePartnerReportColumnIdToName: Record<
  RevSharePartnerReportColumnId,
  string
> = {
  [RevSharePartnerReportColumnId.ReferralCode]: 'Реферальный код',
  [RevSharePartnerReportColumnId.Hits]: 'Хиты',
  [RevSharePartnerReportColumnId.Hosts]: 'Хосты',
  [RevSharePartnerReportColumnId.Registrations]: 'Регистрации',
  [RevSharePartnerReportColumnId.FirstDeposits]: 'Первые депозиты',
  [RevSharePartnerReportColumnId.HostToRegConversion]: 'Host2reg',
  [RevSharePartnerReportColumnId.RegToDepConversion]: 'Reg2dep',

  [RevSharePartnerReportColumnId.AllDeposit]: 'Все депозиты',
  [RevSharePartnerReportColumnId.AverageDeposit]: 'Средний депозит',
  [RevSharePartnerReportColumnId.AllRedeposit]: 'Все редепозиты',
  [RevSharePartnerReportColumnId.Redeposits]: 'Количество редепозитов',
  [RevSharePartnerReportColumnId.NewDeposit]: 'Новые депозиты',
  [RevSharePartnerReportColumnId.IncomingMoneyTransfer]: 'Входящие переводы',

  [RevSharePartnerReportColumnId.Withdrawal]: 'Выводы',
  [RevSharePartnerReportColumnId.NewWithdrawal]: 'Новые выводы',
  [RevSharePartnerReportColumnId.NewProfit]: 'Новый доход',
  [RevSharePartnerReportColumnId.DepositFee]: `Комиссии за${nbsp}депозиты`,
  [RevSharePartnerReportColumnId.WithdrawalFee]: `Комиссии за${nbsp}выводы`,
  [RevSharePartnerReportColumnId.AdminFee]: 'Admin fee',
  [RevSharePartnerReportColumnId.Bonus]: 'Бонусы',
  [RevSharePartnerReportColumnId.TournamentPrize]: 'Призовые',
  [RevSharePartnerReportColumnId.Jackpot]: 'Джекпоты',
  [RevSharePartnerReportColumnId.NGR]: 'NGR',
  [RevSharePartnerReportColumnId.Profit]: 'Доход',
  [RevSharePartnerReportColumnId.Upcoming]: 'Вознаграждение',
};

export const columnsIds = [
  RevSharePartnerReportColumnId.ReferralCode,
  RevSharePartnerReportColumnId.Hits,
  RevSharePartnerReportColumnId.Hosts,
  RevSharePartnerReportColumnId.Registrations,
  RevSharePartnerReportColumnId.FirstDeposits,
  RevSharePartnerReportColumnId.HostToRegConversion,
  RevSharePartnerReportColumnId.RegToDepConversion,

  RevSharePartnerReportColumnId.AllDeposit,
  RevSharePartnerReportColumnId.AverageDeposit,
  RevSharePartnerReportColumnId.AllRedeposit,
  RevSharePartnerReportColumnId.Redeposits,
  RevSharePartnerReportColumnId.NewDeposit,
  RevSharePartnerReportColumnId.IncomingMoneyTransfer,

  RevSharePartnerReportColumnId.Withdrawal,
  RevSharePartnerReportColumnId.NewWithdrawal,
  RevSharePartnerReportColumnId.NewProfit,
  RevSharePartnerReportColumnId.DepositFee,
  RevSharePartnerReportColumnId.WithdrawalFee,
  RevSharePartnerReportColumnId.AdminFee,
  RevSharePartnerReportColumnId.Bonus,
  RevSharePartnerReportColumnId.TournamentPrize,
  RevSharePartnerReportColumnId.Jackpot,
  RevSharePartnerReportColumnId.NGR,
  RevSharePartnerReportColumnId.Profit,
  RevSharePartnerReportColumnId.Upcoming,
];
