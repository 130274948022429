import { RestrictionTypeName } from 'commonTypes';
import { Option } from 'ui/Dropdown';
import getEnumKeys from 'src/utils/getEnumKeys';
import { restrictionTypeMap } from './helpers';

export enum RestrictionsColumnId {
  Id = 'id',
  Type = '__typename',
  Reason = 'reason',
  Status = 'status',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export const mapRestrictionsColumnIdToName: Record<
  RestrictionsColumnId,
  string
> = {
  [RestrictionsColumnId.Id]: 'ID',
  [RestrictionsColumnId.Type]: 'Тип',
  [RestrictionsColumnId.Reason]: 'Причина',
  [RestrictionsColumnId.Status]: 'Статус',
  [RestrictionsColumnId.CreatedAt]: 'Создан',
  [RestrictionsColumnId.UpdatedAt]: 'Обновлён',
};

export const restrictionOptions: Array<Option> = getEnumKeys(
  RestrictionTypeName
).map((restriction: keyof typeof RestrictionTypeName) => ({
  label: restrictionTypeMap[RestrictionTypeName[restriction]],
  id: RestrictionTypeName[restriction],
}));

export const blockColumnsIds = [
  RestrictionsColumnId.Type,
  RestrictionsColumnId.Reason,
  RestrictionsColumnId.Status,
  RestrictionsColumnId.UpdatedAt,
];

export const pageColumnsIds = [
  RestrictionsColumnId.Id,
  RestrictionsColumnId.Type,
  RestrictionsColumnId.Reason,
  RestrictionsColumnId.Status,
  RestrictionsColumnId.CreatedAt,
  RestrictionsColumnId.UpdatedAt,
];
