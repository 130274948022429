import { formatSumWithCurrency } from 'src/utils/accounting';
import { ColumnsSource } from 'ui/Table/types';
import { createColumnHelper } from '@tanstack/react-table';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { addPercentToValue } from 'src/utils/addPercentToValue';
import { formatNumberWithRuLocale } from 'src/utils/formatNumberWithRuLocale';
import {
  columnsIds,
  CpaCompanyPartnersReportColumnId,
  mapCpaCompanyPartnersReportColumnIdToName,
} from '../const';
import { STATISTIC_TOTAL_ROW_TITLE } from '../../../../../const';
import { CpaCompanyPartnersReportRow } from '../../../types';
import { CpaMediaOwnerCell } from '../../CpaMediaOwnerCell';

const columnHelper = createColumnHelper<CpaCompanyPartnersReportRow>();

const columnsSource: ColumnsSource<CpaCompanyPartnersReportRow> = [
  columnHelper.accessor(CpaCompanyPartnersReportColumnId.MediaOwner, {
    id: CpaCompanyPartnersReportColumnId.MediaOwner,
    header:
      mapCpaCompanyPartnersReportColumnIdToName[
        CpaCompanyPartnersReportColumnId.MediaOwner
      ],
    cell: ({ row }) => {
      if (!row.original.companyPartner && !row.original.company) {
        return STATISTIC_TOTAL_ROW_TITLE;
      }

      return <CpaMediaOwnerCell row={row.original} />;
    },
    enablePinning: true,
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(CpaCompanyPartnersReportColumnId.Hits, {
    id: CpaCompanyPartnersReportColumnId.Hits,
    header:
      mapCpaCompanyPartnersReportColumnIdToName[
        CpaCompanyPartnersReportColumnId.Hits
      ],
    cell: ({ row }) => {
      const { hits } = row.original.marketingStatistic;

      return formatNumberWithRuLocale(hits);
    },
  }),
  columnHelper.accessor(CpaCompanyPartnersReportColumnId.Hosts, {
    id: CpaCompanyPartnersReportColumnId.Hosts,
    header:
      mapCpaCompanyPartnersReportColumnIdToName[
        CpaCompanyPartnersReportColumnId.Hosts
      ],
    cell: ({ row }) => {
      const { hosts } = row.original.marketingStatistic;

      return formatNumberWithRuLocale(hosts);
    },
  }),
  columnHelper.accessor(CpaCompanyPartnersReportColumnId.Registrations, {
    id: CpaCompanyPartnersReportColumnId.Registrations,
    header:
      mapCpaCompanyPartnersReportColumnIdToName[
        CpaCompanyPartnersReportColumnId.Registrations
      ],
    cell: ({ row }) => {
      const { registrations } = row.original.marketingStatistic;

      return formatNumberWithRuLocale(registrations);
    },
  }),
  columnHelper.accessor(CpaCompanyPartnersReportColumnId.FirstDeposits, {
    id: CpaCompanyPartnersReportColumnId.FirstDeposits,
    header:
      mapCpaCompanyPartnersReportColumnIdToName[
        CpaCompanyPartnersReportColumnId.FirstDeposits
      ],
    cell: ({ row }) => {
      const { firstDeposits } = row.original.marketingStatistic;

      return formatNumberWithRuLocale(firstDeposits);
    },
  }),
  columnHelper.accessor(CpaCompanyPartnersReportColumnId.HostToRegConversion, {
    id: CpaCompanyPartnersReportColumnId.HostToRegConversion,
    header:
      mapCpaCompanyPartnersReportColumnIdToName[
        CpaCompanyPartnersReportColumnId.HostToRegConversion
      ],
    cell: ({ row }) => {
      const { hostToRegConversion } = row.original.marketingStatistic;

      return addPercentToValue(hostToRegConversion);
    },
  }),
  columnHelper.accessor(CpaCompanyPartnersReportColumnId.RegToDepConversion, {
    id: CpaCompanyPartnersReportColumnId.RegToDepConversion,
    header:
      mapCpaCompanyPartnersReportColumnIdToName[
        CpaCompanyPartnersReportColumnId.RegToDepConversion
      ],
    cell: ({ row }) => {
      const { regToDepConversion } = row.original.marketingStatistic;

      return addPercentToValue(regToDepConversion);
    },
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(CpaCompanyPartnersReportColumnId.AllDeposit, {
    id: CpaCompanyPartnersReportColumnId.AllDeposit,
    header:
      mapCpaCompanyPartnersReportColumnIdToName[
        CpaCompanyPartnersReportColumnId.AllDeposit
      ],
    cell: ({ row }) => {
      const { allDeposit } = row.original.playerStatistic;

      return formatSumWithCurrency(allDeposit.amount, allDeposit.currency);
    },
  }),
  columnHelper.accessor(CpaCompanyPartnersReportColumnId.AverageDeposit, {
    id: CpaCompanyPartnersReportColumnId.AverageDeposit,
    header:
      mapCpaCompanyPartnersReportColumnIdToName[
        CpaCompanyPartnersReportColumnId.AverageDeposit
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.averageDeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaCompanyPartnersReportColumnId.AllRedeposit, {
    id: CpaCompanyPartnersReportColumnId.AllRedeposit,
    header:
      mapCpaCompanyPartnersReportColumnIdToName[
        CpaCompanyPartnersReportColumnId.AllRedeposit
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.allRedeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaCompanyPartnersReportColumnId.Redeposits, {
    id: CpaCompanyPartnersReportColumnId.Redeposits,
    header:
      mapCpaCompanyPartnersReportColumnIdToName[
        CpaCompanyPartnersReportColumnId.Redeposits
      ],
    cell: ({ row }) => {
      const { redeposits } = row.original.marketingStatistic;

      return formatNumberWithRuLocale(redeposits);
    },
  }),
  columnHelper.accessor(CpaCompanyPartnersReportColumnId.NewDeposit, {
    id: CpaCompanyPartnersReportColumnId.NewDeposit,
    header:
      mapCpaCompanyPartnersReportColumnIdToName[
        CpaCompanyPartnersReportColumnId.NewDeposit
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerRegisteredStatistic.newDeposit;

      return formatSumWithCurrency(amount, currency);
    },
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(CpaCompanyPartnersReportColumnId.Withdrawal, {
    id: CpaCompanyPartnersReportColumnId.Withdrawal,
    header:
      mapCpaCompanyPartnersReportColumnIdToName[
        CpaCompanyPartnersReportColumnId.Withdrawal
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.withdrawal;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaCompanyPartnersReportColumnId.NewWithdrawal, {
    id: CpaCompanyPartnersReportColumnId.NewWithdrawal,
    header:
      mapCpaCompanyPartnersReportColumnIdToName[
        CpaCompanyPartnersReportColumnId.NewWithdrawal
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerRegisteredStatistic.newWithdrawal;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaCompanyPartnersReportColumnId.NewProfit, {
    id: CpaCompanyPartnersReportColumnId.NewProfit,
    header:
      mapCpaCompanyPartnersReportColumnIdToName[
        CpaCompanyPartnersReportColumnId.NewProfit
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerRegisteredStatistic.newProfit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaCompanyPartnersReportColumnId.Hold, {
    id: CpaCompanyPartnersReportColumnId.Hold,
    header:
      mapCpaCompanyPartnersReportColumnIdToName[
        CpaCompanyPartnersReportColumnId.Hold
      ],
    cell: ({ row }) => {
      const { hold } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(hold);
    },
  }),
  columnHelper.accessor(CpaCompanyPartnersReportColumnId.Qualified, {
    id: CpaCompanyPartnersReportColumnId.Qualified,
    header:
      mapCpaCompanyPartnersReportColumnIdToName[
        CpaCompanyPartnersReportColumnId.Qualified
      ],
    cell: ({ row }) => {
      const { qualified } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(qualified);
    },
  }),
  columnHelper.accessor(CpaCompanyPartnersReportColumnId.NotQualified, {
    id: CpaCompanyPartnersReportColumnId.NotQualified,
    header:
      mapCpaCompanyPartnersReportColumnIdToName[
        CpaCompanyPartnersReportColumnId.NotQualified
      ],
    cell: ({ row }) => {
      const { canceled } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(canceled);
    },
  }),
  columnHelper.accessor(CpaCompanyPartnersReportColumnId.Approved, {
    id: CpaCompanyPartnersReportColumnId.Approved,
    header:
      mapCpaCompanyPartnersReportColumnIdToName[
        CpaCompanyPartnersReportColumnId.Approved
      ],
    cell: ({ row }) => {
      const { approved } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(approved);
    },
  }),
  columnHelper.accessor(CpaCompanyPartnersReportColumnId.Rejected, {
    id: CpaCompanyPartnersReportColumnId.Rejected,
    header:
      mapCpaCompanyPartnersReportColumnIdToName[
        CpaCompanyPartnersReportColumnId.Rejected
      ],
    cell: ({ row }) => {
      const { rejected } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(rejected);
    },
  }),
  columnHelper.accessor(CpaCompanyPartnersReportColumnId.Paid, {
    id: CpaCompanyPartnersReportColumnId.Paid,
    header:
      mapCpaCompanyPartnersReportColumnIdToName[
        CpaCompanyPartnersReportColumnId.Paid
      ],
    cell: ({ row }) => {
      const { paid } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(paid);
    },
  }),
  columnHelper.accessor(CpaCompanyPartnersReportColumnId.Unpaid, {
    id: CpaCompanyPartnersReportColumnId.Unpaid,
    header:
      mapCpaCompanyPartnersReportColumnIdToName[
        CpaCompanyPartnersReportColumnId.Unpaid
      ],
    cell: ({ row }) => {
      const { unpaid } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(unpaid);
    },
  }),
  columnHelper.accessor(CpaCompanyPartnersReportColumnId.RevShare, {
    id: CpaCompanyPartnersReportColumnId.RevShare,
    header:
      mapCpaCompanyPartnersReportColumnIdToName[
        CpaCompanyPartnersReportColumnId.RevShare
      ],
    cell: ({ row }) => {
      const { revShare } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(revShare);
    },
  }),
  columnHelper.accessor(CpaCompanyPartnersReportColumnId.NGR, {
    id: CpaCompanyPartnersReportColumnId.NGR,
    header:
      mapCpaCompanyPartnersReportColumnIdToName[
        CpaCompanyPartnersReportColumnId.NGR
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerStatistic.financialIndicators.ngr;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaCompanyPartnersReportColumnId.Profit, {
    id: CpaCompanyPartnersReportColumnId.Profit,
    header:
      mapCpaCompanyPartnersReportColumnIdToName[
        CpaCompanyPartnersReportColumnId.Profit
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerStatistic.financialIndicators.profit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaCompanyPartnersReportColumnId.Upcoming, {
    id: CpaCompanyPartnersReportColumnId.Upcoming,
    header:
      mapCpaCompanyPartnersReportColumnIdToName[
        CpaCompanyPartnersReportColumnId.Upcoming
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.affiliateRewardStatistic.upcoming;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaCompanyPartnersReportColumnId.RewardPaid, {
    id: CpaCompanyPartnersReportColumnId.RewardPaid,
    header:
      mapCpaCompanyPartnersReportColumnIdToName[
        CpaCompanyPartnersReportColumnId.RewardPaid
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.affiliateRewardStatistic.paid;

      return formatSumWithCurrency(amount, currency);
    },
  }),
];

export const useGetCpaCompanyPartnersReportColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
