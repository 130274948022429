import { AvailableFilter } from 'ui/TableFilter';
import { MoneyTransferFilterMember } from 'commonTypes';
import { FilterType } from 'ui/TableFilter/const';
import { getMoneyTransferTypesList } from './helpers';

export enum TransfersColumnId {
  Id = 'id',
  Type = 'type',
  Account = 'counterparty.moneyTransferAccount',
  Counterparty = 'counterparty',
  RecipientSender = 'recipientSender',
  NickName = 'counterparty.name',
  Wager = 'wager',
  Sum = 'sum',
  CreatedAt = 'createdAt',
}

export const mapTransfersColumnIdToName: Record<TransfersColumnId, string> = {
  [TransfersColumnId.Id]: 'ID',
  [TransfersColumnId.Type]: 'Тип',
  [TransfersColumnId.Account]: 'Номер счета',
  [TransfersColumnId.Counterparty]: 'Игрок',
  [TransfersColumnId.RecipientSender]: 'Получатель/отправитель',
  [TransfersColumnId.NickName]: 'Имя на\u00a0сайте',
  [TransfersColumnId.Wager]: 'Отыгрыш',
  [TransfersColumnId.Sum]: 'Сумма',
  [TransfersColumnId.CreatedAt]: 'Создан',
};

export const allMoneyTransferFilters: Array<
  AvailableFilter<MoneyTransferFilterMember>
> = [
  {
    title: 'Тип',
    filterId: MoneyTransferFilterMember.Type,
    type: FilterType.List,
    list: getMoneyTransferTypesList(),
  },
];

export const blockColumnsIds = [
  TransfersColumnId.Type,
  TransfersColumnId.Account,
  TransfersColumnId.Counterparty,
  TransfersColumnId.Sum,
  TransfersColumnId.Wager,
  TransfersColumnId.CreatedAt,
];

export const pageColumnsIds = [
  TransfersColumnId.Id,
  TransfersColumnId.Type,
  TransfersColumnId.Account,
  TransfersColumnId.RecipientSender,
  TransfersColumnId.NickName,
  TransfersColumnId.Sum,
  TransfersColumnId.Wager,
  TransfersColumnId.CreatedAt,
];
