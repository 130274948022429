export enum PlayersListColumnId {
  Id = 'id',
  Player = '__typename',
  Rank = 'rank',
  Country = 'country',
  Affiliate = 'affiliate',
  Note = 'note.text',
  LastVisited = 'lastVisited',
}

export const mapPlayersListIdToName: Record<PlayersListColumnId, string> = {
  [PlayersListColumnId.Id]: 'ID',
  [PlayersListColumnId.Player]: 'Пользователь',
  [PlayersListColumnId.Rank]: 'Текущий статус',
  [PlayersListColumnId.Country]: 'Страна',
  [PlayersListColumnId.Affiliate]: 'Партнёр/Компания',
  [PlayersListColumnId.Note]: 'Заметка',
  [PlayersListColumnId.LastVisited]: 'Последний вход',
};

export const columnsIds = [
  PlayersListColumnId.Id,
  PlayersListColumnId.Player,
  PlayersListColumnId.Rank,
  PlayersListColumnId.Country,
  PlayersListColumnId.Affiliate,
  PlayersListColumnId.Note,
  PlayersListColumnId.LastVisited,
];
