import { FC } from 'react';
import BlockTable from 'ui/Block/BlockTable';
import { useNavigate } from 'react-router-dom';
import { Route } from 'src/router/routes.const';
import { PlayerListData } from '../../queries/generated/PlayerListData';
import { useGetPlayersListColumns } from './hooks/useGetPlayersListColumns';

interface PlayersListTableProps {
  playersList?: Array<PlayerListData>;
}

export const PlayersListTable: FC<PlayersListTableProps> = ({
  playersList,
}) => {
  const { columns } = useGetPlayersListColumns();
  const navigate = useNavigate();

  const handleRowClick = (player: PlayerListData) => {
    navigate(`${Route.PLAYERS}/${player.id}`);
  };

  return (
    <BlockTable
      onClickOnRow={handleRowClick}
      columns={columns}
      data={playersList}
      isFullScreenTable
    />
  );
};
