import NowrapCell from 'ui/Table/components/NowrapCell';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { ColumnsSource } from 'ui/Table/types';
import { createColumnHelper } from '@tanstack/react-table';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { MediaCampaignData } from '../../../queries/generated/MediaCampaignData';
import {
  MediaCampaignsColumnId,
  mapMediaCampaignsColumnIdColumnToName,
  columnsIds,
} from '../const';

const columnHelper = createColumnHelper<MediaCampaignData>();

const columnsSource: ColumnsSource<MediaCampaignData> = [
  columnHelper.accessor(MediaCampaignsColumnId.Id, {
    id: MediaCampaignsColumnId.Id,
    header: mapMediaCampaignsColumnIdColumnToName[MediaCampaignsColumnId.Id],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnHelper.accessor(MediaCampaignsColumnId.Title, {
    id: MediaCampaignsColumnId.Title,
    header: mapMediaCampaignsColumnIdColumnToName[MediaCampaignsColumnId.Title],
  }),
  columnHelper.accessor(MediaCampaignsColumnId.Url, {
    id: MediaCampaignsColumnId.Url,
    header: mapMediaCampaignsColumnIdColumnToName[MediaCampaignsColumnId.Url],
  }),
  columnHelper.accessor(MediaCampaignsColumnId.CreatedAt, {
    id: MediaCampaignsColumnId.CreatedAt,
    header:
      mapMediaCampaignsColumnIdColumnToName[MediaCampaignsColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.DefaultDateOld)}
      </NowrapCell>
    ),
  }),
];

export const useGetMediaCampaignsColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
