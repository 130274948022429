export enum TournamentRebuysColumnId {
  Id = 'id',
  TournamentType = 'tournament',
  Name = 'tournament.name',
  Sum = 'sum',
  CreatedAt = 'createdAt',
}

export const mapTournamentRebuysColumnToName: Record<
  TournamentRebuysColumnId,
  string
> = {
  [TournamentRebuysColumnId.Id]: 'ID',
  [TournamentRebuysColumnId.TournamentType]: 'Вид турнира',
  [TournamentRebuysColumnId.Name]: 'Название турнира',
  [TournamentRebuysColumnId.Sum]: 'Сумма',
  [TournamentRebuysColumnId.CreatedAt]: 'Создан',
};

export const blockColumnsIds = [
  TournamentRebuysColumnId.TournamentType,
  TournamentRebuysColumnId.Name,
  TournamentRebuysColumnId.Sum,
  TournamentRebuysColumnId.CreatedAt,
];

export const pageColumnsIds = [
  TournamentRebuysColumnId.Id,
  TournamentRebuysColumnId.TournamentType,
  TournamentRebuysColumnId.Name,
  TournamentRebuysColumnId.Sum,
  TournamentRebuysColumnId.CreatedAt,
];
