import MultilineCellWithIcon from 'ui/Table/components/MultilineCellWithIcon';
import { contactForCommunicationTypeMap } from 'src/const';
import { ColumnsSource } from 'ui/Table/types';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { createColumnHelper } from '@tanstack/react-table';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { CompanyListData } from '../../../queries/generated/CompanyListData';
import {
  mapCompaniesListIdToName,
  CompaniesListColumnId,
  columnsIds,
} from '../const';

const columnHelper = createColumnHelper<CompanyListData>();

const columnsSource: ColumnsSource<CompanyListData> = [
  columnHelper.accessor(CompaniesListColumnId.Id, {
    id: CompaniesListColumnId.Id,
    header: mapCompaniesListIdToName[CompaniesListColumnId.Id],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnHelper.accessor(CompaniesListColumnId.Name, {
    id: CompaniesListColumnId.Name,
    header: mapCompaniesListIdToName[CompaniesListColumnId.Name],
  }),
  columnHelper.accessor(CompaniesListColumnId.Email, {
    id: CompaniesListColumnId.Email,
    header: mapCompaniesListIdToName[CompaniesListColumnId.Email],
  }),
  columnHelper.accessor(CompaniesListColumnId.ContactForCommunication, {
    id: CompaniesListColumnId.ContactForCommunication,
    header:
      mapCompaniesListIdToName[CompaniesListColumnId.ContactForCommunication],
    cell: ({ row }) => {
      const { contactForCommunication } = row.original;

      return (
        <MultilineCellWithIcon
          title={contactForCommunication.value}
          subTitle={
            contactForCommunicationTypeMap[contactForCommunication.type]
          }
        />
      );
    },
  }),
  columnHelper.accessor(CompaniesListColumnId.Note, {
    id: CompaniesListColumnId.Note,
    header: mapCompaniesListIdToName[CompaniesListColumnId.Note],
  }),
];

export const useGetCompaniesListColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
