import { FC } from 'react';
import BlockTable from 'ui/Block/BlockTable';
import { useGetRevShareReferralLinkColumns } from './hooks/useGetRevShareReferralLinkColumns';
import { RevShareReferralLinkReportData } from '../../queries/generated/RevShareReferralLinkReportData';

interface RevShareReferralLinkReportTableProps {
  report?: Array<RevShareReferralLinkReportData>;
  hasTotalRow: boolean;
}

export const RevShareReferralLinkReportTable: FC<
  RevShareReferralLinkReportTableProps
> = ({ report, hasTotalRow }) => {
  const { columns } = useGetRevShareReferralLinkColumns();

  return (
    <BlockTable
      columns={columns}
      data={report}
      hasTotalRow={hasTotalRow}
      isFullScreenTable
    />
  );
};
