import { FC } from 'react';
import { AffiliateTypeName } from 'commonTypes';
import styles from './BlockSubHeaderAffiliateInfo.module.scss';

interface BlockSubHeaderAffiliateInfoProps {
  affiliateType: AffiliateTypeName;
  name: string;
  email?: string | null;
}

export const BlockSubHeaderAffiliateInfo: FC<
  BlockSubHeaderAffiliateInfoProps
> = ({ name, email, affiliateType }) => {
  const affiliateLabel =
    affiliateType === AffiliateTypeName.Partner ? 'Партнёр' : 'Компания';

  return (
    <div className={styles.subHeader}>
      <div>
        <span className={styles.label}>{affiliateLabel}</span>{' '}
        <span className={styles.value}>{name}</span>
      </div>
      {email && (
        <div>
          <span className={styles.label}>Почта</span>{' '}
          <span className={styles.value}>{email}</span>
        </div>
      )}
    </div>
  );
};
