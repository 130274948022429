import { DateFormat, formatDate } from 'src/utils/formatDate';
import NowrapCell from 'ui/Table/components/NowrapCell';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { ColumnsSource } from 'ui/Table/types';
import { createColumnHelper } from '@tanstack/react-table';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { CompanyMediaCampaignBasicData } from '../../../queries/generated/CompanyMediaCampaignBasicData';
import {
  columnsIds,
  CompanyMediaCampaignsColumnId,
  mapCompanyMediaCampaignsColumnIdToName,
} from '../const';

const columnHelper = createColumnHelper<CompanyMediaCampaignBasicData>();

const columnsSource: ColumnsSource<CompanyMediaCampaignBasicData> = [
  columnHelper.accessor(CompanyMediaCampaignsColumnId.Id, {
    id: CompanyMediaCampaignsColumnId.Id,
    header:
      mapCompanyMediaCampaignsColumnIdToName[CompanyMediaCampaignsColumnId.Id],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnHelper.accessor(CompanyMediaCampaignsColumnId.Title, {
    id: CompanyMediaCampaignsColumnId.Title,
    header:
      mapCompanyMediaCampaignsColumnIdToName[
        CompanyMediaCampaignsColumnId.Title
      ],
  }),
  columnHelper.accessor(CompanyMediaCampaignsColumnId.Url, {
    id: CompanyMediaCampaignsColumnId.Url,
    header:
      mapCompanyMediaCampaignsColumnIdToName[CompanyMediaCampaignsColumnId.Url],
  }),
  columnHelper.accessor(CompanyMediaCampaignsColumnId.CreatedAt, {
    id: CompanyMediaCampaignsColumnId.CreatedAt,
    header:
      mapCompanyMediaCampaignsColumnIdToName[
        CompanyMediaCampaignsColumnId.CreatedAt
      ],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.DefaultDateOld)}
      </NowrapCell>
    ),
  }),
];

export const useGetCompanyMediaCampaignsColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
