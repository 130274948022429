import { FC } from 'react';
import BlockTable from 'ui/Block/BlockTable/BlockTable';
import { useLocation } from 'react-router';
import { useTournamentRebuyModal } from '../../hooks/useTournamentRebuyModal';
import { TournamentRebuysBasicData } from '../../queries/generated/TournamentRebuysBasicData';
import { TournamentRebuysData } from '../../queries/generated/TournamentRebuysData';
import { useGetTournamentRebuysColumns } from './hooks/useGetTournamentRebuysColumns';

interface TournamentRebuysTableProps {
  tournamentRebuys?: Array<TournamentRebuysBasicData | TournamentRebuysData>;
  isFullScreenTable: boolean;
}

export const TournamentRebuysTable: FC<TournamentRebuysTableProps> = ({
  tournamentRebuys,
  isFullScreenTable,
}) => {
  const location = useLocation();

  const openTournamentRebuysModal = useTournamentRebuyModal();
  const { columns } = useGetTournamentRebuysColumns(isFullScreenTable);

  const handleRowClick = (
    tournamentRebuy: TournamentRebuysBasicData | TournamentRebuysData,
    initiatorId: string
  ) => {
    openTournamentRebuysModal({
      tournamentRebuy,
      initiatorId,
    });
  };

  return (
    <BlockTable
      onClickOnRow={handleRowClick}
      columns={columns}
      data={tournamentRebuys}
      routeToCustomPage={
        isFullScreenTable ? undefined : `${location.pathname}/tournament-rebuys`
      }
      isFullScreenTable={isFullScreenTable}
    />
  );
};
