import NowrapCell from 'ui/Table/components/NowrapCell/NowrapCell';
import MayBeNullCell from 'ui/Table/components/MayBeNullCell/MayBeNullCell';
import { ColumnsSource } from 'ui/Table/types';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { createColumnHelper } from '@tanstack/react-table';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import {
  columnsIds,
  mapRevShareRewardTariffsColumnIdColumnToName,
  RevShareRewardTariffsColumnId,
} from '../const';
import { RevShareRewardTariffData } from '../../../../../queries/generated/RevShareRewardTariffData';

const columnHelper = createColumnHelper<RevShareRewardTariffData>();

const columnsSource: ColumnsSource<RevShareRewardTariffData> = [
  columnHelper.accessor(RevShareRewardTariffsColumnId.Id, {
    id: RevShareRewardTariffsColumnId.Id,
    header:
      mapRevShareRewardTariffsColumnIdColumnToName[
        RevShareRewardTariffsColumnId.Id
      ],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnHelper.accessor(RevShareRewardTariffsColumnId.Percent, {
    id: RevShareRewardTariffsColumnId.Title,
    header:
      mapRevShareRewardTariffsColumnIdColumnToName[
        RevShareRewardTariffsColumnId.Title
      ],
    cell: ({ row }) => `RevShare ${row.original.percent || 0}%`,
  }),
  columnHelper.accessor(RevShareRewardTariffsColumnId.Percent, {
    id: RevShareRewardTariffsColumnId.Percent,
    header:
      mapRevShareRewardTariffsColumnIdColumnToName[
        RevShareRewardTariffsColumnId.Percent
      ],
    cell: ({ row }) => `${row.original.percent}%`,
  }),
  columnHelper.accessor(RevShareRewardTariffsColumnId.CreatedAt, {
    id: RevShareRewardTariffsColumnId.CreatedAt,
    header:
      mapRevShareRewardTariffsColumnIdColumnToName[
        RevShareRewardTariffsColumnId.CreatedAt
      ],
    cell: ({ row }) => {
      const { createdAt } = row.original;

      return MayBeNullCell(
        createdAt && (
          <NowrapCell>{formatDate(createdAt, DateFormat.FullDate)}</NowrapCell>
        ),
        '⸺'
      );
    },
  }),
];

export const useGetRevShareRewardTariffsColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
