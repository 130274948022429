import { nbsp } from 'src/const';

export enum RewardsColumnId {
  SuspectState = 'suspectState',
  Id = 'id',
  Affiliate = 'affiliate',
  Balance = '__typename',
  Status = 'status',
  Sum = 'sum',
  PaidSum = 'paidSum',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export const mapRewardsColumnIdToName: Record<RewardsColumnId, string> = {
  [RewardsColumnId.SuspectState]: `${nbsp}`,
  [RewardsColumnId.Id]: 'ID',
  [RewardsColumnId.Affiliate]: 'Партнёр/Компания',
  [RewardsColumnId.Balance]: 'Текущий баланс',
  [RewardsColumnId.Status]: 'Статус',
  [RewardsColumnId.Sum]: 'Сумма',
  [RewardsColumnId.PaidSum]: 'Фактическая сумма',
  [RewardsColumnId.CreatedAt]: 'Создан',
  [RewardsColumnId.UpdatedAt]: 'Обновлён',
};

export const columnsIds = [
  RewardsColumnId.SuspectState,
  RewardsColumnId.Id,
  RewardsColumnId.Affiliate,
  RewardsColumnId.Balance,
  RewardsColumnId.Status,
  RewardsColumnId.Sum,
  RewardsColumnId.PaidSum,
  RewardsColumnId.CreatedAt,
  RewardsColumnId.UpdatedAt,
];
