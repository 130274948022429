import { nbsp } from 'src/const';

export enum RevShareCompanyPartnersReportColumnId {
  MediaOwner = 'companyPartner',
  Hits = 'marketingStatistic.hits',
  Hosts = 'marketingStatistic.hosts',
  Registrations = 'marketingStatistic.registrations',
  FirstDeposits = 'marketingStatistic.firstDeposits',
  HostToRegConversion = 'marketingStatistic.hostToRegConversion',
  RegToDepConversion = 'marketingStatistic.regToDepConversion',

  AllDeposit = 'playerStatistic.allDeposit',
  AverageDeposit = 'playerStatistic.averageDeposit',
  AllRedeposit = 'playerStatistic.allRedeposit',
  Redeposits = 'marketingStatistic.redeposits',
  NewDeposit = 'playerRegisteredStatistic.newDeposit',
  IncomingMoneyTransfer = 'playerStatistic.incomingMoneyTransfer',

  Withdrawal = 'playerStatistic.withdrawal',
  NewWithdrawal = 'playerRegisteredStatistic.newWithdrawal',
  NewProfit = 'playerRegisteredStatistic.newProfit',
  DepositFee = 'playerStatistic.depositFee',
  WithdrawalFee = 'playerStatistic.withdrawalFee',
  AdminFee = 'adminFee',
  Bonus = 'playerStatistic.bonus',
  TournamentPrize = 'playerStatistic.tournamentPrize',
  Jackpot = 'playerStatistic.jackpot',
  NGR = 'playerStatistic.financialIndicators.ngr',
  Profit = 'playerStatistic.financialIndicators.profit',
  Upcoming = 'affiliateUpcomingReward',
}

export const mapRevShareCompanyPartnersReportColumnIdToName: Record<
  RevShareCompanyPartnersReportColumnId,
  string
> = {
  [RevShareCompanyPartnersReportColumnId.MediaOwner]: 'Владелец медиа',
  [RevShareCompanyPartnersReportColumnId.Hits]: 'Хиты',
  [RevShareCompanyPartnersReportColumnId.Hosts]: 'Хосты',
  [RevShareCompanyPartnersReportColumnId.Registrations]: 'Регистрации',
  [RevShareCompanyPartnersReportColumnId.FirstDeposits]: 'Первые депозиты',
  [RevShareCompanyPartnersReportColumnId.HostToRegConversion]: 'Host2reg',
  [RevShareCompanyPartnersReportColumnId.RegToDepConversion]: 'Reg2dep',

  [RevShareCompanyPartnersReportColumnId.AllDeposit]: 'Все депозиты',
  [RevShareCompanyPartnersReportColumnId.AverageDeposit]: 'Средний депозит',
  [RevShareCompanyPartnersReportColumnId.AllRedeposit]: 'Все редепозиты',
  [RevShareCompanyPartnersReportColumnId.Redeposits]: 'Количество редепозитов',
  [RevShareCompanyPartnersReportColumnId.NewDeposit]: 'Новые депозиты',
  [RevShareCompanyPartnersReportColumnId.IncomingMoneyTransfer]:
    'Входящие переводы',

  [RevShareCompanyPartnersReportColumnId.Withdrawal]: 'Выводы',
  [RevShareCompanyPartnersReportColumnId.NewWithdrawal]: 'Новые выводы',
  [RevShareCompanyPartnersReportColumnId.NewProfit]: 'Новый доход',
  [RevShareCompanyPartnersReportColumnId.DepositFee]: `Комиссии за${nbsp}депозиты`,
  [RevShareCompanyPartnersReportColumnId.WithdrawalFee]: `Комиссии за${nbsp}выводы`,
  [RevShareCompanyPartnersReportColumnId.AdminFee]: 'Admin fee',
  [RevShareCompanyPartnersReportColumnId.Bonus]: 'Бонусы',
  [RevShareCompanyPartnersReportColumnId.TournamentPrize]: 'Призовые',
  [RevShareCompanyPartnersReportColumnId.Jackpot]: 'Джекпоты',
  [RevShareCompanyPartnersReportColumnId.NGR]: 'NGR',
  [RevShareCompanyPartnersReportColumnId.Profit]: 'Доход',
  [RevShareCompanyPartnersReportColumnId.Upcoming]: 'Вознаграждение',
};

export const columnsIds = [
  RevShareCompanyPartnersReportColumnId.MediaOwner,
  RevShareCompanyPartnersReportColumnId.Hits,
  RevShareCompanyPartnersReportColumnId.Hosts,
  RevShareCompanyPartnersReportColumnId.Registrations,
  RevShareCompanyPartnersReportColumnId.FirstDeposits,
  RevShareCompanyPartnersReportColumnId.HostToRegConversion,
  RevShareCompanyPartnersReportColumnId.RegToDepConversion,

  RevShareCompanyPartnersReportColumnId.AllDeposit,
  RevShareCompanyPartnersReportColumnId.AverageDeposit,
  RevShareCompanyPartnersReportColumnId.AllRedeposit,
  RevShareCompanyPartnersReportColumnId.Redeposits,
  RevShareCompanyPartnersReportColumnId.NewDeposit,
  RevShareCompanyPartnersReportColumnId.IncomingMoneyTransfer,

  RevShareCompanyPartnersReportColumnId.Withdrawal,
  RevShareCompanyPartnersReportColumnId.NewWithdrawal,
  RevShareCompanyPartnersReportColumnId.NewProfit,
  RevShareCompanyPartnersReportColumnId.DepositFee,
  RevShareCompanyPartnersReportColumnId.WithdrawalFee,
  RevShareCompanyPartnersReportColumnId.AdminFee,
  RevShareCompanyPartnersReportColumnId.Bonus,
  RevShareCompanyPartnersReportColumnId.TournamentPrize,
  RevShareCompanyPartnersReportColumnId.Jackpot,
  RevShareCompanyPartnersReportColumnId.NGR,
  RevShareCompanyPartnersReportColumnId.Profit,
  RevShareCompanyPartnersReportColumnId.Upcoming,
];
