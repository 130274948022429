import { FC } from 'react';
import { DataCard } from 'commonComponents/DataCard';
import { useFormikContext } from 'formik';
import { TextOverflow } from 'ui/TextOverflow';
import { promoCodeUsageTypeMap } from 'src/const';
import { FieldName, mapCreatePromoCodeFieldToTitle } from '../../../../const';
import { CreateAffiliatePromoCodeValues } from '../../../../types';
import styles from './DataCardMain.module.scss';

export const DataCardMain: FC = () => {
  const { values } = useFormikContext<CreateAffiliatePromoCodeValues>();

  return (
    <DataCard
      containerClassName={styles.container}
      rows={[
        {
          title: mapCreatePromoCodeFieldToTitle[FieldName.ActivationLimit],
          value: values[FieldName.ActivationLimit],
        },
        {
          title: mapCreatePromoCodeFieldToTitle[FieldName.Token],
          value: values[FieldName.Token],
        },
        {
          title: mapCreatePromoCodeFieldToTitle[FieldName.MediaCampaignName],
          value: values[FieldName.MediaCampaignName],
        },
        {
          title: mapCreatePromoCodeFieldToTitle[FieldName.MediaCampaignUrl],
          value: values[FieldName.MediaCampaignUrl],
        },
        values[FieldName.Usage] && {
          title: mapCreatePromoCodeFieldToTitle[FieldName.Usage],
          value: promoCodeUsageTypeMap[values[FieldName.Usage]],
        },
        {
          title: mapCreatePromoCodeFieldToTitle[FieldName.ReferralLinkUrl],
          value: values[FieldName.ReferralLinkUrl] && (
            <div className={styles.referralLink}>
              {TextOverflow({
                text: values[FieldName.ReferralLinkUrl],
              })}
            </div>
          ),
        },
      ]}
    />
  );
};
