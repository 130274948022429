import { FC, SyntheticEvent } from 'react';
import { TextOverflow } from 'ui/TextOverflow';
import { CardIcon, CardIconColor } from 'ui/Card/CardIcon';
import { faCopy } from '@fortawesome/pro-solid-svg-icons';
import { toast } from 'react-toastify';
import Button from 'ui/Button';
import styles from './CopyTableRow.module.scss';
import { copyToClipboard } from '../../utils/copyToClipboard';

interface CopyTableRowProps {
  value: string;
  toastText: string;
}

export const CopyTableRow: FC<CopyTableRowProps> = ({ value, toastText }) => {
  const handleClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    copyToClipboard(value);
    toast.success(toastText);
  };

  return (
    <Button linkStyled onClick={handleClick}>
      <div className={styles.buttonContent}>
        <div className={styles.referralLink}>
          {TextOverflow({
            text: value,
          })}
        </div>
        <CardIcon
          className={styles.iconContainer}
          config={{ icon: faCopy, iconColor: CardIconColor.TRANSPARENT }}
        />
      </div>
    </Button>
  );
};
