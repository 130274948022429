export enum CompanyAffiliateApplicationsColumnId {
  Id = 'id',
  Company = 'company',
  ContactForCommunication = 'company.contactForCommunication',
  Status = 'status',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export const mapCompanyAffiliateApplicationsColumnIdToName: Record<
  CompanyAffiliateApplicationsColumnId,
  string
> = {
  [CompanyAffiliateApplicationsColumnId.Id]: 'ID',
  [CompanyAffiliateApplicationsColumnId.Company]: 'Компания',
  [CompanyAffiliateApplicationsColumnId.ContactForCommunication]:
    'Контактная информация',
  [CompanyAffiliateApplicationsColumnId.Status]: 'Статус',
  [CompanyAffiliateApplicationsColumnId.CreatedAt]: 'Создан',
  [CompanyAffiliateApplicationsColumnId.UpdatedAt]: 'Обновлён',
};

export const columnsIds = [
  CompanyAffiliateApplicationsColumnId.Id,
  CompanyAffiliateApplicationsColumnId.Company,
  CompanyAffiliateApplicationsColumnId.ContactForCommunication,
  CompanyAffiliateApplicationsColumnId.Status,
  CompanyAffiliateApplicationsColumnId.CreatedAt,
  CompanyAffiliateApplicationsColumnId.UpdatedAt,
];
