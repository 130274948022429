import { FC, ReactElement } from 'react';
import { CompanyPageContent } from '../CompanyPageContent';

interface CompanyPageContainerProps {
  children: ReactElement;
  companyId: string;
  shouldProvideBlock?: boolean;
}

export const CompanyPageContainer: FC<CompanyPageContainerProps> = ({
  companyId,
  shouldProvideBlock = true,
  children,
}) => (
  <CompanyPageContent
    companyId={companyId}
    shouldProvideBlock={shouldProvideBlock}
  >
    {children}
  </CompanyPageContent>
);
