import NowrapCell from 'ui/Table/components/NowrapCell/NowrapCell';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import MultilineCellWithIcon from 'ui/Table/components/MultilineCellWithIcon/MultilineCellWithIcon';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { BonusBalance } from 'commonComponents/BonusBalance';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { createColumnHelper } from '@tanstack/react-table';
import { ColumnsSource } from 'ui/Table/types';
import { TableCellAlign } from 'ui/Table/const';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import {
  AccountEventsColumnId,
  blockColumnsIds,
  mapAccountEventsColumnToName,
  pageColumnsIds,
} from '../const';
import { mapEventGroupTypeToName } from '../../../const';
import { formatTransactionCount } from '../helpers';
import { getEventNameFromEvent, getSumWithSign } from '../../../helpers';
import { AccountEventGroupData } from '../../../queries/fragments/generated/AccountEventGroupData';
import { AccountEventGroupBasicData } from '../../../queries/fragments/generated/AccountEventGroupBasicData';

const columnHelper = createColumnHelper<
  AccountEventGroupBasicData | AccountEventGroupData
>();

const columnsSource: ColumnsSource<
  AccountEventGroupBasicData | AccountEventGroupData
> = [
  columnHelper.accessor(AccountEventsColumnId.Id, {
    id: AccountEventsColumnId.Id,
    header: mapAccountEventsColumnToName[AccountEventsColumnId.Id],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnHelper.accessor(AccountEventsColumnId.EventType, {
    id: AccountEventsColumnId.EventType,
    header: mapAccountEventsColumnToName[AccountEventsColumnId.EventType],
    cell: ({ row }) => {
      const { type, events, eventCount } = row.original;
      const firstEventNode = events.edges[0].node;
      const firstTransactionName = getEventNameFromEvent(firstEventNode);
      const title = mapEventGroupTypeToName[type];
      const subTitle =
        eventCount === 1 && firstTransactionName
          ? firstTransactionName
          : formatTransactionCount(eventCount);

      return <MultilineCellWithIcon title={title} subTitle={subTitle} />;
    },
  }),
  columnHelper.accessor(AccountEventsColumnId.EventType, {
    id: AccountEventsColumnId.Sum,
    header: mapAccountEventsColumnToName[AccountEventsColumnId.Sum],
    cell: ({ row }) => {
      const { billingSummary, bonusSummary } = row.original;
      const billingDeltaWithSign = getSumWithSign({
        amount: billingSummary.delta.sum.amount,
        currency: billingSummary.delta.sum.currency,
        direction: billingSummary.delta.direction,
      });

      if (!bonusSummary) {
        return billingDeltaWithSign;
      }

      return (
        <MultilineCellWithIcon
          title={billingDeltaWithSign}
          subTitle={
            <BonusBalance
              amount={bonusSummary.delta.sum.amount}
              direction={bonusSummary.delta.direction}
            />
          }
          isAlignRight
        />
      );
    },
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnHelper.accessor(AccountEventsColumnId.EventType, {
    id: AccountEventsColumnId.Balance,
    header: mapAccountEventsColumnToName[AccountEventsColumnId.Balance],
    cell: ({ row }) => {
      const { billingSummary, bonusSummary } = row.original;
      const billingBalance = formatSumWithCurrency(
        billingSummary.to.amount,
        billingSummary.to.currency
      );

      if (!bonusSummary) {
        return billingBalance;
      }

      return (
        <MultilineCellWithIcon
          title={billingBalance}
          subTitle={<BonusBalance amount={bonusSummary.to.amount} />}
          isAlignRight
        />
      );
    },
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnHelper.accessor(AccountEventsColumnId.CreatedAt, {
    id: AccountEventsColumnId.CreatedAt,
    header: mapAccountEventsColumnToName[AccountEventsColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
  columnHelper.accessor(AccountEventsColumnId.UpdatedAt, {
    id: AccountEventsColumnId.UpdatedAt,
    header: mapAccountEventsColumnToName[AccountEventsColumnId.UpdatedAt],
    cell: ({ row }) => {
      const { original } = row;

      if ('updatedAt' in original) {
        return (
          <NowrapCell>
            {formatDate(original.updatedAt, DateFormat.FullDate)}
          </NowrapCell>
        );
      }

      return null;
    },
  }),
];

export const useGetAccountEventGroupsColumns = (isFullScreenTable: boolean) => {
  const columns = useGetTableColumns(
    isFullScreenTable ? pageColumnsIds : blockColumnsIds,
    columnsSource
  );

  return {
    columns,
  };
};
