import { FC, useState } from 'react';
import { RewardTariffType } from 'commonTypes';
import { withBlockProvider } from 'ui/Block/store';
import Block from 'ui/Block';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { STATISTIC_BLOCK_TITLE, STATISTIC_NO_DATA } from '../../const';
import { StatisticTabs } from '../StatisticTabs';
import { StatisticBlockHeaderContent } from '../StatisticBlockHeaderContent';
import { useGetCpaGeneralReport } from './hooks/useGetCpaGeneralReport';
import { CpaGeneralReportTable } from './components/CpaGeneralReportTable';

interface StatisticCpaProps {
  setTariffType: (type: RewardTariffType) => void;
}

export const CpaGeneralReport: FC<StatisticCpaProps> = withBlockProvider(
  ({ setTariffType }) => {
    const [isAllTimeCpa, setIsAllTimeCpa] = useState(false);
    const { cpaGeneralReportList, refetch, hasTotalRow } =
      useGetCpaGeneralReport(isAllTimeCpa);

    return (
      <Block
        title={STATISTIC_BLOCK_TITLE}
        id="statisticCpa"
        headerContent={
          <StatisticBlockHeaderContent
            handleRefetchClick={refetch}
            showAllCpa={(value) => setIsAllTimeCpa(value)}
            rewardTariffType={RewardTariffType.Cpa}
          />
        }
        subHeader={
          <StatisticTabs
            currentTabId={RewardTariffType.Cpa}
            setTariffType={setTariffType}
          />
        }
        shrinkLoaderWrapper={false}
        emptyText={STATISTIC_NO_DATA}
        isEmpty={isListEmpty(cpaGeneralReportList)}
        isPermanentOpened
      >
        {hasList(cpaGeneralReportList) && (
          <CpaGeneralReportTable
            report={cpaGeneralReportList}
            hasTotalRow={hasTotalRow}
          />
        )}
      </Block>
    );
  }
);
