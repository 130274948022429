export enum PartnerReferralLinkMediaItemColumnId {
  Id = 'id',
  Title = 'name',
  ReferralLink = 'referralLink',
  RewardTariff = 'mediaItemRewardTariff.rewardTariff.title',
  MediaCampaign = 'mediaCampaign',
  PostbackCount = 'postbackCount',
  CreatedAt = 'createdAt',
}

export const mapPartnerReferralLinkMediaItemIdToName: Record<
  PartnerReferralLinkMediaItemColumnId,
  string
> = {
  [PartnerReferralLinkMediaItemColumnId.Id]: 'ID',
  [PartnerReferralLinkMediaItemColumnId.Title]: 'Название',
  [PartnerReferralLinkMediaItemColumnId.MediaCampaign]: 'Медиа кампания',
  [PartnerReferralLinkMediaItemColumnId.ReferralLink]: 'Реферальная ссылка',
  [PartnerReferralLinkMediaItemColumnId.RewardTariff]: 'Партнёрский тариф',
  [PartnerReferralLinkMediaItemColumnId.PostbackCount]: 'Постбэк',
  [PartnerReferralLinkMediaItemColumnId.CreatedAt]: 'Создан',
};

export const blockColumnsIds = [
  PartnerReferralLinkMediaItemColumnId.Title,
  PartnerReferralLinkMediaItemColumnId.MediaCampaign,
  PartnerReferralLinkMediaItemColumnId.ReferralLink,
  PartnerReferralLinkMediaItemColumnId.RewardTariff,
  PartnerReferralLinkMediaItemColumnId.CreatedAt,
];

export const pageColumnsIds = [
  PartnerReferralLinkMediaItemColumnId.Id,
  PartnerReferralLinkMediaItemColumnId.Title,
  PartnerReferralLinkMediaItemColumnId.MediaCampaign,
  PartnerReferralLinkMediaItemColumnId.ReferralLink,
  PartnerReferralLinkMediaItemColumnId.PostbackCount,
  PartnerReferralLinkMediaItemColumnId.RewardTariff,
  PartnerReferralLinkMediaItemColumnId.CreatedAt,
];
