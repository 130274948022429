import { FC } from 'react';
import { DataCard } from 'commonComponents/DataCard';
import { ProgressBar } from 'ui/ProgressBar';
import { CopyModalRow } from 'ui/CopyModalRow';
import { MediaItemPromoCodeData } from 'src/queries/generated/MediaItemPromoCodeData';
import { promoCodeUsageTypeMap } from 'src/const';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { FreespinActionBlock } from './components/FreespinActionBlock';
import { BonusActionBlock } from './components/BonusActionBlock';

interface PromoCodeModalDetailsProps {
  promoCodeMediaItem: MediaItemPromoCodeData;
}

export const PromoCodeModalDetails: FC<PromoCodeModalDetailsProps> = ({
  promoCodeMediaItem,
}) => {
  const {
    promoCode,
    mediaCampaign,
    usageType,
    referralLink,
    createdAt,
    updatedAt,
  } = promoCodeMediaItem;

  const { token, freespinAction, bonusAction, activation } = promoCode;
  const { name, url } = mediaCampaign;
  const activated = activation?.activated;
  const limit = activation?.limit;

  const hasActivations = activated !== undefined && limit !== undefined;

  const rows = [
    {
      title: 'Токен',
      value: token,
    },
    {
      title: 'Название медиа кампании',
      value: name,
    },
    {
      title: `URL медиа кампании`,
      value: url,
    },
    {
      title: 'Использование',
      value: promoCodeUsageTypeMap[usageType],
    },
    {
      title: `Реферальная ссылка`,
      value: referralLink && (
        <CopyModalRow
          value={referralLink?.referralLink}
          toastText="Реферальная ссылка скопирована"
        />
      ),
    },
    {
      title: 'Создан',
      value: formatDate(createdAt, DateFormat.DefaultDateOld),
    },
    {
      title: 'Обновлён',
      value: formatDate(updatedAt, DateFormat.DefaultDateOld),
    },
  ];

  return (
    <>
      <DataCard
        customSubHeader={
          hasActivations && (
            <ProgressBar
              startTitle={`Активировано: ${activated}`}
              endTitle={`${limit}`}
              percent={(activated / limit) * 100}
            />
          )
        }
        rows={rows}
      />
      {freespinAction && (
        <FreespinActionBlock freespinAction={freespinAction} />
      )}
      {bonusAction && <BonusActionBlock bonusAction={bonusAction} />}
    </>
  );
};
