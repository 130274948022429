import { FC } from 'react';
import BlockTable from 'ui/Block/BlockTable';
import { MediaItemPromoCodeBasicData } from 'src/queries/generated/MediaItemPromoCodeBasicData';
import { useOpenMediaItemPromoCodeModal } from './hooks/useOpenMediaItemPromoCodeModal';
import { useGetMediaItemsPromoCodeColumns } from './hooks/useGetMediaItemsPromoCodeColumns';

interface MediaItemsPromoCodeTableProps {
  mediaItemsPromoCode?: Array<MediaItemPromoCodeBasicData>;
}

export const MediaItemsPromoCodeTable: FC<MediaItemsPromoCodeTableProps> = ({
  mediaItemsPromoCode,
}) => {
  const { columns } = useGetMediaItemsPromoCodeColumns();
  const openModal = useOpenMediaItemPromoCodeModal();

  return (
    <BlockTable
      onClickOnRow={(mediaItemPromoCode, initiatorId) =>
        openModal({
          mediaItemPromoCode,
          initiatorId,
        })
      }
      columns={columns}
      data={mediaItemsPromoCode}
      isFullScreenTable
    />
  );
};
