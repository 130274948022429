import NowrapCell from 'ui/Table/components/NowrapCell';
import { CopyTableRow } from 'ui/CopyTableRow';
import MultilineCellWithIcon from 'ui/Table/components/MultilineCellWithIcon';
import { MediaItemReferralLinkData } from 'src/queries/generated/MediaItemReferralLinkData';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { ownerTypeNameMap } from 'src/const';
import { ColumnsSource } from 'ui/Table/types';
import { createColumnHelper } from '@tanstack/react-table';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import {
  MediaItemsReferralLinkColumnId,
  mapMediaItemsReferralLinkIdToName,
  columnsIds,
} from '../const';

const columnHelper = createColumnHelper<MediaItemReferralLinkData>();

const columnsSource: ColumnsSource<MediaItemReferralLinkData> = [
  columnHelper.accessor(MediaItemsReferralLinkColumnId.Id, {
    id: MediaItemsReferralLinkColumnId.Id,
    header:
      mapMediaItemsReferralLinkIdToName[MediaItemsReferralLinkColumnId.Id],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnHelper.accessor(MediaItemsReferralLinkColumnId.Title, {
    id: MediaItemsReferralLinkColumnId.Title,
    header:
      mapMediaItemsReferralLinkIdToName[MediaItemsReferralLinkColumnId.Title],
  }),
  columnHelper.accessor(MediaItemsReferralLinkColumnId.MediaCampaign, {
    id: MediaItemsReferralLinkColumnId.MediaCampaign,
    header:
      mapMediaItemsReferralLinkIdToName[
        MediaItemsReferralLinkColumnId.MediaCampaign
      ],
    cell: ({ row }) => {
      const { name, url } = row.original.mediaCampaign;

      return MultilineCellWithIcon({
        title: name,
        subTitle: url,
      });
    },
  }),
  columnHelper.accessor(MediaItemsReferralLinkColumnId.AccountType, {
    id: MediaItemsReferralLinkColumnId.AccountType,
    header:
      mapMediaItemsReferralLinkIdToName[
        MediaItemsReferralLinkColumnId.AccountType
      ],
    cell: ({ row }) => ownerTypeNameMap[row.original.owner.__typename],
  }),
  columnHelper.accessor(MediaItemsReferralLinkColumnId.AffiliateName, {
    id: MediaItemsReferralLinkColumnId.AffiliateName,
    header:
      mapMediaItemsReferralLinkIdToName[
        MediaItemsReferralLinkColumnId.AffiliateName
      ],
  }),
  columnHelper.accessor(MediaItemsReferralLinkColumnId.ReferralLink, {
    id: MediaItemsReferralLinkColumnId.ReferralLink,
    header:
      mapMediaItemsReferralLinkIdToName[
        MediaItemsReferralLinkColumnId.ReferralLink
      ],
    cell: ({ row }) => (
      <CopyTableRow
        value={row.original.referralLink}
        toastText="Реферальная ссылка скопирована"
      />
    ),
  }),
  columnHelper.accessor(MediaItemsReferralLinkColumnId.CreatedAt, {
    id: MediaItemsReferralLinkColumnId.CreatedAt,
    header:
      mapMediaItemsReferralLinkIdToName[
        MediaItemsReferralLinkColumnId.CreatedAt
      ],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.DefaultDateOld)}
      </NowrapCell>
    ),
  }),
];

export const useGetMediaItemsReferralLinkColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
