import { nbsp } from 'src/const';

export enum RevShareGeneralReportColumnId {
  Affiliate = 'affiliate',
  Hits = 'marketingStatistic.hits',
  Hosts = 'marketingStatistic.hosts',
  Registrations = 'marketingStatistic.registrations',
  FirstDeposits = 'marketingStatistic.firstDeposits',
  HostToRegConversion = 'marketingStatistic.hostToRegConversion',
  RegToDepConversion = 'marketingStatistic.regToDepConversion',

  AllDeposit = 'playerStatistic.allDeposit',
  AverageDeposit = 'playerStatistic.averageDeposit',
  AllRedeposit = 'playerStatistic.allRedeposit',
  Redeposits = 'marketingStatistic.redeposits',
  NewDeposit = 'playerRegisteredStatistic.newDeposit',
  IncomingMoneyTransfer = 'playerStatistic.incomingMoneyTransfer',

  Withdrawal = 'playerStatistic.withdrawal',
  NewWithdrawal = 'playerRegisteredStatistic.newWithdrawal',
  NewProfit = 'playerRegisteredStatistic.newProfit',
  DepositFee = 'playerStatistic.depositFee',
  WithdrawalFee = 'playerStatistic.withdrawalFee',
  AdminFee = 'adminFee',
  Bonus = 'playerStatistic.bonus',
  TournamentPrize = 'playerStatistic.tournamentPrize',
  Jackpot = 'playerStatistic.jackpot',
  NGR = 'playerStatistic.financialIndicators.ngr',
  Profit = 'playerStatistic.financialIndicators.profit',
  Upcoming = 'affiliateRewardStatistic.upcoming',
  RewardPaid = 'affiliateRewardStatistic.paid',
}

export const mapRevShareGeneralReportColumnIdToName: Record<
  RevShareGeneralReportColumnId,
  string
> = {
  [RevShareGeneralReportColumnId.Affiliate]: 'Партнёр',
  [RevShareGeneralReportColumnId.Hits]: 'Хиты',
  [RevShareGeneralReportColumnId.Hosts]: 'Хосты',
  [RevShareGeneralReportColumnId.Registrations]: 'Регистрации',
  [RevShareGeneralReportColumnId.FirstDeposits]: 'Первые депозиты',
  [RevShareGeneralReportColumnId.HostToRegConversion]: 'Host2reg',
  [RevShareGeneralReportColumnId.RegToDepConversion]: 'Reg2dep',
  [RevShareGeneralReportColumnId.AllDeposit]: 'Все депозиты',
  [RevShareGeneralReportColumnId.AverageDeposit]: 'Средний депозит',
  [RevShareGeneralReportColumnId.AllRedeposit]: 'Все редепозиты',
  [RevShareGeneralReportColumnId.Redeposits]: 'Количество редепозитов',
  [RevShareGeneralReportColumnId.NewDeposit]: 'Новые депозиты',
  [RevShareGeneralReportColumnId.IncomingMoneyTransfer]: 'Входящие переводы',
  [RevShareGeneralReportColumnId.Withdrawal]: 'Выводы',
  [RevShareGeneralReportColumnId.NewWithdrawal]: 'Новые выводы',
  [RevShareGeneralReportColumnId.NewProfit]: 'Новый доход',
  [RevShareGeneralReportColumnId.DepositFee]: `Комиссии за${nbsp}депозиты`,
  [RevShareGeneralReportColumnId.WithdrawalFee]: `Комиссии за${nbsp}выводы`,
  [RevShareGeneralReportColumnId.AdminFee]: 'Admin fee',
  [RevShareGeneralReportColumnId.Bonus]: 'Бонусы',
  [RevShareGeneralReportColumnId.TournamentPrize]: 'Призовые',
  [RevShareGeneralReportColumnId.Jackpot]: 'Джекпоты',
  [RevShareGeneralReportColumnId.NGR]: 'NGR',
  [RevShareGeneralReportColumnId.Profit]: 'Доход',
  [RevShareGeneralReportColumnId.Upcoming]: 'Вознаграждение',
  [RevShareGeneralReportColumnId.RewardPaid]: 'Выплачено',
};

export const columnsIds = [
  RevShareGeneralReportColumnId.Affiliate,
  RevShareGeneralReportColumnId.Hits,
  RevShareGeneralReportColumnId.Hosts,
  RevShareGeneralReportColumnId.Registrations,
  RevShareGeneralReportColumnId.FirstDeposits,
  RevShareGeneralReportColumnId.HostToRegConversion,
  RevShareGeneralReportColumnId.RegToDepConversion,
  RevShareGeneralReportColumnId.AllDeposit,
  RevShareGeneralReportColumnId.AverageDeposit,
  RevShareGeneralReportColumnId.AllRedeposit,
  RevShareGeneralReportColumnId.Redeposits,
  RevShareGeneralReportColumnId.NewDeposit,
  RevShareGeneralReportColumnId.IncomingMoneyTransfer,
  RevShareGeneralReportColumnId.Withdrawal,
  RevShareGeneralReportColumnId.NewWithdrawal,
  RevShareGeneralReportColumnId.NewProfit,
  RevShareGeneralReportColumnId.DepositFee,
  RevShareGeneralReportColumnId.WithdrawalFee,
  RevShareGeneralReportColumnId.AdminFee,
  RevShareGeneralReportColumnId.Bonus,
  RevShareGeneralReportColumnId.TournamentPrize,
  RevShareGeneralReportColumnId.Jackpot,
  RevShareGeneralReportColumnId.NGR,
  RevShareGeneralReportColumnId.Profit,
  RevShareGeneralReportColumnId.Upcoming,
  RevShareGeneralReportColumnId.RewardPaid,
];
