import { formatSumWithCurrency } from 'src/utils/accounting';
import { ColumnsSource } from 'ui/Table/types';
import { createColumnHelper } from '@tanstack/react-table';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { addPercentToValue } from 'src/utils/addPercentToValue';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { formatNumberWithRuLocale } from 'src/utils/formatNumberWithRuLocale';
import { Route } from 'src/router/routes.const';
import {
  columnsIds,
  CpaPartnerReportColumnId,
  mapCpaPartnerReportColumnIdToName,
} from '../const';
import { CpaPartnerReportData } from '../../../queries/generated/CpaPartnerReportData';
import { STATISTIC_TOTAL_ROW_TITLE } from '../../../../../const';
import { MultilineCellWithLinkWithPeriodQueryParams } from '../../../../../components/MultilineCellWithLinkWithPeriodQueryParams';

const columnHelper = createColumnHelper<CpaPartnerReportData>();

const columnsSource: ColumnsSource<CpaPartnerReportData> = [
  columnHelper.accessor(CpaPartnerReportColumnId.ReferralCode, {
    id: CpaPartnerReportColumnId.ReferralCode,
    header:
      mapCpaPartnerReportColumnIdToName[CpaPartnerReportColumnId.ReferralCode],
    cell: ({ row }) => {
      if (!row.original.mediaItem) {
        return STATISTIC_TOTAL_ROW_TITLE;
      }

      const { id, name } = row.original.mediaItem;

      return (
        <MultilineCellWithLinkWithPeriodQueryParams
          id={id}
          title={name}
          subTitle={addHashTagToId(id)}
          route={Route.STATISTIC_CPA_REFERRAL_LINK}
        />
      );
    },
    enablePinning: true,
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(CpaPartnerReportColumnId.Hits, {
    id: CpaPartnerReportColumnId.Hits,
    header: mapCpaPartnerReportColumnIdToName[CpaPartnerReportColumnId.Hits],
    cell: ({ row }) => {
      const { hits } = row.original.marketingStatistic;

      return formatNumberWithRuLocale(hits);
    },
  }),
  columnHelper.accessor(CpaPartnerReportColumnId.Hosts, {
    id: CpaPartnerReportColumnId.Hosts,
    header: mapCpaPartnerReportColumnIdToName[CpaPartnerReportColumnId.Hosts],
    cell: ({ row }) => {
      const { hosts } = row.original.marketingStatistic;

      return formatNumberWithRuLocale(hosts);
    },
  }),
  columnHelper.accessor(CpaPartnerReportColumnId.Registrations, {
    id: CpaPartnerReportColumnId.Registrations,
    header:
      mapCpaPartnerReportColumnIdToName[CpaPartnerReportColumnId.Registrations],
    cell: ({ row }) => {
      const { registrations } = row.original.marketingStatistic;

      return formatNumberWithRuLocale(registrations);
    },
  }),
  columnHelper.accessor(CpaPartnerReportColumnId.FirstDeposits, {
    id: CpaPartnerReportColumnId.FirstDeposits,
    header:
      mapCpaPartnerReportColumnIdToName[CpaPartnerReportColumnId.FirstDeposits],
    cell: ({ row }) => {
      const { firstDeposits } = row.original.marketingStatistic;

      return formatNumberWithRuLocale(firstDeposits);
    },
  }),
  columnHelper.accessor(CpaPartnerReportColumnId.HostToRegConversion, {
    id: CpaPartnerReportColumnId.HostToRegConversion,
    header:
      mapCpaPartnerReportColumnIdToName[
        CpaPartnerReportColumnId.HostToRegConversion
      ],
    cell: ({ row }) => {
      const { hostToRegConversion } = row.original.marketingStatistic;

      return addPercentToValue(hostToRegConversion);
    },
  }),
  columnHelper.accessor(CpaPartnerReportColumnId.RegToDepConversion, {
    id: CpaPartnerReportColumnId.RegToDepConversion,
    header:
      mapCpaPartnerReportColumnIdToName[
        CpaPartnerReportColumnId.RegToDepConversion
      ],
    cell: ({ row }) => {
      const { regToDepConversion } = row.original.marketingStatistic;

      return addPercentToValue(regToDepConversion);
    },
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(CpaPartnerReportColumnId.AllDeposit, {
    id: CpaPartnerReportColumnId.AllDeposit,
    header:
      mapCpaPartnerReportColumnIdToName[CpaPartnerReportColumnId.AllDeposit],
    cell: ({ row }) => {
      const { allDeposit } = row.original.playerStatistic;

      return formatSumWithCurrency(allDeposit.amount, allDeposit.currency);
    },
  }),
  columnHelper.accessor(CpaPartnerReportColumnId.AverageDeposit, {
    id: CpaPartnerReportColumnId.AverageDeposit,
    header:
      mapCpaPartnerReportColumnIdToName[
        CpaPartnerReportColumnId.AverageDeposit
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.averageDeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaPartnerReportColumnId.AllRedeposit, {
    id: CpaPartnerReportColumnId.AllRedeposit,
    header:
      mapCpaPartnerReportColumnIdToName[CpaPartnerReportColumnId.AllRedeposit],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.allRedeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaPartnerReportColumnId.Redeposits, {
    id: CpaPartnerReportColumnId.Redeposits,
    header:
      mapCpaPartnerReportColumnIdToName[CpaPartnerReportColumnId.Redeposits],
    cell: ({ row }) => {
      const { redeposits } = row.original.marketingStatistic;

      return formatNumberWithRuLocale(redeposits);
    },
  }),
  columnHelper.accessor(CpaPartnerReportColumnId.NewDeposit, {
    id: CpaPartnerReportColumnId.NewDeposit,
    header:
      mapCpaPartnerReportColumnIdToName[CpaPartnerReportColumnId.NewDeposit],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerRegisteredStatistic.newDeposit;

      return formatSumWithCurrency(amount, currency);
    },
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(CpaPartnerReportColumnId.Withdrawal, {
    id: CpaPartnerReportColumnId.Withdrawal,
    header:
      mapCpaPartnerReportColumnIdToName[CpaPartnerReportColumnId.Withdrawal],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.withdrawal;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaPartnerReportColumnId.NewWithdrawal, {
    id: CpaPartnerReportColumnId.NewWithdrawal,
    header:
      mapCpaPartnerReportColumnIdToName[CpaPartnerReportColumnId.NewWithdrawal],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerRegisteredStatistic.newWithdrawal;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaPartnerReportColumnId.NewProfit, {
    id: CpaPartnerReportColumnId.NewProfit,
    header:
      mapCpaPartnerReportColumnIdToName[CpaPartnerReportColumnId.NewProfit],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerRegisteredStatistic.newProfit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaPartnerReportColumnId.Hold, {
    id: CpaPartnerReportColumnId.Hold,
    header: mapCpaPartnerReportColumnIdToName[CpaPartnerReportColumnId.Hold],
    cell: ({ row }) => {
      const { hold } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(hold);
    },
  }),
  columnHelper.accessor(CpaPartnerReportColumnId.Qualified, {
    id: CpaPartnerReportColumnId.Qualified,
    header:
      mapCpaPartnerReportColumnIdToName[CpaPartnerReportColumnId.Qualified],
    cell: ({ row }) => {
      const { qualified } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(qualified);
    },
  }),
  columnHelper.accessor(CpaPartnerReportColumnId.NotQualified, {
    id: CpaPartnerReportColumnId.NotQualified,
    header:
      mapCpaPartnerReportColumnIdToName[CpaPartnerReportColumnId.NotQualified],
    cell: ({ row }) => {
      const { canceled } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(canceled);
    },
  }),
  columnHelper.accessor(CpaPartnerReportColumnId.Approved, {
    id: CpaPartnerReportColumnId.Approved,
    header:
      mapCpaPartnerReportColumnIdToName[CpaPartnerReportColumnId.Approved],
    cell: ({ row }) => {
      const { approved } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(approved);
    },
  }),
  columnHelper.accessor(CpaPartnerReportColumnId.Rejected, {
    id: CpaPartnerReportColumnId.Rejected,
    header:
      mapCpaPartnerReportColumnIdToName[CpaPartnerReportColumnId.Rejected],
    cell: ({ row }) => {
      const { rejected } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(rejected);
    },
  }),
  columnHelper.accessor(CpaPartnerReportColumnId.Paid, {
    id: CpaPartnerReportColumnId.Paid,
    header: mapCpaPartnerReportColumnIdToName[CpaPartnerReportColumnId.Paid],
    cell: ({ row }) => {
      const { paid } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(paid);
    },
  }),
  columnHelper.accessor(CpaPartnerReportColumnId.Unpaid, {
    id: CpaPartnerReportColumnId.Unpaid,
    header: mapCpaPartnerReportColumnIdToName[CpaPartnerReportColumnId.Unpaid],
    cell: ({ row }) => {
      const { unpaid } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(unpaid);
    },
  }),
  columnHelper.accessor(CpaPartnerReportColumnId.RevShare, {
    id: CpaPartnerReportColumnId.RevShare,
    header:
      mapCpaPartnerReportColumnIdToName[CpaPartnerReportColumnId.RevShare],
    cell: ({ row }) => {
      const { revShare } = row.original.playerStatusStatistic;

      return formatNumberWithRuLocale(revShare);
    },
  }),
  columnHelper.accessor(CpaPartnerReportColumnId.NGR, {
    id: CpaPartnerReportColumnId.NGR,
    header: mapCpaPartnerReportColumnIdToName[CpaPartnerReportColumnId.NGR],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerStatistic.financialIndicators.ngr;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaPartnerReportColumnId.Profit, {
    id: CpaPartnerReportColumnId.Profit,
    header: mapCpaPartnerReportColumnIdToName[CpaPartnerReportColumnId.Profit],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerStatistic.financialIndicators.profit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaPartnerReportColumnId.Upcoming, {
    id: CpaPartnerReportColumnId.Upcoming,
    header:
      mapCpaPartnerReportColumnIdToName[CpaPartnerReportColumnId.Upcoming],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.affiliateRewardStatistic.upcoming;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(CpaPartnerReportColumnId.RewardPaid, {
    id: CpaPartnerReportColumnId.RewardPaid,
    header:
      mapCpaPartnerReportColumnIdToName[CpaPartnerReportColumnId.RewardPaid],
    cell: ({ row }) => {
      const { amount, currency } = row.original.affiliateRewardStatistic.paid;

      return formatSumWithCurrency(amount, currency);
    },
  }),
];

export const useGetCpaPartnerReportColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
