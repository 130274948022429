export enum OutgoingMoneyTransfersColumnId {
  Id = 'id',
  Account = 'player.moneyTransferAccount',
  Player = 'player',
  Sum = 'sum',
  CreatedAt = 'createdAt',
}

export const mapOutgoingMoneyTransfersColumnIdToName: Record<
  OutgoingMoneyTransfersColumnId,
  string
> = {
  [OutgoingMoneyTransfersColumnId.Id]: 'ID',
  [OutgoingMoneyTransfersColumnId.Account]: 'Номер счёта',
  [OutgoingMoneyTransfersColumnId.Player]: 'Получатель',
  [OutgoingMoneyTransfersColumnId.Sum]: 'Сумма',
  [OutgoingMoneyTransfersColumnId.CreatedAt]: 'Создан',
};

export const blockColumnsIds = [
  OutgoingMoneyTransfersColumnId.Account,
  OutgoingMoneyTransfersColumnId.Player,
  OutgoingMoneyTransfersColumnId.Sum,
  OutgoingMoneyTransfersColumnId.CreatedAt,
];

export const pageColumnsIds = [
  OutgoingMoneyTransfersColumnId.Id,
  OutgoingMoneyTransfersColumnId.Account,
  OutgoingMoneyTransfersColumnId.Player,
  OutgoingMoneyTransfersColumnId.Sum,
  OutgoingMoneyTransfersColumnId.CreatedAt,
];
