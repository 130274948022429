export enum SignInRestrictionHistoryColumnId {
  Id = 'id',
  Type = 'protected',
  Reason = 'reason',
  Status = 'status',
  CreatedAt = 'lockedAt',
  ExpiredAt = 'expiredAt',
}

export const mapSignInRestrictionHistoryColumnToName: Record<
  SignInRestrictionHistoryColumnId,
  string
> = {
  [SignInRestrictionHistoryColumnId.Id]: 'ID',
  [SignInRestrictionHistoryColumnId.Type]: 'Тип',
  [SignInRestrictionHistoryColumnId.Reason]: 'Причина',
  [SignInRestrictionHistoryColumnId.Status]: 'Статус',
  [SignInRestrictionHistoryColumnId.CreatedAt]: 'Создана',
  [SignInRestrictionHistoryColumnId.ExpiredAt]: 'Окончание',
};

export const blockColumnsIds = [
  SignInRestrictionHistoryColumnId.Type,
  SignInRestrictionHistoryColumnId.Reason,
  SignInRestrictionHistoryColumnId.Status,
  SignInRestrictionHistoryColumnId.CreatedAt,
  SignInRestrictionHistoryColumnId.ExpiredAt,
];

export const pageColumnsIds = [
  SignInRestrictionHistoryColumnId.Id,
  SignInRestrictionHistoryColumnId.Type,
  SignInRestrictionHistoryColumnId.Reason,
  SignInRestrictionHistoryColumnId.Status,
  SignInRestrictionHistoryColumnId.CreatedAt,
  SignInRestrictionHistoryColumnId.ExpiredAt,
];
