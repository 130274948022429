import { useCallback, useContext, useMemo } from 'react';
import { BlockContext } from 'ui/Block/store';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import Block from 'ui/Block';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import BlockTable from 'ui/Block/BlockTable';
import Privilege from 'commonComponents/Privilege';
import { UserPrivilege } from 'generatedUserPrivilege';
import { HeaderActionButtonsWrapper } from 'ui/Block/BlockTable/components/HeaderActionButtonsWrapper';
import { ColumnDef } from '@tanstack/react-table';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useGetCompanyReferralLinksByTableSize } from './hooks/useGetCompanyReferralLinksByTableSize';
import { useOpenCreateReferralLinkModal } from './hooks/useOpenCreateReferralLinkModal';
import { useOpenReferralLinkDetailsModal } from './hooks/useOpenReferralLinkDetailsModal';

const COUNT_PER_PAGE = 10;

interface CompanyReferralLinkMediaItemTableProps<T extends object> {
  companyId: string;
  columns: Array<ColumnDef<T>>;
  isFullScreenTable: boolean;
  shouldReturnToPrevPage?: boolean;
  routeToCustomPage?: string;
}

export const CompanyReferralLinkMediaItemTable = <
  T extends { __typename: string }
>({
  companyId,
  columns,
  isFullScreenTable,
  shouldReturnToPrevPage,
  routeToCustomPage,
}: CompanyReferralLinkMediaItemTableProps<T>) => {
  const { state } = useContext(BlockContext);
  const { loadCompanyReferralLinks, data, loading, error, fetchMore, refetch } =
    useGetCompanyReferralLinksByTableSize(companyId, isFullScreenTable);
  const { openReferralLinkDetailsModal } =
    useOpenReferralLinkDetailsModal<T>(companyId);

  useBlockComponentState({
    loadData: loadCompanyReferralLinks,
    loading,
    error,
  });

  const companyReferralLinks = data?.company.referralLinkMediaItems;
  const endCursor = companyReferralLinks?.pageInfo.endCursor;

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: COUNT_PER_PAGE,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false }
      ),
    [endCursor, fetchMore]
  );

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage: companyReferralLinks?.pageInfo.hasNextPage,
    isFullScreenTable,
  });

  const companyReferralLinksList = useMemo(
    () =>
      companyReferralLinks?.edges.map(
        ({ node }) => node
      ) as unknown as Array<T>,
    [companyReferralLinks]
  );

  const { openCreateReferralLinkModal } = useOpenCreateReferralLinkModal({
    companyId,
    isFullScreenTable,
  });

  return (
    <Block
      title="Медиа элементы"
      id="companyReferralLinksList"
      headerContent={
        <HeaderActionButtonsWrapper>
          <Privilege
            privileges={[UserPrivilege.CreateCompanyReferralLinkMediaItem]}
          >
            <ActionButton
              actionType={ButtonType.Add}
              onClick={openCreateReferralLinkModal}
            />
          </Privilege>
          <ActionButton
            actionType={ButtonType.Update}
            hidden={!state.isOpened}
            disabled={state.isLoading}
            onClick={() => refetch()}
          />
        </HeaderActionButtonsWrapper>
      }
      isEmpty={isListEmpty(companyReferralLinksList)}
      emptyText="Медиа элементы не найдены"
      shouldReturnToPrevPage={shouldReturnToPrevPage}
    >
      {hasList(companyReferralLinksList) && (
        <BlockTable
          onClickOnRow={openReferralLinkDetailsModal}
          columns={columns}
          data={companyReferralLinksList}
          isFullScreenTable={isFullScreenTable}
          routeToCustomPage={routeToCustomPage}
        />
      )}
    </Block>
  );
};
