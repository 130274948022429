import MultilineCellWithIcon from 'ui/Table/components/MultilineCellWithIcon';
import MayBeNullCell from 'ui/Table/components/MayBeNullCell';
import NowrapCell from 'ui/Table/components/NowrapCell';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { ColumnsSource } from 'ui/Table/types';
import { createColumnHelper } from '@tanstack/react-table';
import { TableCellAlign } from 'ui/Table/const';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { BonusConfig } from 'generatedGraphql';
import { BonusData } from '../queries/generated/BonusData';
import { getStatusConfig, getTypeBonus } from '../helpers';
import {
  blockColumnsIds,
  BonusesColumnId,
  mapBonusesColumnIdToName,
  pageColumnsIds,
} from '../const';

const columnHelper = createColumnHelper<BonusData>();

const getBetSumCell = (config: BonusConfig) => {
  const minAccountBalance = config.winConditions?.betSum;

  return MayBeNullCell(
    minAccountBalance &&
      formatSumWithCurrency(
        minAccountBalance.amount,
        minAccountBalance.currency
      ),
    '—'
  );
};

const columnsSource: ColumnsSource<BonusData> = [
  columnHelper.accessor(BonusesColumnId.Id, {
    id: BonusesColumnId.Id,
    header: mapBonusesColumnIdToName[BonusesColumnId.Id],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnHelper.accessor(BonusesColumnId.Type, {
    id: BonusesColumnId.Type,
    header: mapBonusesColumnIdToName[BonusesColumnId.Type],
    cell: ({ row }) => {
      const [title, iconConfig, subTitle] = getTypeBonus(row.original);

      return MultilineCellWithIcon({ title, iconConfig, subTitle });
    },
  }),
  columnHelper.accessor(BonusesColumnId.Sum, {
    id: BonusesColumnId.Sum,
    header: mapBonusesColumnIdToName[BonusesColumnId.Sum],
    cell: ({ row }) => {
      const { amount, currency } = row.original.sum;

      return formatSumWithCurrency(amount, currency);
    },
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnHelper.accessor(BonusesColumnId.Wager, {
    id: BonusesColumnId.Wager,
    header: mapBonusesColumnIdToName[BonusesColumnId.Wager],
    cell: ({ row }) => {
      const { winConditions } = row.original.config;

      if (winConditions) {
        return MayBeNullCell(winConditions.wager?.toString(), '—');
      }

      return null;
    },
  }),
  columnHelper.accessor(BonusesColumnId.Cashout, {
    id: BonusesColumnId.Cashout,
    header: mapBonusesColumnIdToName[BonusesColumnId.Cashout],
    cell: ({ row }) => {
      const { config } = row.original;

      if (config) {
        MayBeNullCell(config.cashout, '—');
      }

      return null;
    },
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnHelper.accessor(BonusesColumnId.MinBalance, {
    id: BonusesColumnId.MinBalance,
    header: mapBonusesColumnIdToName[BonusesColumnId.MinBalance],
    cell: ({ row }) => {
      const { minAccountBalance } = row.original.config;

      return MayBeNullCell(
        minAccountBalance &&
          formatSumWithCurrency(
            minAccountBalance.amount,
            minAccountBalance.currency
          ),
        '—'
      );
    },
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnHelper.accessor(BonusesColumnId.BetSum, {
    id: BonusesColumnId.BetSum,
    header: mapBonusesColumnIdToName[BonusesColumnId.BetSum],
    cell: ({ row }) => {
      const { config } = row.original;

      if (config) {
        getBetSumCell(config);
      }

      return null;
    },
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnHelper.accessor(BonusesColumnId.WinSum, {
    id: BonusesColumnId.WinSum,
    header: mapBonusesColumnIdToName[BonusesColumnId.WinSum],
    cell: ({ row }) => {
      const { winSum } = row.original;

      return MayBeNullCell(
        winSum && formatSumWithCurrency(winSum.amount, winSum.currency),
        '—'
      );
    },
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnHelper.accessor(BonusesColumnId.Balance, {
    id: BonusesColumnId.Balance,
    header: mapBonusesColumnIdToName[BonusesColumnId.Balance],
    cell: ({ row }) => {
      const { balance } = row.original;

      return formatSumWithCurrency(balance.amount, balance.currency);
    },
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnHelper.accessor(BonusesColumnId.Status, {
    id: BonusesColumnId.Status,
    header: mapBonusesColumnIdToName[BonusesColumnId.Status],
    cell: ({ row }) => {
      const [title, iconConfig] = getStatusConfig(row.original.status);

      return MultilineCellWithIcon({ title, iconConfig });
    },
  }),
  columnHelper.accessor(BonusesColumnId.CreatedAt, {
    id: BonusesColumnId.CreatedAt,
    header: mapBonusesColumnIdToName[BonusesColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
  columnHelper.accessor(BonusesColumnId.UpdatedAt, {
    id: BonusesColumnId.UpdatedAt,
    header: mapBonusesColumnIdToName[BonusesColumnId.UpdatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.updatedAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
];

export const useGetBonusesColumns = (isFullScreenTable: boolean) => {
  const columns = useGetTableColumns(
    isFullScreenTable ? pageColumnsIds : blockColumnsIds,
    columnsSource
  );

  return {
    columns,
  };
};
