import { FC } from 'react';
import { withBlockProvider } from 'ui/Block/store';
import { useLocation } from 'react-router-dom';
import { useGetMessagesColumns } from './hooks/useGetMessagesColumns';
import MessagesTable from './components/MessagesTable/MessagesTable';

interface MessagesProps {
  id: string;
}

const MessagesBlock: FC<MessagesProps> = ({ id }) => {
  const location = useLocation();
  const { columns } = useGetMessagesColumns(false);

  return (
    <MessagesTable
      playerId={id}
      columns={columns}
      routeToCustomPage={`${location.pathname}/messages`}
      isFullScreenTable={false}
    />
  );
};

export default withBlockProvider(MessagesBlock);
