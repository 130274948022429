import { FC, useState } from 'react';
import Block from 'ui/Block';
import { withBlockProvider } from 'ui/Block/store';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useParams } from 'react-router';
import { Route } from 'src/router/routes.const';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { RewardTariffType } from 'commonTypes';
import {
  CPA_COMPANY_AFFILIATE_STATISTIC_BLOCK_TITLE,
  STATISTIC_NO_DATA,
} from '../../const';
import { useGeneratePathWithSavePeriodQueryParams } from '../../hooks/useGeneratePathWithSavePeriodQueryParams';
import { StatisticBlockHeaderContent } from '../../components/StatisticBlockHeaderContent';
import { BlockSubHeaderCompanyAffiliateInfo } from '../../components/BlockSubHeaderCompanyAffiliateInfo';
import { useGetCpaCompanyAffiliateReport } from './hooks/useGetCpaCompanyAffiliateReport';
import { CpaCompanyAffiliateReportTable } from './components/CpaCompanyAffiliateReportTable';

interface CpaCompanyAffiliateReportProps {
  companyId: string;
  affiliateId: string;
}

const Component: FC<CpaCompanyAffiliateReportProps> = withBlockProvider(
  ({ companyId, affiliateId }) => {
    const [isAllTimeCpa, setIsAllTimeCpa] = useState(false);
    const {
      data,
      loadCpaCompanyAffiliateReport,
      cpaCompanyAffiliateReportList,
      loading,
      error,
      refetch,
      fetchMoreCallback,
      hasNextPage,
      hasTotalRow,
      affiliate,
    } = useGetCpaCompanyAffiliateReport({
      companyId,
      affiliateId,
      isAllTimeCpa,
    });

    useBlockComponentState({
      loadData: loadCpaCompanyAffiliateReport,
      loading,
      error,
    });

    useBlockTableComponentState({
      data,
      fetchMoreCallback,
      refetch,
      loading,
      hasNextPage,
      isFullScreenTable: true,
    });

    const generatePathWithPeriodQueryParams =
      useGeneratePathWithSavePeriodQueryParams();

    const customPathToPrevPage = generatePathWithPeriodQueryParams({
      originalPath: Route.STATISTIC_CPA_COMPANY_PARTNERS,
      params: {
        id: companyId,
      },
    });

    return (
      <Block
        title={CPA_COMPANY_AFFILIATE_STATISTIC_BLOCK_TITLE}
        id="cpaCompanyAffiliateStatistic"
        headerContent={
          <StatisticBlockHeaderContent
            handleRefetchClick={refetch}
            rewardTariffType={RewardTariffType.Cpa}
            showAllCpa={(value) => setIsAllTimeCpa(value)}
          />
        }
        subHeader={
          affiliate && (
            <BlockSubHeaderCompanyAffiliateInfo
              companyName={affiliate.company.name}
              affiliateName={affiliate.name}
            />
          )
        }
        shrinkLoaderWrapper={false}
        emptyText={STATISTIC_NO_DATA}
        isEmpty={isListEmpty(cpaCompanyAffiliateReportList)}
        shouldReturnToPrevPage
        isPermanentOpened
        customPathToPrevPage={customPathToPrevPage}
      >
        {hasList(cpaCompanyAffiliateReportList) && (
          <CpaCompanyAffiliateReportTable
            cpaCompanyAffiliateReport={cpaCompanyAffiliateReportList}
            hasTotalRow={hasTotalRow}
          />
        )}
      </Block>
    );
  }
);

export const CpaCompanyAffiliateReport = () => {
  const { companyId, affiliateId } = useParams();

  if (!companyId || !affiliateId) {
    return null;
  }

  return <Component companyId={companyId} affiliateId={affiliateId} />;
};
