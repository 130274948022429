export enum CompanyApplicationsColumnId {
  Id = 'id',
  Company = '__typename',
  ContactForCommunication = 'contactForCommunication',
  WebSite = 'webSite',
  TrafficSource = 'trafficSource',
  Status = 'status',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export const mapCompanyApplicationsColumnIdToName: Record<
  CompanyApplicationsColumnId,
  string
> = {
  [CompanyApplicationsColumnId.Id]: 'ID',
  [CompanyApplicationsColumnId.Company]: 'Компания',
  [CompanyApplicationsColumnId.ContactForCommunication]:
    'Контактная информация',
  [CompanyApplicationsColumnId.WebSite]: 'Вебсайт',
  [CompanyApplicationsColumnId.TrafficSource]: 'Источник трафика',
  [CompanyApplicationsColumnId.Status]: 'Статус',
  [CompanyApplicationsColumnId.CreatedAt]: 'Создан',
  [CompanyApplicationsColumnId.UpdatedAt]: 'Обновлён',
};

export const columnsIds = [
  CompanyApplicationsColumnId.Id,
  CompanyApplicationsColumnId.Company,
  CompanyApplicationsColumnId.ContactForCommunication,
  CompanyApplicationsColumnId.WebSite,
  CompanyApplicationsColumnId.TrafficSource,
  CompanyApplicationsColumnId.Status,
  CompanyApplicationsColumnId.CreatedAt,
  CompanyApplicationsColumnId.UpdatedAt,
];
