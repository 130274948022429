import { DropdownOption } from 'ui/DropdownOptionsList/types';

export enum CreateMediaElementOptionsIds {
  ReferralLink = 'createReferralLink',
  PromoCode = 'createPromoCode',
}

export const createMediaElementOptions: Array<DropdownOption> = [
  {
    id: CreateMediaElementOptionsIds.ReferralLink,
    label: 'Создать реферальную ссылку',
  },
  { id: CreateMediaElementOptionsIds.PromoCode, label: 'Создать промокод' },
];
