import { FC, useContext } from 'react';
import SearchInput from 'ui/SearchInput';
import { PageTypeStorage } from 'src/utils/storage';
import {
  PLAYERS_SEARCH_INPUT,
  PLAYERS_SEARCH_PLACEHOLDER,
  SEARCH_INPUT_HEIGHT,
} from 'src/const';
import Block from 'ui/Block';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import { BlockContext, withBlockProvider } from 'ui/Block/store';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useGetPlayers } from './hooks';
import {
  SearchContextProvider,
  useSearchContext,
} from '../../contexts/SearchContext';
import { PlayersListTable } from './components/PlayersListTable';
import {
  getCountByWindowHeight,
  Heights,
} from '../../utils/getCountByWindowHeight';

const cellHeight = Heights.defaultCellHeight;

const Component: FC = withBlockProvider(() => {
  const { state } = useContext(BlockContext);
  const { searchValue } = useSearchContext();

  const { playersList, loadPlayers, handleRefetchClick } = useGetPlayers();

  const loadPlayersVariables = {
    variables: {
      search: searchValue,
      first: getCountByWindowHeight(cellHeight, SEARCH_INPUT_HEIGHT),
    },
  };

  const handleSearchSubmit = () => {
    loadPlayers(loadPlayersVariables);
  };

  return (
    <>
      <SearchInput
        name={PLAYERS_SEARCH_INPUT}
        placeholder={PLAYERS_SEARCH_PLACEHOLDER}
        onSubmit={handleSearchSubmit}
      />
      <Block
        title="Игроки"
        id="playersList"
        headerContent={
          <ActionButton
            actionType={ButtonType.Update}
            hidden={!state.isOpened}
            disabled={state.isLoading}
            onClick={handleRefetchClick}
          />
        }
        shrinkLoaderWrapper={false}
        emptyText="Игроки не найдены"
        isEmpty={isListEmpty(playersList)}
        shouldReturnToPrevPage={false}
        isPermanentOpened
      >
        {hasList(playersList) && <PlayersListTable playersList={playersList} />}
      </Block>
    </>
  );
});

export const PlayersList = () => (
  <SearchContextProvider pageType={PageTypeStorage.PlayerPage}>
    <Component />
  </SearchContextProvider>
);
