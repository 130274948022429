import { FC } from 'react';
import { useParams } from 'react-router';
import { CompanyReferralLinkMediaItemTable } from '../../components/CompanyMediaItemsBlock/components/CompanyReferralLinkMediaItemTable';
import { CompanyPageContainer } from '../../components/CompanyPageContainer';
import { useGetCompanyReferralLinksColumns } from '../../components/CompanyMediaItemsBlock/components/CompanyReferralLinkMediaItemTable/hooks/useGetCompanyReferralLinksColumns';

interface CompanyMediaItemsProps {
  companyId: string;
}

export const Component: FC<CompanyMediaItemsProps> = ({ companyId }) => {
  const { columns } = useGetCompanyReferralLinksColumns(true);

  return (
    <CompanyReferralLinkMediaItemTable
      companyId={companyId}
      columns={columns}
      isFullScreenTable
      shouldReturnToPrevPage
    />
  );
};

export const CompanyMediaItems = () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return (
    <CompanyPageContainer companyId={id}>
      <Component companyId={id} />
    </CompanyPageContainer>
  );
};
