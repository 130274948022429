import { FC } from 'react';
import { useGenerateReferralCode } from 'src/utils/hooks/useGenerateReferralCode';
import { Form, Formik, FormikHelpers } from 'formik';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import FormRow from 'ui/FormRow';
import Input from 'ui/Input';
import { MediaCampaignDropdown } from 'commonComponents/MediaCampaignDropdown';
import { Dropdown } from 'ui/Dropdown';
import { RewardTariffType } from 'commonTypes';
import { CpaTariffDropdown } from 'commonComponents/CpaTariffDropdown';
import { RevShareTariffDropdown } from 'commonComponents/RevShareTariffDropdown';
import { Tooltip } from 'ui/Tooltip';
import Button, { ButtonSize, ButtonTheme } from 'ui/Button';
import { rewardTypeOptions } from 'src/helpers';
import { FieldName, mapFieldNameToTitle } from './const';
import { CreateCompanyReferralLinkValues, validate } from './validation';
import { useCreateCompanyReferralLink } from './hooks/useCreateCompanyReferralLink';

interface CreateReferralLinkFormProps {
  companyInfo: {
    id: string;
    name: string;
  };
  isFullScreenTable: boolean;
}

export const CreateReferralLinkForm: FC<CreateReferralLinkFormProps> = ({
  companyInfo,
  isFullScreenTable,
}) => {
  const { referralCodeLoading, referralCode } = useGenerateReferralCode();
  const { id: companyId, name: companyName } = companyInfo;

  const initialValues: CreateCompanyReferralLinkValues = {
    [FieldName.Company]: `#${companyId} ${companyName}` || '',
    [FieldName.MediaCampaignId]: '',
    [FieldName.MediaItemName]: '',
    [FieldName.Code]: referralCode || null,
    [FieldName.PaymentModel]: null,
    [FieldName.CPATariffId]: '',
    [FieldName.FallbackTariffId]: '',
    [FieldName.RevShareTariffId]: '',
  };

  const {
    onCreateReferralLinkSubmitHandler,
    createCompanyReferralLinkLoading,
  } = useCreateCompanyReferralLink(isFullScreenTable);

  const onPaymentModelChangeHandler = (
    setFieldValue: FormikHelpers<typeof initialValues>['setFieldValue'],
    setTouched: FormikHelpers<typeof initialValues>['setTouched']
  ) => {
    setFieldValue(FieldName.CPATariffId, '');
    setFieldValue(FieldName.FallbackTariffId, '');
    setFieldValue(FieldName.RevShareTariffId, '');
    setTouched(
      {
        [FieldName.CPATariffId]: false,
        [FieldName.FallbackTariffId]: false,
        [FieldName.RevShareTariffId]: false,
      },
      false
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        onCreateReferralLinkSubmitHandler(companyId, values);
      }}
      validate={validate}
      validateOnChange={false}
      enableReinitialize
    >
      {({ isValid, dirty, values, setFieldValue, setTouched }) => (
        <Form>
          <FormRowsWrapper>
            <FormRow isWide>
              <Input
                name={FieldName.Company}
                label={mapFieldNameToTitle[FieldName.Company]}
                disabled
              />
            </FormRow>
            <FormRow isWide>
              <Input
                name={FieldName.MediaItemName}
                label={mapFieldNameToTitle[FieldName.MediaItemName]}
                maxLength={50}
              />
            </FormRow>
            <FormRow isWide>
              <MediaCampaignDropdown
                name={FieldName.MediaCampaignId}
                label={mapFieldNameToTitle[FieldName.MediaCampaignId]}
              />
            </FormRow>
            <FormRow isWide>
              <Input
                name={FieldName.Code}
                label={mapFieldNameToTitle[FieldName.Code]}
                maxLength={36}
              />
            </FormRow>
            <FormRow isWide>
              <Dropdown
                onChange={() => {
                  onPaymentModelChangeHandler(setFieldValue, setTouched);
                }}
                name={FieldName.PaymentModel}
                label={mapFieldNameToTitle[FieldName.PaymentModel]}
                options={rewardTypeOptions}
              />
            </FormRow>

            {values[FieldName.PaymentModel] === RewardTariffType.Cpa && (
              <>
                <FormRow isWide>
                  <CpaTariffDropdown
                    name={FieldName.CPATariffId}
                    label={mapFieldNameToTitle[FieldName.CPATariffId]}
                  />
                </FormRow>
                <FormRow isWide>
                  <RevShareTariffDropdown
                    name={FieldName.FallbackTariffId}
                    label={mapFieldNameToTitle[FieldName.FallbackTariffId]}
                  />
                </FormRow>
              </>
            )}

            {values[FieldName.PaymentModel] === RewardTariffType.RevShare && (
              <RevShareTariffDropdown
                name={FieldName.RevShareTariffId}
                label={mapFieldNameToTitle[FieldName.RevShareTariffId]}
              />
            )}
          </FormRowsWrapper>
          <Tooltip
            tooltipContent={
              !isValid || !dirty ? 'Заполните все поля' : undefined
            }
            placement="right"
          >
            <Button
              size={ButtonSize.Large}
              theme={ButtonTheme.Process}
              type="submit"
              isLoading={
                createCompanyReferralLinkLoading || referralCodeLoading
              }
              disabled={!isValid || !dirty}
            >
              Создать
            </Button>
          </Tooltip>
        </Form>
      )}
    </Formik>
  );
};
