import { createColumnHelper } from '@tanstack/react-table';
import { ColumnsSource } from 'ui/Table/types';
import { Route } from 'src/router/routes.const';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { addPercentToValue } from 'src/utils/addPercentToValue';
import { formatNumberWithRuLocale } from 'src/utils/formatNumberWithRuLocale';
import { RevShareGeneralReportData } from '../../../queries/generated/RevShareGeneralReportData';
import {
  columnsIds,
  mapRevShareGeneralReportColumnIdToName,
  RevShareGeneralReportColumnId,
} from '../const';
import { STATISTIC_TOTAL_ROW_TITLE } from '../../../../../const';
import { AddTwoLinedSubTitle } from '../../../../AddTwoLinedSubTitle';
import { getAffiliateInfo } from '../../../../../helpers';
import { MultilineCellWithLinkWithPeriodQueryParams } from '../../../../MultilineCellWithLinkWithPeriodQueryParams';

const columnHelper = createColumnHelper<RevShareGeneralReportData>();

const columnsSource: ColumnsSource<RevShareGeneralReportData> = [
  columnHelper.accessor(RevShareGeneralReportColumnId.Affiliate, {
    id: RevShareGeneralReportColumnId.Affiliate,
    header:
      mapRevShareGeneralReportColumnIdToName[
        RevShareGeneralReportColumnId.Affiliate
      ],
    cell: ({ row }) => {
      if (!row.original.affiliate) {
        return STATISTIC_TOTAL_ROW_TITLE;
      }

      const { id, name, email, phone, typeName } = getAffiliateInfo<
        RevShareGeneralReportData['affiliate']
      >(row.original.affiliate);

      return (
        <MultilineCellWithLinkWithPeriodQueryParams
          id={id}
          title={name}
          subTitle={
            <AddTwoLinedSubTitle firstValue={email} secondValue={phone} />
          }
          route={
            typeName === 'Partner'
              ? Route.STATISTIC_REV_SHARE_PARTNER
              : Route.STATISTIC_REV_SHARE_COMPANY_PARTNERS
          }
        />
      );
    },
    enablePinning: true,
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(RevShareGeneralReportColumnId.Hits, {
    id: RevShareGeneralReportColumnId.Hits,
    header:
      mapRevShareGeneralReportColumnIdToName[
        RevShareGeneralReportColumnId.Hits
      ],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.hits),
  }),
  columnHelper.accessor(RevShareGeneralReportColumnId.Hosts, {
    id: RevShareGeneralReportColumnId.Hosts,
    header:
      mapRevShareGeneralReportColumnIdToName[
        RevShareGeneralReportColumnId.Hosts
      ],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.hosts),
  }),
  columnHelper.accessor(RevShareGeneralReportColumnId.Registrations, {
    id: RevShareGeneralReportColumnId.Registrations,
    header:
      mapRevShareGeneralReportColumnIdToName[
        RevShareGeneralReportColumnId.Registrations
      ],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.registrations),
  }),
  columnHelper.accessor(RevShareGeneralReportColumnId.FirstDeposits, {
    id: RevShareGeneralReportColumnId.FirstDeposits,
    header:
      mapRevShareGeneralReportColumnIdToName[
        RevShareGeneralReportColumnId.FirstDeposits
      ],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.firstDeposits),
  }),
  columnHelper.accessor(RevShareGeneralReportColumnId.HostToRegConversion, {
    id: RevShareGeneralReportColumnId.HostToRegConversion,
    header:
      mapRevShareGeneralReportColumnIdToName[
        RevShareGeneralReportColumnId.HostToRegConversion
      ],
    cell: ({ row }) =>
      addPercentToValue(row.original.marketingStatistic.hostToRegConversion),
  }),
  columnHelper.accessor(RevShareGeneralReportColumnId.RegToDepConversion, {
    id: RevShareGeneralReportColumnId.RegToDepConversion,
    header:
      mapRevShareGeneralReportColumnIdToName[
        RevShareGeneralReportColumnId.RegToDepConversion
      ],
    cell: ({ row }) =>
      addPercentToValue(row.original.marketingStatistic.regToDepConversion),
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(RevShareGeneralReportColumnId.AllDeposit, {
    id: RevShareGeneralReportColumnId.AllDeposit,
    header:
      mapRevShareGeneralReportColumnIdToName[
        RevShareGeneralReportColumnId.AllDeposit
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.allDeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareGeneralReportColumnId.AverageDeposit, {
    id: RevShareGeneralReportColumnId.AverageDeposit,
    header:
      mapRevShareGeneralReportColumnIdToName[
        RevShareGeneralReportColumnId.AverageDeposit
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.averageDeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareGeneralReportColumnId.AllRedeposit, {
    id: RevShareGeneralReportColumnId.AllRedeposit,
    header:
      mapRevShareGeneralReportColumnIdToName[
        RevShareGeneralReportColumnId.AllRedeposit
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.allRedeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareGeneralReportColumnId.Redeposits, {
    id: RevShareGeneralReportColumnId.Redeposits,
    header:
      mapRevShareGeneralReportColumnIdToName[
        RevShareGeneralReportColumnId.Redeposits
      ],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.redeposits),
  }),
  columnHelper.accessor(RevShareGeneralReportColumnId.NewDeposit, {
    id: RevShareGeneralReportColumnId.NewDeposit,
    header:
      mapRevShareGeneralReportColumnIdToName[
        RevShareGeneralReportColumnId.NewDeposit
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerRegisteredStatistic.newDeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareGeneralReportColumnId.IncomingMoneyTransfer, {
    id: RevShareGeneralReportColumnId.IncomingMoneyTransfer,
    header:
      mapRevShareGeneralReportColumnIdToName[
        RevShareGeneralReportColumnId.IncomingMoneyTransfer
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerStatistic.incomingMoneyTransfer;

      return formatSumWithCurrency(amount, currency);
    },
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(RevShareGeneralReportColumnId.Withdrawal, {
    id: RevShareGeneralReportColumnId.Withdrawal,
    header:
      mapRevShareGeneralReportColumnIdToName[
        RevShareGeneralReportColumnId.Withdrawal
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.withdrawal;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareGeneralReportColumnId.NewWithdrawal, {
    id: RevShareGeneralReportColumnId.NewWithdrawal,
    header:
      mapRevShareGeneralReportColumnIdToName[
        RevShareGeneralReportColumnId.NewWithdrawal
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerRegisteredStatistic.newWithdrawal;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareGeneralReportColumnId.NewProfit, {
    id: RevShareGeneralReportColumnId.NewProfit,
    header:
      mapRevShareGeneralReportColumnIdToName[
        RevShareGeneralReportColumnId.NewProfit
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerRegisteredStatistic.newProfit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareGeneralReportColumnId.DepositFee, {
    id: RevShareGeneralReportColumnId.DepositFee,
    header:
      mapRevShareGeneralReportColumnIdToName[
        RevShareGeneralReportColumnId.DepositFee
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.depositFee;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareGeneralReportColumnId.WithdrawalFee, {
    id: RevShareGeneralReportColumnId.WithdrawalFee,
    header:
      mapRevShareGeneralReportColumnIdToName[
        RevShareGeneralReportColumnId.WithdrawalFee
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.withdrawalFee;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareGeneralReportColumnId.AdminFee, {
    id: RevShareGeneralReportColumnId.AdminFee,
    header:
      mapRevShareGeneralReportColumnIdToName[
        RevShareGeneralReportColumnId.AdminFee
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.adminFee;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareGeneralReportColumnId.Bonus, {
    id: RevShareGeneralReportColumnId.Bonus,
    header:
      mapRevShareGeneralReportColumnIdToName[
        RevShareGeneralReportColumnId.Bonus
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.bonus;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareGeneralReportColumnId.TournamentPrize, {
    id: RevShareGeneralReportColumnId.TournamentPrize,
    header:
      mapRevShareGeneralReportColumnIdToName[
        RevShareGeneralReportColumnId.TournamentPrize
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.tournamentPrize;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareGeneralReportColumnId.Jackpot, {
    id: RevShareGeneralReportColumnId.Jackpot,
    header:
      mapRevShareGeneralReportColumnIdToName[
        RevShareGeneralReportColumnId.Jackpot
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.jackpot;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareGeneralReportColumnId.NGR, {
    id: RevShareGeneralReportColumnId.NGR,
    header:
      mapRevShareGeneralReportColumnIdToName[RevShareGeneralReportColumnId.NGR],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerStatistic.financialIndicators.ngr;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareGeneralReportColumnId.Profit, {
    id: RevShareGeneralReportColumnId.Profit,
    header:
      mapRevShareGeneralReportColumnIdToName[
        RevShareGeneralReportColumnId.Profit
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerStatistic.financialIndicators.profit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareGeneralReportColumnId.Upcoming, {
    id: RevShareGeneralReportColumnId.Upcoming,
    header:
      mapRevShareGeneralReportColumnIdToName[
        RevShareGeneralReportColumnId.Upcoming
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.affiliateRewardStatistic.upcoming;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevShareGeneralReportColumnId.RewardPaid, {
    id: RevShareGeneralReportColumnId.RewardPaid,
    header:
      mapRevShareGeneralReportColumnIdToName[
        RevShareGeneralReportColumnId.RewardPaid
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.affiliateRewardStatistic.paid;

      return formatSumWithCurrency(amount, currency);
    },
  }),
];

export const useRevShareGeneralReportColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
