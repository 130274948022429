import NowrapCell from 'ui/Table/components/NowrapCell/NowrapCell';
import MultilineCellWithIcon from 'ui/Table/components/MultilineCellWithIcon';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { contactForCommunicationTypeMap } from 'src/const';
import { createColumnHelper } from '@tanstack/react-table';
import { ColumnsSource } from 'ui/Table/types';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import {
  mapCompanyAffiliateApplicationsColumnIdToName,
  CompanyAffiliateApplicationsColumnId,
  columnsIds,
} from '../const';
import { getCompanyAffiliateApplicationStatus } from '../../../helpers';
import { CompanyAffiliateApplicationsData } from '../../../queries/generated/CompanyAffiliateApplicationsData';

const columnHelper = createColumnHelper<CompanyAffiliateApplicationsData>();

const columnsSource: ColumnsSource<CompanyAffiliateApplicationsData> = [
  columnHelper.accessor(CompanyAffiliateApplicationsColumnId.Id, {
    id: CompanyAffiliateApplicationsColumnId.Id,
    header:
      mapCompanyAffiliateApplicationsColumnIdToName[
        CompanyAffiliateApplicationsColumnId.Id
      ],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnHelper.accessor(CompanyAffiliateApplicationsColumnId.Company, {
    id: CompanyAffiliateApplicationsColumnId.Company,
    header:
      mapCompanyAffiliateApplicationsColumnIdToName[
        CompanyAffiliateApplicationsColumnId.Company
      ],
    cell: ({ row }) => {
      const { email, name } = row.original.company;

      return MultilineCellWithIcon({
        title: name,
        subTitle: email || null,
      });
    },
  }),
  columnHelper.accessor(
    CompanyAffiliateApplicationsColumnId.ContactForCommunication,
    {
      id: CompanyAffiliateApplicationsColumnId.ContactForCommunication,
      header:
        mapCompanyAffiliateApplicationsColumnIdToName[
          CompanyAffiliateApplicationsColumnId.ContactForCommunication
        ],
      cell: ({ row }) => {
        const { value, type } = row.original.company.contactForCommunication;

        return MultilineCellWithIcon({
          title: value,
          subTitle: contactForCommunicationTypeMap[type],
        });
      },
    }
  ),
  columnHelper.accessor(CompanyAffiliateApplicationsColumnId.Status, {
    id: CompanyAffiliateApplicationsColumnId.Status,
    header:
      mapCompanyAffiliateApplicationsColumnIdToName[
        CompanyAffiliateApplicationsColumnId.Status
      ],
    cell: ({ row }) => {
      const { status, admin, statistic } = row.original;
      const [title, iconConfig] = getCompanyAffiliateApplicationStatus(
        status,
        admin,
        statistic
      );

      return MultilineCellWithIcon({ title, iconConfig });
    },
  }),
  columnHelper.accessor(CompanyAffiliateApplicationsColumnId.CreatedAt, {
    id: CompanyAffiliateApplicationsColumnId.CreatedAt,
    header:
      mapCompanyAffiliateApplicationsColumnIdToName[
        CompanyAffiliateApplicationsColumnId.CreatedAt
      ],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.DefaultDateOld)}
      </NowrapCell>
    ),
  }),
  columnHelper.accessor(CompanyAffiliateApplicationsColumnId.UpdatedAt, {
    id: CompanyAffiliateApplicationsColumnId.UpdatedAt,
    header:
      mapCompanyAffiliateApplicationsColumnIdToName[
        CompanyAffiliateApplicationsColumnId.UpdatedAt
      ],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.updatedAt, DateFormat.DefaultDateOld)}
      </NowrapCell>
    ),
  }),
];

export const useGetCompanyAffiliateApplicationsColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
