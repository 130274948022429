import { nbsp } from 'src/const';

export enum CpaCompanyReportColumnId {
  ReferralCode = 'mediaItem',
  Hits = 'marketingStatistic.hits',
  Hosts = 'marketingStatistic.hosts',
  Registrations = 'marketingStatistic.registrations',
  FirstDeposits = 'marketingStatistic.firstDeposits',
  HostToRegConversion = 'marketingStatistic.hostToRegConversion',
  RegToDepConversion = 'marketingStatistic.regToDepConversion',

  AllDeposit = 'playerStatistic.allDeposit',
  AverageDeposit = 'playerStatistic.averageDeposit',
  AllRedeposit = 'playerStatistic.allRedeposit',
  Redeposits = 'marketingStatistic.redeposits',
  NewDeposit = 'playerRegisteredStatistic.newDeposit',

  Withdrawal = 'playerStatistic.withdrawal',
  NewWithdrawal = 'playerRegisteredStatistic.newWithdrawal',
  NewProfit = 'playerRegisteredStatistic.newProfit',
  Hold = 'playerStatusStatistic.hold',
  Qualified = 'playerStatusStatistic.qualified',
  NotQualified = 'playerStatusStatistic.canceled',
  Approved = 'playerStatusStatistic.approved',
  Rejected = 'playerStatusStatistic.rejected',
  Paid = 'playerStatusStatistic.paid',
  Unpaid = 'playerStatusStatistic.unpaid',
  RevShare = 'playerStatusStatistic.revShare',
  NGR = 'playerStatistic.financialIndicators.ngr',
  Profit = 'playerStatistic.financialIndicators.profit',
  Upcoming = 'affiliateRewardStatistic.upcoming',
  RewardPaid = 'affiliateRewardStatistic.paid',
}

export const mapCpaCompanyReportColumnIdToName: Record<
  CpaCompanyReportColumnId,
  string
> = {
  [CpaCompanyReportColumnId.ReferralCode]: 'Реферальный код',
  [CpaCompanyReportColumnId.Hits]: 'Хиты',
  [CpaCompanyReportColumnId.Hosts]: 'Хосты',
  [CpaCompanyReportColumnId.Registrations]: 'Регистрации',
  [CpaCompanyReportColumnId.FirstDeposits]: 'Первые депозиты',
  [CpaCompanyReportColumnId.HostToRegConversion]: 'Host2reg',
  [CpaCompanyReportColumnId.RegToDepConversion]: 'Reg2dep',

  [CpaCompanyReportColumnId.AllDeposit]: 'Все депозиты',
  [CpaCompanyReportColumnId.AverageDeposit]: 'Средний депозит',
  [CpaCompanyReportColumnId.AllRedeposit]: 'Все редепозиты',
  [CpaCompanyReportColumnId.Redeposits]: 'Количество редепозитов',
  [CpaCompanyReportColumnId.NewDeposit]: 'Новые депозиты',

  [CpaCompanyReportColumnId.Withdrawal]: 'Выводы',
  [CpaCompanyReportColumnId.NewWithdrawal]: 'Новые выводы',
  [CpaCompanyReportColumnId.NewProfit]: 'Новый доход',
  [CpaCompanyReportColumnId.Hold]: `В${nbsp}ожидании`,
  [CpaCompanyReportColumnId.Qualified]: 'Квалифицированные',
  [CpaCompanyReportColumnId.NotQualified]: 'Неквалифицированные',
  [CpaCompanyReportColumnId.Approved]: 'Подтвержденные',
  [CpaCompanyReportColumnId.Rejected]: 'Отклонённые',
  [CpaCompanyReportColumnId.Paid]: 'Оплаченные',
  [CpaCompanyReportColumnId.Unpaid]: `Без${nbsp}оплаты`,
  [CpaCompanyReportColumnId.RevShare]: `В${nbsp}RevShare`,
  [CpaCompanyReportColumnId.NGR]: 'NGR',
  [CpaCompanyReportColumnId.Profit]: 'Доход',
  [CpaCompanyReportColumnId.Upcoming]: 'Вознаграждение',
  [CpaCompanyReportColumnId.RewardPaid]: 'Выплачено',
};

export const columnsIds = [
  CpaCompanyReportColumnId.ReferralCode,
  CpaCompanyReportColumnId.Hits,
  CpaCompanyReportColumnId.Hosts,
  CpaCompanyReportColumnId.Registrations,
  CpaCompanyReportColumnId.FirstDeposits,
  CpaCompanyReportColumnId.HostToRegConversion,
  CpaCompanyReportColumnId.RegToDepConversion,

  CpaCompanyReportColumnId.AllDeposit,
  CpaCompanyReportColumnId.AverageDeposit,
  CpaCompanyReportColumnId.AllRedeposit,
  CpaCompanyReportColumnId.Redeposits,
  CpaCompanyReportColumnId.NewDeposit,

  CpaCompanyReportColumnId.Withdrawal,
  CpaCompanyReportColumnId.NewWithdrawal,
  CpaCompanyReportColumnId.NewProfit,
  CpaCompanyReportColumnId.Hold,
  CpaCompanyReportColumnId.Qualified,
  CpaCompanyReportColumnId.NotQualified,
  CpaCompanyReportColumnId.Approved,
  CpaCompanyReportColumnId.Rejected,
  CpaCompanyReportColumnId.Paid,
  CpaCompanyReportColumnId.Unpaid,
  CpaCompanyReportColumnId.RevShare,
  CpaCompanyReportColumnId.NGR,
  CpaCompanyReportColumnId.Profit,
  CpaCompanyReportColumnId.Upcoming,
  CpaCompanyReportColumnId.RewardPaid,
];
