import { nbsp } from 'src/const';

export enum CpaCompanyPartnersReportColumnId {
  MediaOwner = 'companyPartner',
  Hits = 'marketingStatistic.hits',
  Hosts = 'marketingStatistic.hosts',
  Registrations = 'marketingStatistic.registrations',
  FirstDeposits = 'marketingStatistic.firstDeposits',
  HostToRegConversion = 'marketingStatistic.hostToRegConversion',
  RegToDepConversion = 'marketingStatistic.regToDepConversion',

  AllDeposit = 'playerStatistic.allDeposit',
  AverageDeposit = 'playerStatistic.averageDeposit',
  AllRedeposit = 'playerStatistic.allRedeposit',
  Redeposits = 'marketingStatistic.redeposits',
  NewDeposit = 'playerRegisteredStatistic.newDeposit',

  Withdrawal = 'playerStatistic.withdrawal',
  NewWithdrawal = 'playerRegisteredStatistic.newWithdrawal',
  NewProfit = 'playerRegisteredStatistic.newProfit',
  Hold = 'playerStatusStatistic.hold',
  Qualified = 'playerStatusStatistic.qualified',
  NotQualified = 'playerStatusStatistic.canceled',
  Approved = 'playerStatusStatistic.approved',
  Rejected = 'playerStatusStatistic.rejected',
  Paid = 'playerStatusStatistic.paid',
  Unpaid = 'playerStatusStatistic.unpaid',
  RevShare = 'playerStatusStatistic.revShare',
  NGR = 'playerStatistic.financialIndicators.ngr',
  Profit = 'playerStatistic.financialIndicators.profit',
  Upcoming = 'affiliateRewardStatistic.upcoming',
  RewardPaid = 'affiliateRewardStatistic.paid',
}

export const mapCpaCompanyPartnersReportColumnIdToName: Record<
  CpaCompanyPartnersReportColumnId,
  string
> = {
  [CpaCompanyPartnersReportColumnId.MediaOwner]: 'Владелец медиа',
  [CpaCompanyPartnersReportColumnId.Hits]: 'Хиты',
  [CpaCompanyPartnersReportColumnId.Hosts]: 'Хосты',
  [CpaCompanyPartnersReportColumnId.Registrations]: 'Регистрации',
  [CpaCompanyPartnersReportColumnId.FirstDeposits]: 'Первые депозиты',
  [CpaCompanyPartnersReportColumnId.HostToRegConversion]: 'Host2reg',
  [CpaCompanyPartnersReportColumnId.RegToDepConversion]: 'Reg2dep',

  [CpaCompanyPartnersReportColumnId.AllDeposit]: 'Все депозиты',
  [CpaCompanyPartnersReportColumnId.AverageDeposit]: 'Средний депозит',
  [CpaCompanyPartnersReportColumnId.AllRedeposit]: 'Все редепозиты',
  [CpaCompanyPartnersReportColumnId.Redeposits]: 'Количество редепозитов',
  [CpaCompanyPartnersReportColumnId.NewDeposit]: 'Новые депозиты',

  [CpaCompanyPartnersReportColumnId.Withdrawal]: 'Выводы',
  [CpaCompanyPartnersReportColumnId.NewWithdrawal]: 'Новые выводы',
  [CpaCompanyPartnersReportColumnId.NewProfit]: 'Новый доход',
  [CpaCompanyPartnersReportColumnId.Hold]: `В${nbsp}ожидании`,
  [CpaCompanyPartnersReportColumnId.Qualified]: 'Квалифицированные',
  [CpaCompanyPartnersReportColumnId.NotQualified]: 'Неквалифицированные',
  [CpaCompanyPartnersReportColumnId.Approved]: 'Подтвержденные',
  [CpaCompanyPartnersReportColumnId.Rejected]: 'Отклонённые',
  [CpaCompanyPartnersReportColumnId.Paid]: 'Оплаченные',
  [CpaCompanyPartnersReportColumnId.Unpaid]: `Без${nbsp}оплаты`,
  [CpaCompanyPartnersReportColumnId.RevShare]: `В${nbsp}RevShare`,
  [CpaCompanyPartnersReportColumnId.NGR]: 'NGR',
  [CpaCompanyPartnersReportColumnId.Profit]: 'Доход',
  [CpaCompanyPartnersReportColumnId.Upcoming]: 'Вознаграждение',
  [CpaCompanyPartnersReportColumnId.RewardPaid]: 'Выплачено',
};

export const columnsIds = [
  CpaCompanyPartnersReportColumnId.MediaOwner,
  CpaCompanyPartnersReportColumnId.Hits,
  CpaCompanyPartnersReportColumnId.Hosts,
  CpaCompanyPartnersReportColumnId.Registrations,
  CpaCompanyPartnersReportColumnId.FirstDeposits,
  CpaCompanyPartnersReportColumnId.HostToRegConversion,
  CpaCompanyPartnersReportColumnId.RegToDepConversion,

  CpaCompanyPartnersReportColumnId.AllDeposit,
  CpaCompanyPartnersReportColumnId.AverageDeposit,
  CpaCompanyPartnersReportColumnId.AllRedeposit,
  CpaCompanyPartnersReportColumnId.Redeposits,
  CpaCompanyPartnersReportColumnId.NewDeposit,

  CpaCompanyPartnersReportColumnId.Withdrawal,
  CpaCompanyPartnersReportColumnId.NewWithdrawal,
  CpaCompanyPartnersReportColumnId.NewProfit,
  CpaCompanyPartnersReportColumnId.Hold,
  CpaCompanyPartnersReportColumnId.Qualified,
  CpaCompanyPartnersReportColumnId.NotQualified,
  CpaCompanyPartnersReportColumnId.Approved,
  CpaCompanyPartnersReportColumnId.Rejected,
  CpaCompanyPartnersReportColumnId.Paid,
  CpaCompanyPartnersReportColumnId.Unpaid,
  CpaCompanyPartnersReportColumnId.RevShare,
  CpaCompanyPartnersReportColumnId.NGR,
  CpaCompanyPartnersReportColumnId.Profit,
  CpaCompanyPartnersReportColumnId.Upcoming,
  CpaCompanyPartnersReportColumnId.RewardPaid,
];
