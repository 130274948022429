export enum MediaItemsPromoCodeColumnId {
  Id = 'id',
  Token = 'promoCode.token',
  Activations = 'promoCode.activation',
  MediaCampaign = 'mediaCampaign',
  UsageType = 'usageType',
  ReferralLink = 'referralLink',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export const mapMediaItemsPromoCodeIdToName: Record<
  MediaItemsPromoCodeColumnId,
  string
> = {
  [MediaItemsPromoCodeColumnId.Id]: 'ID',
  [MediaItemsPromoCodeColumnId.Token]: 'Токен',
  [MediaItemsPromoCodeColumnId.Activations]: 'Активации',
  [MediaItemsPromoCodeColumnId.MediaCampaign]: 'Медиа кампания',
  [MediaItemsPromoCodeColumnId.UsageType]: 'Использование',
  [MediaItemsPromoCodeColumnId.ReferralLink]: 'Реферальная ссылка',
  [MediaItemsPromoCodeColumnId.CreatedAt]: 'Создан',
  [MediaItemsPromoCodeColumnId.UpdatedAt]: 'Обновлен',
};

export const columnsIds = [
  MediaItemsPromoCodeColumnId.Id,
  MediaItemsPromoCodeColumnId.Token,
  MediaItemsPromoCodeColumnId.Activations,
  MediaItemsPromoCodeColumnId.MediaCampaign,
  MediaItemsPromoCodeColumnId.UsageType,
  MediaItemsPromoCodeColumnId.ReferralLink,
  MediaItemsPromoCodeColumnId.CreatedAt,
  MediaItemsPromoCodeColumnId.UpdatedAt,
];
