import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { Currency } from 'commonTypes';
import { MediaItemReportOrderBy, OrderDirection } from 'generatedGraphql';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useCallback, useMemo } from 'react';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import { useGetCpaReferralLinkReportLazyQuery } from '../queries/generated/GetCpaReferralLinkReport';
import { useGetPeriodOfStatistic } from '../../../hooks/useGetPeriodOfStatistic';

const COUNT_PER_PAGE = 10;
const cellHeight = Heights.defaultCellHeight;

interface CpaReferralLinkReportParams {
  referralLinkId: string;
  isAllTimeCpa: boolean;
}

export const useGetCpaReferralLinkReport = ({
  referralLinkId,
  isAllTimeCpa,
}: CpaReferralLinkReportParams) => {
  const period = useGetPeriodOfStatistic();

  const [
    loadCpaReferralLinkReport,
    { data, loading, error, fetchMore, refetch },
  ] = useGetCpaReferralLinkReportLazyQuery({
    variables: {
      currency: Currency.Usd,
      first: getCountByWindowHeight(cellHeight, 0),
      id: referralLinkId,
      ordering: {
        orderBy: MediaItemReportOrderBy.AllDeposit,
        direction: OrderDirection.Desc,
      },
      period,
      showAllTimeCpa: isAllTimeCpa,
    },
  });

  useBlockComponentState({
    loadData: loadCpaReferralLinkReport,
    loading,
    error,
  });

  const cpaReport = data?.mediaItem.cpaReport;
  const edges = cpaReport?.edges;
  const total = cpaReport?.total;
  const hasTotalRow = !!total;
  const endCursor = cpaReport?.pageInfo.endCursor;
  const hasNextPage = cpaReport?.pageInfo.hasNextPage;

  const cpaReferralLinkReportList = useMemo(() => {
    const report = edges?.map(({ node }) => node);

    if (hasTotalRow) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      report?.unshift(total);
    }

    return report;
  }, [edges, hasTotalRow, total]);

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: COUNT_PER_PAGE,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false }
      ),
    [endCursor, fetchMore]
  );

  const mediaItemInfo = data && {
    id: data.mediaItem.id,
    name: data.mediaItem.name,
    owner: data.mediaItem.owner,
  };

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage,
    isFullScreenTable: true,
  });

  return {
    cpaReferralLinkReportList,
    refetch,
    mediaItemInfo,
    hasTotalRow,
  };
};
