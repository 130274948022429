import { useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { ApolloError } from '@apollo/client';
import { UpdateClientIdentitiesPayload } from 'commonComponents/UpdateClientIdentities/types';
import { useModalManageData, ModalActions, ModalContext } from 'ui/Modal';
import { useGetPartnerAdditionalData } from '../../../queries/generated/GetPartnerAdditionalData';
import { useUpdatePartnerIdentities as useUpdatePartnerIdentitiesMutation } from '../queries/generated/UpdatePartnerIdentities';

interface UseUpdatePartnerIdentitiesParams {
  id: string;
}

interface UseUpdatePartnerIdentitiesResult {
  onUpdatePartnerIdentities: (values: UpdateClientIdentitiesPayload) => void;
  loading: boolean;
  error?: ApolloError;
  email?: string | null;
  phone?: string | null;
}

export const useUpdatePartnerIdentities = ({
  id,
}: UseUpdatePartnerIdentitiesParams): UseUpdatePartnerIdentitiesResult => {
  const { dispatch } = useContext(ModalContext);

  const {
    data,
    loading,
    error: getPartnerError,
  } = useGetPartnerAdditionalData({
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  });

  useModalManageData({
    loading,
    errorConfig: {
      error: getPartnerError,
      toastId: 'get-partner-additional-data',
    },
    hasData: !!data,
  });

  const [
    updatePartnerIdentities,
    { data: updateResult, error, loading: updateLoading },
  ] = useUpdatePartnerIdentitiesMutation();

  const onUpdatePartnerIdentities = async ({
    email,
    phone,
  }: UpdateClientIdentitiesPayload) => {
    const newEmail = email || null;
    const newPhone = phone || null;

    await updatePartnerIdentities({
      variables: { input: { partnerId: id, email: newEmail, phone: newPhone } },
    });
  };

  useEffect(() => {
    if (updateResult) {
      dispatch({
        type: ModalActions.Close,
      });
      toast.success('Данные партнера изменены');
    }
  }, [updateResult, dispatch]);

  return {
    onUpdatePartnerIdentities,
    loading: updateLoading || loading,
    error,
    email: data?.partner.email,
    phone: data?.partner.phone,
  };
};
