import NowrapCell from 'ui/Table/components/NowrapCell/NowrapCell';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import MayBeNullCell from 'ui/Table/components/MayBeNullCell/MayBeNullCell';
import MultilineCellWithIcon from 'ui/Table/components/MultilineCellWithIcon/MultilineCellWithIcon';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { ColumnsSource } from 'ui/Table/types';
import { createColumnHelper } from '@tanstack/react-table';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import {
  blockColumnsIds,
  mapSignInRestrictionHistoryColumnToName,
  pageColumnsIds,
  SignInRestrictionHistoryColumnId,
} from '../const';
import { signInRestrictionProtectedToType } from '../../../helpers';
import { SignInRestrictionHistoryData } from '../../../queries/generated/SignInRestrictionHistoryData';
import { getStatusConfig } from '../helpers';

const columnHelper = createColumnHelper<SignInRestrictionHistoryData>();

const columnsSource: ColumnsSource<SignInRestrictionHistoryData> = [
  columnHelper.accessor(SignInRestrictionHistoryColumnId.Id, {
    id: SignInRestrictionHistoryColumnId.Id,
    header:
      mapSignInRestrictionHistoryColumnToName[
        SignInRestrictionHistoryColumnId.Id
      ],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnHelper.accessor(SignInRestrictionHistoryColumnId.Type, {
    id: SignInRestrictionHistoryColumnId.Type,
    header:
      mapSignInRestrictionHistoryColumnToName[
        SignInRestrictionHistoryColumnId.Type
      ],
    cell: ({ row }) => signInRestrictionProtectedToType(row.original.protected),
  }),
  columnHelper.accessor(SignInRestrictionHistoryColumnId.Reason, {
    id: SignInRestrictionHistoryColumnId.Reason,
    header:
      mapSignInRestrictionHistoryColumnToName[
        SignInRestrictionHistoryColumnId.Reason
      ],
  }),
  columnHelper.accessor(SignInRestrictionHistoryColumnId.Status, {
    id: SignInRestrictionHistoryColumnId.Status,
    header:
      mapSignInRestrictionHistoryColumnToName[
        SignInRestrictionHistoryColumnId.Status
      ],
    cell: ({ row }) => {
      const [title, iconConfig] = getStatusConfig(row.original.status);

      return MultilineCellWithIcon({ title, iconConfig });
    },
  }),
  columnHelper.accessor(SignInRestrictionHistoryColumnId.CreatedAt, {
    id: SignInRestrictionHistoryColumnId.CreatedAt,
    header:
      mapSignInRestrictionHistoryColumnToName[
        SignInRestrictionHistoryColumnId.CreatedAt
      ],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.lockedAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
  columnHelper.accessor(SignInRestrictionHistoryColumnId.ExpiredAt, {
    id: SignInRestrictionHistoryColumnId.ExpiredAt,
    header:
      mapSignInRestrictionHistoryColumnToName[
        SignInRestrictionHistoryColumnId.ExpiredAt
      ],
    cell: ({ row }) => {
      const { expiredAt } = row.original;

      return MayBeNullCell(
        expiredAt && (
          <NowrapCell>{formatDate(expiredAt, DateFormat.FullDate)}</NowrapCell>
        ),
        '⸺'
      );
    },
  }),
];

export const useGetSignInRestrictionHistoryColumns = (
  isFullScreenTable: boolean
) => {
  const columns = useGetTableColumns(
    isFullScreenTable ? pageColumnsIds : blockColumnsIds,
    columnsSource
  );

  return {
    columns,
  };
};
