export enum MediaItemsReferralLinkColumnId {
  Id = 'id',
  Title = 'name',
  AccountType = 'owner',
  AffiliateName = 'owner.name',
  ReferralLink = 'referralLink',
  MediaCampaign = 'mediaCampaign',
  CreatedAt = 'createdAt',
}

export const mapMediaItemsReferralLinkIdToName: Record<
  MediaItemsReferralLinkColumnId,
  string
> = {
  [MediaItemsReferralLinkColumnId.Id]: 'ID',
  [MediaItemsReferralLinkColumnId.Title]: 'Название',
  [MediaItemsReferralLinkColumnId.MediaCampaign]: 'Медиа кампания',
  [MediaItemsReferralLinkColumnId.AccountType]: 'Тип аккаунта',
  [MediaItemsReferralLinkColumnId.AffiliateName]: 'Имя/Название',
  [MediaItemsReferralLinkColumnId.ReferralLink]: 'Реферальная ссылка',
  [MediaItemsReferralLinkColumnId.CreatedAt]: 'Создан',
};

export const columnsIds = [
  MediaItemsReferralLinkColumnId.Id,
  MediaItemsReferralLinkColumnId.Title,
  MediaItemsReferralLinkColumnId.MediaCampaign,
  MediaItemsReferralLinkColumnId.AccountType,
  MediaItemsReferralLinkColumnId.AffiliateName,
  MediaItemsReferralLinkColumnId.ReferralLink,
  MediaItemsReferralLinkColumnId.CreatedAt,
];
