import { useCallback, useMemo } from 'react';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { Currency } from 'commonTypes';
import { OrderDirection, AffiliateReportOrderBy } from 'generatedGraphql';
import { useGetRevSharePartnerReportLazyQuery } from '../queries/generated/GetRevSharePartnerReport';
import { useGetPeriodOfStatistic } from '../../../hooks/useGetPeriodOfStatistic';

const cellHeight = Heights.defaultCellHeight;

export const useGetRevSharePartnerReport = (partnerId: string) => {
  const period = useGetPeriodOfStatistic();

  const [
    loadRevSharePartnerReport,
    { data, loading, error, fetchMore, refetch },
  ] = useGetRevSharePartnerReportLazyQuery({
    variables: {
      first: getCountByWindowHeight(cellHeight, 0),
      currency: Currency.Usd,
      id: partnerId,
      ordering: {
        orderBy: AffiliateReportOrderBy.AllDeposit,
        direction: OrderDirection.Desc,
      },
      period,
    },
  });

  useBlockComponentState({
    loadData: loadRevSharePartnerReport,
    loading,
    error,
  });

  const revShareReport = data?.partner.revShareReport;
  const edges = revShareReport?.edges;
  const total = revShareReport?.total;
  const hasTotalRow = !!total;
  const endCursor = revShareReport?.pageInfo.endCursor;
  const hasNextPage = revShareReport?.pageInfo.hasNextPage;

  const revSharePartnerReport = useMemo(() => {
    const report = edges?.map(({ node }) => node);

    if (hasTotalRow) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      report?.unshift(total);
    }

    return report;
  }, [edges, hasTotalRow, total]);

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: 10,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false }
      ),
    [endCursor, fetchMore]
  );

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage,
    isFullScreenTable: true,
  });

  return {
    refetch,
    revSharePartnerReport,
    hasTotalRow,
    partner: data?.partner,
  };
};
