import { nbsp } from 'src/const';

export enum CpaCompanyAffiliateReportColumnId {
  ReferralCode = 'mediaItem',
  Hits = 'marketingStatistic.hits',
  Hosts = 'marketingStatistic.hosts',
  Registrations = 'marketingStatistic.registrations',
  FirstDeposits = 'marketingStatistic.firstDeposits',
  HostToRegConversion = 'marketingStatistic.hostToRegConversion',
  RegToDepConversion = 'marketingStatistic.regToDepConversion',

  AllDeposit = 'playerStatistic.allDeposit',
  AverageDeposit = 'playerStatistic.averageDeposit',
  AllRedeposit = 'playerStatistic.allRedeposit',
  Redeposits = 'marketingStatistic.redeposits',
  NewDeposit = 'playerRegisteredStatistic.newDeposit',

  Withdrawal = 'playerStatistic.withdrawal',
  NewWithdrawal = 'playerRegisteredStatistic.newWithdrawal',
  NewProfit = 'playerRegisteredStatistic.newProfit',
  Hold = 'playerStatusStatistic.hold',
  Qualified = 'playerStatusStatistic.qualified',
  NotQualified = 'playerStatusStatistic.canceled',
  Approved = 'playerStatusStatistic.approved',
  Rejected = 'playerStatusStatistic.rejected',
  Paid = 'playerStatusStatistic.paid',
  Unpaid = 'playerStatusStatistic.unpaid',
  RevShare = 'playerStatusStatistic.revShare',
  NGR = 'playerStatistic.financialIndicators.ngr',
  Profit = 'playerStatistic.financialIndicators.profit',
  Upcoming = 'affiliateRewardStatistic.upcoming',
  RewardPaid = 'affiliateRewardStatistic.paid',
}

export const mapCpaCompanyAffiliateReportColumnIdToName: Record<
  CpaCompanyAffiliateReportColumnId,
  string
> = {
  [CpaCompanyAffiliateReportColumnId.ReferralCode]: 'Реферальный код',
  [CpaCompanyAffiliateReportColumnId.Hits]: 'Хиты',
  [CpaCompanyAffiliateReportColumnId.Hosts]: 'Хосты',
  [CpaCompanyAffiliateReportColumnId.Registrations]: 'Регистрации',
  [CpaCompanyAffiliateReportColumnId.FirstDeposits]: 'Первые депозиты',
  [CpaCompanyAffiliateReportColumnId.HostToRegConversion]: 'Host2reg',
  [CpaCompanyAffiliateReportColumnId.RegToDepConversion]: 'Reg2dep',

  [CpaCompanyAffiliateReportColumnId.AllDeposit]: 'Все депозиты',
  [CpaCompanyAffiliateReportColumnId.AverageDeposit]: 'Средний депозит',
  [CpaCompanyAffiliateReportColumnId.AllRedeposit]: 'Все редепозиты',
  [CpaCompanyAffiliateReportColumnId.Redeposits]: 'Количество редепозитов',
  [CpaCompanyAffiliateReportColumnId.NewDeposit]: 'Новые депозиты',

  [CpaCompanyAffiliateReportColumnId.Withdrawal]: 'Выводы',
  [CpaCompanyAffiliateReportColumnId.NewWithdrawal]: 'Новые выводы',
  [CpaCompanyAffiliateReportColumnId.NewProfit]: 'Новый доход',
  [CpaCompanyAffiliateReportColumnId.Hold]: `В${nbsp}ожидании`,
  [CpaCompanyAffiliateReportColumnId.Qualified]: 'Квалифицированные',
  [CpaCompanyAffiliateReportColumnId.NotQualified]: 'Неквалифицированные',
  [CpaCompanyAffiliateReportColumnId.Approved]: 'Подтвержденные',
  [CpaCompanyAffiliateReportColumnId.Rejected]: 'Отклонённые',
  [CpaCompanyAffiliateReportColumnId.Paid]: 'Оплаченные',
  [CpaCompanyAffiliateReportColumnId.Unpaid]: `Без${nbsp}оплаты`,
  [CpaCompanyAffiliateReportColumnId.RevShare]: `В${nbsp}RevShare`,
  [CpaCompanyAffiliateReportColumnId.NGR]: 'NGR',
  [CpaCompanyAffiliateReportColumnId.Profit]: 'Доход',
  [CpaCompanyAffiliateReportColumnId.Upcoming]: 'Вознаграждение',
  [CpaCompanyAffiliateReportColumnId.RewardPaid]: 'Выплачено',
};

export const columnsIds = [
  CpaCompanyAffiliateReportColumnId.ReferralCode,
  CpaCompanyAffiliateReportColumnId.Hits,
  CpaCompanyAffiliateReportColumnId.Hosts,
  CpaCompanyAffiliateReportColumnId.Registrations,
  CpaCompanyAffiliateReportColumnId.FirstDeposits,
  CpaCompanyAffiliateReportColumnId.HostToRegConversion,
  CpaCompanyAffiliateReportColumnId.RegToDepConversion,

  CpaCompanyAffiliateReportColumnId.AllDeposit,
  CpaCompanyAffiliateReportColumnId.AverageDeposit,
  CpaCompanyAffiliateReportColumnId.AllRedeposit,
  CpaCompanyAffiliateReportColumnId.Redeposits,
  CpaCompanyAffiliateReportColumnId.NewDeposit,

  CpaCompanyAffiliateReportColumnId.Withdrawal,
  CpaCompanyAffiliateReportColumnId.NewWithdrawal,
  CpaCompanyAffiliateReportColumnId.NewProfit,
  CpaCompanyAffiliateReportColumnId.Hold,
  CpaCompanyAffiliateReportColumnId.Qualified,
  CpaCompanyAffiliateReportColumnId.NotQualified,
  CpaCompanyAffiliateReportColumnId.Approved,
  CpaCompanyAffiliateReportColumnId.Rejected,
  CpaCompanyAffiliateReportColumnId.Paid,
  CpaCompanyAffiliateReportColumnId.Unpaid,
  CpaCompanyAffiliateReportColumnId.RevShare,
  CpaCompanyAffiliateReportColumnId.NGR,
  CpaCompanyAffiliateReportColumnId.Profit,
  CpaCompanyAffiliateReportColumnId.Upcoming,
  CpaCompanyAffiliateReportColumnId.RewardPaid,
];
