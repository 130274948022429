import { FC } from 'react';
import Block from 'ui/Block';
import { withBlockProvider } from 'ui/Block/store';
import { hasList, isListEmpty } from 'ui/Block/BlockTable/utils';
import { useParams } from 'react-router';
import { AffiliateTypeName } from 'commonTypes';
import { RevSharePartnerReportTable } from './components/RevSharePartnerReportTable';
import { useGetRevSharePartnerReport } from './hooks/useGetRevSharePartnerReport';
import {
  REV_SHARE_PARTNER_STATISTIC_BLOCK_TITLE,
  STATISTIC_NO_DATA,
} from '../../const';
import { BlockSubHeaderAffiliateInfo } from '../../components/BlockSubHeaderAffiliateInfo';
import { Route } from '../../../../router/routes.const';
import { useGeneratePathWithSavePeriodQueryParams } from '../../hooks/useGeneratePathWithSavePeriodQueryParams';
import { StatisticBlockHeaderContent } from '../../components/StatisticBlockHeaderContent';

interface RevSharePartnerReportProps {
  partnerId: string;
}
const Component: FC<RevSharePartnerReportProps> = withBlockProvider(
  ({ partnerId }) => {
    const { refetch, revSharePartnerReport, partner, hasTotalRow } =
      useGetRevSharePartnerReport(partnerId);

    const generatePathWithPeriodQueryParams =
      useGeneratePathWithSavePeriodQueryParams();

    const customPathToPrevPage = generatePathWithPeriodQueryParams({
      originalPath: Route.STATISTIC,
    });

    return (
      <Block
        title={REV_SHARE_PARTNER_STATISTIC_BLOCK_TITLE}
        id="revSharePartnerStatistic"
        headerContent={
          <StatisticBlockHeaderContent handleRefetchClick={refetch} />
        }
        subHeader={
          partner && (
            <BlockSubHeaderAffiliateInfo
              name={partner.name}
              email={partner.email}
              affiliateType={AffiliateTypeName.Partner}
            />
          )
        }
        shrinkLoaderWrapper={false}
        emptyText={STATISTIC_NO_DATA}
        isEmpty={isListEmpty(revSharePartnerReport)}
        shouldReturnToPrevPage
        isPermanentOpened
        customPathToPrevPage={customPathToPrevPage}
      >
        {hasList(revSharePartnerReport) && (
          <RevSharePartnerReportTable
            revSharePartnerReport={revSharePartnerReport}
            hasTotalRow={hasTotalRow}
          />
        )}
      </Block>
    );
  }
);

export const RevSharePartnerReport = () => {
  const { id } = useParams();

  if (!id) {
    return null;
  }

  return <Component partnerId={id} />;
};
