import { FC } from 'react';
import classnames from 'classnames';
import { useField } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FormFieldWrapper } from 'ui/FormFieldWrapper';
import { Placement } from '@floating-ui/react';
import styles from './Checkbox.module.scss';

const ERROR_TOOLTIP_OFFSET_DEFAULT = -5;
const ERROR_TOOLTIP_OFFSET_SMALL = -9;

interface Props {
  label: string;
  name: string;
  type?: 'important' | 'default';
  checkboxSize?: CheckboxSize;
  className?: string;
  wrapperClassName?: string;
  onChange?: (value: boolean) => void;
  errorTooltipPlacement?: Placement;
  horizontalOffset?: number;
}

export enum CheckboxSize {
  Default = 'default',
  Small = 'small',
}

export const Checkbox: FC<Props> = ({
  label,
  name,
  type = 'default',
  checkboxSize = CheckboxSize.Default,
  className,
  wrapperClassName,
  onChange,
  errorTooltipPlacement,
  horizontalOffset = CheckboxSize.Default
    ? ERROR_TOOLTIP_OFFSET_DEFAULT
    : ERROR_TOOLTIP_OFFSET_SMALL,
}) => {
  const [field, , setters] = useField(name);
  const isImportantTypeLabel = type === 'important';

  const onChangeHandler = () => {
    setters.setValue(!field.value);
    onChange?.(!field.value);

    setTimeout(() => {
      setters.setTouched(true, true);
    }, 0);
  };

  return (
    <FormFieldWrapper
      wrapperClassName={wrapperClassName}
      name={name}
      errorTooltipPlacement={errorTooltipPlacement}
      horizontalOffset={horizontalOffset}
    >
      <button
        className={classnames(
          styles.checkbox,
          className,
          checkboxSize && styles[checkboxSize]
        )}
        onClick={onChangeHandler}
        type="button"
      >
        <div
          className={classnames(
            styles.checkboxControl,
            field.value && styles.checked,
            checkboxSize && styles[checkboxSize]
          )}
        >
          {field.value && <FontAwesomeIcon icon={faCheck} />}
        </div>
        <span
          className={classnames(
            styles.label,
            isImportantTypeLabel && styles.importantTypeLabel
          )}
        >
          {label}
        </span>
      </button>
    </FormFieldWrapper>
  );
};
