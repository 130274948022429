import { FC } from 'react';
import { withBlockProvider } from 'ui/Block/store';
import { useLocation } from 'react-router-dom';
import { useGetFreespinsColumns } from './hooks/useGetFreespinsColumns';
import FreespinsTable from './components/FreespinsTable';

interface FreespinsBlockProps {
  id: string;
}

const FreespinsBlock: FC<FreespinsBlockProps> = ({ id }) => {
  const location = useLocation();
  const { columns } = useGetFreespinsColumns(false);

  return (
    <FreespinsTable
      playerId={id}
      columns={columns}
      routeToCustomPage={`${location.pathname}/freespins`}
      isFullScreenTable={false}
    />
  );
};

export default withBlockProvider(FreespinsBlock);
