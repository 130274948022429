import { useCallback, useMemo } from 'react';
import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { Currency } from 'commonTypes';
import { OrderDirection, AffiliateReportOrderBy } from 'generatedGraphql';
import { useGetPeriodOfStatistic } from '../../../hooks/useGetPeriodOfStatistic';
import { useGetCpaCompanyAffiliateReportLazyQuery } from '../queries/generated/GetCpaCompanyAffiliateReport';

const cellHeight = Heights.defaultCellHeight;

interface UseGetCpaCompanyAffiliateReportParams {
  companyId: string;
  affiliateId: string;
  isAllTimeCpa: boolean;
}

export const useGetCpaCompanyAffiliateReport = ({
  companyId,
  affiliateId,
  isAllTimeCpa,
}: UseGetCpaCompanyAffiliateReportParams) => {
  const period = useGetPeriodOfStatistic();

  const [
    loadCpaCompanyAffiliateReport,
    { data, loading, error, fetchMore, refetch },
  ] = useGetCpaCompanyAffiliateReportLazyQuery({
    variables: {
      affiliateId,
      companyId,
      currency: Currency.Usd,
      first: getCountByWindowHeight(cellHeight, 0),
      ordering: {
        orderBy: AffiliateReportOrderBy.AllDeposit,
        direction: OrderDirection.Desc,
      },
      period,
      showAllTimeCpa: isAllTimeCpa,
    },
  });

  const cpaReport = data?.company.affiliate.cpaReport;
  const edges = cpaReport?.edges;
  const total = cpaReport?.total;
  const hasTotalRow = !!total;
  const endCursor = cpaReport?.pageInfo.endCursor;
  const hasNextPage = cpaReport?.pageInfo.hasNextPage;
  const affiliate = data?.company.affiliate;

  const cpaCompanyAffiliateReportList = useMemo(() => {
    const report = edges?.map(({ node }) => node);

    if (hasTotalRow) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      report?.unshift(total);
    }

    return report;
  }, [edges, hasTotalRow, total]);

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: 10,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false }
      ),
    [endCursor, fetchMore]
  );

  return {
    data,
    cpaCompanyAffiliateReportList,
    loadCpaCompanyAffiliateReport,
    loading,
    error,
    refetch,
    fetchMoreCallback,
    hasNextPage,
    hasTotalRow,
    affiliate,
  };
};
