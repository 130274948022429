export enum CompanyMediaCampaignsColumnId {
  Id = 'id',
  Title = 'name',
  Url = 'url',
  CreatedAt = 'createdAt',
}

export const mapCompanyMediaCampaignsColumnIdToName: Record<
  CompanyMediaCampaignsColumnId,
  string
> = {
  [CompanyMediaCampaignsColumnId.Id]: 'ID',
  [CompanyMediaCampaignsColumnId.Title]: 'Название',
  [CompanyMediaCampaignsColumnId.Url]: 'URL',
  [CompanyMediaCampaignsColumnId.CreatedAt]: 'Создан',
};

export const columnsIds = [
  CompanyMediaCampaignsColumnId.Id,
  CompanyMediaCampaignsColumnId.Title,
  CompanyMediaCampaignsColumnId.Url,
  CompanyMediaCampaignsColumnId.CreatedAt,
];
