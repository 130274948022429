export enum RevShareRewardTariffsColumnId {
  Id = 'id',
  Title = 'title',
  Percent = 'percent',
  CreatedAt = 'createdAt',
}

export const mapRevShareRewardTariffsColumnIdColumnToName: Record<
  RevShareRewardTariffsColumnId,
  string
> = {
  [RevShareRewardTariffsColumnId.Id]: 'ID',
  [RevShareRewardTariffsColumnId.Title]: 'Название',
  [RevShareRewardTariffsColumnId.Percent]: 'Процент',
  [RevShareRewardTariffsColumnId.CreatedAt]: 'Создан',
};

export const columnsIds = [
  RevShareRewardTariffsColumnId.Id,
  RevShareRewardTariffsColumnId.Title,
  RevShareRewardTariffsColumnId.Percent,
  RevShareRewardTariffsColumnId.CreatedAt,
];
