export enum CpaReferralLinkReportColumnId {
  Player = 'player',
  FirstDeposit = 'firstDeposit',
  Deposit = 'deposit',
  ReferralLink = '__typename',
  ReferralStatus = 'referralStatus',
  Redeposit = 'redeposit',
  Redeposits = 'redeposits',

  Withdrawal = 'withdrawal',
  NGR = 'financialIndicators.ngr',

  Profit = 'financialIndicators.profit',
  Upcoming = 'affiliateRewardStatistic.upcoming',
  Paid = 'affiliateRewardStatistic.paid',
}

export const mapCpaReferralLinkReportColumnIdToName: Record<
  CpaReferralLinkReportColumnId,
  string
> = {
  [CpaReferralLinkReportColumnId.Player]: 'Игрок',
  [CpaReferralLinkReportColumnId.FirstDeposit]: 'Первый депозит',
  [CpaReferralLinkReportColumnId.Deposit]: 'Депозиты',
  [CpaReferralLinkReportColumnId.ReferralLink]: 'CPA тариф',
  [CpaReferralLinkReportColumnId.ReferralStatus]: 'Статус',
  [CpaReferralLinkReportColumnId.Redeposit]: 'Редепозиты',
  [CpaReferralLinkReportColumnId.Redeposits]: 'Количество редепозитов',

  [CpaReferralLinkReportColumnId.Withdrawal]: 'Выводы',
  [CpaReferralLinkReportColumnId.NGR]: 'NGR',

  [CpaReferralLinkReportColumnId.Profit]: 'Доход',
  [CpaReferralLinkReportColumnId.Upcoming]: 'Вознаграждение',
  [CpaReferralLinkReportColumnId.Paid]: 'Выплачено',
};

export const columnsIds: Array<CpaReferralLinkReportColumnId> = [
  CpaReferralLinkReportColumnId.Player,
  CpaReferralLinkReportColumnId.FirstDeposit,
  CpaReferralLinkReportColumnId.Deposit,
  CpaReferralLinkReportColumnId.ReferralLink,
  CpaReferralLinkReportColumnId.ReferralStatus,
  CpaReferralLinkReportColumnId.Redeposit,
  CpaReferralLinkReportColumnId.Redeposits,

  CpaReferralLinkReportColumnId.Withdrawal,
  CpaReferralLinkReportColumnId.NGR,

  CpaReferralLinkReportColumnId.Profit,
  CpaReferralLinkReportColumnId.Upcoming,
  CpaReferralLinkReportColumnId.Paid,
];
