import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { withBlockProvider } from 'ui/Block/store';
import { Route } from 'src/router/routes.const';
import { PartnerReferralLinkMediaItemTable } from './components/PartnerReferralLinkMediaItemTable';
import { useGetPartnerReferralLinkMediaItemColumns } from './hooks/useGetPartnerReferralLinkMediaItemColumns';

interface PartnerMediaItemsBlockProps {
  id: string;
}

export const Component: FC<PartnerMediaItemsBlockProps> = ({ id }) => {
  const location = useLocation();
  const { columns } = useGetPartnerReferralLinkMediaItemColumns(false);

  return (
    <PartnerReferralLinkMediaItemTable
      columns={columns}
      partnerId={id}
      routeToCustomPage={`${location.pathname}${Route.MEDIA_ITEMS}`}
      isFullScreenTable={false}
    />
  );
};

export const PartnerMediaItemsBlock = withBlockProvider(Component);
