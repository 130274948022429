import { nbsp } from 'src/const';

export enum QualifiedReferralsColumnId {
  SuspectState = 'suspectState',
  Id = 'id',
  Player = 'player',
  Affiliate = 'affiliate',
  ReferralCode = 'code',
  CPATariff = 'tariff.title',
  Status = 'status',
  ReviewResult = 'review',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Action = '__typename',
}

export const mapQualifiedReferralsColumnIdToName: Record<
  QualifiedReferralsColumnId,
  string
> = {
  [QualifiedReferralsColumnId.SuspectState]: `${nbsp}`,
  [QualifiedReferralsColumnId.Id]: 'ID',
  [QualifiedReferralsColumnId.Player]: 'Игрок',
  [QualifiedReferralsColumnId.Affiliate]: 'Партнёр/Компания',
  [QualifiedReferralsColumnId.ReferralCode]: 'Реферальный код',
  [QualifiedReferralsColumnId.CPATariff]: 'CPA тариф',
  [QualifiedReferralsColumnId.Status]: 'Статус',
  [QualifiedReferralsColumnId.ReviewResult]: 'Результат проверки',
  [QualifiedReferralsColumnId.CreatedAt]: 'Создан',
  [QualifiedReferralsColumnId.UpdatedAt]: 'Обновлен',
  [QualifiedReferralsColumnId.Action]: 'Действие',
};

export const columnsIds = [
  QualifiedReferralsColumnId.SuspectState,
  QualifiedReferralsColumnId.Id,
  QualifiedReferralsColumnId.Player,
  QualifiedReferralsColumnId.Affiliate,
  QualifiedReferralsColumnId.ReferralCode,
  QualifiedReferralsColumnId.CPATariff,
  QualifiedReferralsColumnId.Status,
  QualifiedReferralsColumnId.ReviewResult,
  QualifiedReferralsColumnId.CreatedAt,
  QualifiedReferralsColumnId.UpdatedAt,
  QualifiedReferralsColumnId.Action,
];
