import { formatSumWithCurrency } from 'src/utils/accounting';
import { ColumnsSource } from 'ui/Table/types';
import { createColumnHelper } from '@tanstack/react-table';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { addPercentToValue } from 'src/utils/addPercentToValue';
import { formatNumberWithRuLocale } from 'src/utils/formatNumberWithRuLocale';
import { Route } from 'src/router/routes.const';
import {
  columnsIds,
  RevSharePartnerReportColumnId,
  mapRevSharePartnerReportColumnIdToName,
} from '../const';
import { RevSharePartnerReportData } from '../../../queries/generated/RevSharePartnerReportData';
import { STATISTIC_TOTAL_ROW_TITLE } from '../../../../../const';
import { MultilineCellWithLinkWithPeriodQueryParams } from '../../../../../components/MultilineCellWithLinkWithPeriodQueryParams';

const columnHelper = createColumnHelper<RevSharePartnerReportData>();

const columnsSource: ColumnsSource<RevSharePartnerReportData> = [
  columnHelper.accessor(RevSharePartnerReportColumnId.ReferralCode, {
    id: RevSharePartnerReportColumnId.ReferralCode,
    header:
      mapRevSharePartnerReportColumnIdToName[
        RevSharePartnerReportColumnId.ReferralCode
      ],
    cell: ({ row }) => {
      if (!row.original.mediaItem) {
        return STATISTIC_TOTAL_ROW_TITLE;
      }

      const { id, name } = row.original.mediaItem;

      return (
        <MultilineCellWithLinkWithPeriodQueryParams
          id={id}
          title={name}
          subTitle={addHashTagToId(id)}
          route={Route.STATISTIC_REV_SHARE_REFERRAL_LINK}
        />
      );
    },
    enablePinning: true,
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(RevSharePartnerReportColumnId.Hits, {
    id: RevSharePartnerReportColumnId.Hits,
    header:
      mapRevSharePartnerReportColumnIdToName[
        RevSharePartnerReportColumnId.Hits
      ],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.hits),
  }),
  columnHelper.accessor(RevSharePartnerReportColumnId.Hosts, {
    id: RevSharePartnerReportColumnId.Hosts,
    header:
      mapRevSharePartnerReportColumnIdToName[
        RevSharePartnerReportColumnId.Hosts
      ],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.hosts),
  }),
  columnHelper.accessor(RevSharePartnerReportColumnId.Registrations, {
    id: RevSharePartnerReportColumnId.Registrations,
    header:
      mapRevSharePartnerReportColumnIdToName[
        RevSharePartnerReportColumnId.Registrations
      ],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.registrations),
  }),
  columnHelper.accessor(RevSharePartnerReportColumnId.FirstDeposits, {
    id: RevSharePartnerReportColumnId.FirstDeposits,
    header:
      mapRevSharePartnerReportColumnIdToName[
        RevSharePartnerReportColumnId.FirstDeposits
      ],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.firstDeposits),
  }),
  columnHelper.accessor(RevSharePartnerReportColumnId.HostToRegConversion, {
    id: RevSharePartnerReportColumnId.HostToRegConversion,
    header:
      mapRevSharePartnerReportColumnIdToName[
        RevSharePartnerReportColumnId.HostToRegConversion
      ],
    cell: ({ row }) =>
      addPercentToValue(row.original.marketingStatistic.hostToRegConversion),
  }),
  columnHelper.accessor(RevSharePartnerReportColumnId.RegToDepConversion, {
    id: RevSharePartnerReportColumnId.RegToDepConversion,
    header:
      mapRevSharePartnerReportColumnIdToName[
        RevSharePartnerReportColumnId.RegToDepConversion
      ],
    cell: ({ row }) =>
      addPercentToValue(row.original.marketingStatistic.regToDepConversion),
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(RevSharePartnerReportColumnId.AllDeposit, {
    id: RevSharePartnerReportColumnId.AllDeposit,
    header:
      mapRevSharePartnerReportColumnIdToName[
        RevSharePartnerReportColumnId.AllDeposit
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.allDeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevSharePartnerReportColumnId.AverageDeposit, {
    id: RevSharePartnerReportColumnId.AverageDeposit,
    header:
      mapRevSharePartnerReportColumnIdToName[
        RevSharePartnerReportColumnId.AverageDeposit
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.averageDeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevSharePartnerReportColumnId.AllRedeposit, {
    id: RevSharePartnerReportColumnId.AllRedeposit,
    header:
      mapRevSharePartnerReportColumnIdToName[
        RevSharePartnerReportColumnId.AllRedeposit
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.allRedeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevSharePartnerReportColumnId.Redeposits, {
    id: RevSharePartnerReportColumnId.Redeposits,
    header:
      mapRevSharePartnerReportColumnIdToName[
        RevSharePartnerReportColumnId.Redeposits
      ],
    cell: ({ row }) =>
      formatNumberWithRuLocale(row.original.marketingStatistic.redeposits),
  }),
  columnHelper.accessor(RevSharePartnerReportColumnId.NewDeposit, {
    id: RevSharePartnerReportColumnId.NewDeposit,
    header:
      mapRevSharePartnerReportColumnIdToName[
        RevSharePartnerReportColumnId.NewDeposit
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerRegisteredStatistic.newDeposit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevSharePartnerReportColumnId.IncomingMoneyTransfer, {
    id: RevSharePartnerReportColumnId.IncomingMoneyTransfer,
    header:
      mapRevSharePartnerReportColumnIdToName[
        RevSharePartnerReportColumnId.IncomingMoneyTransfer
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerStatistic.incomingMoneyTransfer;

      return formatSumWithCurrency(amount, currency);
    },
    meta: {
      hasRightBorder: true,
    },
  }),
  columnHelper.accessor(RevSharePartnerReportColumnId.Withdrawal, {
    id: RevSharePartnerReportColumnId.Withdrawal,
    header:
      mapRevSharePartnerReportColumnIdToName[
        RevSharePartnerReportColumnId.Withdrawal
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.withdrawal;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevSharePartnerReportColumnId.NewWithdrawal, {
    id: RevSharePartnerReportColumnId.NewWithdrawal,
    header:
      mapRevSharePartnerReportColumnIdToName[
        RevSharePartnerReportColumnId.NewWithdrawal
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerRegisteredStatistic.newWithdrawal;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevSharePartnerReportColumnId.NewProfit, {
    id: RevSharePartnerReportColumnId.NewProfit,
    header:
      mapRevSharePartnerReportColumnIdToName[
        RevSharePartnerReportColumnId.NewProfit
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerRegisteredStatistic.newProfit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevSharePartnerReportColumnId.DepositFee, {
    id: RevSharePartnerReportColumnId.DepositFee,
    header:
      mapRevSharePartnerReportColumnIdToName[
        RevSharePartnerReportColumnId.DepositFee
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.depositFee;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevSharePartnerReportColumnId.WithdrawalFee, {
    id: RevSharePartnerReportColumnId.WithdrawalFee,
    header:
      mapRevSharePartnerReportColumnIdToName[
        RevSharePartnerReportColumnId.WithdrawalFee
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.withdrawalFee;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevSharePartnerReportColumnId.AdminFee, {
    id: RevSharePartnerReportColumnId.AdminFee,
    header:
      mapRevSharePartnerReportColumnIdToName[
        RevSharePartnerReportColumnId.AdminFee
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.adminFee;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevSharePartnerReportColumnId.Bonus, {
    id: RevSharePartnerReportColumnId.Bonus,
    header:
      mapRevSharePartnerReportColumnIdToName[
        RevSharePartnerReportColumnId.Bonus
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.bonus;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevSharePartnerReportColumnId.TournamentPrize, {
    id: RevSharePartnerReportColumnId.TournamentPrize,
    header:
      mapRevSharePartnerReportColumnIdToName[
        RevSharePartnerReportColumnId.TournamentPrize
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.tournamentPrize;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevSharePartnerReportColumnId.Jackpot, {
    id: RevSharePartnerReportColumnId.Jackpot,
    header:
      mapRevSharePartnerReportColumnIdToName[
        RevSharePartnerReportColumnId.Jackpot
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.playerStatistic.jackpot;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevSharePartnerReportColumnId.NGR, {
    id: RevSharePartnerReportColumnId.NGR,
    header:
      mapRevSharePartnerReportColumnIdToName[RevSharePartnerReportColumnId.NGR],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerStatistic.financialIndicators.ngr;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevSharePartnerReportColumnId.Profit, {
    id: RevSharePartnerReportColumnId.Profit,
    header:
      mapRevSharePartnerReportColumnIdToName[
        RevSharePartnerReportColumnId.Profit
      ],
    cell: ({ row }) => {
      const { amount, currency } =
        row.original.playerStatistic.financialIndicators.profit;

      return formatSumWithCurrency(amount, currency);
    },
  }),
  columnHelper.accessor(RevSharePartnerReportColumnId.Upcoming, {
    id: RevSharePartnerReportColumnId.Upcoming,
    header:
      mapRevSharePartnerReportColumnIdToName[
        RevSharePartnerReportColumnId.Upcoming
      ],
    cell: ({ row }) => {
      const { amount, currency } = row.original.affiliateUpcomingReward;

      return formatSumWithCurrency(amount, currency);
    },
  }),
];

export const useGetRevSharePartnerReportColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
