export enum MediaCampaignsColumnId {
  Id = 'id',
  Title = 'name',
  Url = 'url',
  CreatedAt = 'createdAt',
}

export const mapMediaCampaignsColumnIdColumnToName: Record<
  MediaCampaignsColumnId,
  string
> = {
  [MediaCampaignsColumnId.Id]: 'ID',
  [MediaCampaignsColumnId.Title]: 'Название',
  [MediaCampaignsColumnId.Url]: 'URL',
  [MediaCampaignsColumnId.CreatedAt]: 'Создан',
};

export const columnsIds = [
  MediaCampaignsColumnId.Id,
  MediaCampaignsColumnId.Title,
  MediaCampaignsColumnId.Url,
  MediaCampaignsColumnId.CreatedAt,
];
