import { createColumnHelper } from '@tanstack/react-table';
import NowrapCell from 'ui/Table/components/NowrapCell';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { MultilineCellWithLink } from 'src/ui/Table/components/MultilineCellWithLink';
import mayBeNullCell from 'ui/Table/components/MayBeNullCell/MayBeNullCell';
import MultilineCellWithIcon from 'ui/Table/components/MultilineCellWithIcon';
import { Route } from 'src/router/routes.const';
import { ColumnsSource } from 'ui/Table/types';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import {
  columnsIds,
  mapQualifiedReferralsColumnIdToName,
  QualifiedReferralsColumnId,
} from '../const';
import { QualifiedReferralReviewResult } from '../components/QualifiedReferralReviewResult';
import { QualifiedReferralData } from '../../../queries/generated/QualifiedReferralData';
import { QualifiedReferralActionButtons } from '../components/QualifiedReferralActionButtons';
import { SuspiciousCellWrapper } from '../components/SuspiciousCellWrapper';
import { getAffiliateInfo, getQualifiedReferralStatus } from '../helpers';

const columnHelper = createColumnHelper<QualifiedReferralData>();

const columnsSource: ColumnsSource<QualifiedReferralData> = [
  columnHelper.accessor(QualifiedReferralsColumnId.SuspectState, {
    id: QualifiedReferralsColumnId.SuspectState,
    header:
      mapQualifiedReferralsColumnIdToName[
        QualifiedReferralsColumnId.SuspectState
      ],
    cell: ({ row }) => {
      const { id, suspectState } = row.original;

      return <SuspiciousCellWrapper id={id} suspectState={suspectState} />;
    },
  }),
  columnHelper.accessor(QualifiedReferralsColumnId.Id, {
    id: QualifiedReferralsColumnId.Id,
    header: mapQualifiedReferralsColumnIdToName[QualifiedReferralsColumnId.Id],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnHelper.accessor(QualifiedReferralsColumnId.Player, {
    id: QualifiedReferralsColumnId.Player,
    header:
      mapQualifiedReferralsColumnIdToName[QualifiedReferralsColumnId.Player],
    cell: ({ row }) => {
      const { id, name, email, phone } = row.original.player;

      return (
        <MultilineCellWithLink
          isOpenInNewWindow
          title={name}
          route={`${Route.PLAYERS}/${id}`}
          subTitle={email || phone}
        />
      );
    },
  }),
  columnHelper.accessor(QualifiedReferralsColumnId.Affiliate, {
    id: QualifiedReferralsColumnId.Affiliate,
    header:
      mapQualifiedReferralsColumnIdToName[QualifiedReferralsColumnId.Affiliate],
    cell: ({ row }) => {
      const { email, name, route } = getAffiliateInfo(row.original.affiliate);

      return mayBeNullCell(
        email && (
          <MultilineCellWithLink
            isOpenInNewWindow
            title={name}
            route={route}
            subTitle={email}
          />
        ),
        '—'
      );
    },
  }),
  columnHelper.accessor(QualifiedReferralsColumnId.ReferralCode, {
    id: QualifiedReferralsColumnId.ReferralCode,
    header:
      mapQualifiedReferralsColumnIdToName[
        QualifiedReferralsColumnId.ReferralCode
      ],
  }),
  columnHelper.accessor(QualifiedReferralsColumnId.CPATariff, {
    id: QualifiedReferralsColumnId.CPATariff,
    header:
      mapQualifiedReferralsColumnIdToName[QualifiedReferralsColumnId.CPATariff],
  }),
  columnHelper.accessor(QualifiedReferralsColumnId.Status, {
    id: QualifiedReferralsColumnId.Status,
    header:
      mapQualifiedReferralsColumnIdToName[QualifiedReferralsColumnId.Status],
    cell: ({ row }) => {
      const [iconConfig, statusText] = getQualifiedReferralStatus(
        row.original.status
      );

      return (
        <MultilineCellWithIcon iconConfig={iconConfig} title={statusText} />
      );
    },
  }),
  columnHelper.accessor(QualifiedReferralsColumnId.ReviewResult, {
    id: QualifiedReferralsColumnId.ReviewResult,
    header:
      mapQualifiedReferralsColumnIdToName[
        QualifiedReferralsColumnId.ReviewResult
      ],
    cell: ({ row }) => (
      <QualifiedReferralReviewResult reviewResult={row.original.review} />
    ),
  }),
  columnHelper.accessor(QualifiedReferralsColumnId.CreatedAt, {
    id: QualifiedReferralsColumnId.CreatedAt,
    header:
      mapQualifiedReferralsColumnIdToName[QualifiedReferralsColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
  columnHelper.accessor(QualifiedReferralsColumnId.UpdatedAt, {
    id: QualifiedReferralsColumnId.UpdatedAt,
    header:
      mapQualifiedReferralsColumnIdToName[QualifiedReferralsColumnId.UpdatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.updatedAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
  columnHelper.accessor(QualifiedReferralsColumnId.Action, {
    id: QualifiedReferralsColumnId.Action,
    header:
      mapQualifiedReferralsColumnIdToName[QualifiedReferralsColumnId.Action],
    cell: ({ row }) => (
      <QualifiedReferralActionButtons qualifiedReferral={row.original} />
    ),
  }),
];

export const useGetQualifiedReferralsColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
