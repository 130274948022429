import NowrapCell from 'ui/Table/components/NowrapCell';
import { CopyTableRow } from 'ui/CopyTableRow';
import MultilineCellWithIcon from 'ui/Table/components/MultilineCellWithIcon';
import { ColumnsSource } from 'ui/Table/types';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { createColumnHelper } from '@tanstack/react-table';
import { TableCellAlign } from 'ui/Table/const';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import { PartnerReferralLinkMediaItemsData } from '../components/PartnerReferralLinkMediaItemTable/queries/generated/PartnerReferralLinkMediaItemsData';
import {
  blockColumnsIds,
  mapPartnerReferralLinkMediaItemIdToName,
  pageColumnsIds,
  PartnerReferralLinkMediaItemColumnId,
} from '../const';

const columnHelper = createColumnHelper<PartnerReferralLinkMediaItemsData>();

const columnsSource: ColumnsSource<PartnerReferralLinkMediaItemsData> = [
  columnHelper.accessor(PartnerReferralLinkMediaItemColumnId.Id, {
    id: PartnerReferralLinkMediaItemColumnId.Id,
    header:
      mapPartnerReferralLinkMediaItemIdToName[
        PartnerReferralLinkMediaItemColumnId.Id
      ],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnHelper.accessor(PartnerReferralLinkMediaItemColumnId.Title, {
    id: PartnerReferralLinkMediaItemColumnId.Title,
    header:
      mapPartnerReferralLinkMediaItemIdToName[
        PartnerReferralLinkMediaItemColumnId.Title
      ],
  }),
  columnHelper.accessor(PartnerReferralLinkMediaItemColumnId.MediaCampaign, {
    id: PartnerReferralLinkMediaItemColumnId.MediaCampaign,
    header:
      mapPartnerReferralLinkMediaItemIdToName[
        PartnerReferralLinkMediaItemColumnId.MediaCampaign
      ],
    cell: ({ row }) => {
      const { mediaCampaign } = row.original;

      return (
        mediaCampaign &&
        MultilineCellWithIcon({
          title: mediaCampaign.name,
          subTitle: mediaCampaign.url,
        })
      );
    },
  }),
  columnHelper.accessor(PartnerReferralLinkMediaItemColumnId.RewardTariff, {
    id: PartnerReferralLinkMediaItemColumnId.RewardTariff,
    header:
      mapPartnerReferralLinkMediaItemIdToName[
        PartnerReferralLinkMediaItemColumnId.RewardTariff
      ],
  }),
  columnHelper.accessor(PartnerReferralLinkMediaItemColumnId.ReferralLink, {
    id: PartnerReferralLinkMediaItemColumnId.ReferralLink,
    header:
      mapPartnerReferralLinkMediaItemIdToName[
        PartnerReferralLinkMediaItemColumnId.ReferralLink
      ],
    cell: ({ row }) => (
      <CopyTableRow
        value={row.original.referralLink}
        toastText="Реферальная ссылка скопирована"
      />
    ),
  }),
  columnHelper.accessor(PartnerReferralLinkMediaItemColumnId.PostbackCount, {
    id: PartnerReferralLinkMediaItemColumnId.PostbackCount,
    header:
      mapPartnerReferralLinkMediaItemIdToName[
        PartnerReferralLinkMediaItemColumnId.PostbackCount
      ],
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnHelper.accessor(PartnerReferralLinkMediaItemColumnId.CreatedAt, {
    id: PartnerReferralLinkMediaItemColumnId.CreatedAt,
    header:
      mapPartnerReferralLinkMediaItemIdToName[
        PartnerReferralLinkMediaItemColumnId.CreatedAt
      ],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.DefaultDateOld)}
      </NowrapCell>
    ),
  }),
];

export const useGetPartnerReferralLinkMediaItemColumns = (
  isFullScreenTable: boolean
) => {
  const columns = useGetTableColumns(
    isFullScreenTable ? pageColumnsIds : blockColumnsIds,
    columnsSource
  );

  return {
    columns,
  };
};
