import { FC } from 'react';
import { withBlockProvider } from 'ui/Block/store';
import { useLocation } from 'react-router-dom';
import DocumentsList from './components/DocumentsList';

interface DocumentsBlockProps {
  id: string;
}

const DocumentsBlock: FC<DocumentsBlockProps> = ({ id }) => {
  const location = useLocation();

  return (
    <DocumentsList
      playerId={id}
      isFullScreenBlock={false}
      routeToCustomPage={`${location.pathname}/documents`}
    />
  );
};

export default withBlockProvider(DocumentsBlock);
