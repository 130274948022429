export enum MessagesColumnId {
  Id = 'id',
  Text = 'text',
  Author = 'author',
  CreatedAt = 'createdAt',
}

export const mapMessagesIdToName: Record<MessagesColumnId, string> = {
  [MessagesColumnId.Id]: 'ID',
  [MessagesColumnId.Text]: 'Текст',
  [MessagesColumnId.Author]: 'Автор',
  [MessagesColumnId.CreatedAt]: 'Создан',
};

export const blockColumnsIds = [
  MessagesColumnId.Text,
  MessagesColumnId.CreatedAt,
];

export const pageColumnsIds = [
  MessagesColumnId.Id,
  MessagesColumnId.Text,
  MessagesColumnId.Author,
  MessagesColumnId.CreatedAt,
];
