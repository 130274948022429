import { useCallback, useMemo } from 'react';
import { useBlockComponentState } from 'ui/Block/hooks/useBlockComponentState';
import { useBlockTableComponentState } from 'ui/Block/BlockTable/helpers';
import {
  getCountByWindowHeight,
  Heights,
} from 'src/utils/getCountByWindowHeight';
import { GeneralReportOrderBy, OrderDirection } from 'generatedGraphql';
import { Currency } from 'commonTypes';
import { useGetRevShareGeneralReportLazyQuery } from '../queries/generated/GetRevShareGeneralReport';
import { useGetPeriodOfStatistic } from '../../../hooks/useGetPeriodOfStatistic';

const COUNT_PER_PAGE = 10;
const cellHeight = Heights.defaultCellHeight;

export const useGetRevShareGeneralReport = () => {
  const period = useGetPeriodOfStatistic();

  const [
    loadRevShareGeneralReport,
    { data, loading, error, fetchMore, refetch },
  ] = useGetRevShareGeneralReportLazyQuery({
    variables: {
      currency: Currency.Usd,
      first: getCountByWindowHeight(cellHeight, 0),
      period,
      ordering: {
        orderBy: GeneralReportOrderBy.AllDeposit,
        direction: OrderDirection.Desc,
      },
    },
  });

  useBlockComponentState({
    loadData: loadRevShareGeneralReport,
    loading,
    error,
  });

  const revShareGeneralReport = data?.revShareGeneralReport;
  const edges = revShareGeneralReport?.edges;
  const total = revShareGeneralReport?.total;
  const hasTotalRow = !!total;
  const endCursor = revShareGeneralReport?.pageInfo.endCursor;
  const hasNextPage = revShareGeneralReport?.pageInfo.hasNextPage;

  const revShareGeneralReportList = useMemo(() => {
    const report = edges?.map(({ node }) => node);

    if (hasTotalRow) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      report?.unshift(total);
    }

    return report;
  }, [edges, hasTotalRow, total]);

  const fetchMoreCallback = useCallback(
    () =>
      fetchMore(
        {
          variables: {
            first: COUNT_PER_PAGE,
            after: endCursor,
          },
        },
        { shouldShowErrorToast: false }
      ),
    [endCursor, fetchMore]
  );

  useBlockTableComponentState({
    data,
    fetchMoreCallback,
    refetch,
    loading,
    hasNextPage,
    isFullScreenTable: true,
  });

  return {
    revShareGeneralReportList,
    hasTotalRow,
    refetch,
  };
};
