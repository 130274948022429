import { PlayerState } from 'generatedGraphql';
import { playerRankMap } from 'src/const';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { Marker } from 'commonComponents/ClientHeader/types';
import { formatDate } from '../../../../../utils/formatDate';
import styles from '../styles/PlayerHeader.module.scss';
import { usePlayerEdit } from './usePlayerEdit';
import { useAuth, UserPrivilege } from '../../../../../components/Auth';
import { PlayerBasicData } from '../../../../../queries/generated/PlayerBasicData';

export const useMarkers = (player: PlayerBasicData): Array<Marker> => {
  const { rank, rankInProgress, deletedAt, twoFactorEnabled, state, trusted } =
    player;

  const { updatePlayerRank, resetPlayerTwoFactor, signInBlocked, unDeletion } =
    usePlayerEdit({ player });

  const auth = useAuth();
  const isDisabled = (privileges: Array<UserPrivilege>) =>
    !auth.privileges.isSomeGranted({
      privileges,
    });

  return [
    {
      id: 'signInRestriction',
      content: 'Заблокирован',
      isHidden: state !== PlayerState.Banned,
      onClick: signInBlocked,
      isDisabled: isDisabled([
        UserPrivilege.DeletePlayer,
        UserPrivilege.CancelSignInRestriction,
      ]),
    },
    {
      id: 'active',
      content: 'Активен',
      onClick: signInBlocked,
      isHidden: state !== PlayerState.Active,
      isDisabled: isDisabled([
        UserPrivilege.DeletePlayer,
        UserPrivilege.CreateSignInRestriction,
      ]),
    },
    {
      id: 'deleted',
      content:
        state === PlayerState.WillBeDeleted
          ? `Удаление ${deletedAt ? formatDate(deletedAt) : ''}`
          : 'Удален',
      onClick: unDeletion,
      isHidden:
        state !== PlayerState.WillBeDeleted && state !== PlayerState.Deleted,
      isDisabled: isDisabled([UserPrivilege.UndeletePlayer]),
    },
    {
      id: 'trustedPlayer',
      content: trusted && 'Доверенный',
      isHidden: !trusted,
    },
    {
      id: 'unTrustedPlayer',
      content: !trusted && 'Не доверенный',
      isHidden: trusted,
    },
    {
      id: 'twoFactorEnable',
      content: twoFactorEnabled && '2FA включена',
      isHidden: !twoFactorEnabled,
      onClick: resetPlayerTwoFactor,
      isDisabled: isDisabled([UserPrivilege.ResetPlayerTwoFactor]),
    },
    {
      id: 'twoFactorDisable',
      content: !twoFactorEnabled && '2FA отключена',
      isHidden: twoFactorEnabled,
      onClick: resetPlayerTwoFactor,
      isDisabled: isDisabled([UserPrivilege.ResetPlayerTwoFactor]),
    },
    {
      id: 'rank',
      content: (
        <>
          {playerRankMap[rank] || rank}
          {player.rankInProgress !== rank && (
            <>
              &nbsp;
              <FontAwesomeIcon
                icon={faArrowRight}
                className={styles.rankArrow}
              />
              &nbsp;
              {playerRankMap[rankInProgress] || rankInProgress}
            </>
          )}
        </>
      ),
      onClick: updatePlayerRank,
      isDisabled: isDisabled([UserPrivilege.UpdatePlayerRank]),
    },
  ];
};
