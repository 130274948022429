import { TabFieldInterface } from 'ui/Tabs';
import { MediaItemType } from '../../types';

export const mediaItemsTabs: Array<TabFieldInterface<MediaItemType>> = [
  {
    id: MediaItemType.ReferralLink,
    name: <span>Ссылки</span>,
  },
  {
    id: MediaItemType.PromoCode,
    name: <span>Промокоды</span>,
  },
];
