import { FC } from 'react';
import { withBlockProvider } from 'ui/Block/store';
import { useLocation } from 'react-router-dom';
import { Route } from 'src/router/routes.const';
import { PartnerMediaCampaignsTable } from './components/PartnerMediaCampaignsTable';
import { useGetPartnerMediaCampaignsColumns } from './hooks/useGetPartnerMediaCampaignsColumns';

interface PartnerMediaCampaignsBlockProps {
  id: string;
}

export const PartnerMediaCampaignsBlock: FC<PartnerMediaCampaignsBlockProps> =
  withBlockProvider(({ id }) => {
    const location = useLocation();
    const { columns } = useGetPartnerMediaCampaignsColumns();

    return (
      <PartnerMediaCampaignsTable
        partnerId={id}
        columns={columns}
        routeToCustomPage={`${location.pathname}${Route.MEDIA_CAMPAIGNS}`}
        isFullScreenTable={false}
      />
    );
  });
