import { nbsp, rewardTariffMap } from 'src/const';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { RewardTariffType } from 'commonTypes';

export const STATISTIC_BLOCK_TITLE = 'Статистика';
export const STATISTIC_NO_DATA = `Данных за${nbsp}этот период нет`;
export const STATISTIC_TOTAL_ROW_TITLE = `Итоговый${nbsp}показатель`;
export const CPA_PARTNER_STATISTIC_BLOCK_TITLE = `Детализация по${nbsp}партнёру ${
  rewardTariffMap[RewardTariffType.Cpa]
}`;
export const REV_SHARE_PARTNER_STATISTIC_BLOCK_TITLE = `Детализация по${nbsp}партнёру ${
  rewardTariffMap[RewardTariffType.RevShare]
}`;
export const CPA_REFERRAL_LINK_REPORT_BLOCK_TITLE = `Детализация по${nbsp}реферальной ссылке ${
  rewardTariffMap[RewardTariffType.Cpa]
}`;
export const REV_SHARE_REFERRAL_LINK_REPORT_BLOCK_TITLE = `Детализация по${nbsp}реферальной ссылке ${
  rewardTariffMap[RewardTariffType.RevShare]
}`;
export const REV_SHARE_COMPANY_PARTNERS_STATISTIC_BLOCK_TITLE = `Детализация по${nbsp}компании ${
  rewardTariffMap[RewardTariffType.RevShare]
}`;
export const CPA_COMPANY_PARTNERS_STATISTIC_BLOCK_TITLE = `Детализация по${nbsp}компании ${
  rewardTariffMap[RewardTariffType.Cpa]
}`;
export const REV_SHARE_COMPANY_STATISTIC_BLOCK_TITLE = `Детализация по${nbsp}компании ${
  rewardTariffMap[RewardTariffType.RevShare]
}`;
export const CPA_COMPANY_STATISTIC_BLOCK_TITLE = `Детализация по${nbsp}компании ${
  rewardTariffMap[RewardTariffType.Cpa]
}`;
export const REV_SHARE_COMPANY_AFFILIATE_STATISTIC_BLOCK_TITLE = `Детализация по${nbsp}партнёру компании ${
  rewardTariffMap[RewardTariffType.RevShare]
}`;
export const CPA_COMPANY_AFFILIATE_STATISTIC_BLOCK_TITLE = `Детализация по${nbsp}партнёру компании ${
  rewardTariffMap[RewardTariffType.Cpa]
}`;

const today = new Date().toISOString();
const formattedToday = formatDate(today, DateFormat.DefaultDateWithDash);
const firstDayOfMonth = new Date(new Date().setDate(1)).toISOString();
const formattedFirstDayOfMonth = formatDate(
  firstDayOfMonth,
  DateFormat.DefaultDateWithDash
);

export const statisticPeriod: {
  firstDayOfCurrentMonth: string;
  currentDay: string;
} = {
  firstDayOfCurrentMonth: formattedFirstDayOfMonth,
  currentDay: formattedToday,
};
