import { createColumnHelper } from '@tanstack/react-table';
import NowrapCell from 'ui/Table/components/NowrapCell';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { MultilineCellWithLink } from 'src/ui/Table/components/MultilineCellWithLink';
import mayBeNullCell from 'ui/Table/components/MayBeNullCell/MayBeNullCell';
import { ColumnsSource } from 'ui/Table/types';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import {
  columnsIds,
  mapQualifiedReferralConfirmationSettingsColumnIdToName,
  QualifiedReferralConfirmationSettingsColumnId,
} from '../const';
import { QualifiedReferralConfirmationSettingData } from '../../../queries/generated/QualifiedReferralConfirmationSettingData';
import { getAffiliateInfo } from '../helpers';
import { qualifiedReferralConfirmationSettingTypeMap } from '../../../const';

const columnHelper =
  createColumnHelper<QualifiedReferralConfirmationSettingData>();

const columnsSource: ColumnsSource<QualifiedReferralConfirmationSettingData> = [
  columnHelper.accessor(QualifiedReferralConfirmationSettingsColumnId.Id, {
    id: QualifiedReferralConfirmationSettingsColumnId.Id,
    header:
      mapQualifiedReferralConfirmationSettingsColumnIdToName[
        QualifiedReferralConfirmationSettingsColumnId.Id
      ],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnHelper.accessor(
    QualifiedReferralConfirmationSettingsColumnId.SettingType,
    {
      id: QualifiedReferralConfirmationSettingsColumnId.SettingType,
      header:
        mapQualifiedReferralConfirmationSettingsColumnIdToName[
          QualifiedReferralConfirmationSettingsColumnId.SettingType
        ],
      cell: ({ row }) =>
        qualifiedReferralConfirmationSettingTypeMap[row.original.type],
    }
  ),
  columnHelper.accessor(
    QualifiedReferralConfirmationSettingsColumnId.Affiliate,
    {
      id: QualifiedReferralConfirmationSettingsColumnId.Affiliate,
      header:
        mapQualifiedReferralConfirmationSettingsColumnIdToName[
          QualifiedReferralConfirmationSettingsColumnId.Affiliate
        ],
      cell: ({ row }) => {
        const { email, name, route } = getAffiliateInfo(row.original.affiliate);

        return mayBeNullCell(
          <MultilineCellWithLink
            isOpenInNewWindow
            title={name}
            route={route}
            subTitle={email}
          />,
          '—'
        );
      },
    }
  ),
  columnHelper.accessor(
    QualifiedReferralConfirmationSettingsColumnId.ReferralCode,
    {
      id: QualifiedReferralConfirmationSettingsColumnId.ReferralCode,
      header:
        mapQualifiedReferralConfirmationSettingsColumnIdToName[
          QualifiedReferralConfirmationSettingsColumnId.ReferralCode
        ],
      cell: ({ row }) => mayBeNullCell(row.original.code, '—'),
    }
  ),
  columnHelper.accessor(QualifiedReferralConfirmationSettingsColumnId.Comment, {
    id: QualifiedReferralConfirmationSettingsColumnId.Comment,
    header:
      mapQualifiedReferralConfirmationSettingsColumnIdToName[
        QualifiedReferralConfirmationSettingsColumnId.Comment
      ],
    cell: ({ row }) => row.original.comment,
  }),
  columnHelper.accessor(
    QualifiedReferralConfirmationSettingsColumnId.CreatedAt,
    {
      id: QualifiedReferralConfirmationSettingsColumnId.CreatedAt,
      header:
        mapQualifiedReferralConfirmationSettingsColumnIdToName[
          QualifiedReferralConfirmationSettingsColumnId.CreatedAt
        ],
      cell: ({ row }) => (
        <NowrapCell>
          {formatDate(row.original.createdAt, DateFormat.FullDate)}
        </NowrapCell>
      ),
    }
  ),
  columnHelper.accessor(
    QualifiedReferralConfirmationSettingsColumnId.UpdatedAt,
    {
      id: QualifiedReferralConfirmationSettingsColumnId.UpdatedAt,
      header:
        mapQualifiedReferralConfirmationSettingsColumnIdToName[
          QualifiedReferralConfirmationSettingsColumnId.UpdatedAt
        ],
      cell: ({ row }) => (
        <NowrapCell>
          {formatDate(row.original.updatedAt, DateFormat.FullDate)}
        </NowrapCell>
      ),
    }
  ),
];

export const useGetQualifiedReferralConfirmationSettingsColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
