import { FC } from 'react';
import { withBlockProvider } from 'ui/Block/store';
import { useLocation } from 'react-router-dom';
import { Route } from 'src/router/routes.const';
import { CompanyMediaCampaignsTable } from './components/CompanyMediaCampaignsTable';
import { useGetCompanyMediaCampaignsColumns } from './components/CompanyMediaCampaignsTable/hooks/useGetCompanyMediaCampaignsColumns';

interface CompanyMediaCampaignsBlockProps {
  id: string;
}

export const CompanyMediaCampaignsBlock: FC<CompanyMediaCampaignsBlockProps> =
  withBlockProvider(({ id }) => {
    const location = useLocation();
    const { columns } = useGetCompanyMediaCampaignsColumns();

    return (
      <CompanyMediaCampaignsTable
        companyId={id}
        columns={columns}
        routeToCustomPage={`${location.pathname}${Route.MEDIA_CAMPAIGNS}`}
        isFullScreenTable={false}
      />
    );
  });
