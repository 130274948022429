import { ColumnsSource } from 'ui/Table/types';
import { createColumnHelper } from '@tanstack/react-table';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import {
  columnsIds,
  mapPartnersListIdToName,
  PartnersListColumnId,
} from '../const';
import { PartnerListData } from '../../../queries/generated/PartnerListData';
import { getTariffTypes } from '../helpers';

const columnHelper = createColumnHelper<PartnerListData>();

const columnsSource: ColumnsSource<PartnerListData> = [
  columnHelper.accessor(PartnersListColumnId.Id, {
    id: PartnersListColumnId.Id,
    header: mapPartnersListIdToName[PartnersListColumnId.Id],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnHelper.accessor(PartnersListColumnId.Name, {
    id: PartnersListColumnId.Name,
    header: mapPartnersListIdToName[PartnersListColumnId.Name],
  }),
  columnHelper.accessor(PartnersListColumnId.Email, {
    id: PartnersListColumnId.Email,
    header: mapPartnersListIdToName[PartnersListColumnId.Email],
  }),
  columnHelper.accessor(PartnersListColumnId.TariffType, {
    id: PartnersListColumnId.TariffType,
    header: mapPartnersListIdToName[PartnersListColumnId.TariffType],
    cell: ({ row }) => getTariffTypes(row.original.rewardTariffTypesInUse),
  }),
  columnHelper.accessor(PartnersListColumnId.Note, {
    id: PartnersListColumnId.Note,
    header: mapPartnersListIdToName[PartnersListColumnId.Note],
  }),
];

export const useGetPartnersListColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
