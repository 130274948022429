import React, { FC, useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import Button, { ButtonTheme } from 'ui/Button';
import Input from 'ui/Input';
import Error from 'ui/Error';
import FormRowsWrapper from 'ui/FormRowsWrapper';
import FormRow from 'ui/FormRow';
import { ApolloError } from '@apollo/client';
import { UpdateClientIdentitiesPayload } from './types';
import styles from './UpdateClientdentities.module.scss';

interface UpdateClientIdentitiesProps {
  phone?: string | null;
  email?: string | null;
  isLoading: boolean;
  error?: ApolloError;
  onUpdateClientIdentities: (values: UpdateClientIdentitiesPayload) => void;
}

const UpdateClientIdentities: FC<UpdateClientIdentitiesProps> = ({
  phone,
  email,
  onUpdateClientIdentities,
  error,
  isLoading,
}) => {
  const [initialValues, setInitialValues] =
    useState<UpdateClientIdentitiesPayload>({
      phone: null,
      email: null,
    });

  useEffect(() => {
    setInitialValues({
      phone: phone || null,
      email: email || null,
    });
  }, [phone, email]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onUpdateClientIdentities}
      enableReinitialize
    >
      <Form>
        {error && <Error error={error} />}
        <FormRowsWrapper>
          <FormRow isWide>
            <Input name="email" label="Почта" />
          </FormRow>

          <FormRow isWide>
            <Input name="phone" label="Телефон" />
          </FormRow>
        </FormRowsWrapper>

        <Button
          className={styles.editSubmitButton}
          theme={ButtonTheme.Process}
          type="submit"
          isLoading={isLoading}
        >
          Изменить
        </Button>
      </Form>
    </Formik>
  );
};

export default UpdateClientIdentities;
