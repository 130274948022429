import { FC } from 'react';
import { withBlockProvider } from 'ui/Block/store';
import { useLocation } from 'react-router-dom';
import BonusesTable from './components/BonusesTable';
import { useGetBonusesColumns } from './hooks/useGetBonusesColumns';
import { useGetPlayerReceiveGiftRestriction } from './queries/generated/GetPlayerReceiveGiftRestriction';

interface BonusBlockProps {
  id: string;
}

const BonusesBlock: FC<BonusBlockProps> = ({ id }) => {
  const location = useLocation();

  const { data } = useGetPlayerReceiveGiftRestriction({
    variables: {
      playerId: id,
    },
  });

  const receiveGiftRestriction =
    data?.player.lastRestrictions.receiveGiftRestriction;

  const { columns } = useGetBonusesColumns(false);

  return (
    <BonusesTable
      playerId={id}
      columns={columns}
      routeToCustomPage={`${location.pathname}/bonuses`}
      isFullScreenTable={false}
      receiveGiftRestriction={receiveGiftRestriction}
    />
  );
};

export default withBlockProvider(BonusesBlock);
