import {
  FC,
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Storage, PageTypeStorage } from 'src/utils/storage';
import { useSearchParams } from 'react-router-dom';
import { QUERY_PARAM_KEY } from './const';

interface SearchContextValue {
  searchValue: string;
  setSearchValue: (value: string) => void;
  saveSearch: (value: string) => void;
}

const defaultValue: SearchContextValue = {
  searchValue: '',
  setSearchValue: () => {},
  saveSearch: () => {},
};
const SearchContext = createContext<SearchContextValue>(defaultValue);

interface SearchContextProviderProps {
  children: ReactElement;
  pageType: PageTypeStorage;
  shouldUpdateUrl?: boolean;
}

export const SearchContextProvider: FC<SearchContextProviderProps> = ({
  children,
  pageType,
  shouldUpdateUrl = true,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { searchListStorage } = Storage;

  const searchParamsFromStorage = searchListStorage.getSearchParams(pageType);
  const searchParamsFromURL = searchParams.get(QUERY_PARAM_KEY);
  const predefinedSearch = searchParamsFromURL || searchParamsFromStorage || '';

  const [searchValue, setSearchValue] = useState(predefinedSearch);

  const saveSearch = useCallback(
    (value: string) => {
      searchListStorage.setSearchParams(value, pageType);

      if (shouldUpdateUrl && value) {
        setSearchParams({
          [QUERY_PARAM_KEY]: value,
        });
      }
    },
    [pageType, searchListStorage, setSearchParams, shouldUpdateUrl]
  );

  useEffect(() => {
    saveSearch(predefinedSearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(
    () => ({ searchValue, setSearchValue, saveSearch }),
    [searchValue, saveSearch]
  );

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};

export const useSearchContext = () => useContext(SearchContext);
