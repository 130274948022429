import { FC } from 'react';
import { Tabs } from 'ui/Tabs';
import { mediaItemsTabs } from './const';
import { MediaItemType } from '../../types';

interface MediaItemsTabsProps {
  currentTabId?: MediaItemType;
  setMediaItemsType: (type: MediaItemType) => void;
}

export const MediaItemsTabs: FC<MediaItemsTabsProps> = ({
  currentTabId,
  setMediaItemsType,
}) => {
  const handleTabChange = (id: MediaItemType) => {
    setMediaItemsType(id);
  };

  return (
    <Tabs
      tabs={mediaItemsTabs}
      currentTabId={currentTabId}
      onChangeTab={handleTabChange}
    />
  );
};
