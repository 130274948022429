import { FreespinStatus } from 'generatedGraphql';

export const DEFAULT_FREESPINS_COUNT = '50';
export const DEFAULT_WAGER_COUNT = 5;

export enum FreespinsColumnId {
  Id = 'id',
  Type = '__typename',
  Game = 'game',
  Count = 'count',
  Currency = 'currency',
  BonusWager = 'bonusWager',
  Status = 'status',
  MinAccountBalance = 'minAccountBalance',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export const freespinStatusMap: {
  [key in FreespinStatus]: string;
} = {
  [FreespinStatus.Active]: 'Активированы',
  [FreespinStatus.Canceled]: 'Отменены',
  [FreespinStatus.Expired]: 'Истекли',
  [FreespinStatus.Pending]: 'В\u00a0ожидании',
  [FreespinStatus.Won]: 'Использованы',
  [FreespinStatus.Unknown]: 'Unknown',
};

export enum FreespinTypeTitle {
  ForRegistration = 'Фриспины за\u00a0регистрацию',
  FromCasino = 'Фриспины от\u00a0казино',
  ByPromoCode = 'Фриспины по\u00a0промокоду',
}

export const mapFreespinsColumnsIdToName: Record<FreespinsColumnId, string> = {
  [FreespinsColumnId.Id]: 'ID',
  [FreespinsColumnId.Type]: 'Тип',
  [FreespinsColumnId.Game]: 'Слот',
  [FreespinsColumnId.Count]: 'Кол-во',
  [FreespinsColumnId.Currency]: 'Валюта',
  [FreespinsColumnId.BonusWager]: 'Вейджер',
  [FreespinsColumnId.MinAccountBalance]: 'Минимальный баланс',
  [FreespinsColumnId.Status]: 'Статус',
  [FreespinsColumnId.CreatedAt]: 'Создан',
  [FreespinsColumnId.UpdatedAt]: 'Обновлен',
};

export const blockColumnsIds = [
  FreespinsColumnId.Type,
  FreespinsColumnId.Game,
  FreespinsColumnId.Count,
  FreespinsColumnId.Currency,
  FreespinsColumnId.BonusWager,
  FreespinsColumnId.Status,
  FreespinsColumnId.CreatedAt,
];

export const pageColumnsIds = [
  FreespinsColumnId.Id,
  FreespinsColumnId.Type,
  FreespinsColumnId.Game,
  FreespinsColumnId.Count,
  FreespinsColumnId.Currency,
  FreespinsColumnId.BonusWager,
  FreespinsColumnId.MinAccountBalance,
  FreespinsColumnId.Status,
  FreespinsColumnId.CreatedAt,
  FreespinsColumnId.UpdatedAt,
];
