export enum WithdrawalsColumnId {
  Id = 'id',
  Actor = 'actor',
  Account = 'account',
  PaymentMethod = 'paymentMethod',
  AccountName = 'paymentMethod.account',
  Bank = 'paymentMethod.bank',
  Sum = 'sum',
  PaidSum = 'paidSum',
  Status = 'status',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export const mapWithdrawalsColumnIdToName: Record<WithdrawalsColumnId, string> =
  {
    [WithdrawalsColumnId.Id]: 'ID',
    [WithdrawalsColumnId.Actor]: 'Пользователь',
    [WithdrawalsColumnId.Account]: 'Счёт',
    [WithdrawalsColumnId.PaymentMethod]: 'Метод',
    [WithdrawalsColumnId.AccountName]: 'Номер счёта',
    [WithdrawalsColumnId.Bank]: 'Банк',
    [WithdrawalsColumnId.Sum]: 'Сумма',
    [WithdrawalsColumnId.PaidSum]: 'Факт. сумма',
    [WithdrawalsColumnId.Status]: 'Статус',
    [WithdrawalsColumnId.CreatedAt]: 'Создан',
    [WithdrawalsColumnId.UpdatedAt]: 'Обновлен',
  };

export const columnsIds = [
  WithdrawalsColumnId.Id,
  WithdrawalsColumnId.Actor,
  WithdrawalsColumnId.Account,
  WithdrawalsColumnId.PaymentMethod,
  WithdrawalsColumnId.AccountName,
  WithdrawalsColumnId.Bank,
  WithdrawalsColumnId.Sum,
  WithdrawalsColumnId.PaidSum,
  WithdrawalsColumnId.Status,
  WithdrawalsColumnId.CreatedAt,
  WithdrawalsColumnId.UpdatedAt,
];
