import { FC } from 'react';
import { withBlockProvider } from 'ui/Block/store';
import { useLocation } from 'react-router-dom';
import RestrictionsTable from './components/RestrictionsTable';
import { useGetRestrictionHistoryColumns } from './hooks/useGetRestrictionHistoryColumns';

interface RestrictionsBlockProps {
  id: string;
}

const RestrictionsBlock: FC<RestrictionsBlockProps> = ({ id }) => {
  const location = useLocation();

  const { columns } = useGetRestrictionHistoryColumns(false);

  return (
    <RestrictionsTable
      playerId={id}
      columns={columns}
      routeToCustomPage={`${location.pathname}/restrictions`}
      isFullScreenTable={false}
    />
  );
};

export default withBlockProvider(RestrictionsBlock);
