import { FC, useContext } from 'react';
import { BlockContext } from 'ui/Block/store';
import { ActionButton, ButtonType } from 'ui/ActionButton';
import { Checkbox } from 'ui/Checkbox';
import { RewardTariffType } from 'commonTypes';
import { Formik } from 'formik';
import styles from './StatisticBlockHeaderContent.module.scss';
import { StatisticDatePeriod } from './components/StatisticDatePeriod';

const SHOW_ALL_CPA_FIELD = 'showAllCpa';

interface StatisticBlockHeaderContentProps {
  handleRefetchClick: () => void;
  rewardTariffType?: RewardTariffType;
  showAllCpa?: (value: boolean) => void;
}
export const StatisticBlockHeaderContent: FC<
  StatisticBlockHeaderContentProps
> = ({ handleRefetchClick, showAllCpa, rewardTariffType }) => {
  const { state } = useContext(BlockContext);

  return (
    <div className={styles.headerContent}>
      <StatisticDatePeriod />
      {rewardTariffType === RewardTariffType.Cpa && (
        <Formik
          initialValues={{ [SHOW_ALL_CPA_FIELD]: false }}
          onSubmit={() => undefined}
        >
          <Checkbox
            label="Показать все CPA"
            name={SHOW_ALL_CPA_FIELD}
            onChange={(value) => {
              showAllCpa?.(value);
            }}
          />
        </Formik>
      )}
      <ActionButton
        actionType={ButtonType.Update}
        disabled={state.isLoading}
        onClick={() => handleRefetchClick()}
        dataTestId="refetch-statistic-report-btn"
      />
    </div>
  );
};
