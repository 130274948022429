export enum AffiliatesListColumnId {
  Id = 'id',
  Name = 'name',
  Note = 'note.text',
  MediaItemsCount = 'mediaItemsCount',
  CreatedAt = 'createdAt',
}

export const mapAffiliatesListIdToName: Record<AffiliatesListColumnId, string> =
  {
    [AffiliatesListColumnId.Id]: 'ID',
    [AffiliatesListColumnId.Name]: 'Имя пользователя',
    [AffiliatesListColumnId.Note]: 'Заметка',
    [AffiliatesListColumnId.MediaItemsCount]: 'Медиа элементы',
    [AffiliatesListColumnId.CreatedAt]: 'Создан',
  };

export const columnsIds = [
  AffiliatesListColumnId.Id,
  AffiliatesListColumnId.Name,
  AffiliatesListColumnId.Note,
  AffiliatesListColumnId.MediaItemsCount,
  AffiliatesListColumnId.CreatedAt,
];
