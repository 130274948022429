export enum PartnersListColumnId {
  Id = 'id',
  Name = 'name',
  Email = 'email',
  TariffType = 'rewardTariffTypesInUse',
  Note = 'note.text',
}

export const mapPartnersListIdToName: Record<PartnersListColumnId, string> = {
  [PartnersListColumnId.Id]: 'ID',
  [PartnersListColumnId.Name]: 'Имя пользователя',
  [PartnersListColumnId.Email]: 'Почта',
  [PartnersListColumnId.TariffType]: 'Модель оплаты',
  [PartnersListColumnId.Note]: 'Заметка',
};

export const columnsIds = [
  PartnersListColumnId.Id,
  PartnersListColumnId.Name,
  PartnersListColumnId.Email,
  PartnersListColumnId.TariffType,
  PartnersListColumnId.Note,
];
