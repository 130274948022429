import { FC, useState } from 'react';
import { MediaItemsReferralLink } from './components/MediaItemsReferralLink';
import { MediaItemsPromoCode } from './components/MediaItemsPromoCode';
import { MediaItemType } from './types';

export const MediaItems: FC = () => {
  const [mediaItemsType, setMediaItemsType] = useState(
    MediaItemType.ReferralLink
  );

  if (mediaItemsType === MediaItemType.PromoCode) {
    return <MediaItemsPromoCode setMediaItemsType={setMediaItemsType} />;
  }

  return <MediaItemsReferralLink setMediaItemsType={setMediaItemsType} />;
};
