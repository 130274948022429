import NowrapCell from 'ui/Table/components/NowrapCell';
import { DateFormat, formatDate } from 'src/utils/formatDate';
import { formatSumWithCurrency } from 'src/utils/accounting';
import { BoldSumCellByCompare } from 'ui/Table/components/BoldSumCellByCompare';
import MultilineCellWithIcon from 'ui/Table/components/MultilineCellWithIcon';
import mayBeNullCell from 'ui/Table/components/MayBeNullCell/MayBeNullCell';
import { MultilineCellWithLink } from 'ui/Table/components/MultilineCellWithLink';
import { rewardStatusMap } from 'src/const';
import { createColumnHelper } from '@tanstack/react-table';
import { ColumnsSource } from 'ui/Table/types';
import { TableCellAlign } from 'ui/Table/const';
import { useGetTableColumns } from 'ui/Table/hooks/useGetTableColumns';
import { addHashTagToId } from 'src/utils/addHashTagToId';
import {
  RewardsColumnId,
  mapRewardsColumnIdToName,
  columnsIds,
} from '../const';
import { RewardData } from '../../../queries/generated/RewardData';
import { getRewardStatusIcon, getAffiliateInfo } from '../../../helpers';
import { SuspiciousCellWrapper } from '../../SuspiciousCellWrapper';

const columnsHelper = createColumnHelper<RewardData>();

const columnsSource: ColumnsSource<RewardData> = [
  columnsHelper.accessor(RewardsColumnId.SuspectState, {
    id: RewardsColumnId.SuspectState,
    header: mapRewardsColumnIdToName[RewardsColumnId.SuspectState],
    cell: ({ row }) => {
      const { id, suspectState } = row.original;

      return <SuspiciousCellWrapper id={id} suspectState={suspectState} />;
    },
  }),
  columnsHelper.accessor(RewardsColumnId.Id, {
    id: RewardsColumnId.Id,
    header: mapRewardsColumnIdToName[RewardsColumnId.Id],
    cell: ({ row }) => addHashTagToId(row.original.id),
  }),
  columnsHelper.accessor(RewardsColumnId.Affiliate, {
    id: RewardsColumnId.Affiliate,
    header: mapRewardsColumnIdToName[RewardsColumnId.Affiliate],
    cell: ({ row }) => {
      const { email, route, name } = getAffiliateInfo(row.original.affiliate);

      return mayBeNullCell(
        email && (
          <MultilineCellWithLink
            isOpenInNewWindow
            title={name}
            route={route}
            subTitle={email}
          />
        ),
        '—'
      );
    },
  }),
  columnsHelper.accessor(RewardsColumnId.Balance, {
    id: RewardsColumnId.Balance,
    header: mapRewardsColumnIdToName[RewardsColumnId.Balance],
    cell: () => mayBeNullCell(null, '—'),
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnsHelper.accessor(RewardsColumnId.Status, {
    id: RewardsColumnId.Status,
    header: mapRewardsColumnIdToName[RewardsColumnId.Status],
    cell: ({ row }) => {
      const iconConfig = getRewardStatusIcon(row.original.status);
      const title = rewardStatusMap[row.original.status];

      return MultilineCellWithIcon({ title, iconConfig });
    },
  }),
  columnsHelper.accessor(RewardsColumnId.Sum, {
    id: RewardsColumnId.Sum,
    header: mapRewardsColumnIdToName[RewardsColumnId.Sum],
    cell: ({ row }) => {
      const { amount, currency } = row.original.sum;

      return formatSumWithCurrency(amount, currency);
    },
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnsHelper.accessor(RewardsColumnId.PaidSum, {
    id: RewardsColumnId.PaidSum,
    header: mapRewardsColumnIdToName[RewardsColumnId.PaidSum],
    cell: ({ row }) => {
      const { paidSum, sum } = row.original;

      return <BoldSumCellByCompare renderedSum={paidSum} comparedSum={sum} />;
    },
    meta: {
      align: TableCellAlign.Right,
    },
  }),
  columnsHelper.accessor(RewardsColumnId.CreatedAt, {
    id: RewardsColumnId.CreatedAt,
    header: mapRewardsColumnIdToName[RewardsColumnId.CreatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.createdAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
  columnsHelper.accessor(RewardsColumnId.UpdatedAt, {
    id: RewardsColumnId.UpdatedAt,
    header: mapRewardsColumnIdToName[RewardsColumnId.UpdatedAt],
    cell: ({ row }) => (
      <NowrapCell>
        {formatDate(row.original.updatedAt, DateFormat.FullDate)}
      </NowrapCell>
    ),
  }),
];

export const useGetRewardColumns = () => {
  const columns = useGetTableColumns(columnsIds, columnsSource);

  return {
    columns,
  };
};
