import { nbsp } from 'src/const';

export enum CpaGeneralReportColumnId {
  Affiliate = 'affiliate',
  Hits = 'marketingStatistic.hits',
  Hosts = 'marketingStatistic.hosts',
  Registrations = 'marketingStatistic.registrations',
  FirstDeposits = 'marketingStatistic.firstDeposits',
  HostToRegConversion = 'marketingStatistic.hostToRegConversion',
  RegToDepConversion = 'marketingStatistic.regToDepConversion',

  AllDeposit = 'playerStatistic.allDeposit',
  AverageDeposit = 'playerStatistic.averageDeposit',
  AllRedeposit = 'playerStatistic.allRedeposit',
  Redeposits = 'marketingStatistic.redeposits',
  NewDeposit = 'playerRegisteredStatistic.newDeposit',

  Withdrawal = 'playerStatistic.withdrawal',
  NewWithdrawal = 'playerRegisteredStatistic.newWithdrawal',
  NewProfit = 'playerRegisteredStatistic.newProfit',
  Hold = 'playerStatusStatistic.hold',
  Qualified = 'playerStatusStatistic.qualified',
  NotQualified = 'playerStatusStatistic.canceled',
  Approved = 'playerStatusStatistic.approved',
  Rejected = 'playerStatusStatistic.rejected',
  Paid = 'playerStatusStatistic.paid',
  Unpaid = 'playerStatusStatistic.unpaid',
  RevShare = 'playerStatusStatistic.revShare',
  NGR = 'playerStatistic.financialIndicators.ngr',
  Bonus = 'playerStatistic.bonus',
  Profit = 'playerStatistic.financialIndicators.profit',
  Upcoming = 'affiliateRewardStatistic.upcoming',
  RewardPaid = 'affiliateRewardStatistic.paid',
}

export const mapCpaGeneralReportColumnIdToName: Record<
  CpaGeneralReportColumnId,
  string
> = {
  [CpaGeneralReportColumnId.Affiliate]: 'Партнёр',
  [CpaGeneralReportColumnId.Hits]: 'Хиты',
  [CpaGeneralReportColumnId.Hosts]: 'Хосты',
  [CpaGeneralReportColumnId.Registrations]: 'Регистрации',
  [CpaGeneralReportColumnId.FirstDeposits]: 'Первые депозиты',
  [CpaGeneralReportColumnId.HostToRegConversion]: 'Host2reg',
  [CpaGeneralReportColumnId.RegToDepConversion]: 'Reg2dep',
  [CpaGeneralReportColumnId.AllDeposit]: 'Все депозиты',
  [CpaGeneralReportColumnId.AverageDeposit]: 'Средний депозит',
  [CpaGeneralReportColumnId.AllRedeposit]: 'Все редепозиты',
  [CpaGeneralReportColumnId.Redeposits]: 'Количество редепозитов',
  [CpaGeneralReportColumnId.NewDeposit]: 'Новые депозиты',
  [CpaGeneralReportColumnId.Withdrawal]: 'Выводы',
  [CpaGeneralReportColumnId.NewWithdrawal]: 'Новые выводы',
  [CpaGeneralReportColumnId.NewProfit]: 'Новый доход',
  [CpaGeneralReportColumnId.Hold]: `В${nbsp}ожидании`,
  [CpaGeneralReportColumnId.Qualified]: 'Квалифицированные',
  [CpaGeneralReportColumnId.NotQualified]: 'Неквалифицированные',
  [CpaGeneralReportColumnId.Approved]: 'Подтвержденные',
  [CpaGeneralReportColumnId.Rejected]: 'Отклонённые',
  [CpaGeneralReportColumnId.Paid]: 'Оплаченные',
  [CpaGeneralReportColumnId.Unpaid]: `Без${nbsp}оплаты`,
  [CpaGeneralReportColumnId.RevShare]: `В${nbsp}RevShare`,
  [CpaGeneralReportColumnId.NGR]: 'NGR',
  [CpaGeneralReportColumnId.Bonus]: 'Бонусы',
  [CpaGeneralReportColumnId.Profit]: 'Доход',
  [CpaGeneralReportColumnId.Upcoming]: 'Вознаграждение',
  [CpaGeneralReportColumnId.RewardPaid]: 'Выплачено',
};

export const columnsIds = [
  CpaGeneralReportColumnId.Affiliate,
  CpaGeneralReportColumnId.Hits,
  CpaGeneralReportColumnId.Hosts,
  CpaGeneralReportColumnId.Registrations,
  CpaGeneralReportColumnId.FirstDeposits,
  CpaGeneralReportColumnId.HostToRegConversion,
  CpaGeneralReportColumnId.RegToDepConversion,
  CpaGeneralReportColumnId.AllDeposit,
  CpaGeneralReportColumnId.AverageDeposit,
  CpaGeneralReportColumnId.AllRedeposit,
  CpaGeneralReportColumnId.Redeposits,
  CpaGeneralReportColumnId.NewDeposit,
  CpaGeneralReportColumnId.Withdrawal,
  CpaGeneralReportColumnId.NewWithdrawal,
  CpaGeneralReportColumnId.NewProfit,
  CpaGeneralReportColumnId.Hold,
  CpaGeneralReportColumnId.Qualified,
  CpaGeneralReportColumnId.NotQualified,
  CpaGeneralReportColumnId.Approved,
  CpaGeneralReportColumnId.Rejected,
  CpaGeneralReportColumnId.Paid,
  CpaGeneralReportColumnId.Unpaid,
  CpaGeneralReportColumnId.RevShare,
  CpaGeneralReportColumnId.NGR,
  CpaGeneralReportColumnId.Bonus,
  CpaGeneralReportColumnId.Profit,
  CpaGeneralReportColumnId.Upcoming,
  CpaGeneralReportColumnId.RewardPaid,
];
